import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { Alimento } from '../pages/Productos/Alimento';
import { Juguetes } from '../pages/Productos/Juguetes';
import { EnglishRoute } from './EnglishRoute';
import { SpanishRoute } from './SpanishRoute';

export const ProductosRouter = () => {

    const { url } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${ url }`}>
                <Redirect to={`/`} />
            </Route>   
            <SpanishRoute exact path={`${ url }/alimento`} component={ Alimento } />
            <SpanishRoute exact path={`${ url }/juguetes`} component={ Juguetes } />
            <EnglishRoute exact path={`${ url }/food`} component={ Alimento } />
            <EnglishRoute exact path={`${ url }/toys`} component={ Juguetes } />
            <Route path="*">
                <Redirect to="/" />
            </Route>
        </Switch>
    )
}
