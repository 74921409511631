/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { useKW2P } from '../../hooks/useKW2P';
import { CatalogoSAT, Venta, Empresa, Cliente } from '../../interfaces/donativosInterfaces';
import { AuthContext } from '../../context/AuthContext';
import { IsLoading } from '../ui/IsLoading';
import kw2pApi from '../../api/kw2pApi';
import Logotipo from '../../logotipo.svg';

interface Params {
  donativo: Venta | undefined,
  cliente: Cliente | null;
  closePop: () => void;
};

export const FacturarDonativo = ({ donativo, cliente, closePop}: Params) => {

  const { handleError } = useContext(AuthContext);
  const { consultarCatalogo } = useKW2P();

  const [loadingCatalogos, setLoadingCatalogos] = useState(false);
  const [usosCfdi, setUsosCfdi] = useState([]);
  const [formasPago, setFormasPago] = useState([]);
  const [empresa, setEmpresa] = useState<Empresa>();

  const usoCfdiElement = useRef<HTMLSelectElement>(null);
  const formaPagoElement = useRef<HTMLSelectElement>(null);

  const history = useHistory();

  useEffect(() => {
    if (donativo === undefined || cliente === null) {
      closePop();
    }
  }, [cliente, closePop, donativo]);
  
  const consultarCatalogosSat = async() => {
    try {
      setLoadingCatalogos(true);
      
      const { data: { data: usos_cfdi } } = await consultarCatalogo({ schema: 'sat_uso_cfdi' });
      setUsosCfdi(usos_cfdi);
      
      const { data: { data: formas_pago } } = await consultarCatalogo({ schema: 'sat_formas_pago' });
      setFormasPago(formas_pago);

      const { data: { data: empresa } } = await consultarCatalogo({
        schema: 'empresas',
        limit: 1
      });
      
      if (empresa.length) {
        setEmpresa(empresa[0]);
      }

      setLoadingCatalogos(false);

    } catch (err: any) {
      setLoadingCatalogos(false);
      handleError(err);
    }
  };

  useEffect(() => {
    consultarCatalogosSat();
  }, []);

  const handleSubmit = async() => {
    try {
      setLoadingCatalogos(true);
      if (!empresa) {
        closePop();
        Swal.fire({
          icon: 'error',
          title: 'Lo sentimos ocurrio un error...',
          text: 'Aun no se cuenta con la configuración del emisor de la factura'
        });
        setLoadingCatalogos(false);
        return;
      }

      if (!cliente?.payload.rfc ||!cliente?.payload.razon_social) {
        Swal.fire({
          title: '¡Espera!',
          text: 'Detectamos que aún no completas tus datos de facturación',
          footer:  'Seras redireccionado para completar tu perfil',
          imageUrl: Logotipo,
          imageHeight: 70, 
          imageWidth: 325
        }).then(() => {
            history.push('/mi-perfil');
        });
      }

      const body = {
        schema: 'cfdis',
        serie: donativo?.serie,
        payload: {
          encabezado: {
            sucursal_id: donativo?.payload.encabezado.sucursal_id,
            cliente_id: cliente?.id,
            empresa_id: empresa.id,
            tipo: 'I',
            forma_pago: formaPagoElement.current?.value,
            uso_cfdi_codigo: usoCfdiElement.current?.value
          },
          detalles: {
            schema: 'ventas',
            documentos: [{
              serie: donativo?.serie,
              folio: donativo?.folio
            }]
          }
        }
      };

      await kw2pApi.post<any>('documentos:crear', body);

      Swal.fire({
        title: 'Donativo facturado con éxito',
        text: 'Puedes descargar tu comprobante en el listado de donativos!',
        imageUrl: Logotipo,
        imageHeight: 70, 
        imageWidth: 325
      });

      setLoadingCatalogos(false);
      closePop();
    } catch (err: any) {
      setLoadingCatalogos(false);
      closePop();
      handleError(err);
    }

  };

  return (<Modal onHide={ closePop } dialogClassName="facturar_modal" backdrop="static" keyboard={false} show>
    <Modal.Body>
      <div className="row">
        <div className="col-12">
          <h5>Solicitar factura del donativo <b>{ donativo?.serie }{ donativo?.folio }</b></h5>
        </div>
        { !loadingCatalogos ?
          <div className="col-12">
            <label htmlFor="tamano">Uso de CFDI</label>
            <select name="tamano" id="tamano" className='form-control' ref={usoCfdiElement}>
              { usosCfdi.map((uso: CatalogoSAT) => ( <option key={uso.id} value={uso.payload.codigo}>{uso.payload.nombre}</option> ))}
            </select>
            
            <label htmlFor="tamano" className='mt-3'>Forma de Pago</label>
            <select name="tamano" id="tamano" className='form-control' ref={formaPagoElement}>
              { formasPago.map((forma: CatalogoSAT) => ( <option key={forma.id} value={forma.payload.codigo}>{forma.payload.nombre}</option> ))}
            </select>
            <button className="btn btn-callejeritos w-100" type='button' onClick={handleSubmit}>Solicitar factura</button>
          </div> :
          <IsLoading/>
        }
      </div>
      <button className="btn btn-sm btn-outline-danger mt-3" onClick={closePop}>Cancelar</button>
    </Modal.Body>
  </Modal>);
}
