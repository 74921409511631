import React from 'react';

export const AvisoPrivacidad = () => {
    return (
        <div className="container aviso__container">
            <div className="aviso__titlebox">
                <h1 className="aviso__title">Aviso de privacidad</h1>
            </div>
            <div className="aviso__content">
                <p><b>Callejeritos México</b> con nombre comercial <b>Callejeritos</b>, empresa mexicana dedicada a
                    la comercialización y distribución de alimento para mascotas, artículos, promoción de campañas
                    de esterilización y adopciones responsables.</p>
                <p>Usted con <b>Callejeritos México</b> puede estar seguro de que sus Datos Personales y la
                    información que nos proporciona motivo de la relación de negocios, contractual o laboral <b>serán
                    tratados siempre de manera segura, correcta, responsable y con ética;</b> Es por ello que ponemos
                    un especial énfasis en la protección y seguridad de los Datos Personales que nos comparten
                    nuestros Clientes, Proveedores y Colaboradores; su información es tratada con estricto rigor
                    legal. Esto con el único fin de ofrecerle un servicio de alta calidad y con el respeto que usted
                    siempre nos merece.</p>
                <p>En <b>Callejeritos México </b> estamos comprometidos con la seguridad de nuestros clientes
                    y prospectos en cumplimiento a la <b>“Ley Federal de Protección de Datos Personales en Posesión
                    de Particulares</b> (en lo sucesivo LFPDPPP), publicada el pasado 05 de Julio del 2010 en el Diario
                    Oficial de la Federación, se apega a las disposiciones normadas por esta regulación; que además se
                    encuentra en plena sincronía con el objetivo que tenemos como empresa en el desarrollo y
                    servicios especializados de empaques primarios de plástico; y por supuesto ser garantes del
                    tratamiento de sus Datos Personales. Cabe mencionar que si usted tiene interés de conocer más
                    acerca de esta legislación y de los derechos que le garantizamos, puede acceder al contenido de la
                    <b> LFPDPPP</b> a través de los portales que el propio Gobierno Federal, por conducto de la <b>Secretaría
                    de Gobernación</b>, y de la <b>Cámara de Diputados del H. Congreso de la Unión</b>, y cuyas direcciones
                    son: <a href="http://www.ordenjuridico.gob.mx" target="_blank" rel="noreferrer"><b>http://www.ordenjuridico.gob.mx</b></a> y 
                    <a href="http://www.diputados.gob.mx" target="_blank" rel="noreferrer"> <b>http://www.diputados.gob.mx</b>.</a></p>
                    <p>En relación a lo anteriormente expuesto y en cumplimiento de la <b>LFPDPPP</b> hemos establecido
                        procedimientos y señalamientos determinados para el resguardo y protección de sus datos
                        personales, por lo que ponemos a consideración de usted lo siguiente:</p>
                    <p className="m-0"><b>Responsables del Tratamiento de sus Datos Personales</b></p>
                    <p>Calljeritos designará como el Responsable del Tratamiento de sus Datos Personales al Director
                        General, Comercial y Gerente de Sistemas de Información; quien será en lo sucesivo el encargado
                        de autorizar cada uno de los movimientos de la Base de Datos tales como: actualizaciones o
                        supresión de datos almacenados.</p>
                    <p>Asimismo, se encargará de manipular y/o administrar la base de datos y se somete a las
                        condiciones de lealtad, seguridad y manejo de acuerdo a lo establecido en la LFPDPPP,
                        garantizándole a usted el uso correcto y debido de sus datos personales y de la información que a
                        la empresa le ha proporcionado.</p>
                    <p className="m-0"><b>Domicilio de Callejeritos México</b></p>
                    <p>Para efectos del presente Aviso de Privacidad, así como para cualquier asunto relacionado con el
                    mismo, se señala el domicilio ubicado en Calle Kinich Ahaw 1 entre Kabah y Balam, Aldea
                    Fracc. Aldea Zamá 77760 Tulum, Q.R.</p>
                    <p className="m-0"><b>Datos personales que podrán ser recabados para los clientes:</b></p>
                    <p><b>Callejeritos México</b> podrá recabar sus Datos Personales así como, la información que
                    a su juicio considere adecuada y precisa, para estar en condiciones de prestarle con calidad el
                    servicio que requiere usted; al solicitar su información no existe el afán de molestarle o poner en
                    peligro su integridad con un manejo negligente o doloso de sus datos; por el contrario, buscamos
                    atenderlo con calidad, contando siempre con su consentimiento y conformidad. Se entenderá
                    que usted consiente tácitamente el tratamiento de sus datos, cuando habiéndose puesto a su
                    disposición el presente Aviso de Privacidad, no manifieste su oposición.</p>
                    <p>Debido a esto, se le podrá solicitar la siguiente información cuando se trate de un cliente: Nombre
                    o Razón Social, Domicilio, Código Postal, Registro Federal de Contribuyente, Número de teléfono
                    de oficina, Número de teléfono celular, Número de teléfono particular, Correo electrónico,
                    dirección electrónica o sitio web, Firma, Correo Electrónico, Antefirma, Copia de la Credencial
                    del Instituto Federal Electoral, Número de Pasaporte, Nombre o datos del Representante Legal,
                    Ciudad y País de origen, Giro de su actividad empresarial, Número de la Matricula de su Vehículo,
                    Número de cuentas bancarias, CLABE interbancaria y Acta Constitutiva.</p>
                    <p>El tratamiento de esta información se verá limitado y condicionado al acuerdo que se establezca
                    en el lineamiento contractual celebrado con el cliente.</p>
                    <p>En caso de presentar una solicitud de crédito, se le solicitarán Datos Personales, que de acuerdo a
                    la LFPDPPP se consideran como sensibles, y son los siguientes: Percepción Salarial o Ingresos,
                    Historial Crediticio, Autorización para Investigación en Buró de Crédito, en algunos de los casos
                    se hace una verificación en sitio de bienes inmuebles que manifiesta el solicitante del crédito y
                    debido a que se recaba la copia de su credencial de elector, la huella digital, por lo tanto <>Callejeritos México</> solicitará, solo en este caso, su Consentimiento Expreso para el
                    Tratamiento de sus Datos Personales en los formularios de Solicitud de Crédito.</p>
                    <p className="m-0"><b>Finalidad del Tratamiento de Datos Personales para clientes:</b></p>
                    <p>Los datos personales y la información que <b>Callejeritos México</b> recabe, serán utilizados
                    única y exclusivamente para: El correcto seguimiento de los servicios brindados de acuerdo a
                    nuestras actividades principales; para la debida facturación del pago del servicio o producto
                    contratado; identificación y verificación al momento de contratar algún servicio; contacto para el
                    seguimiento de la entrega y recepción del servicio o productos adquiridos; gestionar el cobro de
                    adeudos o pagos pendientes; registro de ingreso a nuestras instalaciones y el control
                    administrativo adecuado; realizar encuestas de calidad y monitoreo de llamadas grabadas hechas
                    al área de Tele marketing para validar el seguimiento y eficacia de nuestros servicios
                    proporcionados a distancia; control y seguimiento a través de nuestra página web
                    (www.callejeritos.mx); generar pólizas de garantía; y contar con un directorio de todos nuestros
                    clientes a los que les servimos.
                    Cabe destacar que sus datos personales así como su información, estarán resguardados por
                    nuestra empresa por 5 años contados a partir del primer día en que solicite la prestación de un
                    servicio conforme lo señala la LFPDPPP.</p>
                    <p className="m-0"><b>Datos personales que podrán ser recabados para los empleados y su finalidad:</b></p>
                    <p><b>Callejeritos México</b> Podrá recabar sus datos personales así como la información que a
                    su juicio considere adecuada y precisa para la selección del personal y su debida contratación. Por
                    tanto, se le podrá solicitar la siguiente información: Nombre, Registro Federal de Contribuyentes,
                    Domicilio Particular, Teléfono Celular, Teléfono Particular, CURP, Correo Electrónico,
                    Comprobante de Domicilio, Estado Civil, Género, Datos académicos, Trayectoria educativa,
                    Cédula Profesional, Titulo Profesional, Nombre de beneficiarios de Nómina, Nombre de los
                    Padres, Carta de no antecedentes penales, Datos de INFONAVIT, Firma, Huella Digital,
                    Fotografía, Número del IFE, Número de Licencia de Conducir, Número de la Matricula de su
                    Vehículo, Número de Seguridad Social, Número de pasaporte, Número de FM3 y Número de
                    Afore, Lugar de nacimiento, Edad, Referencias personales y Currículum Vitae.</p>
                    <p>También se solicitarán datos, que de acuerdo a la LFPDPPP se consideran como <b>sensibles</b>, y son
                    los siguientes: Historial clínico, Información de salud Psicológica, Datos biométricos, Tipo de
                    Sangre.</p>
                    <p>Huella digital, Debido a que se recaba la copia de su credencial de elector, la huella digital, Estudio
                    socioeconómico y psicométrico, Cuenta bancaria para pago de nómina; por lo tanto <b>Callejeritos México</b>, solicitará su Consentimiento Expreso para el Tratamiento de sus Datos
                    Personales a la firma de su Contrato Laboral respectivo.</p>
                    <p>Los datos personales y la información que <b>Callejeritos México</b> recabe, <b>serán utilizados
                    única y exclusivamente</b> para: Relación y control laboral, Capacitación, identificación y
                    verificación al momento de contratar; identificación, control administrativo; verificación y
                    control al momento de acceso a las instalaciones; y contar con un Expediente laboral.</p>
                    <p>Cabe destacar que sus datos personales así como su información, estarán resguardados por
                    nuestra empresa por 10 años contados a partir del primer día en que solicite la prestación de un
                    servicio conforme lo señala la <b>LFPDPPP</b>.</p>
                    <p className="m-0"><b>Datos personales que podrán ser recabados para los proveedores y su finalidad:</b></p>
                    <p>En caso de que usted sea proveedor, persona física o jurídica, de <b>Callejeritos México</b>,
                    le será igualmente aplicable nuestro Aviso de Privacidad aquí contenido; solo el tratamiento de
                    su información puede verse limitada a los acuerdos que se establezcan en el lineamiento
                    contractual celebrado con usted.</p>
                    <p>Bajo el término anterior <b>Callejeritos México</b> podrá recabar Datos Personales así como
                    la información que a su juicio considere adecuada y precisa para formalizar la relación comercial
                    con proveedores y su debida contratación. Por tanto, se le podrá solicitar la siguiente
                    información: Nombre, Registro Federal de Contribuyentes, Domicilio, Teléfono Celular, Teléfono
                    Particular, Correo Electrónico, Currículum de la Empresa; del Representante Legal requerimos:
                    Credencial del Instituto Federal Electoral, Nacionalidad, Firma, Currículum de la empresa, Copias
                    cotejadas del Acta Constitutiva y Copias cotejadas del Mandato.</p>
                    <p>Los datos personales y la información que <b>Callejeritos México</b> recabe de sus
                    proveedores, <b>serán utilizados única y exclusivamente</b> para: Formalizar la Relación Comercial
                    y/o Contractual del caso; e integrar el debido expediente de proveedor.</p>
                    <p>Cabe destacar que sus datos personales así como su información, estarán resguardados por
                    nuestra empresa por 10 años contados a partir del primer día en que solicite la prestación de un
                    servicio, conforme lo señala la LFPDPPP.</p>
                    <p className="m-0"><b>Datos personales que podrán ser recabados por nuestro sistema de video vigilancia y su finalidad:</b></p>
                    <p>Le informamos que contamos con sistema de video vigilancia instalado en las áreas de ingreso,
                    salida, oficinas y almacén de nuestras instalaciones, las imágenes obtenidas serán utilizadas
                    exclusivamente para garantizar la seguridad del espacio y evitar la posible comisión de un delito.</p>
                    <p className="m-0"><b>Datos personales que podrán ser recabados en nuestro sitio web y su finalidad:</b></p>
                    <p>Podemos recabar por este medio su nombre, teléfono, domicilio, ciudad, país de origen y su
                    dirección de correo electrónico, con la finalidad de establecer contacto con usted y poder
                    ofrecerle los productos y servicios que manejamos. No hacemos uso de cookies, únicamente
                    utilizamos un sistema de monitoreo web para obtener información y sólo nos arroja información
                    estadística en general de los visitantes a nuestra web.</p>
                    <p className="m-0"><b>Transferencias de sus Datos Personales</b></p>
                    <p><b>Callejeritos México</b> podrá transferir, de forma enunciativa pero no limitativa, la
                    información referente a sus datos personales a terceros mexicanos y extranjeros.</p>
                    {/* <p className="m-0"><b>Bancos:</b></p>
                    <div className="row mb-3">
                        <div className="col-12 col-md-6">
                            <p className="m-0">Banco Nacional de Mexico S.A.</p>
                            <p className="m-0">HSBC Mexico S.A.</p>
                            <p className="m-0">BBVA Bancomer S.A.</p>
                            <p className="m-0">Banco Regional de Monterrey S.A.</p>
                            <p className="m-0">Banca Afirme S.A.</p>
                            <p className="m-0">Banco Inbursa S.A.V</p>
                        </div>
                        <div className="col-12 col-md-6">
                            <p className="m-0">Banco del Bajío S.A. de C.V.</p>
                            <p className="m-0">Ixe Banco S.A. de C.V.</p>
                            <p className="m-0">Banco Monex S.A. de C.V.</p>
                            <p className="m-0">Banco Mercantil del Norte S.A. de C.V.</p>
                            <p className="m-0">Banco Santander S.A. de C.V.</p>
                            <p className="m-0">American Express CO. S.A. de C.V.</p>
                        </div>
                    </div>
                    <p className="m-0"><b>Despachos:</b></p>
                    <div className="row mb-3">
                        <div className="col-12 col-md-6">
                            <p className="m-0">Importaciones Hecafra S.A. de C.V.</p>
                            <p className="m-0">INTECO Consultores S.A. de C.V.</p>
                            <p className="m-0">BSI Group México S. de R.L. de C.V.</p>
                            <p className="m-0">Coll Managment Group S.A. de C.V.</p>
                            <p className="m-0">Consorcio Aduanal Serviport S.C.</p>
                            <p className="m-0">CRV LEA S.C.</p>
                            <p className="m-0">Despachos Aduanales Integrales S.C.</p>
                            <p className="m-0">Integración Jurídica Asesores S.C.</p>
                            <p className="m-0">Kalisch Alonso Agencias Aduanales S.C.</p>
                            <p className="m-0">Klayware S.A. de C.V.</p>
                            <p className="m-0">Intersexion Guadalajara, S.C.</p>
                            <p className="m-0">Risk Management de México S.A. de C.V.</p>
                            <p className="m-0">WebPac Limited</p>
                            <p className="m-0">Agencia de consultoria SC</p>
                            <p className="m-0">XCF, S.A. de C.V.</p>
                            <p className="m-0">Centro Universitario Enrique Díaz de León A.C.</p>
                        </div>
                        <div className="col-12 col-md-6">
                           <p className="m-0">American Chamber of Commerce of Mexico</p>
                           <p className="m-0">Arcsa Servicios S.A. de C.V.</p>
                           <p className="m-0">ASIC Linux S.a. de C.V</p>
                           <p className="m-0">Camara Nacional de Comercio</p>
                           <p className="m-0">Centro Mexicano para la Filantropia AC</p>
                           <p className="m-0">Consejo de Camaras Industriales</p>
                           <p className="m-0">Dun and Bradstreet S.A. SIC</p>
                           <p className="m-0">GS Logistics S.A. de C.V.</p>
                           <p className="m-0">Singular Companies S.A. de C.V.</p>
                           <p className="m-0">Soltegra Grupo Consultor S.C.</p>
                           <p className="m-0">Informes Mexico S.A. de C.V.</p>
                           <p className="m-0">Integración Juridica Asesores S.C.</p>
                           <p className="m-0">Intrategia Servicios Integrales S.C.</p>
                           <p className="m-0">Neubox Internet S.A. de C.V.</p>
                           <p className="m-0">SEA Innovacion S de R.L. de C.V.</p>
                           <p className="m-0">Toka Internacional S.A.P.I. de C.V. SOFOM E.N.R.</p>
                           <p className="m-0">Tecnología Interactiva de Occidente S.A. de C.V.</p>
                           <p className="m-0">Sanchez &amp; Carranza Abogados S.C.</p>
                        </div>
                    </div>
                    <p className="m-0"><b>Reclutamiento y Selección de Personal:</b></p>
                    <div className="row mb-3">
                        <div className="col-12 col-md-6">
                            <p className="m-0">Manpower S.A. de C.V.</p>
                            <p className="m-0">Especialistas En Reclutamiento Mexico S.R.L. de C.V.</p>
                            <p className="m-0">Entreprise Adecco, S.A. de C.V.</p>
                            <p className="m-0">Performance Adecco, S.A. de C.V.</p>
                            <p className="m-0">Reussite Adecco, S.A. de C.V.</p>
                            <p className="m-0">Emprexitus S.A. de C.V.</p>
                        </div>
                        <div className="col-12 col-md-6">
                            <p className="m-0">Outsol S.A. de C.V.</p>
                        </div>
                    </div>
                    <p className="m-0"><b>Mensajería y paquetería:</b></p>
                    <div className="row mb-3">
                        <div className="col-12 col-md-6">
                            <p className="m-0">Estafeta Mexicana S.A. de C.V.</p>
                            <p className="m-0">UPS SCS México S.A. de C.V.</p>
                            <p className="m-0">Servicios Comerciales Amazon México S. de R.L. de C.V.</p>
                        </div>
                        <div className="col-12 col-md-6">
                            <p className="m-0">DHL Express México S.A. de C.V.</p>
                            <p className="m-0">Federal Express Holdings México y Compañía</p>
                            <p className="m-0">Fedex de México S. de R.L. de C.V.</p>
                        </div>
                    </div>
                    <p className="m-0"><b>Autotransportes:</b></p>
                    <div className="row mb-3">
                        <div className="col-12 col-md-6">
                            <p className="m-0">Transportes Julian de Obregon S.A. de C.V.</p>
                            <p className="m-0">Transportes Pitic S.A. de C.V.</p>
                            <p className="m-0">Coordinados de Carga Paquetexpress S de R.L. C.V.</p>
                            <p className="m-0">Autolineas JR S.A. de C.V.</p>
                        </div>
                        <div className="col-12 col-md-6">
                            <p className="m-0">Autoexpress 24 Horas S.A. de C.V.</p>
                            <p className="m-0">Autolíneas Villarreal S.A. de C.V.</p>
                            <p className="m-0">Autotransportes de Carga Tres Guerras S.A. de C.V.</p>
                        </div>
                    </div>
                    <p className="m-0"><b>Instancias Gubernamentales:</b></p>
                    <div className="row mb-5">
                        <div className="col-12 col-md-6">
                            <p className="m-0">Secretaria de Hacienda y Crédito Público.</p>
                            <p className="m-0">Instituto Mexicano del Seguro Social.</p>
                            <p className="m-0">INFONAVIT</p>
                        </div>
                    </div> */}
                    {/* <p>El listado anterior es enunciativo más no limitativo.</p> */}
                    <p><b>La no transferencia de sus datos personales, en el caso de que así se requiera, puede dar como
                    resultado la posibilidad de no estar en condiciones de ofrecerle el servicio que usted nos
                    demanda.</b></p>
                    <p>Cabe mencionar, que <b>Callejeritos México procura que los terceros a los que les
                    transfiere su información y datos personales, cumplan con la Política de Privacidad de acuerdo
                    a lo establecido en la LFPDPPP.</b></p>
                    <p className="m-0"><b>Revocación del consentimiento:</b></p>
                    <p>Usted, puede revocar de forma gratuita y en cualquier momento cuando lo considere oportuno
                    su consentimiento para el tratamiento de datos personales, así como también, puede oponerse a
                    la transferencia de Información con terceros; le insistimos que la no transferencia a terceros le
                    puede traer como consecuencia el no cumplimiento del servicio que usted nos demanda.
                    Para efectuar dicha revocación es necesario que envié vía correo electrónico, su solicitud, bajo los
                    criterios determinados en el formulario correspondiente, que podrá solicitar por la misma vía,
                    por escrito con el título de mensaje electrónico: “Revocación del Consentimiento” a la dirección
                    de correo electrónico hola@callejeritos.mx y en un plazo máximo a 30 días naturales, contando
                    desde la fecha en que se recibió su solicitud se le notificará, por esta misma vía, la procedencia de
                    su petición para que se haga efectiva.</p>
                    <p className="m 0"><b>Derechos ARCO:</b></p>
                    <p>Usted, debidamente acreditado por los medios legales establecidos por las autoridades, podrá
                    limitar el uso o divulgación de sus datos personales, así como ejercer, cuando así procedan, los
                    derechos de Acceso, Rectificación, Cancelación u Oposición (Derechos ARCO) que la LFPDPPP
                    establece y prevé; para invocar dichos derechos, es necesario que se apegue a la forma
                    determinada por <b>Callejeritos México</b> sobre el procedimiento y el formulario de
                    llenado correspondiente; estos los podrá encontrar en nuestro domicilio ubicado Calle Kinich
                    Ahaw 1 entre Kabah y Balam, Aldea Fracc. Aldea Zamá 77760 Tulum, Q.R. donde podrá
                    solicitarlos. En un plazo máximo a 30 días naturales, contando desde la fecha en que se recibió su
                    solicitud se le notificará, por la vía determinada en nuestro procedimiento, de la procedencia de
                    su petición para que se haga efectiva; cabe mencionar que hacer uso de alguno de estos derechos,
                    puede traer como consecuencia la no prestación del servicio requerido.</p>
                    <p className="m-0"><b>Modificaciones al Aviso de Privacidad:</b></p>
                    <p>Se le informa que cualquier modificación a este Aviso de Privacidad, se hará a través de un
                    mensaje enviado a su correo electrónico cuando se disponga de dicho dato, o en un mensaje
                    publicado en las instalaciones de <b>Callejeritos México</b> y mediante de nuestra página
                    electrónica www.callejeritos.mx</p>
                    <p className="m-0"><b>Garantía de Confianza en el Tratamiento de Datos Personales:</b></p>
                    <p>Para garantizar el correcto resguardo de sus datos personales y para dar pleno cumplimiento a las
                        obligaciones que la Ley establece en la materia, se le informa que <b>Callejeritos México </b>
                        tiene implementadas las medidas de seguridad administrativas, técnicas, físicas necesarias y
                        suficientes para la correcta protección de los datos. Nuestra empresa se conducirá en todo
                        momento con altos niveles de responsabilidad y privacidad en el tratamiento de sus datos
                        personales; sin embargo, si usted considera que su derecho de protección de Datos Personales ha
                        sido lesionado por alguna conducta de nuestros empleados o de nuestras actuaciones o
                        respuestas; presume que en el tratamiento de sus datos personales existe alguna violación a las
                        disposiciones previstas en la Ley Federal de Protección de Datos Personales en Posesión de los
                        Particulares; entonces podrá interponer la queja o denuncia correspondiente ante el IFAI; para
                        mayor información visite <a href="http://www.ifai.org.mx/" target="_blank" rel="noreferrer">www.ifai.org.mx</a></p>
                        <p><b>Callejeritos México</b> le agradece la deferencia hacia nuestro negocio y la atención que
                        nos brindó para informarle sobre sus derechos y del compromiso que tenemos con el manejo
                        legítimo, controlado y seguro de su información.</p>
                        <p className="mt-4 mb-0">Hasta su próxima visita.</p>
                        <p className="float-right"><b>Callejeritos México</b></p>
                </div>
        </div>
    )
}
