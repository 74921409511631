import React, { useContext } from 'react';
import { productosImages } from '../../helpers/callejeritosImages';
import { UserContext } from '../../context/UserContext';

export const Juguetes = () => {

    const { languaje } = useContext(UserContext);

    return (
        <div className="div-juguetes animate__animated animate__fadeIn">
        {
            languaje === 'ES' ?
            <>
                <div className="header-juguetes">
                    <img src={ productosImages('./Juguetes/titulo.png').default } alt="Juguetes" className="img-fluid title-juguetes"/>
                </div>
                <div className="body-juguetes animate__animated animate__fadeInUp">
                    <h1 className="title">Próximamente</h1>
                    <p className="text">Estamos trabajando en diseñar lo mejor para nuestros mejores amigos.</p>
                </div>
            </>
            :
            <>
                <div className="header-juguetes">
                    <img src={ productosImages('./Juguetes/tituloEN.png').default } alt="Juguetes" className="img-fluid title-juguetesEN"/>
                </div>
                <div className="body-juguetes animate__animated animate__fadeInUp">
                    <h1 className="title">Coming Soon</h1>
                    <p className="text">We are working on designing the best for our best friends.</p>
                </div>
            </>
        }
    </div>
    )
}
