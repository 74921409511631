import React from 'react';
import { useContext } from 'react';
import { Accordion } from 'react-bootstrap';
import { contactoImages, callejeritosImages } from '../../helpers/callejeritosImages';
import { UserContext } from '../../context/UserContext';
import { TextToggler } from '../../components/ui/TextToggler';
import { DownloadButton } from '../../components/DownloadButton';
import { AuthContext } from '../../context/AuthContext';
import { RegisterForm } from '../../components/Auth/RegisterForm';
import { LoginForm } from '../../components/Auth/LoginForm';

interface Props {
    lang: string;
}

export const Unete = () => {

    const { languaje } = useContext(UserContext);
    const { status } = useContext(AuthContext);

    return (
        <div className="div-unete animate__animated animate__fadeIn">
            <div className="header-unete">
                <img src={ contactoImages(`./Unete/titulo${ languaje }.png`).default } alt="UNETE" className="img-fluid title-unete"/>
            </div>
            <div className="body-unete animate__animated animate__fadeInUp">
                <DesktopText lang={ languaje } />
                <MobileText lang={ languaje } />
                { status !== 'authenticated' &&
                    <div className="row donaciones_login">
                        <div className="col-12 col-md">
                            <h3 className="form__title">{ languaje === 'ES' ? 'Únete a Callejeritos': 'Join Callejeritos' }</h3>
                            <RegisterForm />
                        </div>
                        <div className="col-12 col-md mt-2 mt-md-0">
                            <h3 className="form__title">{ languaje === 'ES' ? 'Inicia sesión': 'Login' }</h3>
                            <LoginForm showLabels/>
                        </div>
                        <div className="col-2 d-xl-flex d-md-none d-none flex-column justify-content-center p-0 m-0">
                            <img src={ callejeritosImages('./Auth/Login/chihuahuas.png').default } alt="Cachorros" className="img-fluid"/>
                        </div>
                    </div> 
                }
            </div>
        </div>
    )
}

function DesktopText({ lang }: Props) {
    return (<div className="d-none d-lg-block">{
        lang === 'ES' ?
        <>
            <p className="title-gray">APASIONADO</p>
            <p className="text">Si eres un apasionado a la causa, te encuentras en la industria y deseas sumarte a nuestra red de voluntariado,
            servicio social, practicantes, médicos independientes para apoyo a la causa, campañas y promoción entra en contacto con nosotros.</p>
            <p className="text">Envíanos un correo a <b className="text-link">hola@callejeritos.mx</b> informando cómo te gustaría participar.</p>
            <p className="title-gray">Programa de prácticas profesionales, servicio social y voluntariado</p>
            <p className="text">Nos encontramos trabajando en desarollar un programa integral para quienes desean aprender, practicar y sensibilizar
            en la vida real, en escuelas y universidades, así como sociedad en general que nos permita generar un beneficio para todos, pero sobre
            todo para ellos... nuestros mejores amigos.</p>
            <DownloadSection lang={ lang } />
        </>
        :
        <>
            <p className="title-gray">PASSIONATE</p>
            <p className="text">If you are passionate about the cause, you are in the industry and want to join our network of volunteers, social
            service, interns, independent doctors to support the cause, campaigns and promotion, contact us.</p>
            <p className="text">Send us an email to <b className="text-link">hola@callejeritos.mx</b> informing how you would like to participate.</p>
            <p className="title-gray">PROFESSIONAL INTERNSHIP AND VOLUNTEER PROGRAM</p>
            <p className="text">We are working on developing a comprehensive program for those who want to learn, practice and raise awareness in real
            life, in schools and universities, as well as society in general that allows us to generate a benefit for everyone, but above all for them ...
            our best friends.</p>
            <DownloadSection lang={ lang } />
        </>
    }</div>);
}


const MobileText = ({ lang }: Props) => {
    return (<div className="d-block d-lg-none">{
        lang === 'ES' ?
        <Accordion defaultActiveKey="0">
            <div>
                <div className="callejeritos_accordion_header">
                    <TextToggler eventKey="0" text="APASIONADO"/>
                </div>
                <Accordion.Collapse eventKey="0">
                    <>
                        <p className="text">Si eres un apasionado a la causa, te encuentras en la industria y deseas sumarte a nuestra red de voluntariado,
                        servicio social, practicantes, médicos independientes para apoyo a la causa, campañas y promoción entra en contacto con nosotros.</p>
                        <p className="text">Envíanos un correo a <b className="text-link">hola@callejeritos.mx</b> informando cómo te gustaría participar.</p>
                    </>
                </Accordion.Collapse>
            </div>
            <div className="mt-5">
                <div className="callejeritos_accordion_header">
                    <TextToggler eventKey="1" text="Programa de prácticas profesionales, servicio social y voluntariado" />
                </div>
                <Accordion.Collapse eventKey="1">
                    <>
                        <p className="text">Nos encontramos trabajando en desarollar un programa integral para quienes desean aprender, practicar y sensibilizar
                        en la vida real, en escuelas y universidades, así como sociedad en general que nos permita generar un beneficio para todos, pero sobre
                        todo para ellos... nuestros mejores amigos.</p>
                        <DownloadSection lang={ lang } />
                    </>
                </Accordion.Collapse>
            </div>
        </Accordion>  
        :
        <Accordion defaultActiveKey="0">
            <div>
                <div className="callejeritos_accordion_header">
                    <TextToggler eventKey="0" text="PASSIONATE"/>
                </div>
                <Accordion.Collapse eventKey="0">
                    <>
                        <p className="text">If you are passionate about the cause, you are in the industry and want to join our network of volunteers, social
                        service, interns, independent doctors to support the cause, campaigns and promotion, contact us.</p>
                        <p className="text">Send us an email to <b className="text-link">hola@callejeritos.mx</b> informing how you would like to participate.</p>
                    </>
                </Accordion.Collapse>
            </div>
            <div className="mt-5">
                <div className="callejeritos_accordion_header">
                    <TextToggler eventKey="1" text="PROFESSIONAL INTERNSHIP AND VOLUNTEER PROGRAM" />
                </div>
                <Accordion.Collapse eventKey="1">
                    <>
                        <p className="text">We are working on developing a comprehensive program for those who want to learn, practice and raise awareness in real
                        life, in schools and universities, as well as society in general that allows us to generate a benefit for everyone, but above all for them ...
                        our best friends.</p>
                        <DownloadSection lang={ lang } />
                    </>
                </Accordion.Collapse>
            </div>
        </Accordion>
    }</div>);
}


const DownloadSection = ({ lang }: Props) => {

    return (<div className="recusos-files download_section">
            { lang === 'ES'
            ? <>
                <DownloadButton file="servicio_practicas_voluntariado.pdf" text="Servicio, prácticas y voluntariado"/>
                <DownloadButton file="infografia_voluntariado.pdf" text="Infografía Voluntariado"/>
            </>
            : <>
                <DownloadButton file="volunteering_and_internships.pdf" text="Volunteering and Internships"/>
                <DownloadButton file="volunteering_infographic.jpg" text="Volunteering Infographic"/>
            </>}
        </div>
    )

}