import React, { useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import kw2pApi from '../../api/kw2pApi';
import { UserContext } from '../../context/UserContext';
import { callejeritosImages, emailPattern, ReCAPTCHAAPIKEY } from '../../helpers/callejeritosImages';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Logotipo from '../../logotipo.svg';

export const Recovery = () => {

    const { languaje } = useContext(UserContext);

    const { formState: { errors }, handleSubmit, register, watch, setValue } = useForm<IFormInputs>();

    const history = useHistory();

    const onSubmit: SubmitHandler<IFormInputs> = async ({ correo }) => {
        try {

            await kw2pApi.post<any>('/usuarios:recuperar_contrasena', { correo });

            Swal.fire({
                title: 'Correo enviado',
                text: 'Hemos enviado un correo electronico para recuperar tú contraseña!',
                footer: 'Revisa tú bandeja de entrada y sigue las instrucciones',
                imageUrl: Logotipo,
                imageHeight: 70, 
                imageWidth: 325
            }).then(() => {
                history.push('/login');
            });
                
        } catch (err: any) {
            console.log({ err });
            Swal.fire({
                icon: 'error',
                title: 'Lo sentimos ocurrio un error...',
                text: JSON.stringify(err.response.data.errors, null, 2).replace(/[{}]/g, '')
            });
        }
    };

    const onChange = (value: any) => {
        setValue('captcha', value);
    };

    const disableCaptcha = !watch('captcha');


    return (
        <div className="div-recoverychange animate__animated animate__fadeIn">
            <div className="body-recoverychange animate__animated animate__fadeInUp">
                <img src={ callejeritosImages('./logotipo.png').default } alt="Callejeritos" className="img-fluid w-75"/>
                <p className="recoverychange_title mb-0">{ languaje === 'ES' ? 'Recuperar contraseña' : 'Recover password' }</p>
                <p className="recoverychange_subtitle">{ languaje === 'ES'
                    ?'Para restablecer tú contraseña ingresa la dirección de correo electrónico utilizada en el registro.'
                    :'To reset your password, enter the email address used for registration.' }</p>
                <form onSubmit={ handleSubmit(onSubmit) }>
                    <div className="row g-3">
                        <div className="col-12 mt-2">
                            <label htmlFor="correo">{ languaje === 'ES' ? 'Correo electrónico:' : 'Email:' }</label>
                            <input id="correo" className="form-control" placeholder={ languaje === 'ES' ? 'Ingresa tú correo electrónico' : 'Enter your email' } autoComplete="off"
                            { ...register('correo', { 
                                required: languaje === 'ES' ? 'El correo es requerido': 'The email is required',
                                pattern: { value : emailPattern, message: languaje === 'ES' ? 'Ingresa un correo válido': 'Enter a valid email' } }) }/>
                            { errors.correo && <small className="text-danger">{ errors.correo.message }</small> }
                        </div>
                    </div>
                    <div className="d-flex flex-column mt-2">
                        <ReCAPTCHA sitekey={ ReCAPTCHAAPIKEY } onChange={onChange} hl={ languaje === 'ES' ? 'es-419' : 'en' }/>
                        <Link to="/login" className="login_link">{ languaje === 'ES' ? 'Iniciar sesión' : 'Login' }</Link>
                        <button className="btn login_btn btn-block" disabled={ disableCaptcha } type="submit">{ languaje === 'ES' ? 'Recuperar contraseña' : 'Recover password' }</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

interface IFormInputs {
    correo   : string;
    captcha : string;
}
