import React, { useContext } from 'react'
import { Redirect, Route, useLocation } from 'react-router';
import { AuthContext } from '../context/AuthContext';

interface Props {
    exact: boolean;
    path: string;
    component: React.ComponentType<any>;
}

export const PrivateRoute = ({ component: Component ,...rest }: Props) => {

    const { status } = useContext(AuthContext);
    const location = useLocation();

    return (
        <Route { ...rest }>
            {/* @ts-ignore */}
            { status === 'authenticated' ? <Component /> : <Redirect to={{ pathname:'/login', state: { from: location } }} /> }
        </Route>
    )
};
