import React, { useContext, useEffect, useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  emailPattern,
  landingpageImages,
  ReCAPTCHAAPIKEY,
} from '../../helpers/callejeritosImages';
import { useForm, SubmitHandler } from 'react-hook-form';
import { UserContext } from '../../context/UserContext';
import { useFetch } from '../../hooks/useFetch';

export const ContactoSection = () => {
  const { languaje } = useContext(UserContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm<IFormInputs>();

  const [succes, setSucces] = useState(false);
  const [fail, setFail] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    register('captcha', { required: true });
  }, [register]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const { beDocumentoCrear } = useFetch();

  const onSubmit: SubmitHandler<IFormInputs> = (form) => {
    const body = {
      schema: 'mensajes',
      serie: '',
      payload: {
        encabezado: {
          nombre: form.nombre,
          asunto: form.asunto,
          mensaje: form.mensaje,
          email_destino: form.email,
          lang: languaje.toLowerCase(),
        },
      },
    };
    reset();
    beDocumentoCrear('documentos:crear', body)
      .then(() => {
        setSucces(true);
        setTimeout(() => {
          setSucces(false);
        }, 5000);
      })
      .catch(() => {
        setFail(true);
        setTimeout(() => {
          setFail(false);
        }, 5000);
      });
  };

  const onChange = (value: any) => {
    setValue('captcha', value);
  };

  const disableCaptcha = !watch('captcha');

  const showCaptcha = Boolean(watch('email'));

  return (
    <div className="container-form animate__animated animate__fadeIn">
      <div className="form-div">
        <p className="contacto-title">
          {languaje === 'ES' ? 'Contáctanos' : 'Contact Us'}
        </p>
        {succes && (
          <div
            className="alert alert-callejeritos alert-dismissible fade show"
            role="alert"
          >
            <strong>
              {languaje === 'ES'
                ? '¡Gracias por contactar a Callejeritos!'
                : 'Thank you for contacting Callejeritos!'}
            </strong>
            <p className="mb-0">
              {languaje === 'ES'
                ? 'Nuestro equipo estará en contacto contigo en cuanto nos sea posible.'
                : 'Our team will be in contact with you as soon as possible.'}
            </p>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        )}
        {fail && (
          <div
            className="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            {languaje === 'ES' ? (
              <>
                <p className="mb-0">
                  <strong>Lo sentimos...</strong> ocurrio un error al contactar
                  a callejeritos.
                </p>
                <p className="mb-0">
                  Nuestro equipo está trabajando en para brindarte una solución
                  lo antes posible.
                </p>
              </>
            ) : (
              <>
                <p className="mb-0">
                  <strong>We're sorry...</strong> an error occurred when
                  contacting callejeritos.
                </p>
                <p className="mb-0">
                  Our team is working to provide you with a solution as soon as
                  possible.
                </p>
              </>
            )}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row g-3 mb-3">
            <div className="col-12 col-md-4 mb-2">
              <input
                type="text"
                id="nombre"
                className="form-control"
                placeholder={languaje === 'ES' ? 'Nombre' : 'Name'}
                autoComplete="off"
                {...register('nombre', {
                  required:
                    languaje === 'ES'
                      ? 'El nombre es requerido'
                      : 'The name is required',
                })}
              />
              {errors.nombre && (
                <small className="text-danger">{errors.nombre.message}</small>
              )}
            </div>
            <div className="col-12 col-md-4 mb-2">
              <input
                id="email"
                className="form-control"
                placeholder={languaje === 'ES' ? 'Correo' : 'Email'}
                autoComplete="off"
                {...register('email', {
                  required:
                    languaje === 'ES'
                      ? 'El correo es requerido'
                      : 'The email is required',
                  pattern: {
                    value: emailPattern,
                    message:
                      languaje === 'ES'
                        ? 'Ingresa un correo válido'
                        : 'Enter a valid email',
                  },
                })}
              />
              {errors.email && (
                <small className="text-danger">{errors.email.message}</small>
              )}
            </div>
            <div className="col-12 col-md-4 mb-2">
              <input
                type="text"
                id="asunto"
                className="form-control"
                placeholder={languaje === 'ES' ? 'Asunto' : 'Subject'}
                autoComplete="off"
                {...register('asunto', {
                  required:
                    languaje === 'ES'
                      ? 'El asunto es requerido'
                      : 'The subject is required',
                })}
              />
              {errors.asunto && (
                <small className="text-danger">{errors.asunto.message}</small>
              )}
            </div>
            <div className="col-12 mb-2">
              <textarea
                className="form-control"
                id="mensaje"
                rows={7}
                placeholder={languaje === 'ES' ? 'Mensaje' : 'Message'}
                {...register('mensaje', {
                  required:
                    languaje === 'ES'
                      ? 'El mensaje es requerido'
                      : 'The message is required',
                })}
              ></textarea>
              {errors.mensaje && (
                <small className="text-danger">{errors.mensaje.message}</small>
              )}
            </div>
          </div>
          {showCaptcha && (
            <ReCAPTCHA
              sitekey={ReCAPTCHAAPIKEY}
              onChange={onChange}
              hl={languaje === 'ES' ? 'es-419' : 'en'}
            />
          )}
          <button
            className="btn btn-callejeritos w-100"
            disabled={disableCaptcha}
          >
            {languaje === 'ES' ? 'Enviar' : 'Send'}
          </button>
        </form>
      </div>
      <div className="contacto-div">
        <p className="text font-weight-bold">
          <img
            src={landingpageImages('./Contacto/marcador.png').default}
            alt="Leer más"
            className="ml-2 mr-4"
            height="43"
          />
          Tulum, Q. Roo
          <br />
          Guadalajara, Jalisco.
        </p>
        <div className="text">
          <img
            src={landingpageImages('./Contacto/reloj.png').default}
            alt="Leer más"
            className="mr-3"
            height="43"
          />
          {languaje === 'ES' ? (
            <p>
              Esta labor no puede ni debe descansar, es un proyecto hecho con el
              corazón y pasión que trabaja 24/7, los 365 dias del año.
            </p>
          ) : (
            <p>
              This work cannot and should not rest, it is a project made with
              the heart and passion that works 24/7, 365 days a year.
            </p>
          )}
        </div>
        <div className="div-doglover">
          <p className="text font-weight-bold">
            <img
              src={landingpageImages('./Contacto/sobre.png').default}
              alt="Leer más"
              className="mr-3"
              height="43"
            />
            hola@callejeritos.mx
          </p>
          <img
            src={landingpageImages('./Contacto/doglover.png').default}
            alt="doglover"
            className="doglover"
          />
        </div>
      </div>
    </div>
  );
};

interface IFormInputs {
  nombre: string;
  email: string;
  asunto: string;
  mensaje: string;
  captcha: string;
}
