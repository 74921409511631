import React, { createContext, useEffect, useReducer } from 'react';
import Swal from 'sweetalert2';

import { ConsultarUsuariosResp, LoginResponse, ObtenerApikeyData, ObtenerApikeyResponse, Usuario } from '../interfaces/usuariosInterfaces';
import { authReducer, AuthState } from './authReducer';
import kw2pApi from '../api/kw2pApi';
import kw2pApiPublic from '../api/kw2pPublic';
import { useKW2P } from '../hooks/useKW2P';
import Logotipo from '../logotipo.svg';

type AuthContextProps = {
    errorMessage: string;
    token:  string  | null;
    user:   Usuario | null;
    apikey: string  | null;
    status: 'checking' | 'authenticated' | 'not-authenticated';
    isLoading: boolean;
    obtenerApikey: (obtenerApikeyData: ObtenerApikeyData) => void;
    iniciarSesion: (loginData: ObtenerApikeyData) => void;
    logOut: () => void;
    removeError: () => void;
    recuperarContrasena: (correo: string) => Promise<any>;
    setLoading: (loading: boolean) => void;
    obtenerUsuario: (id: number) => void;
    actualizarUsuario: (usuario: Usuario) => void;
    handleError: (err: any) => void;
    consultarCliente: (user: Usuario) => Promise<any>;
};

interface AuthProviderProps {
    children: JSX.Element | JSX.Element[]
}

const authInitialState: AuthState = {
    status: 'checking',
    token: null,
    user: null,
    apikey: null,
    errorMessage: '',
    ambito: null,
    isLoading: false
};

export const AuthContext = createContext({} as AuthContextProps);

export const AuthProvider = ({ children }: AuthProviderProps) => {

    const [state, dispatch] = useReducer(authReducer, authInitialState);

    const { consultarCatalogo } = useKW2P();

    useEffect(() => {
        checkToken();
     }, []);

    const checkToken = async() => {

        const jwt = localStorage.getItem('CA_JWT');
        !jwt &&  dispatch({ type: 'notAuthenticated' });
        

        const apikey = localStorage.getItem('CA_apikey');

        apikey && dispatch({ type: 'setApikey', payload: apikey });

        if (jwt) {
            const usuario = localStorage.getItem('CA_usuario');
            usuario && dispatch({ type: 'iniciarSesion', payload: { token: jwt, user: JSON.parse(usuario) } });
        } else {
            dispatch({ type: 'notAuthenticated' });
        }
        

    };

    const obtenerApikey = async ({ correo, contrasena }: ObtenerApikeyData) => {
        try {
            const { data: { data: { apikey } } } = await kw2pApi.post<ObtenerApikeyResponse>('/usuarios:obtener_apikey', { correo, contrasena });
            return apikey;
            
        } catch (err: any) {
            handleError(err);
        }
    };

    const iniciarSesion = async({ correo, contrasena }: ObtenerApikeyData) => {
        try {

            dispatch({ type: 'setLoading', payload: true });

            const apikey = await obtenerApikey({ correo, contrasena });

            if (!apikey) return;

            dispatch({ type: 'setApikey', payload: apikey });
            localStorage.setItem('CA_apikey', apikey);

            const { data: { data: { usuario, jwt } } } = await kw2pApi.post<LoginResponse>('/sesion:iniciar', { correo, apikey });
            dispatch({ type: 'iniciarSesion', payload: { token: jwt, user: usuario }});

            localStorage.setItem('CA_JWT', jwt);
            localStorage.setItem('CA_usuario', JSON.stringify(usuario));

            dispatch({ type: 'setLoading', payload: false });

            consultarCliente(usuario);

        } catch (err: any) {
            handleError(err);
        }
    };

    const logOut = async() => {

        try {
            await kw2pApi.post<any>('/sesion:cerrar');
            dispatch({ type: 'logout' });
            const keysToRemove = ['CA_apikey', 'CA_JWT', 'CA_usuario'];
            keysToRemove.forEach(k => localStorage.removeItem(k));

        } catch (err: any) {
            dispatch({ type: 'logout' });
            const keysToRemove = ['CA_apikey', 'CA_JWT', 'CA_usuario'];
            keysToRemove.forEach(k => localStorage.removeItem(k));
        }
    };

    const removeError = () => {
        dispatch({ type: 'removeError' });
    };

    const recuperarContrasena = async(correo: string): Promise<any> => {
        try {
            const resp = await kw2pApi.post<any>('/usuarios:recuperar_contrasena', { correo });

            dispatch({ type: 'logout' });

            if (resp.data.status !== 'success') {
                return false;
            }

            dispatch({ type: 'setLoading', payload: false });

            return true;
        } catch (err: any) {
            handleError(err);
        }
    };

    const setLoading = (loading: boolean) => dispatch({ type: 'setLoading', payload: loading });

    const obtenerUsuario = async(id: number) => {
        console.log("id",id);
        try {

            dispatch({ type: 'setLoading', payload: true });

            const payload = {
                id,
                limit: 1,
                filter: {
                    where: [
                        {
                            field: 'id',
                            operator: '=',
                            value: id
                        }
                    ]
                }
            };


            const { data:{ data } } = await kw2pApiPublic.post<ConsultarUsuariosResp>('/usuarios:consultar', payload);
            
            localStorage.setItem('CA_usuario', JSON.stringify(data[0]));
            console.log(data[0]);
            dispatch({ type: 'setUsuario', payload: data[0] });
            dispatch({ type: 'setLoading', payload: false });

        } catch (err: any) {
            console.log(err);
            handleError(err);
        }

    };

    const actualizarUsuario = async(user: Usuario) => {

        try {

            if (!user.id) {
                return;
            }

            dispatch({ type: 'setLoading', payload: true });

            await kw2pApiPublic.post<any>('/usuarios:actualizar', user);


             Swal.fire({
                icon:   'success',
                title:  'Registro actualizado',
                text:   'Se actualizaron los datos del usuario correctamente!',
                imageUrl: Logotipo,
                imageHeight: 70, 
                imageWidth: 325
            }).then(() => {
                obtenerUsuario(user.id);
            });
            
            dispatch({ type: 'setLoading', payload: false });

        } catch (err: any) {
            handleError(err);
        }

    };

    const handleError = async(err: any) => {
        dispatch({ type: 'setLoading', payload: false });
        if (err.response.data.message === 'No hay resultados de consulta [Usuario].' || err.response.data.message === 'Los datos dados no eran válidos.') {
            Swal.fire({
                icon: 'error',
                title: 'Lo sentimos ocurrio un error...',
                text: JSON.stringify(err.response.data.message, null, 2).replace(/[{}]/g, ''),
                imageUrl: Logotipo,
                imageHeight: 70, 
                imageWidth: 325
            });
        } else if (err.response.data.message === 'Token de autorizacion no encontrado.' || err.response.data.message === 'Token invalido.'
            ||  err.response.data.message === 'Token expirado.' || err.response.data.message === 'Usuario no tiene sesion activa.') {
                console.log('Sin sesion activa');
                dispatch({ type: 'addError', payload: 'Su sesión a caducado, ingrese de nuevo para continuar' });
                await logOut();
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Lo sentimos ocurrio un error...',
                text: JSON.stringify(err.response.data.message, null, 2).replace(/[{}]/g, ''),
                imageUrl: Logotipo,
                imageHeight: 70, 
                imageWidth: 325
            });
          }
    };

    const consultarCliente = async(user: Usuario) => {
        try {
            const payloadClientes = {
                schema: 'clientes',
                order: [{
                    field: 'payload->nombre',
                    direction: 'asc'
                }],
                filter: {
                    where : [
                        {
                            field    : 'payload->email',
                            operator : 'like',
                            value    : '%' + user.correo + '%',
                            collate  : 'utf8mb4_general_ci',
                        }
                    ]
                }
            };

            const { data: { data } } = await consultarCatalogo(payloadClientes);

            const usr = {
                ...user,
                cliente_id: data[0] ? data[0].id : null
            };

            localStorage.setItem('CA_usuario', JSON.stringify(usr));

            dispatch({ type: 'setUsuario', payload: usr });

            return data[0];

        } catch (err: any) {
            console.log(err);
            handleError(err);
        }
    };

    return (
        <AuthContext.Provider value={{
            ...state,
            obtenerApikey,
            iniciarSesion,
            logOut,
            removeError,
            recuperarContrasena,
            setLoading,
            obtenerUsuario,
            actualizarUsuario,
            handleError,
            consultarCliente
        }}>
            { children }
        </AuthContext.Provider>
    );

};

