import React, { useContext } from 'react';
import { equipoImages } from '../../helpers/callejeritosImages';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { UserContext } from '../../context/UserContext';

export const Patrocinadores = () => {

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };

    const CustomRight = ({ onClick }: any) => (
        <button className="arrow right" onClick={onClick}>
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            {/* <span className="visually-hidden">Siguiente</span> */}
        </button>
    );

    const CustomLeft = ({ onClick }: any) => (
        <button className="arrow left" onClick={onClick}>
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            {/* <span className="visually-hidden">Anterior</span> */}
        </button>
    );

    const { languaje } = useContext(UserContext);

    return (
        <div className="div-patrocinadores animate__animated animate__fadeIn">
            {
                languaje === 'ES' ?
                <>
                    <div className="header-patrocinadores">
                        <img src={ equipoImages('./Patrocinadores/titulo.png').default } alt="PATROCINADORES"
                            className="img-fluid title-patrocinadores"/>
                    </div>
                    <div className="body-patrocinadores animate__animated animate__fadeInUp">
                        <p className="text patrocinadores-text">Agradecemos a todas las personas y empresas que aportan de manera económica o en
                        especie, pero sobre todo la aportación más importante que es tiempo y voluntariado a la causa.</p>
                    </div>
                </>
                :
                <>
                    <div className="header-patrocinadores">
                        <img src={ equipoImages('./Patrocinadores/tituloEN.png').default } alt="PATROCINADORES" 
                            className="img-fluid title-patrocinadoresEN"/>
                    </div>
                    <div className="body-patrocinadores animate__animated animate__fadeInUp">
                        <p className="text patrocinadores-text">We thank all the people and companies that contribute financially or in kind, but
                        above all the most important contribution, which is time and volunteering, to the cause.</p>
                    </div>
                </>
            }
            <div className="container-fluid">
            {/* @ts-ignore */}
                <Carousel responsive={responsive} infinite={true} arrows={true} customRightArrow={<CustomRight />} customLeftArrow={<CustomLeft />}>
                    <div className="pat-item">
                        <a href="https://www.nudopackaging.com/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/nudo.png').default } alt="NUDO smart packaging" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="https://www.multiplastic.com.mx/inicio" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/multiplastic.png').default } alt="Multilastic" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="https://singularlaw.mx/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/singular.png').default } alt="Singular law group" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="http://www.stx.com.mx/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/stx.png').default } alt="STX" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="https://www.expedienteazul.com/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/expediente_azul.png').default } alt="Expediete Azul" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="http://www.chivacan.com.mx/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/chivacan.png').default } alt="Chivacan" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="https://www.xolonutricion.com/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/xolo.png').default } alt="XOLO" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <img src={ equipoImages('./Patrocinadores/greendog.png').default } alt="GreenDog" className="img-fluid"/>
                    </div>
                    <div className="pat-item">
                        <img src={ equipoImages('./Patrocinadores/stellar.png').default } alt="Stellar" className="img-fluid"/>
                    </div>
                    <div className="pat-item">
                        <img src={ equipoImages('./Patrocinadores/maxpet.png').default } alt="MaxPet" className="img-fluid"/>
                    </div>
                    <div className="pat-item">
                        <a href="https://www.easytrack.mx/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/easytrack.png').default } alt="Easy Track" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="https://www.miagente.mx/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/seguros_fianzas.png').default } alt="Seguros y Fianzas" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="https://www.aimww.com/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/aim.png').default } alt="AIM Worldwide" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="https://motidigital.com/index.html" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/moti.png').default } alt="Moti" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="http://www.kaoarrendadora.mx/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/kao_arrendadora.jpg').default } alt="Kao Arrendadora" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="https://lideratto.com.mx/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/lideratto.png').default } alt="Kao Arrendadora" className="img-fluid"/>
                        </a>
                    </div>
                </Carousel>
            </div>
        </div>
    )
}
