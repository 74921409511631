import { Usuario } from '../interfaces/usuariosInterfaces';

export interface AuthState {
    status:       'checking' | 'authenticated' | 'not-authenticated';
    token:        string  | null;
    errorMessage: string;
    user:         Usuario  | null;
    apikey:       string | null;
    ambito:       string | null;
    isLoading:    boolean;
}

export type AuthAction =
    | { type: 'setApikey', payload: string }
    | { type: 'iniciarSesion', payload: { token: string, user: Usuario } }
    | { type: 'addError', payload: string }
    | { type: 'removeError' }
    | { type: 'notAuthenticated' }
    | { type: 'logout' }
    | { type: 'addAmbito', payload: string }
    | { type: 'setLoading', payload: boolean }
    | { type: 'setUsuario', payload: Usuario };

export const authReducer = (state: AuthState, action: AuthAction): AuthState => {

    switch (action.type) {
        case 'addError':
            return {
                ...state,
                user: null,
                status: 'not-authenticated',
                token: null,
                errorMessage: action.payload
            };
        case 'removeError':
            return {
                ...state,
                errorMessage: ''
            };
        case 'setApikey':
            return {
                ...state,
                apikey: action.payload
            };
        case 'addAmbito':
            return {
                ...state,
                ambito: action.payload
            };
        case 'iniciarSesion':
            return {
                ...state,
                errorMessage: '',
                status: 'authenticated',
                token: action.payload.token,
                user: action.payload.user
            };
        case 'logout':
        case 'notAuthenticated':
            return {
                ...state,
                status: 'not-authenticated',
                apikey: null,
                token: null,
                user: null,
                ambito: null
            };
        case 'setLoading':
            return {
                ...state,
                isLoading: action.payload
            };
        case 'setUsuario':
            return {
                ...state,
                user: action.payload
            }
        default:
            return state;
    }

};
