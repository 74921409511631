/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react'
import { AxiosResponse } from 'axios';
import moment from 'moment';
import 'moment/locale/es-mx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

import { Loader } from '../../components/ui/Loader';
import { AuthContext } from '../../context/AuthContext';
import { useKW2P } from '../../hooks/useKW2P';
import { UserContext } from '../../context/UserContext';
import { Cliente, DonacionesResp, Venta } from '../../interfaces/donativosInterfaces';
import { FacturarDonativo } from '../../components/Donaciones/FacturarDonativo';

export const MisDonaciones = () => {

    const { user, isLoading, setLoading, handleError, consultarCliente } = useContext(AuthContext);
    const { languaje } = useContext(UserContext);
    const { consultarDocumento, downloadReport, consultarCatalogo } = useKW2P();

    const [donaciones, setDonaciones] = useState<Venta[]>([]);
    
    // Facturacion vars
    const [cliente, setCliente] = useState<Cliente|null>(null);
    const [donativo, setDonativo] = useState<Venta>();

    // Show Facturacion popup
    const [showFacturar, setShowFacturar] = useState<boolean>(false);

    const fetchData = async() => {
        
        if (!user?.correo) {
            return;
        }

        try {
            setLoading(true);
            setDonaciones([]);

            const cliente = await consultarCliente(user);
            const cliente_id = cliente ? cliente.id : null;

            if (!cliente_id) {
                setLoading(false);
                return;
            }

            setCliente(cliente);

            const { data: { data } } = await consultarDonaciones(cliente_id);
            setDonaciones(data);

            setLoading(false);
        } catch (err: any) {
            setLoading(false);
            handleError(err);
        }
    };

    const consultarDonaciones = (cliente_id: number):Promise<AxiosResponse<DonacionesResp>> => {
        const payloadVentas = {
            schema: 'ventas',
            order: [{
                field: 'folio',
                direction: 'desc'
            }],
            limit: 50,
            page: 1,
            filter: {
                where: [
                    {
                        field: 'estado',
                        operator: '=',
                        value: 'vigente'
                    },
                    {
                        field: 'payload->encabezado->sucursal_id',
                        operator: '=',
                        value: 1
                    },
                    {
                        field: 'payload->encabezado->cliente_id',
                        operator: '=',
                        value: cliente_id
                    }
                ]
            },
            "with-storage": ["*"]
        };

        return consultarDocumento(payloadVentas);
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleFacturar = (donativo: Venta) => {
        setDonativo(donativo);
        setShowFacturar(true);
    };

    const handleCloseFacturar = () => {
        setDonativo(undefined);
        setShowFacturar(false);
        fetchData();
    };

    const handleDownload = async(serie: any, folio: any, sucursal_id: number) => {
        try {
            setLoading(true);

            /* const { serie, folio, payload: { encabezado: { sucursal_id } } } = donativo; */

            const { data: { data: empresa } } = await consultarCatalogo({
                schema: 'empresas',
                limit: 1
            });

            if (!empresa.length) {
                setLoading(false);
                return;
            }

            const body = {
                reporte: 'pdf_cfdi',
                params: {
                    serie,
                    folio,
                    empresa: empresa[0].id,
                    sucursales: [
                        sucursal_id
                    ]
                },
                pdf: true
            };

            downloadReport('reportes:html', body)
            .then((resp) => {
                /* let objectURL = URL.createObjectURL(resp);
                window.open(objectURL, '_blank'); */
                const link = document.createElement('a');
                link.href = URL.createObjectURL(resp);
                link.download = `Comprobante donativo ${serie}${folio}.pdf`;
                link.click();
                setLoading(false);
            }).catch((err: any) => {
                setLoading(false);
                handleError(err);
            });


        } catch (err: any) {
            setLoading(false);
            handleError(err);
        }
    };

    return (<>
        <div className="misdonativos_container">
            <div className="misdonativos_header">
                <p>{ languaje === 'ES' ? 'Historial de mis donativos' : 'History of my donations' }</p>
            </div>
            <div className="misdonativos_body">
                <div className="table-responsive">
                    <table className="table table-sm table-hover table-striped">
                        <thead>
                            <tr>
                                <th>Folio</th>
                                <th>{ languaje === 'ES' ? 'Fecha' : 'Date' }</th>
                                <th>{ languaje === 'ES' ? 'Cliente' : 'Client' }</th>
                                <th>{ languaje === 'ES' ? 'Tipo de donativo' : 'Donation type' }</th>
                                <th>{ languaje === 'ES' ? 'Total' : 'Amount' }</th>
                                <th style={{width: '140px'}}></th>

                            </tr>
                        </thead>
                        <tbody>
                            { donaciones.map((donacion: Venta) => (
                                <tr key={ donacion.folio }>
                                    <td>{ donacion.serie }{ donacion.folio }</td>
                                    <td>{ moment(donacion.payload.encabezado.fecha).locale('es-mx').format('LL') }</td>
                                    <td>{ donacion.payload.encabezado._relaciones.cliente.payload.nombre_comercial }</td>
                                    <td>{ donacion.payload.detalles[0]._relaciones.producto.payload.nombre }</td>
                                    <td>${ (donacion.payload.movimientos.cuentas_por_cobrar[0].MONTO).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') }</td>
                                    <td>
                                        { donacion.payload.encabezado.impuestos ?
                                            donacion.payload.cfdi === undefined ?
                                                <button className="btn btn-sm btn-primary" type='button' onClick={() => handleFacturar(donacion)}>
                                                    Generar factura
                                                </button>
                                                : 
                                                <button className="btn btn-sm btn-danger" type='button'
                                                    onClick={ () => handleDownload(donacion.payload.cfdi?.serie, donacion.payload.cfdi?.folio, donacion.payload.encabezado.sucursal_id)}>
                                                       <FontAwesomeIcon icon={faFilePdf as IconProp} className="mr-2" /> Factura  
                                                </button>
                                            : <></>
                                        }
                                    </td>
                                </tr>
                            ))}
                            {
                                !donaciones.length &&
                                <tr className='text-center'>
                                        <td colSpan={6}>
                                            <b>{languaje === 'ES' ? 'No se tiene registro de donativos al día de hoy' : 'There is no record of donations as of today'}</b>
                                        </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        { isLoading && <Loader/>}
        { showFacturar && <FacturarDonativo donativo={donativo} cliente={cliente} closePop={ handleCloseFacturar }/> }
    </>)
}
