import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { footerImages } from '../../helpers/callejeritosImages';
import { Sitemap } from './Sitemap';

export const Footer = () => {

    const { facebookLink, instagramLink, languaje } = useContext(UserContext);


    return (
        <footer id="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="copyright d-flex justify-content-around">
                            <div>
                                <img src={ footerImages('./dog.png').default } alt="Callejeritos" className="img-fluid dog-footer" />
                            </div>
                            <div className="d-flex flex-column">
                                <div className="aviso-term-div">
                                    <Link className="aviso-term" to="/contacto/aviso-privacidad">
                                        { languaje === 'ES' ? 'Aviso de privacidad' : 'Privacy policies' }
                                    </Link>
                                    <img src={ footerImages('./separador.png').default } alt="huella" className="huella-img" />
                                    <p className="aviso-term">
                                        <Link className="aviso-term" to="/terminos-condiciones">
                                            { languaje === 'ES' ? 'Términos y condiciones' : 'Terms and conditions' }
                                        </Link>
                                    </p>
                                </div>
                                <div className="cop">
                                    <p className="m-0">&copy; {new Date().getFullYear()} { languaje === 'ES' ? 'Derechos Reservados' : 'All Rights Reserved' } Callejeritos</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-1">
                        <div className="social">
                            <a href={ facebookLink } target="_blank" rel="noopener noreferrer">
                                <img src={ footerImages('./facebook.png').default } alt="facebook" className="social-icon pr-3" />
                            </a>
                            <a href={ instagramLink } target="_blank" rel="noopener noreferrer">
                                <img src={ footerImages('./instagram.png').default } alt="instragram" className="social-icon pr-3" />
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-lg-5 pr-0">
                        <div className="adoptme">
                            <img src={ footerImages('./numero.png').default } alt="01-800-ADOPT ME" className="adopt-num" />
                            <img src={ footerImages('./dog-right.png').default } alt="Cute dog" className="dog-adopt" />
                        </div>
                    </div>
                    <Sitemap languaje={ languaje } />
                </div>
            </div>
        </footer>
    )
}
