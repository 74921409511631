
const callejeritosRouteList: any = {
  '/home': '/',
  '/': '/home',
  /* NOSOTROS */
  '/nosotros': '/us',
  '/nosotros/historia': '/us/history',
  '/nosotros/manifiesto': '/us/manifesto',
  '/nosotros/causa-social': '/us/social-cause',
  '/nosotros/albergues': '/us/shelters',
  '/us': '/nosotros',
  '/us/history': '/nosotros/historia',
  '/us/manifesto': '/nosotros/manifiesto',
  '/us/social-cause': '/nosotros/causa-social',
  '/us/shelters': '/nosotros/albergues',
  /* DONACIONES */
  '/donaciones': '/donations',
  '/donations': '/donaciones',
  /* MASCOTAS */
  '/mascotas/adopcion': '/pets/adoption',
  '/mascotas/rehabilitacion': '/pets/rehabilitation',
  '/pets/adoption': '/mascotas/adopcion',
  '/pets/rehabilitation': '/mascotas/rehabilitacion',
  /* PRODUCTOS */
  '/productos/alimento': '/products/food',
  '/productos/juguetes': '/products/toys',
  '/products/food': '/productos/alimento',
  '/products/toys': '/productos/juguetes',
  /* EQUIPO */
  '/equipo/patrocinadores': '/team/sponsors',
  '/equipo/alianzas-estrategicas': '/team/strategic-alliances',
  '/team/sponsors': '/equipo/patrocinadores',
  '/team/strategic-alliances': '/equipo/alianzas-estrategicas',
  /* RECURSOS */
  '/recursos':'/resources',
  '/resources':'/recursos',
  /* CONTACTO */
  '/contacto/unete-al-proyecto': '/contact/join-the-proyect',
  '/contacto/informate': '/contact/inform-yourself',
  '/contacto/programa-rumbo-a-casa': '/contact/going-home-program',
  '/contacto/aviso-privacidad': '/contact/privacy-notice',
  '/contacto/boletin-informativo': '/contact/newsletter',
  '/contact/join-the-proyect': '/contacto/unete-al-proyecto',
  '/contact/inform-yourself': '/contacto/informate',
  '/contact/going-home-program': '/contacto/programa-rumbo-a-casa',
  '/contact/privacy-notice': '/contacto/aviso-privacidad',
  '/contact/newsletter':'contacto/boletin-informativo',
  '/preguntas-frecuentes': '/faqs',
  '/faqs': '/preguntas-frecuentes'
};

export default callejeritosRouteList;