import { useContext, useRef, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import { estados } from '../../helpers/callejeritosFunciones';

interface Props {
    scope: 'adopcion' | 'rehabilitacion';
    fetchData: (where: any[]) => void;
};

export const BuscadorComponent = ({ scope, fetchData }: Props) => {

    const { languaje } = useContext(UserContext);

    const tamanoElement = useRef < HTMLSelectElement > (null);
    const sexoElement = useRef < HTMLSelectElement > (null);
    const razaElement = useRef < HTMLSelectElement > (null);
    const especieElement = useRef < HTMLSelectElement > (null);
    const conviveOtrasElement = useRef < HTMLSelectElement > (null);
    const conviveElement = useRef < HTMLSelectElement > (null);
    const conviveNinosElement = useRef < HTMLSelectElement > (null);
    const entrenadoElement = useRef < HTMLSelectElement > (null);
    const educadoElement = useRef < HTMLSelectElement > (null);
    const edadValorElement = useRef < HTMLInputElement > (null);
    const edadUnidadElement = useRef < HTMLSelectElement > (null);
    const ciudadValorElement = useRef < HTMLInputElement > (null);
    const estadoElement = useRef < HTMLSelectElement > (null);
    const desparasitacionElement = useRef < HTMLSelectElement > (null);
    const esterilizadaElement = useRef < HTMLSelectElement > (null);


    const handleFetch = () => {

        let where: any[] = [
            {
                field: 'payload->estado',
                operator: '=',
                value: scope
            }
        ];

        if (tamanoElement.current?.value) {
            where.push({
                field: 'payload->tamano',
                operator: '=',
                value: tamanoElement.current.value
            });
        }

        if (sexoElement.current?.value) {
            where.push({
                field: 'payload->sexo',
                operator: '=',
                value: sexoElement.current.value
            });
        }

        if (razaElement.current?.value) {
            where.push({
                field: 'payload->raza',
                operator: '=',
                value: razaElement.current.value
            });
        }

        if (especieElement.current?.value) {
            where.push({
                field: 'payload->especie',
                operator: '=',
                value: especieElement.current.value
            });
        }

        if (conviveOtrasElement.current?.value) {
            where.push({
                field: 'payload->convine_otras',
                operator: '=',
                value: conviveOtrasElement.current.value
            });
        };

        if (conviveElement.current?.value) {
            where.push({
                field: 'payload->convine',
                operator: '=',
                value: conviveElement.current.value
            });
        };

        if (conviveNinosElement.current?.value) {
            where.push({
                field: 'payload->convine_ninos',
                operator: '=',
                value: conviveNinosElement.current.value
            });
        };

        if (entrenadoElement.current?.value) {
            where.push({
                field: 'payload->entrenado',
                operator: '=',
                value: entrenadoElement.current.value
            });
        };

        if (desparasitacionElement.current?.value) {
            where.push({
                field: 'payload->desparasitacion',
                operator: '=',
                value: desparasitacionElement.current.value
            });
        };

        if (esterilizadaElement.current?.value) {
            where.push({
                field: 'payload->esterilizada',
                operator: '=',
                value: esterilizadaElement.current.value
            });
        };

        if (educadoElement.current?.value) {
            where.push({
                field: 'payload->educado',
                operator: '=',
                value: educadoElement.current.value
            });
        };

        if (edadValorElement.current?.value && edadUnidadElement.current?.value) {

            where.push({
                field: 'payload->edad->valor',
                operator: '=',
                value: edadValorElement.current.value
            });

            where.push({
                field: 'payload->edad->unidad',
                operator: '=',
                value: edadUnidadElement.current.value
            });
        }

        if (ciudadValorElement.current?.value) {
            where.push({
                field: 'payload->ciudad',
                operator: 'like',
                value: `%${ciudadValorElement.current.value}%`,
                collate: 'utf8mb4_general_ci'
            });
        };

        if (estadoElement.current?.value) {
            where.push({
                field: 'payload->entidad_federativa',
                operator: 'like',
                value: `%${estadoElement.current.value}%`,
                collate: 'utf8mb4_general_ci'
            });
        };

        fetchData(where);
    };

    const [edadValor, setEdadValor] = useState('');
    const handleEdadValorChange = () => {
        const test_value = edadValorElement.current?.value.replace(/[^0-9]+/g, "");
        if (test_value)
            setEdadValor(test_value);
        else
            setEdadValor('');
    };
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleFetch();
        }
    }

    return (
        <div className='row mb-4'>
            <div className="col-12">
                <p className="filter_title">{languaje === 'ES' ? 'Utiliza nuestros campos y filtros de búsqueda' : 'Search and filter'}</p>
            </div>
            <div className="col-12 col-md-6 col-xl-3 mt-2">
                <label htmlFor="tamano">{languaje === 'ES' ? 'TAMAÑO' : 'SIZE'}</label>
                <select name="tamano" id="tamano" className='form-control' ref={tamanoElement} onChange={handleFetch}>
                    <option value=""></option>
                    <option value='mini'>MINI</option>
                    <option value='pequeño'>{languaje === 'ES' ? 'PEQUEÑO' : 'SMALL'}</option>
                    <option value='mediano'>{languaje === 'ES' ? 'MEDIANO' : 'MEDIUM'}</option>
                    <option value='grande'>{languaje === 'ES' ? 'GRANDE' : 'BIG'}</option>
                    <option value='muy grande'>{languaje === 'ES' ? 'MUY GRANDE' : 'GIANT'}</option>
                </select>
            </div>
            <div className="col-12 col-md-6 col-xl-3 mt-2">
                <label htmlFor="sexo">{languaje === 'ES' ? 'SEXO' : 'GENDER'}</label>
                <select name="sexo" id="sexo" className='form-control' ref={sexoElement} onChange={handleFetch}>
                    <option value=""></option>
                    <option value='macho'>{languaje === 'ES' ? 'MACHO' : 'MALE'}</option>
                    <option value='hembra'>{languaje === 'ES' ? 'HEMBRA' : 'FEMALE'}</option>
                </select>
            </div>
            <div className="col-12 col-md-6 col-xl-3 mt-2">
                <label htmlFor="raza">{languaje === 'ES' ? 'RAZA' : 'BREED'}</label>
                <select name="raza" id="raza" className='form-control' ref={razaElement} onChange={handleFetch}>
                    <option value=""></option>
                    <option value='mestizo'>{languaje === 'ES' ? 'MESTIZO' : 'MONGREL'}</option>
                    <option value='mezcla de raza con mestizo'>{languaje === 'ES' ? 'MEZCLA DE RAZA CON MESTIZO' : 'CROSS-BREED'}</option>
                    <option value='de raza'>{languaje === 'ES' ? 'DE RAZA' : 'BREED'}</option>
                    <option value='no se'>{languaje === 'ES' ? 'NO SE' : 'IDK'}</option>
                </select>
            </div>
            <div className="col-12 col-md-6 col-xl-3 mt-2">
                <label htmlFor="especie">{languaje === 'ES' ? 'ESPECIE' : 'SPECIES'}</label>
                <select name="especie" id="especie" className='form-control' ref={especieElement} onChange={handleFetch}>
                    <option value=""></option>
                    <option value='gato'>{languaje === 'ES' ? 'GATO' : 'CAT'}</option>
                    <option value='perro'>{languaje === 'ES' ? 'PERRO' : 'DOG'}</option>
                </select>
            </div>
            <div className="col-12 col-md-6 col-xl-3 mt-2">
                <label htmlFor="convive_otras">{languaje === 'ES' ? '¿CONVIVE CON OTRAS ESPECIES?' : 'LIVES WITH OTHER SPECIES?'}</label>
                <select name="convive_otras" id="convive_otras" className='form-control' ref={conviveOtrasElement} onChange={handleFetch}>
                    <option value=""></option>
                    
                    <option value='SI'>{languaje === 'ES' ? 'SI' : 'YES'}</option>
                    <option value='NO'>NO</option>
                    <option value='NO SE'>{languaje === 'ES' ? 'NO SE' : 'IDK'}</option>
                </select>
            </div>
            <div className="col-12 col-md-6 col-xl-3 mt-2">
                <label htmlFor="convive_ninos">{languaje === 'ES' ? '¿CONVIVE CON NIÑOS?' : 'KIDS FRIENDLY?'}</label>
                <select name="convive_ninos" id="convive_ninos" className='form-control' ref={conviveNinosElement} onChange={handleFetch}>
                    <option value=""></option>
                    
                    <option value='SI'>{languaje === 'ES' ? 'SI' : 'YES'}</option>
                    <option value='NO'>NO</option>
                    <option value='NO SE'>{languaje === 'ES' ? 'NO SE' : 'IDK'}</option>
                </select>
            </div>

            <div className="col-12 col-md-6 col-xl-3 mt-2">
                <label htmlFor="entrenado">{languaje === 'ES' ? '¿ESTÁ ENTRENADO?' : 'TRAINED?'}</label>
                <select name="entrenado" id="entrenado" className='form-control' ref={entrenadoElement} onChange={handleFetch}>
                    <option value=""></option>
                    <option value='true'>{languaje === 'ES' ? 'SI' : 'YES'}</option>
                    <option value='false'>NO</option>
                </select>
            </div>
            <div className="col-12 col-md-6 col-xl-3 mt-2">
                <label htmlFor="educado">{languaje === 'ES' ? '¿ES EDUCADO?' : 'EDUCATED?'}</label>
                <select name="educado" id="educado" className='form-control' ref={educadoElement} onChange={handleFetch}>
                    <option value=""></option>
                    <option value='true'>{languaje === 'ES' ? 'SI' : 'YES'}</option>
                    <option value='false'>NO</option>
                </select>
            </div>
            <div className="col-12 col-md-6 mt-2">
                <div className="row">
                    <div className="col-12 col-md-6 pr-2">
                        <label htmlFor="edad">{languaje === 'ES' ? 'EDAD:' : 'AGE:'}</label>
                        <input type="text" id="edad" className="form-control" ref={edadValorElement} value={edadValor} onChange={handleEdadValorChange} onKeyDown={handleKeyDown} />
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <select name="educado" id="educado" className='form-control mt-2' ref={edadUnidadElement} onChange={handleFetch}>
                            <option value=""></option>
                            <option value='meses'>{languaje === 'ES' ? 'MESES' : 'MONTHS'}</option>
                            <option value='años'>{languaje === 'ES' ? 'AÑOS' : 'YEARS'}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 mt-2">
                <div className="row">
                    <div className="col-12 col-md-6 pr-2">
                        <label htmlFor="ciudad">{languaje === 'ES' ? 'CIUDAD/POBLACION:' : 'CITY:'}</label>
                        <input type="text" id="ciudad" className="form-control" ref={ciudadValorElement} onKeyDown={handleKeyDown} />
                    </div>
                    <div className="col-12 col-md-6 mt-1 mt-md-0">
                        <label htmlFor="entidad_federativa">{languaje === 'ES' ? 'ESTADO:' : 'STATE:'}</label>
                        <select name="entidad_federativa" id="entidad_federativa" className='form-control' ref={estadoElement} onChange={handleFetch}>
                            <option value=""></option>
                            {estados.map((estado: string) => (<option key={estado} value={estado}>{estado}</option>))}
                        </select>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3 mt-2">
                <label htmlFor="convive">{languaje === 'ES' ? '¿CONVIVE CON OTROS DE SU ESPECIE?' : 'LIVES WITH OTHERS OF ITS SPECIES?'}</label>
                <select name="convive" id="convive" className='form-control' ref={conviveElement} onChange={handleFetch}>
                    <option value=""></option>

                    <option value='SI'>{languaje === 'ES' ? 'SI' : 'YES'}</option>
                    <option value='NO'>NO</option>
                    <option value='NO SE'>{languaje === 'ES' ? 'NO SE' : 'IDK'}</option>
                </select>
            </div>
            <div className="col-12 col-md-6 col-xl-3 mt-2">
                <label htmlFor="desparasitacion">{languaje === 'ES' ? '¿ESTA VACUNADO/DESPARAZITADO?' : 'VACCINATION/DEWORMING?'}</label>
                <select name="desparasitacion" id="desparasitacion" className='form-control' ref={desparasitacionElement} onChange={handleFetch}>
                    <option value=""></option>
                    <option value='true'>{languaje === 'ES' ? 'SI' : 'YES'}</option>
                    <option value='false'>NO</option>
                </select>
            </div>
            <div className="col-12 col-md-6 col-xl-3 mt-2">
                <label htmlFor="esterilizada">{languaje === 'ES' ? '¿ESTA ESTERILIZADO?' : 'PET STERILIZED?'}</label>
                <select name="esterilizada" id="esterilizada" className='form-control' ref={esterilizadaElement} onChange={handleFetch}>
                    <option value=""></option>
                    <option value='true'>{languaje === 'ES' ? 'SI' : 'YES'}</option>
                    <option value='false'>NO</option>
                </select>
            </div>
        </div>
    )
}
