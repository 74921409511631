import React, { useContext } from 'react';
import { Accordion } from 'react-bootstrap';
import { nosotrosImages } from '../../helpers/callejeritosImages';
import { TextToggler } from '../../components/ui/TextToggler';
import { UserContext } from '../../context/UserContext';


export const Historia = () => {

    const { languaje } = useContext(UserContext);

    return (
        <div className="div-historia animate__animated animate__fadeIn">
            <div className="header-historia">
                <img src={nosotrosImages(`./Historia/titulo${languaje}.png`).default} alt="Nosotros"
                    className={ 'img-fluid title-historia' + languaje } />
            </div>
            <div className="body-historia animate__animated animate__fadeInUp">
                <DesktopText lang={ languaje } />
                <MobileText lang={ languaje } />
            </div>
        </div>
    )

}

function DesktopText({ lang }: { lang: string }) {
    return (<div className="d-none d-lg-block">{
        lang === 'ES' ?
        <>
            <p className="title-gray">Nacimiento</p>
            <p className="text">El proyecto nace de la inquietud y frustración de ver un gran número de perros en la calle sin hogar y dueño,
                albergues y rescatistas con intención de ayudar, pero luchando por sobrevivir cada día al igual que ellos… en el anonimato.</p>
            <p className="title-gray">Creación y pasión</p>
            <p className="text">
                Trabajamos con total entrega, pasión en cada una de nuestras actividades para poder crear experiencias dignas, memorables y que trasciendan para cada una de las vidas de mascotas en situación de calle, maltrato y/o abandono, así como para cada uno de nuestr@s adoptantes responsables. Crear conciencia social e individual es el objetivo para poder erradicar estas situaciones y permitirnos ser una sociedad empática, sensible y responsable.
            </p>
        </>
        :
        <>
            <p className="title-gray">Birth</p>
                <p className="text">The project started from the restlessness and frustration of seeing a large number of dogs on the streets
                 without a home or owner. Furthermore, knowing that shelters and rescuers, with the intention of helping, are struggling to
                 survive everyday just like our canine friends, also inspired us to act.</p>
            <p className="title-gray">Creation and passion</p>
            <p className="text">
                We work with total dedication and passion in each of our activities to create worthy, memorable experiences that transcend each of the lives of pets in situations of street, abuse and/or abandonment, as well as for each of our responsible adopters. Creating social and individual awareness is the objective to eradicate these situations and allow us to be an empathetic, sensitive and responsible society.
            </p>
            
        </>
    }</div>);
}


function MobileText({ lang }: { lang: string }) {
    return (<div className="d-block d-lg-none">{
        lang === 'ES' ?
         <Accordion defaultActiveKey="0">
            <div>
                <div className="callejeritos_accordion_header">
                    <TextToggler eventKey="0" text="Nacimiento" />
                </div>
                <Accordion.Collapse eventKey="0">
                    <p className="text">El proyecto nace de la inquietud y frustración de ver un gran número de perros en la calle sin hogar y dueño,
                    albergues y rescatistas con intención de ayudar, pero luchando por sobrevivir cada día al igual que ellos… en el anonimato.</p>
                </Accordion.Collapse>
            </div>
            <div className="mt-5">
                <div className="callejeritos_accordion_header">
                    <TextToggler eventKey="1" text="Creación y pasión" />
                </div>
                <Accordion.Collapse eventKey="1">
                    <p className="text">Trabajamos con total entrega, pasión en cada una de nuestras actividades para poder crear experiencias dignas, memorables y que trasciendan para cada una de las vidas de mascotas en situación de calle, maltrato y/o abandono, así como para cada uno de nuestr@s adoptantes responsables. Crear conciencia social e individual es el objetivo para poder erradicar estas situaciones y permitirnos ser una sociedad empática, sensible y responsable.</p>
                </Accordion.Collapse>
            </div>
        </Accordion>        
        :
        <Accordion defaultActiveKey="0">
            <div>
                <div className="callejeritos_accordion_header">
                    <TextToggler eventKey="0" text="Birth" />
                </div>
                <Accordion.Collapse eventKey="0">
                    <p className="text">The project started from the restlessness and frustration of seeing a large number of dogs on the streets
                     without a home or owner. Furthermore, knowing that shelters and rescuers, with the intention of helping, are struggling to
                     survive everyday just like our canine friends, also inspired us to act.</p>
                </Accordion.Collapse>
            </div>
            <div className="mt-5">
                <div className="callejeritos_accordion_header">
                    <TextToggler eventKey="1" text="Creation and passion" />
                </div>
                <Accordion.Collapse eventKey="1">
                <p className="text">We work with total dedication and passion in each of our activities to create worthy, memorable experiences that transcend each of the lives of pets in situations of street, abuse and/or abandonment, as well as for each of our responsible adopters. Creating social and individual awareness is the objective to eradicate these situations and allow us to be an empathetic, sensitive and responsible society.</p>
                </Accordion.Collapse>
            </div>
        </Accordion>    
    }</div>);
}
