/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { useForm, SubmitHandler, useWatch } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import Select from 'react-select';

import { AuthContext } from '../../context/AuthContext';
import { UserContext } from '../../context/UserContext';
import { useKW2P } from '../../hooks/useKW2P';
import { Mascota, MascotasActualizar } from '../../interfaces/mascotasInterfaces';
import { Loader } from '../ui/Loader';
import { DropImages } from './DropImages';
import { MascotaGalery } from './MascotaGalery';
import { Producto } from '../../interfaces/donativosInterfaces';
import { estados } from '../../helpers/callejeritosFunciones';
import { useGetProductosCallejeritos } from '../../hooks';
import { DropImagesSmall } from './DropImagesSmall';

interface Params {
    mascota: Mascota | null;
    scope: 'adopcion' | 'rehabilitacion';
    dueno: any;
    modificar: any;
}

export const MascotaForm = ({ mascota, scope, dueno, modificar }: Params) => {
    console.log("mascota", mascota);

    const ImageDropper = useRef < any > ();
    const ImageCedule = useRef < any > ();
    const { languaje } = useContext(UserContext);
    const { isLoading, user, handleError, setLoading } = useContext(AuthContext);

    const [padecimientos, setPadecimientos] = useState < Option[] > ([]);
    const [necesidades, setNecesidades] = useState < string[] > ([]);
    const [padecimientosCurrent, setPadecimientosCurrent] = useState < Option[] > ([]);
    // const [desparacitacionCompleta, setDesparacitacionCompleta] = useState(false);


    const { formState: { errors }, handleSubmit, register, control, setValue } = useForm < FormValues > ();
    const esterilizada_ = useWatch({ name: 'esterilizada', control });
    const esterilizada = useMemo(() => esterilizada_ ? JSON.parse(esterilizada_) : '', [esterilizada_]);
    const nombre = useWatch({ name: 'nombre', control });


    const desparasitacion_ = useWatch({ name: 'desparasitacion', control });
    const desparasitacion = useMemo(() => desparasitacion_ ? JSON.parse(desparasitacion_) : '', [desparasitacion_]);

    const { actualizarCatalogoPublic, reemplazarCatalogoPublic } = useKW2P();

    const { productos, gettingProducts } = useGetProductosCallejeritos('rehabilitacion');


    // if(mascota == undefined){
    //     document.getElementById('dueno_mascota').value = dueno_mascota;
    // }

    function abrirConfirm() {
        debugger
        // let nombre = document.getElementById('nombre');
        let nombre = (document.getElementById('nombre') as HTMLInputElement).value;
        let color = (document.getElementById('color') as HTMLInputElement).value;
        let peso = (document.getElementById('peso') as HTMLInputElement).value;
        let edad = (document.getElementById('edad') as HTMLInputElement).value;
        let ciudad = (document.getElementById('ciudad') as HTMLInputElement).value;
        let personalidad = (document.getElementById('personalidad') as HTMLInputElement).value;
        let desparacitacion = (document.getElementById('comentDesparasitacion') as HTMLInputElement).value;

        if (nombre != '' && color != '' && peso != '' && edad != '' && ciudad != '' && personalidad != '' && desparacitacion != '') {
            document.getElementById('modal_confirm')!.style.display = 'block';
        }
    }

    function cerrarConfirm() {
        document.getElementById('modal_confirm')!.style.display = 'none';
    }


    const onSubmit: SubmitHandler<FormValues> = async (FormData) => {
        try {

            if (!ImageDropper.current.dropZoneValid() || ImageCedule.current&&!ImageCedule.current.dropZoneValid()) {
                cerrarConfirm();
                return;
            }

            if (FormData.estado === 'rehabilitacion' && !necesidades.length) {
                cerrarConfirm();
                return;
            }

            setLoading(true);
            const { data: { data: { id } } } = await actualizarMascota(FormData);
            // console.log("cambiar deleted_at:",data);
            ImageCedule.current&&ImageCedule.current.submitFiles(id);
            ImageDropper.current&&ImageDropper.current.submitFiles(id);

        } catch (err: any) {
            const error = {response:{data:err}};
            handleError(error);
        }
    };

    const actualizarMascota = (data: FormValues): Promise<AxiosResponse<MascotasActualizar>> => {
        console.log("data:", data);
        const tiempoTranscurrido = Date.now();
        const hoy = new Date(tiempoTranscurrido);
        const fechaActual = new Date();
        // fecha de creación dando formato a la fecha
        let dayActual: any = fechaActual.getDate();
        if (dayActual < 10) {
            dayActual = '0' + dayActual;
        }
        let monthActual: any = (fechaActual.getMonth()) + 1;
        if (monthActual < 10) {
            monthActual = '0' + monthActual;
        }
        let yearActual = fechaActual.getFullYear();
        console.log("dayActual", `${yearActual}-${monthActual}-${dayActual}`)

        // sumando 15 dias para la fecha de notificación
        let suma15dias = 15 * 24 * 60 * 60 * 1000;
        let sumandoDias = new Date(new Date(fechaActual).getTime() + suma15dias);
        let day: any = sumandoDias.getDate();
        if (day < 10) {
            day = '0' + day;
        }
        let month: any = (sumandoDias.getMonth()) + 1;
        if (month < 10) {
            month = '0' + month;
        }
        let year = sumandoDias.getFullYear();
        console.log("day", `${year}-${month}-${day}`)

        // sumando 35 días para la fecha de expiración
        let suma35dias = 35 * 24 * 60 * 60 * 1000;
        let sumandoDiasExpiracion = new Date(new Date(fechaActual).getTime() + suma35dias);
        let dayExpiracion: any = sumandoDiasExpiracion.getDate();
        if (dayExpiracion < 10) {
            dayExpiracion = '0' + dayExpiracion;
        }
        let monthExpiracion: any = (sumandoDiasExpiracion.getMonth()) + 1;
        if (monthExpiracion < 10) {
            monthExpiracion = '0' + monthExpiracion;
        }
        let yearExpiracion = sumandoDiasExpiracion.getFullYear();
        console.log("sumandoDiasExpiracion", `${yearExpiracion}-${monthExpiracion}-${dayExpiracion}`)

        if (modificar) {
            const { convine_otras, convine, convine_ninos, entrenado, educado, peso, edad, desparasitacion, esterilizada, comentDesparasitacion, dueno_mascota, dueno_mascota_telefono, dueno_mascota_correo, fecha_ultima_desparasitacion, esterilizacion_mvz_telefono, esterilizacion_mvz_nombre, eliminado, fecha_creado, fecha_notificacion, fecha_expiracion
            } = data;

            const body = {
                schema: 'mascotas',
                id: mascota?.id,
                payload: {
                    ...data,
                    peso: Number(peso),
                    edad: {
                        valor: Number(edad.valor),
                        unidad: edad.unidad
                    },
                    convine_otras: convine_otras,
                    convine: convine,
                    convine_ninos: convine_ninos,
                    entrenado: entrenado === 'true' ? true : false,
                    educado: educado === 'true' ? true : false,
                    usuario_id: user?.id,
                    estado: scope,
                    desparasitacion: desparasitacion === 'true' ? true : false,
                    esterilizada: esterilizada === 'true' ? true : false,
                    eliminado: false,
                    comentDesparasitacion,
                    necesidades,
                    dueno_mascota,
                    dueno_mascota_telefono,
                    dueno_mascota_correo,
                    fecha_creado: `${yearActual}-${monthActual}-${dayActual}`,
                    fecha_notificacion: `${year}-${month}-${day}`,
                    fecha_expiracion: `${yearExpiracion}-${monthExpiracion}-${dayExpiracion}`,
                    fecha_ultima_desparasitacion,
                    esterilizacion_mvz_telefono,
                    esterilizacion_mvz_nombre
                }
            };
            return mascota?.id ? reemplazarCatalogoPublic(body) : actualizarCatalogoPublic(body);
        } else {
            const { convine_otras, convine, convine_ninos, entrenado, educado, peso, edad, desparasitacion, esterilizada, esterilizacion_mvz_telefono, esterilizacion_mvz_nombre, comentDesparasitacion, dueno_mascota, dueno_mascota_telefono, dueno_mascota_correo, fecha_ultima_desparasitacion, eliminado, fecha_notificacion, fecha_expiracion
            } = data;

            const body = {
                schema: 'mascotas',
                id: mascota?.id,
                payload: {
                    ...data,
                    peso: Number(peso),
                    edad: {
                        valor: Number(edad.valor),
                        unidad: edad.unidad
                    },
                    convine_otras: convine_otras,
                    convine: convine,
                    convine_ninos: convine_ninos,
                    entrenado: entrenado === 'true' ? true : false,
                    educado: educado === 'true' ? true : false,
                    estado: scope,
                    desparasitacion: desparasitacion === 'true' ? true : false,
                    esterilizada: esterilizada === 'true' ? true : false,
                    eliminado: false,
                    comentDesparasitacion,
                    necesidades,
                    dueno_mascota,
                    dueno_mascota_telefono,
                    dueno_mascota_correo,
                    fecha_ultima_desparasitacion,
                    esterilizacion_mvz_telefono,
                    esterilizacion_mvz_nombre
                }
            };
            return mascota?.id ? reemplazarCatalogoPublic(body) : actualizarCatalogoPublic(body);
        }

    }

    const onChange = (option: readonly Option[]) => {
        /* if (!option.length) {
            setNecesidades([]);
            return;
        } */
        const necesidades_temp = option.map(opt => (opt.value));
        const padecimientos = necesidades_temp.map((ned: string) => ({ value: ned, label: ned }));
        setPadecimientosCurrent(padecimientos);
        setNecesidades(necesidades_temp);
    }

    const formatPhone = (val: string, name: any) => {
        debugger
        const phone = val.replace(/[^0-9.]/g, '');
        const telformated = phone.slice(0, 3) + (phone.length > 3 ? ("-" + phone.slice(3, 6)) : '') + (phone.length > 6 ? ("-" + phone.slice(6)) : '');
        setValue(name, telformated, { shouldValidate: true, shouldDirty: true });
    };

    useEffect(() => {
        if (mascota) {
            const necesidadesTemp = mascota.payload.necesidades.map((ned: string) => ({ value: ned, label: ned }));
            setPadecimientosCurrent(necesidadesTemp);
            setNecesidades(mascota.payload.necesidades);

            setValue('nombre', mascota?.payload.nombre)
            setValue('color', mascota?.payload.color)
            setValue('peso', mascota?.payload.peso)
            setValue('personalidad', mascota?.payload.personalidad)
            setValue('ciudad', mascota?.payload.ciudad)
            setValue('comentarios', mascota?.payload.comentarios)
            setValue('comentDesparasitacion', mascota?.payload.comentDesparasitacion)

            setValue('dueno_mascota', mascota?.payload.dueno_mascota)
            setValue('dueno_mascota_correo', mascota?.payload.dueno_mascota_correo)
            setValue('dueno_mascota_telefono', mascota?.payload.dueno_mascota_telefono)

            setValue('convine_otras', mascota?.payload.convine_otras.toString())
            setValue('convine', mascota?.payload.convine.toString())
            setValue('tamano', mascota?.payload.tamano)
            setValue('convine_ninos', mascota?.payload.convine_ninos.toString())
            setValue('sexo', mascota?.payload.sexo)
            setValue('entrenado', mascota?.payload.entrenado.toString())
            setValue('raza', mascota?.payload.raza)
            setValue('educado', mascota?.payload.educado.toString())
            setValue('especie', mascota?.payload.especie)
            setValue('edad.valor', mascota?.payload.edad.valor)
            setValue('edad.unidad', mascota?.payload.edad.unidad)
            setValue('entidad_federativa', mascota?.payload.entidad_federativa)

            setValue('esterilizada', mascota?.payload.esterilizada?.toString() ?? "false")
            setValue('esterilizacion_mvz_telefono', mascota?.payload.esterilizacion_mvz_telefono)
            setValue('esterilizacion_mvz_nombre', mascota?.payload.esterilizacion_mvz_nombre)

            setValue('fecha_ultima_desparasitacion', mascota?.payload.fecha_ultima_desparasitacion)
            setValue('desparasitacion', mascota?.payload.desparasitacion?.toString() ?? "false")
        }
    }, []);

    useEffect(() => {
        scope === 'rehabilitacion' ? obtenerPadecimientos() : setLoading(false);
    }, [productos]);

    const obtenerPadecimientos = () => {
        const padecimientos = productos.map((prod: Producto) => ({ value: prod.payload.nombre, label: prod.payload.nombre }));
        setPadecimientos(padecimientos);
    };

    if (scope === 'rehabilitacion' && gettingProducts) return <Loader />;

    return (
        <>
            {/* {JSON.stringify({errors})} */}
            <form onSubmit={handleSubmit(onSubmit,cerrarConfirm)}>
                <div className="row mb-3">
                    <div className="col-12 col-md-6">
                        <div className="row">
                            <div className="col-12 col-lg-6 mt-2">
                                <label htmlFor="nombre">{languaje === 'ES' ? 'NOMBRE:' : 'NAME:'}</label>
                                <input id="nombre" className="form-control" placeholder={languaje === 'ES' ? 'Ingresa el nombre de la mascota' : `Enter the pet's name`} autoComplete="off"
                                    {...register('nombre', { required: languaje === 'ES' ? 'El nombre de la mascota es requerido' : 'The name of pet is required' })} />
                                {errors.nombre && <small className="text-danger">{errors.nombre.message}</small>}
                                {/* {JSON.stringify({nombre,pNombre:mascota?.payload.nombre})} */}
                            </div>
                            <div className="col-12 col-lg-6 mt-2">
                                <label htmlFor="convive_otras">{languaje === 'ES' ? '¿CONVIVE CON OTRAS ESPECIES?' : 'LIVES WITH OTHER SPECIES?'}</label>
                                <select id="convive_otras" className="form-control" {...register('convine_otras',
                                    { required: languaje === 'ES' ? 'Elije una opción' : 'Choose one option' })}
                                >
                                    <option value="" disabled selected hidden>{languaje === 'ES' ? 'Elije una opción' : 'Choose one option'}</option>
                                    <option value='SI'>{languaje === 'ES' ? 'SI' : 'YES'}</option>
                                    <option value='NO'>NO</option>
                                    <option value='NO SE'>{languaje === 'ES' ? 'NO SE' : 'IDK'}</option>
                                </select>
                                {errors.convine_otras && <small className="text-danger">{errors.convine_otras.message}</small>}
                            </div>
                            <div className="col-12 col-lg-6 mt-2">
                                <label htmlFor="color">COLOR:</label>
                                <input id="color" className="form-control" placeholder={languaje === 'ES' ? 'Ingresa el color de la mascota' : `Enter the pet's color`} autoComplete="off"
                                    {...register('color', { required: languaje === 'ES' ? 'El color de la mascota es requerido' : 'The color of pet is required' })} />
                                {errors.color && <small className="text-danger">{errors.color.message}</small>}
                            </div>
                            <div className="col-12 col-lg-6 mt-2">
                                <label htmlFor="convive">{languaje === 'ES' ? '¿CONVIVE CON OTROS DE SU ESPECIE?' : 'LIVES WITH OTHERS OF ITS SPECIES?'}</label>
                                <select id="convive" className="form-control"
                                    {...register('convine', { required: languaje === 'ES' ? 'Elije una opción' : 'Choose one option' })}
                                >
                                    <option value="" disabled selected hidden>{languaje === 'ES' ? 'Elije una opción' : 'Choose one option'}</option>
                                    <option value='SI'>{languaje === 'ES' ? 'SI' : 'YES'}</option>
                                    <option value='NO'>NO</option>
                                    <option value='NO SE'>{languaje === 'ES' ? 'NO SE' : 'IDK'}</option>
                                </select>
                                {errors.convine && <small className="text-danger">{errors.convine.message}</small>}
                            </div>
                            <div className="col-12 col-lg-6 mt-2">
                                <label htmlFor="tamano">{languaje === 'ES' ? 'TAMAÑO:' : 'SIZE:'}</label>
                                <select id="tamano" className="form-control" {...register('tamano', { required: languaje === 'ES' ? 'Elije una opción' : 'Choose one option' })}
                                >
                                    <option value="" disabled selected hidden>{languaje === 'ES' ? 'Elije una opción' : 'Choose one option'}</option>
                                    <option value='mini'>MINI</option>
                                    <option value='pequeño'>{languaje === 'ES' ? 'PEQUEÑO' : 'SMALL'}</option>
                                    <option value='mediano'>{languaje === 'ES' ? 'MEDIANO' : 'MEDIUM'}</option>
                                    <option value='grande'>{languaje === 'ES' ? 'GRANDE' : 'BIG'}</option>
                                    <option value='muy grande'>{languaje === 'ES' ? 'MUY GRANDE' : 'GIANT'}</option>
                                </select>
                                {errors.tamano && <small className="text-danger">{errors.tamano.message}</small>}
                            </div>
                            <div className="col-12 col-lg-6 mt-2">
                                <label htmlFor="convine_ninos">{languaje === 'ES' ? '¿CONVIVE CON NIÑOS?' : 'KIDS FRIENDLY?'}</label>
                                <select id="convine_ninos" className="form-control"
                                    {...register('convine_ninos', { required: languaje === 'ES' ? 'Elije una opción' : 'Choose one option' })}
                                >
                                    <option value="" disabled selected hidden>{languaje === 'ES' ? 'Elije una opción' : 'Choose one option'}</option>
                                    <option value='SI'>{languaje === 'ES' ? 'SI' : 'YES'}</option>
                                    <option value='NO'>NO</option>
                                    <option value='NO SE'>{languaje === 'ES' ? 'NO SE' : 'IDK'}</option>
                                </select>
                                {errors.convine_ninos && <small className="text-danger">{errors.convine_ninos.message}</small>}
                            </div>
                            <div className="col-12 col-lg-6 mt-2">
                                <label htmlFor="sexo">{languaje === 'ES' ? 'SEXO:' : 'GENDER:'}</label>
                                <select id="sexo" className="form-control" {...register('sexo', { required: languaje === 'ES' ? 'Elije una opción' : 'Choose one option' })}
                                >
                                    <option value="" disabled selected hidden>{languaje === 'ES' ? 'Elije una opción' : 'Choose one option'}</option>
                                    <option value='macho'>{languaje === 'ES' ? 'MACHO' : 'MALE'}</option>
                                    <option value='hembra'>{languaje === 'ES' ? 'HEMBRA' : 'FEMALE'}</option>
                                </select>
                                {errors.tamano && <small className="text-danger">{errors.tamano.message}</small>}
                            </div>
                            <div className="col-12 col-lg-6 mt-2">
                                <label htmlFor="entrenado">{languaje === 'ES' ? '¿ESTÁ ENTRENADO?' : 'TRAINED?'}</label>
                                <select id="entrenado" className="form-control"
                                    {...register('entrenado', { required: languaje === 'ES' ? 'Elije una opción' : 'Choose one option' })}
                                >
                                    <option value="" disabled selected hidden>{languaje === 'ES' ? 'Elije una opción' : 'Choose one option'}</option>
                                    <option value='true'>{languaje === 'ES' ? 'SI' : 'YES'}</option>
                                    <option value='false'>NO</option>
                                </select>
                                {errors.entrenado && <small className="text-danger">{errors.entrenado.message}</small>}
                            </div>
                            <div className="col-12 col-lg-6 mt-2">
                                <label htmlFor="raza">{languaje === 'ES' ? 'RAZA:' : 'BREED:'}</label>
                                <select id="raza" className="form-control" {...register('raza', { required: languaje === 'ES' ? 'Elije una opción' : 'Choose one option' })}
                                >
                                    <option value="" disabled selected hidden>{languaje === 'ES' ? 'Elije una opción' : 'Choose one option'}</option>
                                    <option value='mestizo'>{languaje === 'ES' ? 'MESTIZO' : 'MONGREL'}</option>
                                    <option value='mezcla de raza con mestizo'>{languaje === 'ES' ? 'MEZCLA DE RAZA CON MESTIZO' : 'CROSS-BREED'}</option>
                                    <option value='de raza'>{languaje === 'ES' ? 'DE RAZA' : 'BREED'}</option>
                                    <option value='no se'>{languaje === 'ES' ? 'NO SE' : 'IDK'}</option>
                                </select>
                                {errors.raza && <small className="text-danger">{errors.raza.message}</small>}
                            </div>
                            <div className="col-12 col-lg-6 mt-2">
                                <label htmlFor="educado">{languaje === 'ES' ? '¿ES EDUCADO?' : 'EDUCATED?'}</label>
                                <select id="educado" className="form-control"
                                    {...register('educado', { required: languaje === 'ES' ? 'Elije una opción' : 'Choose one option' })}
                                >
                                    <option value="" disabled selected hidden>{languaje === 'ES' ? 'Elije una opción' : 'Choose one option'}</option>
                                    <option value='true'>{languaje === 'ES' ? 'SI' : 'YES'}</option>
                                    <option value='false'>NO</option>
                                </select>
                                {errors.educado && <small className="text-danger">{errors.educado.message}</small>}
                            </div>
                            <div className="col-12 col-lg-6 mt-2">
                                <div>
                                    <label htmlFor="especie">{languaje === 'ES' ? 'ESPECIE:' : 'SPECIES:'}</label>
                                    <select id="especie" className="form-control"
                                        {...register('especie', { required: languaje === 'ES' ? 'Elije una opción' : 'Choose one option' })}
                                    >
                                        <option value="" disabled selected hidden>{languaje === 'ES' ? 'Elije una opción' : 'Choose one option'}</option>
                                        <option value='gato'>{languaje === 'ES' ? 'GATO' : 'CAT'}</option>
                                        <option value='perro'>{languaje === 'ES' ? 'PERRO' : 'DOG'}</option>
                                    </select>
                                    {errors.especie && <small className="text-danger">{errors.especie.message}</small>}
                                </div>
                                <div>
                                    <label htmlFor="peso" className='mt-2'>{languaje === 'ES' ? 'PESO' : 'WEIGHT'} (KG):</label>
                                    <input id="peso" className="form-control" type="text" pattern="^-?[0-9]\d*\.?\d*$"
                                        {...register('peso', { required: languaje === 'ES' ? 'El peso de la mascota es requerido' : `The pet's weight is required` })} />
                                    {errors.peso && <small className="text-danger">{errors.peso.message}</small>}
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 mt-2">
                                <label htmlFor="personalidad">{languaje === 'ES' ? 'PERSONALIDAD:' : 'PERSONALITY:'}</label>
                                <textarea className="form-control" id="personalidad" rows={4}
                                    {...register('personalidad', { required: languaje === 'ES' ? 'Platicanos sobre la personalidad de tú mascota' : 'Tell us about the personality of your pet' })}
                                ></textarea>
                                {errors.personalidad && <small className="text-danger">{errors.personalidad.message}</small>}
                            </div>
                            <div className="col-12 col-lg-6 mt-2">
                                <label htmlFor="edad" className='mt-2'>{languaje === 'ES' ? 'EDAD:' : 'AGE:'}</label>
                                <input id="edad" className="form-control" type="number" pattern="^-?[0-9]\d*\.?\d*$"
                                    {...register('edad.valor', { required: languaje === 'ES' ? 'La edad de la mascota es requerida' : `The age of the pet is required` })} />
                                {errors.edad?.valor && <small className="text-danger">{errors.edad.valor.message}</small>}
                            </div>
                            <div className="col-12 col-lg-6 mt-2">
                                <label htmlFor="unidad" className='mt-lg-4'></label>
                                <select id="unidad" className="form-control"
                                    {...register('edad.unidad', { required: languaje === 'ES' ? 'Elije una unidad' : 'Choose a unit' })}
                                >
                                    <option value="" disabled selected hidden>{languaje === 'ES' ? 'Elije una unidad' : 'Choose a unit'}</option>
                                    <option value='meses'>{languaje === 'ES' ? 'MESES' : 'MONTHS'}</option>
                                    <option value='años'>{languaje === 'ES' ? 'AÑOS' : 'YEARS'}</option>
                                </select>
                                {errors.edad?.unidad && <small className="text-danger">{errors.edad.unidad.message}</small>}
                            </div>
                            <div className="col-12 col-lg-6 mt-2">
                                <label htmlFor="ciudad" className='mt-2'>{languaje === 'ES' ? 'Ciudad/Población:' : 'City:'}</label>
                                <input id="ciudad" className="form-control" type="text"
                                    {...register('ciudad', { required: languaje === 'ES' ? 'La ciudad/población de la mascota es requerida' : `The city of the pet is required` })} />
                                {errors.ciudad && <small className="text-danger">{errors.ciudad.message}</small>}
                            </div>
                            <div className="col-12 col-lg-6 mt-2">
                                <label htmlFor="entidad_federativa" className='mt-lg-4'></label>
                                <select id="entidad_federativa" className="form-control"
                                    {...register('entidad_federativa', { required: languaje === 'ES' ? 'Elije una estado' : 'Choose a state' })}
                                >
                                    <option value="" disabled selected hidden>{languaje === 'ES' ? 'Elije una estado' : 'Choose a state'}</option>
                                    {estados.map((estado: string) => (<option key={estado} value={estado}>{estado}</option>))}
                                </select>
                                {errors?.entidad_federativa && <small className="text-danger">{errors?.entidad_federativa?.message ?? ''}</small>}
                            </div>
                            <div className='col-12 mt-2'>
                                <label htmlFor="esterilizada">{languaje === 'ES' ? '¿MASCOTA ESTERILIZADA?' : 'Pet Sterilized?'}</label>
                                <select id="esterilizada" className='form-control'
                                    {...register('esterilizada', { required: languaje === 'ES' ? 'Elije una opción' : 'Choose one option' })}
                                    required>
                                    <option value="" disabled selected hidden>{languaje === 'ES' ? 'Elije una opción' : 'Choose one option'}</option>
                                    <option value="true">{languaje === 'ES' ? 'SI' : 'YES'}</option>
                                    <option value="false">{languaje === 'ES' ? 'NO' : 'NOT'}</option>
                                </select>
                                {errors.esterilizada && <small className="text-danger">{errors.esterilizada.message}</small>}
                                {/* {JSON.stringify({esterilizada})} */}
                            </div>




                            {esterilizada && <>
                                <div className='col-12 mt-2'>
                                    <label htmlFor="comentarios">
                                        {languaje === 'ES' ?
                                            'Cargar evidencia fotográfica o certificado de esterilización'
                                            : 'Upload photographic evidence or sterilization certificate'}
                                    </label>
                                    <br />
                                    <small className="text-danger">{languaje === 'ES' ? 'Sube un documento que corrobore la esterilización' : 'Upload a document that corroborates sterilization'}</small>
                                    <DropImagesSmall scope={scope} ref={ImageCedule} mascota={mascota} />

                                </div>
                                <div className="col-12 col-lg-6 mt-2">
                                    <label htmlFor="esterilizacion_mvz_telefono">{languaje === 'ES' ? 'TELÉFONO DE MVZ QUE ESTERILIZÓ:' : 'VET PHONE THAT STERILIZED:'}</label>
                                    <input id="esterilizacion_mvz_telefono" className="form-control" placeholder={languaje === 'ES' ? 'Ingresa el numero' : `Enter the number`} autoComplete="off"
                                        {...register('esterilizacion_mvz_telefono', {
                                            required: esterilizada ? (languaje === 'ES' ? 'El numero es requerido' : 'The number is required') : false,
                                            maxLength: { value: 12, message: languaje === 'ES' ? 'Ingresa un telefono válido' : 'Enter a valid celphone' },
                                            minLength: { value: 12, message: languaje === 'ES' ? 'Ingresa un telefono válido' : 'Enter a valid celphone' }
                                        })}
                                        onChange={(e) => formatPhone(e.target.value, 'esterilizacion_mvz_telefono')} maxLength={12}
                                    />
                                    {errors.esterilizacion_mvz_telefono && <small className="text-danger">{errors.esterilizacion_mvz_telefono.message}</small>}
                                    {/* {JSON.stringify({nombre,pNombre:mascota?.payload.nombre})} */}
                                </div>
                                <div className="col-12 col-lg-6 mt-2">
                                    <label htmlFor="esterilizacion_mvz_nombre">{languaje === 'ES' ? 'NOMBRE MVZ Y CEDULA PROFESIONAL:' : 'VET NAME AND PROFESSIONAL ID:'}</label>
                                    <input id="esterilizacion_mvz_nombre" className="form-control" placeholder={languaje === 'ES' ? 'Ingresa el nombre' : `Enter the name`} autoComplete="off"
                                        {...register('esterilizacion_mvz_nombre', { required: false })} />

                                    {/* {JSON.stringify({nombre,pNombre:mascota?.payload.nombre})} */}
                                </div>
                            </>
                            }
                            <div className='col-12 mt-2 row align-items-end'>
                                <div className='col'>
                                    <label htmlFor="desparasitacion">{languaje === 'ES' ? '¿VACUNACIÓN Y DESPARASITACIÓN COMPLETA?' : 'Vaccination and deworming?'}</label>
                                    <select id="desparasitacion" className='form-control'
                                        {...register('desparasitacion', { required: languaje === 'ES' ? 'Elije una opción' : 'Choose one option' })}
                                        required>
                                        <option value="" disabled selected hidden>{languaje === 'ES' ? 'Elije una opción' : 'Choose one option'}</option>
                                        <option value="true">{languaje === 'ES' ? 'SI' : 'YES'}</option>
                                        <option value="false">{languaje === 'ES' ? 'NO' : 'NOT'}</option>
                                    </select>
                                    {errors.desparasitacion && <small className="text-danger">{errors.desparasitacion.message}</small>}
                                </div>
                                {desparasitacion ?
                                    <div>
                                        <label htmlFor="fecha_ultima_desparasitacion">{languaje === 'ES' ? 'Fecha de última desparasitación' : 'Date of last deworming'}</label>
                                        <input id="fecha_ultima_desparasitacion" className='form-control' type="date"
                                            {...register('fecha_ultima_desparasitacion', { required: desparasitacion ? (languaje === 'ES' ? 'Elije una fecha' : 'Choose one date') : '' })}
                                            required />
                                        {errors.fecha_ultima_desparasitacion && <small className="text-danger">{errors.fecha_ultima_desparasitacion.message}</small>}
                                    </div>

                                    : null}
                            </div>

                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="col-12 mt-2">
                            <label htmlFor="comentarios">{languaje === 'ES' ? 'CUÉNTANOS ACERCA DE TÚ MASCOTA' : 'TELL US ABOUT YOUR PET'}</label>
                            <textarea className="form-control" id="personalidad1" rows={7}
                                {...register('comentarios',
                                    { required: languaje === 'ES' ? 'Esté campo es requerido' : 'This field is required' })}></textarea>
                            {errors.comentarios && <small className="text-danger">{errors.comentarios.message}</small>}
                        </div>
                        {
                            scope === 'rehabilitacion' &&
                            <div className="col-12 mt-2">
                                <label htmlFor="necesidades">{languaje === 'ES' ? '¿CUÁLES SON LAS NECESIDADES DE TÚ MASCOTA?' : 'WHAT ARE THE NEEDS OF YOUR PET?'}</label>
                                <Select isMulti options={padecimientos} placeholder="Selecciona las necesidades de tu mascota" onChange={onChange} defaultValue={padecimientosCurrent} />
                                {!necesidades.length && <small className="text-danger">{languaje === 'ES' ? 'Elige al menos una opción' : 'Choose at least one option'}</small>}
                            </div>
                        }
                        <div className="col-12 mt-2">
                            <label htmlFor="comentarios">
                                {languaje === 'ES' ?
                                    'COMPARTE UN VIDEO E IMAGENES DE TÚ MASCOTA (MÁXIMO 3 ARCHIVOS)'
                                    : 'SHARE A VIDEO AND IMAGES OF YOUR PET (MAX 3 FILES)'}
                            </label>
                            <br />
                            <small className="text-danger">{languaje === 'ES' ? 'Sube al menos una imagen de tú mascota' : 'Upload at least one picture of your pet'}</small>
                            <DropImages scope={scope} ref={ImageDropper} mascota={mascota} />
                            <small className="text-danger d-block" style={{ whiteSpace: 'pre-line' }}>
                                {languaje === 'ES' ? `Máximo 5MB por foto y 20 MB por video
                                    Te recomendamos subir fotos bien enfocadas, nítidas, con la mascota sola ya que despiertan más interés y tienen mejor visibilidad. NO serán fotos validas aquellas que incluyan marcas de agua, datos de contacto, redes o teléfono. Serán eliminadas` :
                                    `Maximum 5MB per photo and 20MB per video
                                    We recommend that you upload well-focused, clear photos with the pet alone as they arouse more interest and have better visibility. Those that include watermarks, contact information, networks or telephone numbers will NOT be valid photos. They will be eliminated`
                                }
                            </small>
                            {mascota && <MascotaGalery id={mascota.id} canDelete={true} scope={scope} />}
                        </div>

                        <div className='col-12 mt-2'>
                            <label htmlFor="necesidades">{languaje === 'ES' ? '¿CUALES VACUNAS TIENE?' : 'Specify Vaccines:'}</label>
                            <textarea id="comentDesparasitacion" cols={20} rows={7} minLength={15} className="form-control"
                                {...register('comentDesparasitacion',
                                    {
                                        required: languaje === 'ES' ? 'Esté campo es requerido' : 'This field is required',
                                        minLength: {
                                            value: 15,
                                            message: languaje === 'ES' ? 'Mínimo 15 caracteres' : 'Minimum 15 characters'
                                        }
                                    })
                                }
                                required></textarea>
                            {errors.comentDesparasitacion && <small className="text-danger">{errors.comentDesparasitacion.message}</small>}
                        </div>
                        {/* <input type="hidden" id="dueno_mascota"/> */}
                        {dueno ? <input type="hidden" id="dueno_mascota" value={dueno.nombre}
                            {...register('dueno_mascota',
                                { required: languaje === 'ES' ? 'Esté campo es requerido' : 'This field is required' })} />
                            : <input type="hidden" id="dueno_mascota" />
                        }
                        {dueno ? <input type="hidden" id="dueno_mascota_correo" value={dueno.correo}
                            {...register('dueno_mascota_correo',
                                { required: languaje === 'ES' ? 'Esté campo es requerido' : 'This field is required' })} />
                            : <input type="hidden" id="dueno_mascota_correo" />
                        }
                        {dueno ? <input type="hidden" id="dueno_mascota_telefono" value={dueno.telefono ? dueno?.telefono : ' '}
                            {...register('dueno_mascota_telefono',
                            )} />
                            : <input type="hidden" id="dueno_mascota_telefono" />
                        }
                    </div>
                </div>
                {isLoading
                    ? <Loader />
                    : <button className="btn login_btn btn-sm" type="button" onClick={handleSubmit(abrirConfirm)}>{languaje === 'ES' ? 'Guardar' : 'Save'}</button>
                }

                <div className="modal_confirmacion sombra_contenedor" id="modal_confirm">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2>CONFIRMACIÓN</h2>
                            </div>
                            <div className="col-12 text-center">
                                <p>{languaje === 'ES' ? 'Declaro que la información aquí proporcionada es real y correcta, en caso de no serla y poder mostrar evidencia el registro de mascota y/o usuario será eliminado.' : 'I declare that the information provided here is real and correct, if it is not and I can show evidence, the pet and/or user record will be deleted.'}</p>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-2"></div>
                                    <div className="col-3">
                                        <button className="btn form-control" type="submit">si Acepto</button>
                                    </div>
                                    <div className="col-2"></div>
                                    <div className="col-3">
                                        <button className='btn form-control' onClick={cerrarConfirm}>no, Declino</button>
                                    </div>
                                    <div className="col-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

interface FormValues {
    nombre: string;
    color: string;
    tamano: 'mini' | 'pequeño' | 'mediano' | 'grande' | 'muy grande';
    sexo: 'macho' | 'hembra';
    raza: 'mestizo' | 'mezcla de raza con mestizo' | 'de raza' | 'no se';
    especie: 'gato' | 'perro';
    peso: number;
    edad: {
        valor: number;
        unidad: 'meses' | 'años';
    },
    personalidad: string;
    convine_otras: string;
    convine: string;
    convine_ninos: string;
    entrenado: string;
    educado: string;
    comentarios: string;
    estado: 'adopcion' | 'rehabilitacion';
    imagenes?: string[];
    ciudad: string;
    entidad_federativa: string;
    esterilizada: string;
    esterilizacion_mvz_nombre: string;
    esterilizacion_mvz_telefono: string;
    desparasitacion: string;
    comentDesparasitacion?: string;
    fecha_ultima_desparasitacion: string;
    dueno_mascota: any;
    dueno_mascota_telefono: any;
    dueno_mascota_correo: any;
    eliminado: string;
    fecha_eliminado: string;
    fecha_creado: any;
    fecha_notificacion: any;
    fecha_expiracion: any;
};

interface Option {
    value: string;
    label: string;
}
