import React, { useContext } from 'react';
import { landingpageImages } from '../../helpers/callejeritosImages';
import { UserContext } from '../../context/UserContext';
import { AuthContext } from '../../context/AuthContext';
import { RegisterForm } from '../../components/Auth/RegisterForm';
import { LoginForm } from '../../components/Auth/LoginForm';


export const CompromisoSection = () => {
    
    const { languaje } = useContext(UserContext);
    const { status } = useContext(AuthContext);

    return (
        <> 
            <div className="compromiso-container animate__animated animate__fadeIn"></div>
            { status !== 'authenticated' &&
                <div className="row home_login">
                    <div className="col-12 col-md-12 col-lg">
                        <h3 className="form__title">{ languaje === 'ES' ? 'Únete a Callejeritos': 'Join Callejeritos' }</h3>
                        <RegisterForm />
                    </div>
                    <div className="col-12 col-md-12 col-lg mt-2 mt-md-0">
                        <h3 className="form__title">{ languaje === 'ES' ? 'Inicia sesión': 'Login' }</h3>
                        <div className="d-flex justify-content-end">
                            <div className="w-100">
                                <LoginForm showLabels/>
                            </div>
                        </div>
                    </div>
                    <div className="col-2 p-0 m-0 d-xl-block d-md-none d-none">
                        <img src={ landingpageImages('./Compromiso/kitty.png').default } alt="Callejerito" className="img-fluid home_cat"/>
                    </div>
                </div> 
            }
            <div className="compromiso-div">
                <div>
                    <img src={ landingpageImages('./Compromiso/perro.png').default } alt="Callejerito" className="dog img-fluid"/>
                </div>
                <div className="compromiso">
                    { languaje === 'ES' ?
                        <>
                            <h1>Compromiso,</h1>
                            <h1>conciencia y confianza.</h1>
                        </> : <>
                            <h1>Commitment,</h1>
                            <h1>awareness and trust.</h1>
                        </>
                    }
                    
                </div>
            </div>
            <div className="container-fluid impacto-container animate__animated animate__fadeInLeft">
                <img src={ landingpageImages(`./Compromiso/${ languaje }/sostenibilidad.png`).default } alt="sostenibilidad" className="img-fluid imp-img" />
                <img src={ landingpageImages(`./Compromiso/${ languaje }/impacto.png`).default } alt="impacto" className="img-fluid imp-img"/>
                <img src={ landingpageImages(`./Compromiso/${ languaje }/profesionales.png`).default } alt="profesionales" className="img-fluid imp-img" />
            </div>
        </>
    )
}
