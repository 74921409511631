/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react'
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/es-mx';
import Swal from 'sweetalert2';

import { AuthContext } from '../../context/AuthContext';
import { UserContext } from '../../context/UserContext';
import { PaypalPlanDetails, PaypalSubscrptionDetails } from '../../interfaces/servicesInterfaces';

const SubscriptionInitialState: PaypalSubscrptionDetails = {
    status: '',
    status_update_time: '',
    id: '',
    plan_id: '',
    start_time: '',
    subscriber: {
        email_address: '',
        name: {
            given_name: '',
            surname: ''
        }
    },
    billing_info: {
        next_billing_time: ''
    },
    create_time: '',
    update_time: ''
};

const planInitialState: PaypalPlanDetails = {
    id: '',
    name: '',
    status: '',
    description: '',
    billing_cycles: [{
        pricing_scheme: {
            fixed_price: {
                currency_code: '',
                value: ''
            }
        },
        frequency: {
            interval_unit: '',
            interval_count: 0
        }
    }]

};

interface Props {
    setKey: (key: 'data' | 'exp') => void;
  };

export const SubscripcionDetalles = ({ setKey }: Props) => {

    const { user, actualizarUsuario } = useContext(AuthContext);
    const { languaje } = useContext(UserContext);

    const [subscription, setSubscription] = useState(SubscriptionInitialState);
    const [plan, setPlan] = useState(planInitialState);

    const uri =  `${process.env.REACT_APP_PAYPAL_URL}/subscriptions/${ user?.subscription_id }`;

    const apiConfig = {
        headers: {
            'Content-Type': 'application/json'
        },
        auth: {
            username: (process.env.REACT_APP_PAYPAL_CLIENT_ID as string),
            password: (process.env.REACT_APP_PAYPAL_SECRET as string),
        }
    }

    useEffect(() => {

        if (!user?.subscription_id) {
            return;
        }

        (async() => {
            try {
        
                const { data } = await axios.get<PaypalSubscrptionDetails>(uri, apiConfig);
                const { data: plan } = await axios.get<PaypalPlanDetails>(`${process.env.REACT_APP_PAYPAL_URL}/plans/${ data.plan_id }`, apiConfig);

                setSubscription(data);
                setPlan(plan);
                
            } catch (err: any) {
                console.log(err);
            }
        })();

    }, [ user?.subscription_id, uri ]);

    const handleCancelPlan = () => {

        const title = languaje === 'ES'
            ? `Estas seguro que deseas cancelar el plan ${ plan.name }`
            : `Are you sure to cancel the subscription to ${ plan.name }`;
        const text = languaje === 'ES'
            ? 'No seremos capaces de revertir este efecto!'
            : `We won't be able to revert this!`;
        const confirmButtonText = languaje === 'ES' ? 'Si, cancelar subscripción!' : `Yes, cancel it!`;
        const cancelButtonText = languaje === 'ES' ? 'Cancelar' : 'Cancel';

        Swal.fire({
            title,
            text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText,
            cancelButtonText
        }).then( async (result) => {
            if (result.isConfirmed) {
                try {

                    if (!user?.subscription_id) {
                        return;
                    }

                    await axios.post<any>(`${ uri }/cancel`,{},apiConfig,);

                    const payload = {
                        ...user,
                        subscription_id: ''
                    };
                    
                    await actualizarUsuario(payload);

                    setKey('data');

                } catch (err: any) {
                    console.log(err);
                }
            }
        });
    };

    const handleRemoveSubs = async() => {
        try {

            if (!user?.subscription_id) {
                return;
            }

            const payload = {
                ...user,
                subscription_id: ''
            };
            
            await actualizarUsuario(payload);

            Swal.fire(
                'Completado!',
                'success'
            );

            setKey('data');

        } catch (err: any) {
            console.log(err);
        }
    };

    return (
        <div className="row p-4">
            <div className="col-12 col-md-6">
                <h2>{ languaje === 'ES' ? 'Subscripción' : 'Subscription' }</h2>
                <div>
                    <p className="m-0">{ languaje === 'ES' ? 'Estado' : 'Status' }</p>
                    
                    { subscription.status === 'ACTIVE'
                        ? <h5><span className="badge badge-success">{ languaje === 'ES' ? 'ACTIVA' : 'ACTIVE' }</span></h5>
                        : <h5><span className="badge badge-danger">{ languaje === 'ES' ? 'CANCELADA' : 'CANCELED' }</span></h5>
                    }
                </div>
               <div>
                    <p className="m-0">{ languaje === 'ES' ? 'ID de la subscripción' : 'Subscription ID' }</p>
                    <h5 className="m-0">{ subscription.id }</h5>
               </div>
               <div className="mt-3">
                    <p className="m-0">{ languaje === 'ES' ? 'Fecha de inicio:' : 'Start time' }</p>
                    <h5 className="m-0">{ languaje === 'ES'
                        ? moment(subscription.start_time).locale('es-mx').format('LLLL a')
                        : moment(subscription.start_time).locale('en').format('LLLL a')
                    }</h5>
               </div>
               <div className="mt-3">
                    <p className="m-0">{ languaje === 'ES' ? 'Subscriptor' : 'Subscriber' }</p>
                    <h5 className="m-0">{ subscription.subscriber.name.given_name }</h5>
                    <h5 className="m-0">{ subscription.subscriber.email_address }</h5>
               </div>
               <div className="mt-3 mb-4">
                    <p className="m-0">{ languaje === 'ES' ? 'Siguiente cobro' : 'Next billing time' }</p>
                    <h5 className="m-0">{ languaje === 'ES'
                        ? moment(subscription.billing_info.next_billing_time).locale('es-mx').format('LL')
                        : moment(subscription.billing_info.next_billing_time).locale('en').format('LL')
                    }</h5>
               </div>
                { subscription.status === 'ACTIVE' ? 
                    <button className="btn btn-danger" onClick={ handleCancelPlan }>
                        { languaje === 'ES' ? 'Cancelar subscripción' : 'Cancel subscription' }
                    </button>
                    : <>
                        <button className="btn btn-danger" onClick={ handleRemoveSubs }>
                            { languaje === 'ES' ? 'Remover relación' : 'Remove realation' }
                        </button>
                        <div>
                            <small> { languaje === 'ES' ? 'Hemos detectado que la suscripción del donativo hasido cancelada por un medio externo, remueve la relación para poder subscribirte a una nueva'
                            : 'We have detected that the subscription of the donation has been canceled by an external means, remove the relationship to be able to subscribe to a new one' }</small>
                        </div>
                    </>
                }
            </div>
            <div className="col-12 col-md-6">
                <h2>PLAN</h2>
                <div>
                    <p className="m-0">{ languaje === 'ES' ? 'ID del plan' : 'Plan ID' }</p>
                    <h5 className="m-0">{ plan.id }</h5>
               </div>
               <div className="mt-3">
                    <p className="m-0">{ languaje === 'ES' ? 'Descripcion' : 'Description' }</p>
                    <h5 className="m-0">{ plan.name }</h5>
                    <h5 className="m-0">{ plan.description }</h5>
               </div>

               <div className="mt-3">
                    <p className="m-0">{ languaje === 'ES' ? 'Monto del donativo' : 'Donation amout' }</p>
                    <h5 className="m-0">
                        { plan.billing_cycles[0].pricing_scheme.fixed_price.value }  { plan.billing_cycles[0].pricing_scheme.fixed_price.currency_code }
                    </h5>
               </div>

               <div className="mt-3">
                    <p className="m-0">{ languaje === 'ES' ? 'Frecuencia del pago' : 'Payment frequency' }</p>
                    <h5 className="m-0"> { languaje === 'ES' ? `Cada 1 Mes` : 'Each 1 Month' } </h5>
               </div>
            </div>
        </div>
    )
}
