/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { Route } from 'react-router';
import { UserContext } from '../context/UserContext';

interface Props {
  exact: boolean;
  path: string;
  component: React.ComponentType<any>;
}

export const EnglishRoute = ({ component: Component, ...rest }: Props) => {

  const { setLanguaje } = useContext(UserContext);

  const handleSetLanguaje = (lang: string) => {
    localStorage.setItem('lang', lang);
    setLanguaje(lang);
  };

  useEffect(() => {
    handleSetLanguaje('EN');
  }, []);
  
  return (
    <Route {...rest}>
      {/* @ts-ignore */}
      <Component/>
    </Route>
  )
}
