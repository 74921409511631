import axios from 'axios';

let baseURL: string =  (process.env.REACT_APP_KW2P as string);

const kw2pApi  = axios.create({ baseURL });

kw2pApi.interceptors.request.use(
    async(config) => {    
        const jwt = await localStorage.getItem('CA_JWT');

        if (jwt) {
            config.headers.Authorization = `Bearer ${ jwt }`;
        }

        return config;
    }
);

export default kw2pApi;
