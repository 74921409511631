/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { UserContext } from '../../context/UserContext';
// import { AxiosResponse } from 'axios';
import { useKW2P } from '../../hooks/useKW2P';
import { Loader } from '../../components/ui/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

export const Boletin = () => {

    const { languaje } = useContext(UserContext);
    const { handleError, setLoading, isLoading, user } = useContext(AuthContext);
    const { consultarDocumento , eliminarDocumento} = useKW2P();

    const[ boletines, setBoletines] = useState<Boletines[]>([]);

    const fetchData = async() => {
        try {
            setLoading(true);
            const { data: { data } } = await consultarBoletines();
            setBoletines(data);
            setLoading(false);
        } catch (err: any) {
            setLoading(false);
            handleError(err);
        }
    }

    // const consultarBoletines = ():Promise<AxiosResponse<BoletinesData>> => {
        const consultarBoletines = () => {
        
            const BODY = {
                schema: 'boletines',
                order: [{
                    field: 'payload->nombre',
                    direction: 'asc'
                }]
            };
            return consultarDocumento(BODY);

    };

    useEffect(() => {

        if (!user) {
            return;
        }

        fetchData();

    }, []);

    const handleDelete = (boletines: Boletines) => {
        Swal.fire({
            title: languaje === 'ES' ? `¿Está seguro de eliminar a ${ boletines.payload.nombre }`:`Are you sure that you want delete ${ boletines.payload.nombre }?`,
            text: languaje === 'ES' ? '¡No podrás revertir esto!': "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: languaje === 'ES' ? '¡Sí, bórralo!':'Yes, delete it!',
            cancelButtonText: languaje === 'ES' ? 'Cancelar':'Cancel'
        }).then(async (result) => {
            if (result.isConfirmed) {

                try {
                    setLoading(true);
                    const BODY = {
                        schema: 'boletines',
                        serie: boletines.serie,
                        folio: boletines.folio
                    };
                    await eliminarDocumento(BODY);
                    setLoading(false);
                    Swal.fire(
                        languaje === 'ES' ? 'Eliminado':'Deleted!',
                        languaje === 'ES' ? 'eliminada con éxito': 'deleted.',
                        'success'
                    );
                    fetchData();
                } catch (err: any) {
                    console.log(err);
                    handleError(err);
                }
            }
        });
    };

    return(
        <div className="mascotas__container">
                <div className="mascotas__header">
                    <p>{ languaje === 'ES' ? 'Usuarios suscritos al boletin' : 'Users subscribed to the newsletter' }</p>
                </div>

            <div className="mascotas__body">
                <div className="row">
                    <div className="col-12">
                        <div className="table-responsive">
                            <table className="table table-sm table-hover">
                                <thead>
                                    <tr>
                                        <th>{languaje === 'ES' ? 'NOMBRE':'NAME'}</th>
                                        <th>{languaje === 'ES' ? 'EMAIL':'EMAIL'}</th>
                                        <th>{languaje === 'ES' ? 'Teléfono' : 'PHONE'}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        boletines.filter(boletin => boletin.estado !== 'cancelado')
                                        .map((boletin: Boletines) => (
                                            <tr key={ boletin.folio } >
                                                    
                                                    <td>{boletin.payload.nombre}</td>
                                                    <td>{boletin.payload.email}</td>
                                                    <td>{boletin.payload.telefono}</td>
                                                    <td>
                                                <div className='d-flex justify-content-end'>
                                                    <button onClick={() => handleDelete(boletin)} className="btn btn-sm btn-outline-danger mr-2">
                                                        <FontAwesomeIcon icon={faTrash as IconProp}/>
                                                    </button>
                                                </div>
                                            </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            { isLoading && <Loader/>}
        </div>
    )
} 

// interface BoletinesData {
//     status:   string;
//     data:     Boletines[];
//     paginate: null;
// }

interface Boletines {
    schema:     string;
    estado:     string;
    serie:      string;
    folio:      number;
    payload:    Payload;
}

interface Payload {
    nombre   : string;
    email: string;
    telefono : string;
    captcha : string;
}