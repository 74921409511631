import { useEffect, useState } from 'react';

export const useKeys = () => {
    
    const facebookID = '105688408197525';
    /* const intagramID = '45449302416'; */
    
    const [facebookLink, setFacebookLink] = useState('https://www.facebook.com/callejeritosmexico/');
    
    const [instagramLink, setInstagramLink] = useState('https://www.instagram.com/callejeritosmexico/')

    const getMobileOperatingSystem = () => {
        let userAgent = navigator.userAgent || navigator.vendor;

         // Windows Phone must come first because its UA also contains "Android"
         if (/windows phone/i.test(userAgent)) {
            return 'Windows Phone';
        }

        if (/android/i.test(userAgent)) {
            return 'Android';
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent)) {
            return 'iOS';
        }

        return 'unknown';

    }

    const handleRedirect = () => {
        let moviltype = getMobileOperatingSystem();

        if (moviltype === 'Android') {
            setFacebookLink(`fb://page/${ facebookID }`);
            setInstagramLink(`https://www.instagram.com/_u/callejeritosmexico/`);
        }
    };

    const [languaje, setLanguaje] = useState('ES');

    useEffect(() => {
        handleRedirect();
    });

    useEffect(() => {
        const lang = localStorage.getItem('lang') || 'ES';
        setLanguaje(lang);
    }, [ languaje, setLanguaje ]);


    return {
        facebookLink,
        instagramLink,
        languaje,
        setLanguaje
    }

}
