import React from 'react';
import { Link } from 'react-router-dom';

import { docsFiles, nosotrosImages } from '../helpers/callejeritosImages';

interface Props {
    file: string;
    text: string;
    showImg?: boolean;
}

export const DownloadButton = ({ file, text, showImg = true }: Props) => {

    if (file.length === 0) {
        return (
            <button className="btn btn-descarga">
                <div className="btn_descarga_content">
                    { showImg && <img  className="mr-4" src={ nosotrosImages('./Donaciones/descarga_icon.png').default } height={ 30 } alt="descarga" /> }
                    <p className="m-0">{ text }</p>
                </div>
            </button>
        )
    }

    return (
        <Link className="btn btn-descarga ml-2" to={ docsFiles(`./${ file }`).default } target="_blank" download>
            <div >
                <div className="btn_descarga_content">
                    { showImg && <img  className="mr-4" src={ nosotrosImages('./Donaciones/descarga_icon.png').default } height={ 30 } alt="descarga" /> }
                    <p className="m-0">{ text }</p>
                </div>
            </div>
        </Link>
    )
}
