import React from 'react';
import { CompromisoSection } from '../pages/LandingPage/CompromisoSection';
import { ContactoSection } from '../pages/LandingPage/ContactoSection';
import { MainSlider } from '../pages/LandingPage/MainSlider';
import { ProductoSection } from '../pages/LandingPage/ProductoSection';
import { SocialSection } from '../pages/LandingPage/SocialSection';

export const HomeApp = () => {

    return (
        <main className="animate__animated animate__fadeIn">
            <MainSlider />
            <CompromisoSection/>
            <ProductoSection />
            <ContactoSection />
            <SocialSection />
        </main>
    )
}
