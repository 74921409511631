import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { Albergues } from '../pages/Nosotros/Albergues';
import { CausaSocial } from '../pages/Nosotros/CausaSocial';
import { Historia } from '../pages/Nosotros/Historia';
import { Manifiesto } from '../pages/Nosotros/Manifiesto';
import { Nosotros } from '../pages/Nosotros/Nosotros';
import { EnglishRoute } from './EnglishRoute';
import { SpanishRoute } from './SpanishRoute';

export const NosotrosRouter = () => {
    
    const { url } = useRouteMatch();

    return(
        <Switch>
            <SpanishRoute exact path='/nosotros' component={Nosotros}/>
            <SpanishRoute exact path={`${ url }/historia`} component={ Historia } />
            <SpanishRoute exact path={`${ url }/manifiesto`} component={ Manifiesto } />
            <SpanishRoute exact path={`${ url }/causa-social`} component={ CausaSocial } />
            <SpanishRoute exact path={`${ url }/albergues`} component={ Albergues } />

            <EnglishRoute exact path={`/us`} component={ Nosotros } />
            <EnglishRoute exact path={`${ url }/history`} component={ Historia } />
            <EnglishRoute exact path={`${ url }/manifesto`} component={ Manifiesto } />
            <EnglishRoute exact path={`${ url }/social-cause`} component={ CausaSocial } />
            <EnglishRoute exact path={`${ url }/shelters`} component={ Albergues } />

            <Route path="*">
                <Redirect to="/" />
            </Route>
        </Switch>
    );
}
