import { useContext } from 'react';
import { DonacionForm } from './';
import { nosotrosImages } from '../../helpers/callejeritosImages';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';

export const DonacionEsterilizacion = () => {

    const { languaje } = useContext(UserContext);

    return (
        <div className="esterilizacion-container body-donacionali-form animate__animated animate__fadeIn text-center">
            {
                languaje === 'ES' ?
                <>
                    <h3>Donación de esterilización</h3>
                     <p className="text font-weight-bold">En compromiso con nuestra causa y total transparencia hacia todos nuestros donantes, es importante y 
                        requisito contar con los datos del formulario para poder generar tu recibo como el ejemplo que mostramos a continuación, donde se mencionará 
                        el nombre de la mascota, fecha y evidencia fotográfica. Las infografías aquí mostradas y descargables son un aproximado, debido a los 
                        incrementos en costos en medicamentos e insumos cada año por inflación, los costos se actualizan año con año.</p>
                    <div className='d-flex justify-content-center'>        
                        <DonacionForm  tipo='esterilizacion' />
                    </div>
                    <div className="form-donacion-div">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-start">
                                    <Link className="btn btn-descarga" to={ nosotrosImages(`./Donaciones/Esterilizaciones/costo_personas.jpg`).default }
                                    target="_blank" download>
                                        <div className="d-flex align-items-center">
                                            <img className="mr-4" src={ nosotrosImages('./Donaciones/descarga_icon.png').default } height={ 30 } alt="descarga" />
                                            <p className="btn-descarga-text">Descargar infografía donación costo a personas</p>
                                        </div>
                                </Link>
                            </div>
                            <div className="col-12 mt-3 d-flex justify-content-start">
                                <Link className="btn btn-descarga" to={ nosotrosImages(`./Donaciones/Esterilizaciones/costo_empresas.jpg`).default }
                                    target="_blank" download>
                                        <div className="d-flex align-items-center">
                                            <img  className="mr-4" src={ nosotrosImages('./Donaciones/descarga_icon.png').default } height={ 30 } alt="descarga" />
                                            <p className="btn-descarga-text">Descargar infografía donación costo a empresas</p>
                                        </div>
                                </Link>
                            </div>
                        </div>
                        <img src={ nosotrosImages(`./Donaciones/Esterilizaciones/formato.png`).default } alt="formato esterilización"
                                className="img-fluid img-formato" />
                    </div>
                </>
                :
                <>
                    <h3>Sterilization donation</h3>
                    <p className="text font-weight-bold">In commitment to our cause and total transparency towards all our donors, it is important and required to have 
                        the data from the form to be able to generate your receipt like the example shown below, where the name of the pet, date and photographic evidence 
                        will be mentioned. The infographics shown here and downloadable are approximate, due to the increases in costs of medicines and supplies each 
                        year due to inflation, the costs are updated year after year.</p>
                    <div className='d-flex justify-content-center'>
                        <DonacionForm  tipo='esterilizacion' />
                    </div>
                        <div className="form-donacion-div">
                            <div className="row">
                                <div className="col-12">
                                    <Link className="btn btn-descarga" to={ nosotrosImages(`./Donaciones/Esterilizaciones/costo_personas.jpg`).default }
                                        target="_blank" download>
                                            <div className="d-flex align-items-center">
                                                <img className="mr-4" src={ nosotrosImages('./Donaciones/descarga_icon.png').default } height={ 30 } alt="descarga" />
                                                <p className="btn-descarga-text">Download infographic donation cost to people</p>
                                            </div>
                                    </Link>
                                </div>
                                <div className="col-12 mt-3">
                                    <Link className="btn btn-descarga" to={ nosotrosImages(`./Donaciones/Esterilizaciones/costo_empresas.jpg`).default }
                                        target="_blank" download>
                                            <div className="d-flex align-items-center">
                                                <img  className="mr-4" src={ nosotrosImages('./Donaciones/descarga_icon.png').default } height={ 30 } alt="descarga" />
                                                <p className="btn-descarga-text">Download infographic donation cost to companies</p>
                                            </div>
                                    </Link>
                                </div>
                            </div>
                        <img src={ nosotrosImages(`./Donaciones/Esterilizaciones/formato.png`).default } alt="formato esterilización"
                                className="img-fluid img-formato" />
                    </div>
                </>
            }
        </div>
    )
}