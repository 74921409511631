import React, { useContext } from 'react'
import { RegisterForm } from '../../components/Auth/RegisterForm'
import { UserContext } from '../../context/UserContext';
import { callejeritosImages } from '../../helpers/callejeritosImages';

export const Register = () => {

    const { languaje } = useContext(UserContext);

    return (
        <div className="div-register animate__animated animate__fadeIn">
            <div className="header__register">
                <img src={ callejeritosImages(`./Auth/Register/titulo${ languaje }.png`).default } alt="Unete" className="img-fluid register_title"/>
            </div>
            <div className="body__register animate__animated animate__fadeInUp">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <RegisterForm />
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
                        <img src={ callejeritosImages('./Auth/Register/gatito_rg.jpg').default } alt="Gatito" className="img-fluid gatito_register"/>
                    </div>
                </div>
            </div>
        </div>
    )
}
