import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { DonacionForm } from './';

export const DonacionAlimento = () => {

    const { languaje } = useContext(UserContext);

    return (
        <div className="body-donacionali-form pb-4 animate__animated animate__fadeIn text-center">
            {
                languaje === 'ES'
                ? <>
                    <h3>Donación de alimento</h3>
                    <p className="text font-weight-bold">Para donación de alimento podemos recibirlo físicamente o si deseas donar
                    ya sea una ocasión o recurrentemente para la compra del mismo, de igual manera obtendrás un
                    comprobante y evidencia real a donde fue destinado.</p>
                </>
                : <>
                    <h3>Food donation</h3>
                    <p className="text font-weight-bold">For food donation we can receive it physically or if you want to donate either once or repeatedly
                    for the purchase of it, in the same way you will obtain a receipt and real evidence where it was destined.</p>
                </>
            }
            <div className='d-flex justify-content-center'>
                <DonacionForm tipo='alimento' />            
            </div>
        </div>
    )
}