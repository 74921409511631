import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FacebookShareButton } from 'react-share';
import FacebookIcon from 'react-share/lib/FacebookIcon';
import { UserContext } from '../../context/UserContext';

import { callejeritosImages } from '../../helpers/callejeritosImages';
import { Mascota } from '../../interfaces/mascotasInterfaces';


interface Params {
    mascota: Mascota;
    scope: 'adopcion' | 'rehabilitacion';
}

export const MascotaCard = ({ mascota, scope }: Params) => {

    
    const { payload: { imagenes }, id } = mascota;
    const portada = imagenes ? imagenes[0] : callejeritosImages('./no_disponible.png').default;
    
    const { languaje } = useContext(UserContext);

    // <meta property='og:image' content={`${portada}`}/>
    return (
        <>
            {console.log(mascota.payload)}
         <Card style={{ width: '20rem' }} className='h-100'>
            <Card.Img variant="top" src={ portada } className='card__img' />
            <Card.Body>
                <Card.Title>{mascota.payload.nombre}</Card.Title>
                <div className='card__detalles '>
                    <Link to="/donaciones" className="btn btn-sm btn-callejeritos">{ languaje === 'ES' ? 'Donar' : 'Donate' }</Link>
                    <Link className="btn btn-sm btn-callejeritos" to={ `/mascotas/${ scope }/${ id }` } >{ languaje === 'ES' ? 'Detalles' : 'Details' }</Link>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-1 col-lg-3"></div>
                        <div className="col-3 text-center whatsapp"><a className='btn btn-md btn-success mt-4' href={`https://api.whatsapp.com/send?text=https://callejeritos.mx/mascotas/adopcion/${ id }`} target="_blank"><FontAwesomeIcon icon={faWhatsapp as IconProp}/></a></div>
                        <div className="col-3 col-lg-6 text-center"><a className='btn btn-md btn-primary mt-4' href={`http://www.facebook.com/sharer.php?u=https://callejeritos.mx/mascotas/adopcion/${ id }`} target="_blank"><FontAwesomeIcon icon={faFacebook as IconProp}/></a></div>
                        {/* <div className="col-3 col-lg-6 text-center">
                        <FacebookShareButton
                            url={`http://www.facebook.com/sharer/sharer.php?u=https://callejeritos.mx/mascotas/adopcion/${ id }`}
                            quote={'Dummy text! esto es un texto'}
                            hashtag="#callejeritos"
                        >
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        </div> */}
                        <div className="col-3 text-center whatsapp"><a className='btn btn-md btn-danger mt-4' href={`https://instagram.com/callejeritosmexico?igshid=NTc4MTIwNjQ2YQ==`} target="_blank"><FontAwesomeIcon icon={faInstagram as IconProp}/></a></div>
                    </div>
                </div>
            </Card.Body>
        </Card>   
    </>
    )
}