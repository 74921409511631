import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { PaypalCheckoutSubscription } from '../Pagos/PaypalCheckoutSubscription';

export const DonacionRecurrente = () => {

    const { languaje } = useContext(UserContext);

    return (
        <div className="body-donacionali-form animate__animated animate__fadeIn text-center">
            {
                languaje === 'ES' ?
                <>
                    <h3>Donación recurrente</h3>
                    <p className="text font-weight-bold">Si deseas hacer una donación recurrente para apadrinar a
                    uno de nuestros mejores amigos, recibirás un recibo mes con mes, así como información
                    de la mascota y evidencia. Oportunidad de conocerle y saber su situación real actual y avances.</p>
                </>
                :
                <>
                    <h3>Recurring donation</h3>
                    <p className="text font-weight-bold">If you want to make a recurring donation to sponsor one of our
                    best friends, you will receive a monthly receipt, as well as information about the pet and evidence.
                    opportunity to meet them and know their current real situation and progress.</p>
                </>
            }
            
            <PaypalCheckoutSubscription />     
        </div>
    )
}
