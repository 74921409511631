/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { MascotaForm } from '../../../components/Mascotas/MascotaForm';
import { Loader } from '../../../components/ui/Loader';

import { AuthContext } from '../../../context/AuthContext';
import { UserContext } from '../../../context/UserContext';
import { useKW2P } from '../../../hooks/useKW2P';
import { MascostasData, Mascota } from '../../../interfaces/mascotasInterfaces';
import { ExpedienteAzulFooter } from '../../../components/ui/ExpedienteAzulFooter';

export const EditarAdopcion = () => {

    const history = useHistory();
    const { id } = useParams<RouteParams>();

    const { languaje } = useContext(UserContext);
    const { handleError, setLoading, isLoading, user } = useContext(AuthContext);

    const { consultarCatalogoPublic } = useKW2P();

    const [mascota, setMascota] = useState<Mascota>();

    const consultarMascotas = (): Promise<AxiosResponse<MascostasData>> => {
        const BODY = {
            schema: 'mascotas',
            filter: {
                    where: [
                    {
                        field: 'id',
                        operator: '=',
                        value: Number(id)
                    }
                ]
            }
        };

        return consultarCatalogoPublic(BODY);
    }

    const fetchData = async() => {
        try {
            setLoading(true);
            const { data: { data } } = await consultarMascotas();

            if (!data.length) {
                history.push(`/mascotas/mis-adopciones`);
                return;
            }

            // if (data[0].payload.usuario_id !== user?.id) {
            //     history.push(`/mascotas/mis-adopciones`);
            //     return;
            // }

            setMascota(data[0]);
            setLoading(false);
        } catch (err: any) {
            setLoading(false);
            handleError(err);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div className='mascotas__container mb-0'>
                <div className="mascotas__header">
                    <h3>{ languaje === 'ES' ? 'Editar Mascota para Adopción' : 'Edit Pet to Adoption' }</h3>
                    <Link to="/mascotas/mis-adopciones" className="btn btn-callejeritos">{ languaje === 'ES' ? 'REGRESAR' : 'BACK' }</Link>
                </div>
                <div className="mascotas__body">
                    { mascota && <MascotaForm mascota={ mascota } dueno={null} scope='adopcion' modificar={false}/> }
                </div>
                <div className="mascotas__footer mb-2">
                    <ExpedienteAzulFooter/>
                </div>
            </div>
            { isLoading && <Loader/>}
            
        </>
    )
}

interface RouteParams {
    id: string;
    /* scope: 'adopcion' | 'rehabilitacion'; */
}
