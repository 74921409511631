import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { Alianza } from '../pages/Equipo/Alianza';
import { Patrocinadores } from '../pages/Equipo/Patrocinadores';
import { EnglishRoute } from './EnglishRoute';
import { SpanishRoute } from './SpanishRoute';

export const EquipoRouter = () => {

    const { url } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${ url }`}>
                <Redirect to={`/`} />
            </Route>   
            <SpanishRoute exact path={`${ url }/patrocinadores`} component={ Patrocinadores } />
            <SpanishRoute exact path={`${ url }/alianzas-estrategicas`} component={ Alianza } />
            <EnglishRoute exact path={`${ url }/sponsors`} component={ Patrocinadores } />
            <EnglishRoute exact path={`${ url }/strategic-alliances`} component={ Alianza } />
            <Route path="*">
                <Redirect to="/" />
            </Route>
        </Switch>
    )
}
