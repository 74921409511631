const callejeritosImages = require.context('../assets/img', true);
const contactoImages = require.context('../assets/img/Contacto', true);
const equipoImages = require.context('../assets/img/Equipo', true);
const footerImages = require.context('../assets/img/Footer', true);
const headerImages = require.context('../assets/img/Header', true);
const landingpageImages = require.context('../assets/img/Landing', true);
const nosotrosImages = require.context('../assets/img/Nosotros', true);
const productosImages = require.context('../assets/img/Productos', true);
const recursosImages = require.context('../assets/img/Recursos', true);
const docsFiles = require.context('../assets/docs');
const videosModal = require.context('../assets/videos', true);

const ReCAPTCHAAPIKEY = '6LcjwdoaAAAAAD_ChsTlzFmbyuzEsMt_V90cz8s7';
const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export {
    videosModal,
    docsFiles,
    recursosImages,
    productosImages,
    nosotrosImages,
    landingpageImages,
    headerImages,
    footerImages,
    equipoImages,
    contactoImages,
    callejeritosImages,
    emailPattern,
    ReCAPTCHAAPIKEY
};
