import React from 'react';
import { Accordion } from 'react-bootstrap';
import { TextToggler } from '../../components/ui/TextToggler';
import { DownloadButton } from '../../components/DownloadButton';

interface FaqsComponent {
  lang: string;
}

export const ESFaqs = ({ lang }: FaqsComponent) => {
  return (
    <Accordion defaultActiveKey="0">
      <div>
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="0"
            text="Ya no confió en este tipo de proyectos, asociaciones, personas que dicen ayudar y no es así
                 ¿Cómo se que Callejeritos México es proyecto real y no una estafa?"
          />
        </div>
        <Accordion.Collapse eventKey="0">
          <>
            <p className="text">
              Ante todo, estamos conscientes de que esto es una triste realidad
              en México y el mundo. Existen personas, “asociaciones, rescatistas
              y/o albergues” que lucran con el sufrimiento animal, dicen apoyar
              a la causa y en realidad no es así o es mínimo lo que hacen a
              cambio de lo que reciben. Parte de nuestra labor es evidenciar y
              tener total transparencia a nuestras operaciones. Contamos con un
              equipo de profesionales, procesos, sistemas que nos permiten
              respaldar lo que hacemos y decimos. Somos un proyecto legalmente
              constituido y con una política de CERO TOLERANCIA es este tipo de
              actividades apócrifas y falsas. Callejeritos México nace entre
              muchas otra razones por haber sido victima de estafa y mentiras
              sobre lo que personas dicen hacer, por lo que entendemos
              perfectamente la frustración y desconfianza que suele existir.
              Empresas e iniciativa privada nos respaldan, pero sobre todo la
              apertura, transparencia y pasión que hay detrás de este gran
              proyecto hecho en México con el Corazón
            </p>
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="1"
            text="¿Quién integra y forma parte de Callejeritos México?"
          />
        </div>
        <Accordion.Collapse eventKey="1">
          <>
            <p className="text">
              Somos un equipo de ciudadanos emprendedores, conscientes,
              empáticos y sensibles que busca apoyar esta noble causa y
              problemática social en México y el mundo. Iniciativa privada
              también forma parte de este gran proyecto.
            </p>
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler eventKey="2" text="¿Son una Fundación o Asociación?" />
        </div>
        <Accordion.Collapse eventKey="2">
          <>
            {/* <p className="text">
              Callejeritos México no es una asociación o fundación. Somos un
              proyecto de iniciativa privada bajo el nombre de Yo Soy Patas SAPI
              de CV que busca generar recursos propios a través de su modelo
              económico sostenible con venta de alimento, productos y servicios
              los cuales son deducibles de impuestos mediante la facturación de
              estos. Todas las utilidades son reinvertidas y donadas a la causa.
            </p> */}
            <p className="text">
              Callejeritos México si es una asociación y tambien somos un
              proyecto de iniciativa privada bajo el nombre de Yo Soy Patas México
              AC que busca generar recursos propios a través de su modelo
              económico sostenible con venta de alimento, productos y servicios
              los cuales son deducibles de impuestos mediante la facturación de
              estos. Todas las utilidades son reinvertidas y donadas a la causa.
            </p>
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="3"
            text="¿Como obtengo mi recibo de donación y si es deducible?"
          />
        </div>
        <Accordion.Collapse eventKey="3">
          <>
            <p className="text">
              Nuestro sistema de pagos a través de la plataforma Pay Pal,
              tiendas Oxxo o tarjeta de crédito/débito genera un recibo
              electrónico que respalda la operación, así como un correo. De
              igual manera para personas físicas y morales que requieran
              factura, esta es emitida con los datos que nos sean proporcionados
              bajo previa solicitud de facturación.
            </p>
            <p className="text">
              Recibos personalizados con la imagen corporativa y validación de
              Callejeritos México son generados cuando existen donaciones para
              alimento, esterilizaciones, tratamientos o económicamente.{' '}
            </p>
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="4"
            text="Quiero/puedo donar no en económico, pero si en especie."
          />
        </div>
        <Accordion.Collapse eventKey="4">
          <>
            <p className="text">
              Si deseas donar alimento en especie, cobijas, juguetes o algún
              otro tipo de donación en especie favor de contactarnos por correo
              electrónico a{' '}
              <span style={{ color: 'blue' }}> hola@callejeritos.mx</span> para
              que nuestro equipo pueda coordinarse contigo y la logística que
              implica.
            </p>
            <p className="text">
              Para donaciones en diferentes partes de la República Mexicana y
              con la intención de eficientar costos y logística, esas donaciones
              en especie pueden ir para albergues y rescatistas que formen parte
              de la red de aliados y previamente validados que cumplen con
              buenas prácticas, transparencia y honestidad ante la causa.
            </p>
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="5"
            text="¿Cómo puedo apoyar y sumarme a la causa?"
          />
        </div>
        <Accordion.Collapse eventKey="5">
          <>
            <p className="text">
              Si tu como nosotros eres amante de los seres vivos y en especial
              de los perros, puedes apoyar de muchas maneras con tiempo
              voluntariado, prácticas profesionales, servicio social y/o
              promoviendo nuestro proyecto. Contáctanos por correo o redes
              sociales para informarte más a detalle cómo puedes apoyar a esta
              gran y noble causa.
            </p>
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="6"
            text="¿Cuánto es lo mínimo que puedo donar?"
          />
        </div>
        <Accordion.Collapse eventKey="6">
          <>
            <p className="text">
              Aunque compartimos los costos para nuestro programa de donación de
              esterilizaciones de bajo costo y tratamientos (sarna y
              desnutrición) la aportación mínima que solicitamos es de 200 pesos
              para donaciones de única ocasión. Para el programa de donación
              recurrente tenemos el importe desde 100 pesos. Para mayores
              informes visitar{' '}
              <a
                target="_blank"
                rel="noreferrer"
                style={{ color: 'blue' }}
                href="https://www.callejeritos.mx/donaciones"
              >
                www.callejeritos.mx/donaciones
              </a>
              .
            </p>
          </>
        </Accordion.Collapse>
      </div>
      {/* <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="7"
            text="Quiero ser distribuidor de alimento Callejeritos Premium"
          />
        </div>
        <Accordion.Collapse eventKey="7">
          <>
            <p className="text">
              Si estás interesado en formar parte de la red comercial de nuestro
              alimento con causa, favor de contactarnos al correo de{' '}
              <span style={{ color: 'blue' }}> hola@callejeritos.mx</span> con
              tus datos completos, ubicación, necesidades ideales de consumo
              para podernos contactar y compartir más información
            </p>
          </>
        </Accordion.Collapse>
      </div> */}
      {/* <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="8"
            text="¿Dónde puedo comprar alimento Callejeritos Premium?"
          />
        </div>
        <Accordion.Collapse eventKey="8">
          <>
            <p className="text">
              El alimento se encuentra disponible en diferentes puntos de venta,
              albergues y rescatistas independientes, veterinarias y en línea
              dentro de nuestro sitio web. Conforme se va ampliando la red de
              distribución, vamos actualizando los datos
            </p>
          </>
        </Accordion.Collapse>
      </div> */}
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="9"
            text="¿Como saber si soy candidata(o) para la adopción?"
          />
        </div>
        <Accordion.Collapse eventKey="9">
          <>
            <p className="text">
              Favor de apoyarnos llenando el siguiente cuestionario en línea
              adopciones.callejeritos.mx y una vez recibido, nuestro equipo se
              pone en contacto para hacer una serie de preguntas, validar los
              requisitos, integrar un expediente, validar documentación, agendar
              una visita al domicilio donde se pretende viva la mascota.
            </p>
            <p className="text">
              De igual manera puedes contactarnos a{' '}
              <a
                rel="noreferrer"
                style={{ color: 'blue' }}
                href="mailto:adoptar@callejeritos.mx?subject=¿Como saber si soy candidata(o) para la adopción?"
              >
                adoptar@callejeritos.mx
              </a>{' '}
              si tienes dudas al respecto.
            </p>
            <DownloadButton
              file="importancia_adopciones_responsables__y_beneficios.pdf"
              text="Importancia de las adopciones responsables y beneficios"
            />
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler eventKey="10" text="¿Que necesito para la adopción?" />
        </div>
        <Accordion.Collapse eventKey="10">
          <>
            <p className="text">
              Después de que nuestro comité de adopciones valida que se cumple
              el perfil y requisitos, se debe de integrar un expediente con
              documentos como firma de contrato, comprobante de domicilio,
              identificación oficial vigente, pago de esterilización pendiente
              en caso de que la mascota sea muy pequeña y aun no haya sido
              esterilizada, así como los gastos de vacunas y desparasitaciones
              previamente realizadas. La mascota debe de cumplir una serie de
              requisitos y protocolos previamente validados previo a ir Rumbo a
              Casa.{' '}
            </p>
            <DownloadButton
              file="requisitos_para_adopcion.pdf"
              text="Descargar Infografía"
            />
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler eventKey="11" text="¿Dónde puedo Visitarlos?" />
        </div>
        <Accordion.Collapse eventKey="11">
          <>
            <p className="text">
              Por el momento podrás encontrarnos en lugares públicos y espacios
              comerciales dentro de la ciudad y calles de Guadalajara, México;
              así como información en algunos puntos de nuestros aliados
              estratégicos y patrocinadores
            </p>
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="12"
            text="¿Porque apoyar a Callejeritos México?"
          />
        </div>
        <Accordion.Collapse eventKey="12">
          <>
            <p className="text">
              El problema y reto va más allá de lo que normalmente se puede o
              alcanza a ver y entender. Abandono y maltrato de animales es un
              tema de salud y seguridad pública, es un reflejo de una sociedad
              con falta de valores y empatía hacia quien solemos llamar el mejor
              amigo del hombre. Es el inicio de un problema de violencia
              familiar, maltrato, falta de sensibilidad hacia seres vivos y
              eventualmente incluso hacia personas y grupos vulnerables.
              Buscamos concientizar a nuestra sociedad y tomar acciones reales.
              Solo conociendo, entendiendo, escuchando y observando es posible
              sensibilizarnos y generar un cambio real.{' '}
            </p>
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="13"
            text="¿Dónde, cuándo y cómo esterilizan?"
          />
        </div>
        <Accordion.Collapse eventKey="13">
          <>
            <p className="text">
              Buscamos crear alianzas con albergues y rescatistas independientes
              para aplicar esterilizaciones a sus poblaciones de perros de
              calle, maltrato y abandonado que se encuentran con ellos y gracias
              al apoyo de las y los donantes. Si son perros que se encuentran en
              situación crítica se busca logar el rescate, apadrinamiento y
              rehabilitación mediante nuestro programa Rumbo a Casa para
              posteriormente encontrarle una familia y hogar.
            </p>
            <DownloadButton
              file="esterilizacion_a_costo_mayo22.jpg"
              text="Descargar Infografía"
            />
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler eventKey="14" text="Guía post Esterilización" />
        </div>
        <Accordion.Collapse eventKey="14">
          <>
            <p className="text">
              Recomendaciones después de la esterilización con algunos posibles
              escenarios de alergia, de comportamiento, de alimentación.
            </p>
            <DownloadButton
              file="cuidados_post_esterilizacion.jpg"
              text="Descargar Infografía"
            />
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler eventKey="15" text="Guía post Adopción" />
        </div>
        <Accordion.Collapse eventKey="15">
          <>
            <p className="text">
              Recomendaciones después de la adopción, con algunos escenarios o
              comportamientos de la mascota más comunes en un nuevo hogar.
            </p>
            <DownloadButton
              file="infografia_ post_adopcion.pdf"
              text="Descargar Infografía"
            />
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="16"
            text="¿En que se emplea mi aportación o donación?"
          />
        </div>
        <Accordion.Collapse eventKey="16">
          <>
            <p className="text">
              Como nuestra sección de donaciones lo indica, cada una tiene un
              propósito específico. Sin embargo, donaciones en dinero o especie
              van directamente a habilitar espacios, tratamientos e incluso
              capacitación a rescatistas, albergue e independientes que cumplen
              con los requisitos de buenas prácticas, transparencia y
              seguimiento a las operaciones. Promoción de la causa y generación
              de contenido es parte de lo que se requiere y es utilizado con
              parte de las donaciones.
            </p>
          </>
        </Accordion.Collapse>
      </div>
    </Accordion>
  );
};
