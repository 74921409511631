import React, { useContext } from 'react';
import { headerImages } from '../../helpers/callejeritosImages';
import { UserContext } from '../../context/UserContext';
import { useHistory, useLocation } from 'react-router-dom';
import callejeritosRouteList from '../../routers/routeList';

export const LangSelect = ({ handleExpandOff }: LangSelectParams) => {
 
  const { languaje } = useContext(UserContext);

  const location = useLocation();
  const history = useHistory();

 /*  const handleSetLanguaje = (lang: string) => {
    localStorage.setItem('lang', lang);
    setLanguaje(lang);
    handleExpandOff();
  }; */
  
  const handleSwitchRoute = () => {
    const current_path: string = location.pathname;
    const newRoute = callejeritosRouteList[current_path];
    history.push(newRoute);
  };

  return (
    <>
      <div className="d-none d-md-none d-lg-none d-xl-block">
        {
          languaje === 'ES' ?
          (<img src={  headerImages(`./btn-english.png`).default } alt="Callejeritos" className="img-fluid btg-languaje-lg" 
            onClick={handleSwitchRoute}/>)
          :
          (<img src={ headerImages(`./btn-español.png`).default } alt="Callejeritos" className="img-fluid btg-languaje-lg"
          onClick={handleSwitchRoute}/>)
        }
      </div>
      <div className="d-block d-md-block d-lg-block d-xl-none">
        {
          languaje === 'ES' ?
          (<img src={  headerImages(`./btn-en.png`).default } alt="Callejeritos" className="img-fluid btg-languaje-md" 
            onClick={handleSwitchRoute}/>)
          :
          (<img src={ headerImages(`./btn-es.png`).default } alt="Callejeritos" className="img-fluid btg-languaje-md"
          onClick={handleSwitchRoute}/>)
        }
      </div>
    </>
  )
}

interface LangSelectParams {
  handleExpandOff: () => void;
}
