import kw2pApiPublic from '../api/kw2pPublic';
import Swal from 'sweetalert2';


const excluded = ['tratamiento', 'recurrente', 'esterilizacion', 'alimento'];

const obtenerProductosCallejeritos = async (categoria: string) => {

  const categoriasRequest = {
    schema: 'categorias',
    filter: {
      where: [
        {
          field: 'payload->nombre',
          operator: 'like',
          value: `%${categoria}%`,
          collate: 'utf8mb4_general_ci'
        }
      ]
    }
  };


  const { data: { data: categorias = [] } } = await kw2pApiPublic.post<any>('/catalogos:consultar', categoriasRequest);

  if (!categorias.length) {
    Swal.fire({
      icon: 'error',
      title: 'Lo sentimos ocurrio un error de configuración...',
      text: 'Nuestro equipo esta trabajando en la solución'
    });

    return;
  }

  const productosRequest = {
    schema: 'productos',
    filter: {
      where: [
        {
          field: 'payload->categoria_id',
          operator: '=',
          value: categorias[0]['id']
        }
      ]
    }
  };

  const { data: { data = [] } } = await kw2pApiPublic.post<any>('/catalogos:consultar', productosRequest);

  const productos = data.filter((producto: any) => !excluded.includes(producto.payload.nombre))

  return productos;
};

const obtenerAllProductosCallejeritos = async (categoria: string) => {

  const categoriasRequest = {
    schema: 'categorias',
  };


  const { data: { data: categorias = [] } } = await kw2pApiPublic.post<any>('/catalogos:consultar', categoriasRequest);

  if (!categorias.length) {
    Swal.fire({
      icon: 'error',
      title: 'Lo sentimos ocurrio un error de configuración...',
      text: 'Nuestro equipo esta trabajando en la solución'
    });

    return;
  }

  const productosRequest = {
    schema: 'productos',
  };

  const { data: { data = [] } } = await kw2pApiPublic.post<any>('/catalogos:consultar', productosRequest);

  const productos = data.filter((producto: any) => !excluded.includes(producto.payload.nombre))

  return productos;
};

export {
  obtenerProductosCallejeritos,
  obtenerAllProductosCallejeritos
};