import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router';
import { AuthContext } from '../context/AuthContext';

interface Props {
    exact: boolean;
    path: string;
    component: React.ComponentType<any>;
}

export const PublicRoute = ({ component: Component, ...rest }: Props) => {

    const { status } = useContext(AuthContext);

    return (
        <Route { ...rest }>
            {/* @ts-ignore */}
            { status === 'not-authenticated' ? <Component /> : <Redirect to="/"/> }
        </Route>
    )
}
