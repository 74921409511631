import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
// import { faWhatsapp } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { UserContext } from "../../context";

function FloatingWhatsapp() {
  const { languaje } = React.useContext(UserContext);
  return (
    <div className="fab-container whatsapp">
      <a
        className="fab fab-icon-holder"
        target="_blanc"
        href={`https://api.whatsapp.com/send?phone=5213331896978&text=${
          languaje === "ES"
            ? "Quiero%20más%20información"
            : "I%20want%20more%20information"
        }`}
      >
        <FontAwesomeIcon icon={faWhatsapp as IconProp} size="2x" color="#fff" />
      </a>
    </div>
  );
}

// FloatingWhatsapp.propTypes = {}

export default FloatingWhatsapp;
