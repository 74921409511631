import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDVNnBd4crb5zr0OB94R-azZ577ZqkL-Ik",
    authDomain: "sandboxcallejeritos.firebaseapp.com",
    projectId: "sandboxcallejeritos",
    storageBucket: "sandboxcallejeritos.appspot.com",
    messagingSenderId: "137228751210",
    appId: "1:137228751210:web:4b480a2c5a45d5ee4ed513",
    measurementId: "G-51E2Z4YEM6"
};

const firebaseApp = initializeApp(firebaseConfig);
const storage = getStorage(firebaseApp);


export {
    storage,
    firebaseConfig
};
