import React from 'react';
import { useContext } from 'react';
import { productosImages } from '../../helpers/callejeritosImages';
import { UserContext } from '../../context/UserContext';

export const Alimento = () => {

    const { languaje } = useContext(UserContext);

    return (
        <div className="div-alimento animate__animated animate__fadeIn">
            {
                languaje === 'ES' ?
                <>
                    <div className="header-alimento">
                        <img src={ productosImages('./Alimento/tituloES.png').default } alt="Nosotros" className="img-fluid title-alimento"/>
                    </div>
                    <div className="body-alimento animate__animated animate__fadeInUp">
                        <h1 className="title">Próximamente</h1>
                        <p className="text">Trabajando en diseñar y formular un producto de gran calidad, beneficios y accesibilidad para beneficiar a los más necesitados.</p>
                    </div>
                </>
                :
                <>
                    <div className="header-alimento">
                        <img src={ productosImages('./Alimento/tituloEN.png').default } alt="Nosotros" className="img-fluid title-alimentoEN"/>
                    </div>
                    <div className="body-alimento animate__animated animate__fadeInUp">
                        <h1 className="title">Coming Soon</h1>
                        <p className="text">Working to design and formulate a product of great quality, benefits and accessibility to benefit those most in need.</p>
                    </div>
                </>
            }
            <div className="container">
                <div className="row">
                    <div className="col-6 col-md-3 p-0">
                        <img src={ productosImages('./Alimento/frente.png').default } alt="Alimento frente" className="img-fluid"/>
                    </div>
                    <div className="col-6 col-md-3 p-0">
                        <img src={ productosImages('./Alimento/derecha.png').default } alt="Alimento lado" className="img-fluid"/>
                        </div>
                    <div className="col-6 col-md-3 p-0">
                        <img src={ productosImages('./Alimento/atras.png').default } alt="Alimento reverso" className="img-fluid"/>
                    </div>
                    <div className="col-6 col-md-3 p-0">
                        <img src={ productosImages('./Alimento/izqv2.png').default } alt="Alimento lado" className="img-fluid"/>
                    </div> 
                </div>
            </div>
        </div>
    )
}
