/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFileImport, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ref as fbref , uploadBytesResumable, getDownloadURL, listAll } from 'firebase/storage';
import Swal from 'sweetalert2';

import { storage } from '../../firebase/firebaseConfig';
import { UserContext } from '../../context/UserContext';
import { AuthContext } from '../../context/AuthContext';
import { useKW2P } from '../../hooks/useKW2P';
import { useHistory } from 'react-router-dom';
import { Mascota } from '../../interfaces/mascotasInterfaces';
import Logotipo from '../../logotipo.svg';

interface Props {
    scope: 'adopcion' | 'rehabilitacion';
    mascota: Mascota | null;
};

export const DropImages = forwardRef(({ scope, mascota }: Props, ref: any) => {

    const { languaje } = useContext(UserContext);
    const { setLoading, handleError } = useContext(AuthContext);

    const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
    const [errorMessage, setErrorMessage] = useState<any>('');
    const [validFiles, setValidFiles] = useState<any[]>([]);
    const [videoFile, setVideoFile] = useState<any>(null);
    const [unsupportedFiles, setUnsupportedFiles] = useState<any[]>([]);

    const [storageCount, setStorageCount] = useState(0);

    const modalImageRef = useRef<any>();
    const modalRef = useRef<any>();
    const fileInputRef = useRef<any>();
    
    const history = useHistory();

    const { reemplazarCatalogoPublic } = useKW2P();

    const dragOver = (e: any) => {
        e.preventDefault();
    };

    const dragEnter = (e: any) => {
        e.preventDefault();
    };

    const dragLeave = (e: any) => {
        e.preventDefault();
    };

    const fileDrop = (e: any) => {
        e.preventDefault();
        const files = e.dataTransfer.files;

        if (files.length <= 3) {
            handleFiles(files);
        }
    };

    const handleFiles = (files: any) => {

        if ((storageCount + validFiles.length) >= 3) {
            return;
        }

        for (let i = 0; i < files.length; i++) {
            if (validateFile(files[i])) {
                // add to an array so we can display the name of file
                setSelectedFiles(prevArray => [...prevArray, files[i]]);
            } else {
                // add a new property called invalid
                files[i]['invalid'] = true;
                // add to the same array so we can display the name of the file
                setSelectedFiles(prevArray => [...prevArray, files[i]]);
                // set error message
                const msj = languaje === 'ES' ? 'Archivo no admitido' : 'File type not permitted';
                setErrorMessage(msj);
                setUnsupportedFiles(prevArray => [...prevArray, files[i]]);
            }
        }
    };

    const validateFile = (file: any) => {
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon',
            'video/mp4', 'video/mov', 'video/wmv', 'video/avi', 'video/webm', 'video/ogg'];
        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }

        const sizeMB = file.size / 1024 / 1024;
        if (sizeMB > 40) {
            return false;
        }

        return true;
    };

    const fileSize = (size: any) => {
        if (size === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const fileType = (fileName: any) => {
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    };

    useEffect(() => {
        let filteredArray = selectedFiles.reduce((file, current) => {
            const x = file.find((item: any) => item.name === current.name);
            if (!x) {
                return file.concat([current]);
            } else {
                return file;
            }
        }, []);
        setValidFiles([...filteredArray]);

    }, [selectedFiles]);

    const removeFile = (name: any) => {
        // find the index of the item
        // remove the item from array

        const validFileIndex = validFiles.findIndex(e => e.name === name);
        validFiles.splice(validFileIndex, 1);
        // update validFiles array
        setValidFiles([...validFiles]);
        const selectedFileIndex = selectedFiles.findIndex(e => e.name === name);
        selectedFiles.splice(selectedFileIndex, 1);
        // update selectedFiles array
        setSelectedFiles([...selectedFiles]);

        const unsupportedFileIndex = unsupportedFiles.findIndex(e => e.name === name);
        if (unsupportedFileIndex !== -1) {
            unsupportedFiles.splice(unsupportedFileIndex, 1);
            // update unsupportedFiles array
            setUnsupportedFiles([...unsupportedFiles]);
        }
    };

    const openImageModal = (file: any) => {
        const reader = new FileReader();
        modalRef.current.style.display = "block";

        if (file.type.includes('video/')) {
            setVideoFile(file);
        } else {
            reader.readAsDataURL(file);
            reader.onload = function (e) {
                modalImageRef.current.style.backgroundImage = `url(${e.target?.result})`;
            }
        }
    };

    const closeModal = () => {
        modalRef.current.style.display = "none";
        modalImageRef.current.style.backgroundImage = 'none';
        setVideoFile(null);
    };

    const fileInputClicked = () => {
        fileInputRef.current.click();
    };

    const filesSelected = () => {
        if (fileInputRef.current.files.length) {
            handleFiles(fileInputRef.current.files);
        }
    };

    useImperativeHandle(ref, () => ({
        async submitFiles (id: any) {

            if (unsupportedFiles.length || !validFiles.length) {
                Swal.fire({
                    title: 'Mascota registrada',
                    text: 'Gracias por participar en esta noble causa',
                    imageUrl: Logotipo,
                    imageHeight: 70, 
                    imageWidth: 325
                });

                setLoading(false);

                history.push(`/mascotas/${scope}`);

                return;

            }

            let urls: any[] = [];
            
            const task = new Promise((resolve, reject) => {
                validFiles.forEach(async (un_file: any) => {
                    const file_url = await uploadToFirebase(id, un_file);
                    resolve(urls.push(file_url));
                });
            });

            task.then(() => {
                actualizarMascota(urls, id);
            });
        },
        dropZoneValid() {

            if (unsupportedFiles.length) {
                return false;
            }

            if ((validFiles.length + storageCount) <= 0) {
                return false;
            }

            return true;
        }
    }));

    const uploadToFirebase = async (id: any, file: any) => {

        return new Promise<any>((resolve, reject) => {
            const storageRef = fbref(storage, `mascotas/${id}/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
            
            uploadTask.on('state_changed',
                (snapshot) => {
                    //const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    //console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            //console.log('Upload is paused');
                            break;
                        case 'running':
                            //console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                    console.log(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo sentimos ocurrio un error...',
                        text: 'contacta al administrador'
                    });
                    reject();
                    setLoading(false);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        resolve(downloadURL);
                    });
                }
            );
        });

    };

    const actualizarMascota = async (imagenes: any, id: any) => {

        try {
            const body = {
                schema: 'mascotas',
                id,
                payload: {
                    imagenes
                }
            };

            await reemplazarCatalogoPublic(body);

            Swal.fire({
                title: 'Mascota registrada',
                text: 'Gracias por participar en esta noble causa',
                imageUrl: Logotipo,
                imageHeight: 70, 
                imageWidth: 325
            });

            setLoading(false);

            history.push(`/mascotas/${scope}`);

        } catch (err: any) {
            console.log(err);
            handleError(err);
        }
    };

    const getGalery = async () => {

        if (!mascota) return;

        setLoading(true);
        const storageRef = fbref(storage, `mascotas/${mascota.id}/`);
        new Promise((resolve, reject) => {
            listAll(storageRef).then((res) => {
                setStorageCount(res.items.length);
                setLoading(false);
            }).catch((error) => {
                console.log(error);
                reject();
                setLoading(false);
            });
        });
        
    };

    useEffect(() => {
        getGalery();
    }, [mascota]);

    return (
        <>
            <div className="container">
                {unsupportedFiles.length ?
                    <p className='text-danger'>{languaje === 'ES' ? 'Elimina todos los archivos no admitidos.' : 'Please remove all unsupported files'}</p> : ''
                }
                <div className="drop-container"
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}
                    onClick={fileInputClicked}
                >
                    <div className="drop-message">
                        <FontAwesomeIcon icon={faFileImport as IconProp} size="2x" />
                        <p>{languaje === 'ES' ? 'Arrastra y suelta archivos aquí o haz clic para subir' : 'Drag & Drop files here or click to upload'}</p>
                        <input
                            ref={fileInputRef}
                            className="file-input"
                            type="file"
                            multiple
                            onChange={filesSelected}
                        />
                    </div>
                </div>
                <div className="file-display-container">
                    {validFiles.length ? <p className="text-secondary m-0">{languaje === 'ES' ? 'Da click para visualizar el archivo' : 'Click to preview the file'}</p> : ''}
                    {
                        validFiles.map((data, i) =>
                            <div className="file-status-bar" key={i}>
                                <div onClick={!data.invalid ? () => openImageModal(data) : () => removeFile(data.name)}>
                                    <div className="file-type-logo"></div>
                                    <div className="file-type">{fileType(data.name)}</div>
                                    <span className={`file-name ${data.invalid ? 'file-error' : ''}`}>{data.name}</span>
                                    <span className="file-size">({fileSize(data.size)})</span> {data.invalid && <span className='file-error-message'>({errorMessage})</span>}
                                </div>
                                <div className="file-remove" onClick={() => removeFile(data.name)}><FontAwesomeIcon icon={faTimes as IconProp} /></div>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className="modal" ref={modalRef}>
                <div className="overlay"></div>
                <span className="close" onClick={(() => closeModal())}><FontAwesomeIcon icon={faTimes as IconProp} /></span>
                <div className="modal-image" ref={modalImageRef}></div>
                
                {videoFile &&
                    <div className='video-shown'>
                        <video width={ 300 } controls autoPlay>
                            <source src={URL.createObjectURL(videoFile)} />
                        </video>
                    </div>
                }
            </div>
        </>
    )
});
