import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { UserContext } from '../../context/UserContext';
import { landingpageImages } from '../../helpers/callejeritosImages';

export const SocialSection = () => {

    const { facebookLink, instagramLink, languaje } = useContext(UserContext);

    return (
        <div className="container-social animate__animated animate__fadeIn">
            <div className="social-hechodiv">
             <img src={ landingpageImages(`./Social/${ languaje }/hecho.png`).default } alt="Callejeritos" className="social-hecho" />
            </div>
            <div className="social-div">
                <div className="social-adoptdiv">
                    <img src={ landingpageImages(`./Social/${ languaje }/adopt.png`).default } alt="Callejeritos" className="adopt-social" />
                </div>
                <div className="social-links">
                    <a href={ facebookLink } target="_blank" rel="noopener noreferrer" className="btn btn-social rounded-circle mr-4">
                        <FontAwesomeIcon icon={faFacebookF as IconProp} size="2x"/>
                    </a>
                    <a href={ instagramLink } target="_blank" rel="noopener noreferrer" className="btn btn-social2 rounded-circle">
                        <FontAwesomeIcon icon={faInstagram as IconProp} size="2x"/>
                    </a>
                </div>
            </div>
        </div>
    )
}
