import { Edad } from "../interfaces/mascotasInterfaces";

const mascotaRaza = (raza: string, languaje: string) => {
    switch (raza) {
        case 'mestizo':
            return languaje === 'ES' ? 'Mestizo' : 'Mongrel';
        case 'mezcla de raza con mestizo':
            return languaje === 'ES' ? 'Mezcla de raza con mestizo' : 'Cross-breed';
        case 'de raza':
            return languaje === 'ES' ? 'De raza' : 'Breed';
        default:
            return raza;
    }
};

const mascotaSexo = (sexo: string, languaje: string) => {
    switch (sexo) {
        case 'macho':
            return languaje === 'ES' ? 'Macho' : 'Male';
        case 'hembra':
            return languaje === 'ES' ? 'Hembra' : 'Female';
        default:
            return sexo;
    }
};

const mascotaEspecie = (especie: string, languaje: string) => {
    switch (especie) {
        case 'perro':
            return languaje === 'ES' ? 'Perro' : 'Dog';
        case 'gato':
            return languaje === 'ES' ? 'Gato' : 'Cat';
        default:
            return especie;
    }
};

const mascotaTamano = (tamano: string, languaje: string) => {
    switch (tamano) {
        case 'mini':
            return 'Mini';
        case 'pequeño':
            return languaje === 'ES' ? 'Pequeño' : 'Small';
        case 'mediano':
            return languaje === 'ES' ? 'Mediano' : 'Medium';
        case 'grande':
            return languaje === 'ES' ? 'Grande' : 'Big';
        case 'muy grande':
            return languaje === 'ES' ? 'Muy grande' : 'Giant';
        default:
            return tamano;
    }
};

const mascotaEdad = ({ valor, unidad }: Edad, languaje: string): string => {

    let unit = '';

    if (valor === 1) {
        switch (unidad) {
            case 'meses':
                unit = languaje === 'ES' ? 'mes' : 'month';
                break;
            case 'años':
                unit = languaje === 'ES' ? 'año' : 'year';
                break;
            default:
                unit = unidad;
                break;
        }
    } else {
        switch (unidad) {
            case 'meses':
                unit = languaje === 'ES' ? 'meses' : 'months';
                break;
            case 'años':
                unit = languaje === 'ES' ? 'años' : 'years';
                break;
            default:
                unit = unidad;
                break;
        }
    }
    return `${ valor } ${ unit }`;
};

const estados: string[] = [
    'Aguascalientes',
    'Baja California',
    'Baja California Sur',
    'Campeche',
    'Coahuila',
    'Colima',
    'Chiapas',
    'Chihuahua',
    'Durango',
    'CDMX',
    'Guanajuato',
    'Guerrero',
    'Hidalgo',
    'Jalisco',
    'México',
    'Michoacán',
    'Morelos',
    'Nayarit',
    'Nuevo León',
    'Oaxaca',
    'Puebla',
    'Querétaro',
    'Quintana Roo',
    'San Luis Potosí',
    'Sinaloa',
    'Sonora',
    'Tabasco',
    'Tamaulipas',
    'Tlaxcala',
    'Veracruz',
    'Yucatán',
    'Zacatecas'
];

export {
    mascotaRaza,
    mascotaSexo,
    mascotaEspecie,
    mascotaTamano,
    mascotaEdad,
    estados
};
