
export const useFetch = () => {

    const base_url = 'https://themisto.klayware.com/callejeritos/'; 
    // const base_url = `http://127.0.0.1:8000/callejeritos/`;
    // const base_url ='https://newton.klayware.com/callejeritos/';

    const beDocumentoCrear = (action: string, formData: any) => {

        return fetch(`${ base_url }${ action }`, {
            method: 'POST',
            body : JSON.stringify(formData),
            headers : {
                'Content-Type':  'application/json'
            }
        })
        .then(reps => reps.json());

    }

    return {
        beDocumentoCrear
    };
    
}
