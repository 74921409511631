import React from 'react';
import { Link, NavLink } from 'react-router-dom';

export const Sitemap = ({ languaje }: { languaje: string }) => {

    return (
        <>
            <div className="col-12">
                <div className="site_map">
                    <p className="mapa_title m-0">Mapa del sitio</p>
                </div>
            </div>
            <div className="col-12">
                <div className="site_map">
                    <ul>
                        <li>
                            <Link className="map_item" to="/">{ languaje === 'ES' ? 'Inicio' : 'Home' }</Link>
                        </li>
                        <li>
                            <NavLink className="map_item" exact to="/nosotros">{ languaje === 'ES' ? 'Nosotros' : 'Us' }</NavLink>
                            <ul>
                                <li><Link className="map_item" to="/nosotros">{ languaje === 'ES' ? 'Nosotros' : 'Us' }</Link></li>
                                <li><Link className="map_item" to="/nosotros/historia">{ languaje === 'ES' ? 'Historia' : 'History' }</Link></li>
                                <li><Link className="map_item" to="/nosotros/manifiesto">{ languaje === 'ES' ? 'Manifiesto' : 'Manifesto' }</Link></li>
                                <li><Link className="map_item" to="/nosotros/causa-social">{ languaje === 'ES' ? 'Causa social' : 'Social Cause' }</Link></li>
                                <li><Link className="map_item" to="/nosotros/albergues">{ languaje === 'ES' ? 'Albergues' : 'Shelters' }</Link></li>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link className="map_item" to="/Productos">{ languaje === 'ES' ? 'Productos' : 'Products' }</Link>
                            <ul>
                                <li><NavLink className="map_item" to="/productos/alimento">{ languaje === 'ES' ? 'Alimento' : 'Food' }</NavLink></li>
                                <li><NavLink className="map_item" to="/productos/juguetes">{ languaje === 'ES' ? 'Juguetes' : 'Toys' }</NavLink></li>
                            </ul>
                        </li>
                        <li>
                            <Link className="map_item" to="/equipo">{ languaje === 'ES' ? 'Equipo' : 'Team' }</Link>
                            <ul>
                                <li><Link className="map_item" to="/equipo/patrocinadores">{ languaje === 'ES' ? 'Patrocinadores' : 'Sponsors' }</Link></li>
                                <li><Link className="map_item" to="/equipo/alianzas-estrategicas">{ languaje === 'ES' ? 'Alianzas estratégicas' : 'Strategic Alliances' }</Link></li>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link className="map_item" to="/donaciones">{ languaje === 'ES' ? 'Donaciones' : 'Donations' }</Link>
                        </li>
                        <li>
                            <Link className="map_item" to="/faqs">{ languaje === 'ES' ? 'Preguntas Frecuentes' : 'FAQS' }</Link>
                        </li>
                        <li>
                            <Link className="map_item" to="/recursos">{ languaje === 'ES' ? 'Recursos' : 'Resources' }</Link>
                            <ul>
                                <li><Link className="map_item" to="/recursos">{ languaje === 'ES' ? 'Contrato de adopción' : 'Adoption contract' }</Link></li>
                                <li><Link className="map_item" to="/recursos">{ languaje === 'ES' ? 'Contrato de esterilización' : 'Sterilization Contract' }</Link></li>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link className="map_item" to="/contacto">{ languaje === 'ES' ? 'Contacto' : 'Contact' }</Link>
                            <ul>
                                <li><Link className="map_item" to="/contacto/unete-al-proyecto">{ languaje === 'ES' ? 'Únete al proyecto' : 'Join the project' }</Link></li>
                                <li><Link className="map_item" to="/contacto/informate">{ languaje === 'ES' ? 'Infórmate' : 'Inform yourself' }</Link></li>
                                <li>
                                    <Link className="map_item" to="/contacto/programa-rumbo-a-casa">
                                        { languaje === 'ES' ? <i>Programa <small>RUMBO A CASA</small></i> : <i><small>GOING HOME</small> Program</i> }
                                    </Link>
                                </li>
                                <li><Link className="map_item" to="/contacto/aviso-privacidad">{ languaje === 'ES' ? 'Aviso de privacidad' : 'Privacy notice' }</Link></li>
                                <li><Link className="map_item" to="/contacto/boletin-informativo">{ languaje === 'ES' ? 'Boletín informativo' : 'Newsletter' }</Link></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
