import React from 'react';
import { Accordion } from 'react-bootstrap';
import { TextToggler } from '../ui/TextToggler';
import { DownloadButton } from '../DownloadButton';

interface FaqsComponent {
  lang: string;
}

export const ENFaqs = ({ lang }: FaqsComponent) => {
  return (
    <Accordion defaultActiveKey="0">
      <div>
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="0"
            text="I NO LONGER TRUST THESE KIND OF PROJECTS, ASSOCIATIONS, PEOPLE WHO SAY TO HELP AND IT IS NOT SO. HOW
                    DO I KNOW THAT CALLEJERITOS MEXICO IS A REAL PROJECT AND NOT A SCAM?"
          />
        </div>
        <Accordion.Collapse eventKey="0">
          <>
            <p className="text">
              Above all, we are aware that this is a sad reality in Mexico and
              the world. There are people, "associations, rescuers and / or
              shelters" who profit from animal suffering, claim to support the
              cause and in reality it is not like that or what they do in
              exchange for what they receive is minimal. Part of our work is to
              demonstrate and have total transparency in our operations. We have
              a team of professionals, processes, and systems that allow us to
              support what we do and say. We are a legally constituted project
              and with a policy of ZERO TOLERANCE is this type of apocryphal and
              false activities. Callejeritos Mexico was born among many other
              reasons for having been a victim of scam and lies about what
              people say they do, so we fully understand the frustration and
              mistrust that usually exists. Companies and private initiative
              support us, but above all the openness, transparency and passion
              behind this great project made in Mexico with the Heart.
            </p>
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="1"
            text="WHO IS PART OF CALLEJERITOS MEXICO?"
          />
        </div>
        <Accordion.Collapse eventKey="1">
          <>
            <p className="text">
              We are a team of entrepreneurs, conscious, empathetic and
              sensitive citizens who seek to support this noble cause and social
              problem in Mexico and the world. Private initiative is also part
              of this great project.
            </p>
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="2"
            text="ARE YOU A FOUNDATION OR ASSOCIATION?"
          />
        </div>
        <Accordion.Collapse eventKey="2">
          <>
            <p className="text">
              {/* Callejeritos Mexico is not an association or foundation. We are a
              private initiative project under the name of Yo Soy Patas SAPI de
              CV that seeks to generate its own resources through its
              sustainable economic model with the sale of food, products and
              services which are tax deductible by billing them. All profits are
              reinvested and donated to the cause. */}
              Callejeritos México is an association and we are also a
               private initiative project under the name of Yo Soy Patas México
               AC that seeks to generate its own resources through its model
               sustainable economy with sale of food, products and services
               which are tax deductible through the billing of
               these. All profits are reinvested and donated to the cause.
            </p>
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="3"
            text="HOW DO I GET MY DONATION RECEIPT AND IF IT IS DEDUCTIBLE?"
          />
        </div>
        <Accordion.Collapse eventKey="3">
          <>
            <p className="text">
              Our payment system through Pay Pal, Oxxo stores or credit/debit
              card generates an electronic receipt that supports the operation,
              as well as an email. In the same way, for individuals and legal
              entities that require an invoice, this is issued with the data
              provided to us upon prior invoice request.
            </p>
            <p className="text">
              Personalized receipts with the corporate image and validation of
              Callejeritos Mexico are generated when there are donations for
              food, sterilizations, treatments or money.
            </p>
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="4"
            text="I WANT / CAN DONATE NOT IN ECONOMIC, BUT IN KIND."
          />
        </div>
        <Accordion.Collapse eventKey="4">
          <>
            <p className="text">
              If you wish to donate food in kind, blankets, toys or any other
              type of donation in kind, please contact us by email at{' '}
              <span style={{ color: 'blue' }}> hola@callejeritos.mx</span> so
              that our team can coordinate with you and the logistics involved.
            </p>
            <p className="text">
              For donations in different parts of Mexico and with the intention
              of making costs and logistics more efficient, these donations in
              kind can go to shelters and rescuers that are part of our network
              and allies and previously validated that comply with good
              practices, transparency and honesty for the cause.
            </p>
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="5"
            text="HOW CAN I SUPPORT AND JOIN THE CAUSE?"
          />
        </div>
        <Accordion.Collapse eventKey="5">
          <>
            <p className="text">
              If you like us are a lover of living beings and especially dogs,
              you can support in many ways with volunteering time, professional
              practices, social service and / or promoting our project. Contact
              us by email or social networks to find out in more detail how you
              can support this great and noble cause.
            </p>
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="6"
            text="HOW MUCH IS THE MINIMUM I CAN DONATE?"
          />
        </div>
        <Accordion.Collapse eventKey="6">
          <>
            <p className="text">
              Although we share the costs for our donation program for low-cost
              sterilizations and treatments (scabies and malnutrition), the
              minimum contribution we request is 200 pesos for one-time
              donations. For the recurring donation program we have the amount
              from 100 pesos. For more information visit{' '}
              <a
                target="_blank"
                rel="noreferrer"
                style={{ color: 'blue' }}
                href="https://www.callejeritos.mx/donations"
              >
                {' '}
                https://www.callejeritos.mx/donations
              </a>
              .
            </p>
          </>
        </Accordion.Collapse>
      </div>
      {/* <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="7"
            text="I WANT TO BE A CALLEJERITOS PREMIUM  FOOD DISTRIBUTOR"
          />
        </div>
        <Accordion.Collapse eventKey="7">
          <>
            <p className="text">
              If you are interested in being part of the commercial network of
              our pet food with cause, please contact us at
              <span style={{ color: 'blue' }}> hola@callejeritos.mx</span> with
              your complete information, location, ideal consumption needs to
              contact you and share more information.
            </p>
          </>
        </Accordion.Collapse>
      </div> */}
      {/* <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="8"
            text="WHERE CAN I BUY CALLEJERITOS PREMIUM FOOD?"
          />
        </div>
        <Accordion.Collapse eventKey="8">
          <>
            <p className="text">
              The food is available at different points of sale, shelters and
              independent rescuers, veterinarians in Mexico and online at{' '}
              <a
                href="http://www.callejeritos.mx"
                style={{ color: 'blue' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                www.callejeritos.mx{' '}
              </a>
            </p>
            <p className="text">
              As the distribution network expands, we update the data.
            </p>
          </>
        </Accordion.Collapse>
      </div> */}
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="9"
            text="HOW DO I KNOW IF I AM A CANDIDATE FOR ADOPTION?"
          />
        </div>
        <Accordion.Collapse eventKey="9">
          <>
            <p className="text">
              Please support us by filling out the following online
              questionnaire adopciones.callejeritos.mx and once received, our
              team gets in touch to ask a series of questions, validate the
              requirements, integrate a file, validate documentation, schedule a
              visit to the address where pretend the pet lives.
            </p>
            <p className="text">
              In the same way you can contact us at{' '}
              <a
                rel="noreferrer"
                style={{ color: 'blue' }}
                href="mailto:adoptar@callejeritos.mx?subject=¿Como saber si soy candidata(o) para la adopción?"
              >
                adoptar@callejeritos.mx
              </a>{' '}
              if you have any doubts about it.
            </p>
            <DownloadButton
              file="importancia_adopciones_responsables__y_beneficios.pdf"
              text="Importance of responsible adoptions and benefits"
            />
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler eventKey="10" text="WHAT DO I NEED FOR THE ADOPTION?" />
        </div>
        <Accordion.Collapse eventKey="10">
          <>
            <p className="text">
              After our adoption committee validates that the profile and
              requirements are met, a file with documents such as contract
              signing, proof of address and valid ID, pending sterilization
              payment should be integrated in case the pet is too young and it
              has not yet been sterilized, as well as the expenses of vaccines
              and deworming previously carried out. The pet must meet a series
              of previously validated requirements and protocols prior to going
              Home.
            </p>
            <DownloadButton
              file="requisitos_para_adopcion.pdf"
              text="Download Infographic"
            />
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="11"
            text="WHERE CAN I VISIT CALLEJERITOS MEXICO?"
          />
        </div>
        <Accordion.Collapse eventKey="11">
          <>
            <p className="text">
              At the moment you can find us in public places and commercial
              spaces within the city and streets of Guadalajara, Mexico; as well
              as information in some points of our strategic allies and
              sponsors.
            </p>
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler eventKey="12" text="WHY SUPPORT CALLEJERITOS MEXICO?" />
        </div>
        <Accordion.Collapse eventKey="12">
          <>
            <p className="text">
              The problem and challenge go beyond what can or can normally be
              seen and understood. Abandonment and mistreatment of animals is a
              matter of public health and safety, it is a reflection of a
              society with a lack of values ​​and empathy towards whom we
              usually call man's best friend. It is the beginning of a problem
              of family violence, abuse, lack of sensitivity towards living
              beings and eventually even towards vulnerable people and groups.
              We seek to raise awareness in our society and take real actions.
              Only by knowing, understanding, listening and observing is it
              possible to sensitize ourselves and generate a real change.
            </p>
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="13"
            text="WHERE, WHEN AND HOW ARE THEY STERILIZED?"
          />
        </div>
        <Accordion.Collapse eventKey="13">
          <>
            <p className="text">
              We are looking for create alliances with shelters and independent
              rescuers to apply sterilizations to their populations of street,
              abused and abandoned dogs that are found with them and thanks to
              the support of donors. If they are dogs that are in a critical
              situation, we seek to achieve rescue, sponsorship and
              rehabilitation through our Rumbo a Casa program to later find a
              family and a home.
            </p>
            <DownloadButton
              file="esterilizacion_a_costo_mayo22.jpg"
              text="Download Infographic"
            />
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler eventKey="14" text="POST STERILIZATION GUIDE" />
        </div>
        <Accordion.Collapse eventKey="14">
          <>
            <p className="text">
              Recommendations after sterilization with some possible scenarios
              of allergy, behavior, feeding.
            </p>
            <DownloadButton
              file="cuidados_post_esterilizacion.jpg"
              text="Descargar Infografía"
            />
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler eventKey="15" text="POST ADOPTION GUIDE" />
        </div>
        <Accordion.Collapse eventKey="15">
          <>
            <p className="text">
              Recommendations after adoption, with some most common scenarios or
              behaviors of the pet in a new home.
            </p>
            <DownloadButton
              file="infografia_ post_adopcion.pdf"
              text="Download Infographic"
            />
          </>
        </Accordion.Collapse>
      </div>
      <div className="mt-5">
        <div className="callejeritos_accordion_header">
          <TextToggler
            eventKey="16"
            text="WHAT IS MY CONTRIBUTION OR DONATION USED FOR?"
          />
        </div>
        <Accordion.Collapse eventKey="16">
          <>
            <p className="text">
              As our donations section indicates, each one has a specific
              purpose. However, donations in money or in kind go directly to
              enable spaces, treatments and even training for rescuers, shelter
              and independent workers who meet the requirements of good
              practices, transparency and monitoring of operations. Promotion of
              the cause and generation of content is part of what is required
              and is used with part of the donations.
            </p>
          </>
        </Accordion.Collapse>
      </div>
    </Accordion>
  );
};
