/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useDebouncedCallback } from 'use-debounce';
import { AxiosResponse } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    faArrowLeft,
    faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import states from '../../../assets/contanst/states.json';

import { Loader } from "../../../components/ui/Loader";
import { useKW2P } from "../../../hooks/useKW2P";
import { UserContext } from "../../../context/UserContext";
import { AuthContext } from "../../../context/AuthContext";
import { MascostasData, Mascota, FuncMap } from "../../../interfaces/mascotasInterfaces";
import {
    mascotaEdad,
    mascotaRaza,
    mascotaSexo,
    mascotaTamano,
} from "../../../helpers/callejeritosFunciones";
import "../../../style.css";


import { Modal, Button } from 'react-bootstrap'

import { useForm, useWatch } from "react-hook-form";

const ITEMS_PER_PAGE = 15;


export function EventosAdopcion() {
    const signalsRef = useRef < FuncMap > ({})
    const { languaje } = useContext(UserContext);

    const { consultarCatalogo } =
        useKW2P();


    const queries = {
        'payload->nombre': ({ value, onChange }: { value: any | string | null, onChange: Function | null }) => (
            <div className="col">

                <input
                    type="text"
                    className="form-control"
                    style={{ minWidth: '300px' }}
                    placeholder={
                        languaje === "ES" ? "Buscar mascota" : "Search pet"
                    }
                    value={value}
                    onChange={v => onChange?.(v.target.value)}
                />

            </div>
        ),
        'payload->nuestro_canal': ({ value, onChange }: { value: any | string | null, onChange: Function | null }) => (
            <div className="col">
                <select
                    className="form-control"
                    style={{ minWidth: '300px' }}
                    value={value}
                    onChange={v => {
                        debugger
                        return onChange?.(v.target.value)
                    }}
                >
                    <option value="" selected>{languaje === 'ES' ? '¿Fue adoptado por medio de Callejeritos?' : 'He was adopted through Callejeritos?'}</option>
                    <option value="SI">{languaje === "ES" ? "SI" : "YES"}</option>
                    <option value="NO">NO</option>
                </select>
            </div>
        )
    }



    const [usuarioMascotaDuenio, setUsuarioMascotaDuenio] = useState < Mascota | null > (null);


    const consultarMascotasEstado = (
        { estado, query, page }:
            { estado?: string, query?: any, page?: number | null }): Promise<AxiosResponse<MascostasData>> => {
        return consultarCatalogo({
            schema: "mascotas",
            ...page ? {
                limit: ITEMS_PER_PAGE,
                page: page,
            } : {},

            filter: {
                where: [
                    ...estado ? [{
                        field: "payload->estado",
                        operator: "=",
                        value: estado,
                    }] : [],
                    ...Object.entries(query ?? {}).map(([key, value]) => ({
                        field: key,
                        operator: "like",
                        value: `%${value}%`,
                        collate: "utf8mb4_unicode_ci"
                    })),
                    {
                        field: "payload->eliminado",
                        operator: "=",
                        value: false,
                    }
                ],
            },
        });

    };

    const fetchData = async () => {
        consultarMascotasEstado({ estado: 'adoptado' })
            .then(({ data: { data } }) => {
                // debugger
            })
    };




    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div className="mascotas__container justify-content-start">


                <div className="mascotas__header">
                    <p>{languaje === "ES" ? "Eventos de adopción" : "Adoption Events"}</p>
                    <button className="btn btn-callejeritos" onClick={signalsRef.current['modal']}>Agregar +</button>
                </div>
                <div className="macotas__body">
                    <TablaEventos
                        estado={'adopcion'}

                        consultarMascotasEstado={consultarMascotasEstado}
                        onUpdateState={signalsRef.current?.['modal'] ?? (() => { })}
                        // @ts-ignore
                        signals={cb => signalsRef.current['table'] = cb}
                        queries={queries}
                    />
                </div>
            </div>

            <ModalEventos signals={(cb: Function) => signalsRef.current['modal'] = cb} onFinish={() => {
                fetchData()//implementar funcion
                // @ts-ignore
                signalsRef.current['table']?.(true)
            }} />
        </>
    )
};



function TablaEventos({
    estado,
    consultarMascotasEstado,
    onUpdateState,
    signals,
    queries,
}: {
    estado: string;
    consultarMascotasEstado: Function;
    onUpdateState: Function;
    signals: Function;
    queries: any;
}) {
    const { languaje } = useContext(UserContext);
    const { handleError, setLoading, isLoading, user } = useContext(AuthContext);


    const [page, setPage] = useState < number > (1);
    const [lastPage, setLastPage] = useState < number > (0);
    const [query, setQuery] = useState < any > ({});
    const [mascotas, setMascotas] = useState < Mascota[] > ([]);



    const obtenerIdDueno = (event: any) => {
        let idUsuario = event.target.value;
        console.log("valor_dueño", idUsuario);
        const { payload: usr } = mascotas.find((mascota: Mascota) => mascota.id == idUsuario) as Mascota;

    };

    const fetchData = async (sinLoader = false) => {


        try {
            !sinLoader && setLoading(true);
            const {
                data: {
                    data,
                    paginate
                },
            } = await consultarMascotasEstado({ estado, query, page, sinLoader });

            setLastPage(paginate?.last_page ?? 0);
            console.log("mascotas", data)
            // @ts-ignore
            setMascotas(data.map((m, i) => ({ ...m, index: (page - 1) * ITEMS_PER_PAGE + 1 + i })));

            setLoading(false);
        } catch (err: any) {
            setLoading(false);
            handleError(err);
        }
    };
    const handleSearch = useDebouncedCallback(() => {
        fetchData(true)
    }, 500);
    useEffect(() => {
        handleSearch()
    }, [query]);
    useEffect(() => {
        fetchData();
        signals(fetchData)
    }, [estado, page,]);
    return (<>

        <div className="row w-100 mb-2">

            {Object.entries(queries).map(([key, Value]) => (
                // @ts-ignore
                <Value
                    key={key}
                    value={query[key]}
                    onChange={(v: any) => {
                        setQuery((q: any) => ({ ...q, [key]: v }))
                    }}
                />
            ))}

        </div>

        <div className="row">
            <div className="col-12">
                <div className="table-responsive">
                    <table className="table table-sm table-hover">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{languaje === "ES" ? "NOMBRE" : "NAME"}</th>
                                {estado === 'adoptado' ?
                                    <th>
                                        {languaje === "ES" ? "POR CALLEJERITOS" : "BY CALLEJERITOS"}
                                    </th>
                                    : null}
                                <th>
                                    {languaje === "ES" ? "FECHA CREACIÓN" : "CREATED AT"}
                                </th>
                                <th>{languaje === "ES" ? "RAZA" : "BREED"}</th>
                                <th>COLOR</th>
                                <th>{languaje === "ES" ? "EDAD" : "AGE"}</th>
                                <th>{languaje === "ES" ? "SEXO" : "GENDER"}</th>
                                <th>{languaje === "ES" ? "TAMAÑO" : "SIZE"}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {console.log("array",mascotasPaginadas)} */}
                            {mascotas.length === 0 ?
                                (<tr>
                                    <td colSpan={8} className="text-center" style={{ height: 150, placeContent: 'center' }}>
                                        {languaje === "ES" ? "No hay mascotas" : "No pets"}
                                    </td>
                                </tr>)
                                :
                                mascotas.map((mascota: Mascota) => (
                                    // mascota.payload.eliminado === "" || mascota.payload.eliminado === false ?
                                    <tr key={mascota.id}>
                                        <td>{mascota.index}</td>
                                        <td>{mascota.payload.nombre}</td>
                                        {estado === 'adoptado' ?
                                            <td>
                                                {mascota.payload.nuestro_canal}
                                            </td>
                                            : null}
                                        <td>{mascota.created_at.slice(0, -17)}</td>

                                        <td>{mascotaRaza(mascota.payload.raza, languaje)}</td>
                                        <td>{mascota.payload.color}</td>
                                        <td>{mascotaEdad(mascota.payload.edad, languaje)}</td>
                                        <td>{mascotaSexo(mascota.payload.sexo, languaje)}</td>
                                        <td>{mascotaTamano(mascota.payload.tamano, languaje)}</td>
                                        <td>

                                            <div className="d-flex justify-content-end" style={{ gap: '.5rem' }}>
                                                <button
                                                    value={mascota.id}
                                                    className="btn btn-sm btn-outline-info"
                                                    onClick={_ => onUpdateState(mascota)}
                                                    style={{ placeContent: 'center' }}
                                                >
                                                    {languaje === "ES" ? "Cambiar estatus" : "Change status"}
                                                </button>
                                                <button
                                                    value={mascota.id}
                                                    className="btn btn-sm btn-outline-primary"
                                                    onClick={obtenerIdDueno}
                                                    style={{ placeContent: 'center' }}
                                                >
                                                    {languaje === "ES" ? "Dueño" : "Owner"}
                                                </button>
                                                <Link
                                                    to={`/mascotas/adopcion/${mascota.id}`}
                                                    className="btn btn-sm btn-detalles"
                                                    style={{ placeContent: 'center' }}
                                                >
                                                    {languaje === "ES" ? "Detalles" : "Details"}
                                                </Link>
                                                {mascota.payload.expediente_id && (
                                                    <Link
                                                        to={`/expediente-azul/expediente/${mascota.payload.nombre}/${mascota.payload.expediente_id}`}
                                                        className="btn btn-sm btn-outline-primary mr-2 font-weight-bold"
                                                        style={{ placeContent: 'center' }}
                                                    >
                                                        {languaje === "ES" ? "Expediente" : "File"}
                                                    </Link>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                    // : null
                                ))}
                            {/* {console.log(usuarioMascota)} */}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-6"></div>
            <div className="col-6">
                <div className="row align-items-center">
                    <div className="col">
                        {/* <button className='form-control' onClick={prevHandle}>{languaje === 'ES' ? 'página anterior' : 'previous page'}</button> */}
                        {page > 1 ?
                            <button className="form-control" onClick={_ => setPage(p => Number(p) - 1)}>
                                <FontAwesomeIcon icon={faArrowLeft as IconProp} />
                            </button>
                            : null}
                    </div>
                    <div>
                        {page} de {lastPage}
                    </div>
                    <div className="col">
                        {/* <button className='form-control' onClick={nextHandler}>{languaje === 'ES' ? 'siguiente página' : 'next page'}</button> */}
                        {page < lastPage ?
                            <button className="form-control" onClick={_ => setPage(p => Number(p) + 1)}>
                                <FontAwesomeIcon icon={faArrowRight as IconProp} />
                            </button>
                            : null}
                    </div>
                </div>
            </div>
        </div>
        {isLoading && <Loader />}
    </>)
}

const MascotaDic = () => []

function ModalEventos({ signals, onFinish }: { signals: Function, onFinish: Function }) {
    const { actualizarCatalogoPublic } = useKW2P();
    const { languaje } = useContext(UserContext);
    const [selectedMascota, setSelectedMascota] = useState < Mascota | null > (null);
    const { formState: { errors }, handleSubmit, register, control, setValue, reset } = useForm()
    const estado = useWatch({ control, name: 'estado' })
    const tabs = MascotaDic(languaje)
    const handleUpdateState = (mascota: Mascota) => {
        reset({})
        setSelectedMascota(mascota);
        setValue('estado', mascota.payload.estado)
        setValue('nuestro_canal', '')
    }
    const onSubmit = (data: any) => {
        console.log(data)
        debugger
        // actualizarCatalogoPublic({
        //     id: selectedMascota?.id,
        //     schema: selectedMascota?.schema,
        //     payload: {
        //         ...selectedMascota?.payload ?? {},
        //         estado,
        //         nuestro_canal,
        //     }
        // }).then(({ data: { data } }) => {
        //     onFinish()
        //     setSelectedMascota(null)
        // })
    }
    useEffect(() => {
        signals(handleUpdateState)
    }, [])
    return (
        <Modal size="lg" centered show={!!selectedMascota} onHide={() => setSelectedMascota(null)}>
            <Modal.Header style={{ borderBottom: 'unset' }}>
                <Modal.Title>
                    Nuevo evento de adopción
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="row">
                <div className="col-12 mt-2">
                    <label htmlFor="descripcion">DESCRIPCIÓN:</label>
                    <textarea id="descripcion" className="form-control" placeholder={languaje === 'ES' ? 'Ingresa la descripcion del evento' : `Enter the descriptions of the event`} autoComplete="off"
                        {...register('descripcion', { required: languaje === 'ES' ? 'La descripcion del evento es requerido' : 'The event descriptions is required' })} />
                    {errors.descripcion && <small className="text-danger">{errors.descripcion.message}</small>}
                </div>
                <div className="col-5 mt-2">
                    <label htmlFor="fecha">FECHA:</label>
                    <input id="fecha" className="form-control" autoComplete="off"
                        {...register('fecha', { required: languaje === 'ES' ? 'La fecha del evento es requerido' : 'The event date is required' })} type="date" />
                    {errors.fecha && <small className="text-danger">{errors.fecha.message}</small>}
                </div>
                <div className="col-7 row">
                    <div className="col mt-2">
                        <label htmlFor="horario_de">DE:</label>
                        <input id="horario_de" className="form-control" autoComplete="off"
                            {...register('horario.de', { required: languaje === 'ES' ? 'La fecha del evento es requerido' : 'The event date is required' })} type="time" />
                        {errors.horario?.de && <small className="text-danger">{errors.horario?.de.message}</small>}
                    </div>
                    <div className="col mt-2">
                        <label htmlFor="horario_a">A:</label>
                        <input id="horario_a" className="form-control" autoComplete="off"
                            {...register('horario.a', { required: languaje === 'ES' ? 'La fecha del evento es requerido' : 'The event date is required' })} type="time" />
                        {errors.horario?.a && <small className="text-danger">{errors.horario?.a.message}</small>}
                    </div>
                </div>

                <div className="col-4 mt-2">
                    <label htmlFor="lugar_direccion">DIRECCIÓN:</label>
                    <input id="lugar_direccion" className="form-control" placeholder={languaje === 'ES' ? 'Ingresa la dirección del evento' : `Enter the address of the event`} autoComplete="off"
                        {...register('lugar.direccion', { required: languaje === 'ES' ? 'La dirección del evento es requerido' : 'The event address is required' })} />
                    {errors.lugar?.direccion && <small className="text-danger">{errors.lugar.direccion?.message}</small>}
                </div>
                <div className="col mt-2">
                    <label htmlFor="lugar_colonia">Colonia:</label>
                    <input id="lugar_colonia" className="form-control" placeholder={languaje === 'ES' ? 'Colinia' : `Neighborhood`} autoComplete="off"
                        {...register('lugar.colonia', { required: languaje === 'ES' ? 'La colinia es requerido' : 'The neighborhood is required' })} />
                    {errors.lugar?.colonia && <small className="text-danger">{errors.lugar.colonia?.message}</small>}
                </div>
                <div className="col-2 mt-2">
                    <label htmlFor="lugar_cp">CP:</label>
                    <input id="lugar_cp" className="form-control" placeholder={languaje === 'ES' ? 'CP' : `zip code`} autoComplete="off"
                        {...register('lugar.cp', { 
                            required: languaje === 'ES' ? 'La cp es requerido' : 'The zip code is required',
                            pattern: {
                                value: /^[0-9]{5}$/,
                                message: languaje === 'ES' ? 'El CP debe ser de 5 digitos' : 'The zip code must be 5 digits'
                            },
                        })} maxLength={5} />
                    {errors.lugar?.cp && <small className="text-danger">{errors.lugar.cp?.message}</small>}
                </div>
                <div className="col mt-2">
                    <label htmlFor="lugar_estado">{languaje === 'ES' ? 'Estado' : 'State'}</label>
                    <select id="lugar_estado" className="form-control" {...register('lugar.estado',
                        {required: languaje === 'ES' ? 'Elije una estado' : 'Choose one state',}
                    )}
                    >
                        <option value="" disabled selected hidden>{languaje === 'ES' ? 'Estado' : 'State'}</option>
                        {states.map((tab) => (
                            <option key={tab.key} value={tab.key}>{tab.label}</option>
                        ))}
                    </select>
                    {errors.lugar?.estado && <small className="text-danger">{errors.lugar?.estado.message}</small>}
                </div>
                {/* <pre>{JSON.stringify({ selectedMascota }, null, 2)}</pre> */}
            </Modal.Body>
            <Modal.Footer style={{ borderTop: 'unset' }}>
                <Button variant="secondary" onClick={_ => setSelectedMascota(null)}>
                    {languaje === 'ES' ? 'Cancelar' : 'Cancel'}
                </Button>
                {/* @ts-ignore */}
                <Button variant="primary" onClick={handleSubmit(onSubmit, console.log)}>
                    {languaje === 'ES' ? 'Guardar' : 'Save'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}