import React from 'react';
import { useContext } from 'react';
import { contactoImages, callejeritosImages } from '../../helpers/callejeritosImages';
import { UserContext } from '../../context/UserContext';
import { AuthContext } from '../../context/AuthContext';
import { RegisterForm } from '../../components/Auth/RegisterForm';
import { LoginForm } from '../../components/Auth/LoginForm';
import { DownloadButton } from '../../components/DownloadButton';

export const Programa = () => {

    const { languaje } = useContext(UserContext);
    const { status } = useContext(AuthContext);

    return (
        <div className="div-programa animate__animated animate__fadeIn">
            {
                languaje === 'ES' ?
                <>
                <div className="header-programa">
                    <img src={ contactoImages('./Programa/tituloES.png').default } alt="INFORMATE" className="title-programaES"/>
                </div>
                <div className="body-programa animate__animated animate__fadeInUp">
                    <p className="title">Según INEGI en México casi 57 de cada 100 hogares tiene una mascota. Forbes México dice que 7 de cada 10 hogares cuenta
                    con una mascota, lo que conlleva que alrededor de 80% de los mexicanos tiene un perro en su hogar. Estas son simplemente cifras y estadísticas,
                    sin embargo muchos de ellos viven en abandono, sin atención médica básica, sin un lugar digno de esparcimiento, sin tiempo de calidad y compañía,
                    incluso se encuentran en patios y azoteas sin sombra, agua y alimento.</p>
                    <p className="text">Trabajamos para salvar a compañeros de vida y evitar su sacrificio en perreras y antirrábicos, atropellamiento, agresión de
                    la ciudadanía, abandono, indiferencia entre otros incluso “teniendo un hogar”.</p>
                    <p className="text">Se les rehabilita física y psicológicamente, realizando protocolos médicos, perfiles de conducta e integración de un expediente.
                    Desparasitación, vacunación, esterilización y baños medicados forman parte del proceso y protocolo de recepción, integración y seguimiento al
                    programa de adopción Rumbo a Casa.</p>
                    <p className="text">Contamos con alianzas y nos encontramos en constante gestión y comunicación con organizaciones en México, Estados Unidos y Canadá,
                    para así poder exportar a algunas mascotas que han cumplido con su proceso de rehabilitación y se encuentran listas para su nuevo hogar y familia.</p>
                    <p className="title">Buscamos hacer público para quienes lo desean, mostrar su experiencia de adopción y casos de éxito a través de nuestras redes y sitio web.</p>

                    <div className="recusos-files">
                        { languaje === 'ES'
                            ? <>
                                <DownloadButton file="requisitos_adopcion_responsable.pdf" text="Requisitos protocolo para adopción"/>
                            </>
                            : <>
                                <DownloadButton file="requisitos_adopcion_responsable.pdf" text="Adoption requirements protocol"/>
                            </>
                        }
                    </div>


                    { status !== 'authenticated' &&
                        <div className="row donaciones_login ">
                            <div className="col-12 col-md">
                                <h3 className="form__title">{ languaje === 'ES' ? 'Únete a Callejeritos': 'Join Callejeritos' }</h3>
                                <RegisterForm />
                            </div>
                            <div className="col-12 col-md mt-2 mt-md-0">
                                <h3 className="form__title">{ languaje === 'ES' ? 'Inicia sesión': 'Login' }</h3>
                                <LoginForm showLabels/>
                            </div>
                            <div className="col-2 d-xl-flex d-md-none d-none flex-column justify-content-center p-0 m-0">
                                <img src={ callejeritosImages('./Auth/Login/chihuahuas.png').default } alt="Cachorros" className="img-fluid"/>
                            </div>
                        </div> 
                    }
                </div>
                </>
                :
                <>
                    <div className="header-programa">
                        <img src={ contactoImages('./Programa/tituloEN.png').default } alt="INFORMATE" className="title-programaEN"/>
                    </div>
                    <div className="body-programa animate__animated animate__fadeInUp">
                        <p className="title">According to INEGI in Mexico, almost 57 out of every 100 households have a pet. Forbes Mexico says that 7 out of 10 households
                        have a pet, which means that around 80% of Mexicans have a dog in their home. These are simply figures and statistics, however many of them live in
                        abandonment, without basic medical care, without a place worthy of recreation, without quality time and company, they are even found on patios and
                        rooftops without shade, water and food.</p>
                        <p className="text">We work to save life partners and avoid their sacrifice in local, regional and national government “shelters”, run over,
                        aggression by citizens, abandonment, indifference among others, even “having a home”.</p>
                        <p className="text">They are physically and psychologically rehabilitated, carrying out medical protocols, behavioral profiles and integration of
                        a file. Deworming, vaccination, sterilization and medicated baths are part of the process and protocol for receiving, integrating and monitoring
                        the Going Home adoption program.</p>
                        <p className="text">We have alliances and we are in constant management and communication with organizations in Mexico, the United States and
                        Canada, in order to export some pets that have completed their rehabilitation process and are ready for their new home and family.</p>
                        <p className="title">We seek to make public for those who want it, show their adoption experience and success stories through our networks and website.</p>
                        { status !== 'authenticated' &&
                            <div className="row donaciones_login">
                                <div className="col-12 col-md">
                                    <h3 className="form__title">{ languaje === 'ES' ? 'Únete a Callejeritos': 'Join Callejeritos' }</h3>
                                    <RegisterForm />
                                </div>
                                <div className="col-12 col-md mt-2 mt-md-0">
                                    <h3 className="form__title">{ languaje === 'ES' ? 'Inicia sesión': 'Login' }</h3>
                                    <LoginForm showLabels/>
                                </div>
                                <div className="col-2 d-xl-flex d-md-none d-none flex-column justify-content-center p-0 m-0">
                                    <img src={ callejeritosImages('./Auth/Login/chihuahuas.png').default } alt="Cachorros" className="img-fluid"/>
                                </div>
                            </div> 
                        }
                    </div>
                </>
            }
        </div>
    )
}
