import React, { useState } from 'react';
import { useContext } from 'react';
import { Accordion } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { LoginForm, RegisterForm } from '../../components/Auth';
import { TextToggler } from '../../components/ui/TextToggler';

import { UserContext } from '../../context/UserContext';
import { AuthContext } from '../../context/AuthContext';

import {
    DonacionAlimento,
    DonacionEsterilizacion,
    DonacionRecurrente,
    DonacionTratamiento,
    DonacionOtros
} from '../../components/Donaciones';

import { nosotrosImages, callejeritosImages } from '../../helpers/callejeritosImages';
import Logotipo from '../../logotipo.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';

export const Donaciones = () => {

    const formRef = React.useRef < HTMLDivElement | null > (null);

    const { facebookLink, instagramLink, languaje } = useContext(UserContext);
    const { status } = useContext(AuthContext);

    const [donacionType, setDonacionType] = useState('');

    const selectDonationType = (e: React.MouseEvent<HTMLImageElement, MouseEvent>, type: string) => {
        if (type === donacionType) {
            setDonacionType('');
            return;
        }
        setDonacionType(type);

        if (status !== 'authenticated') {
            {
                languaje === 'ES' ?
                    Swal.fire({
                        title: 'Para realizar donativos, llevar trazabilidad, transparencia y seguimiento hacia los donantes, es necesario registrarte en nuestro portal. ¡Es fácil y rápido!',
                        imageUrl: Logotipo,
                        imageHeight: 70,
                        imageWidth: 325,
                        allowOutsideClick: false
                    })
                    :
                    Swal.fire({
                        title: 'TO MAKE DONATIONS, BRING TRACEABILITY, TRANSPARENCY AND FOLLOW UP TOWARDS DONORS, IT IS NECESSARY TO REGISTER IN OUR WEBSITE. IT IS EASY AND FAST! IF YOU ARE ALREADY REGISTERED, LOG IN TO YOUR ACCOUNT TO DONATE',
                        imageUrl: Logotipo,
                        imageHeight: 70,
                        imageWidth: 325,
                        allowOutsideClick: false
                    })
            }
        } else {
            const scrollY = window.scrollY
            const rect = formRef.current?.parentElement?.getBoundingClientRect();
            const yPosViewport = rect?.top ?? 0;
            const yPosDocument = yPosViewport + scrollY - 60;
            window.scrollTo({
                top: yPosDocument,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div>
            <div className="header-donaciones animate__animated animate__fadeIn">
                <img src={nosotrosImages(`./Donaciones/${languaje}/titulo.png`).default} alt="Causa social" className="img-fluid title-donaciones" />
            </div>
            <div className="body-donaciones animate__animated animate__fadeInUp">
                <DesktopText lang={languaje} />
                <MobileText lang={languaje} />
            </div>
            <div className="social-links">
                <a href={facebookLink} target="_blank" rel="noopener noreferrer" className="btn btn-social rounded-circle mr-4">
                    <FontAwesomeIcon icon={faFacebookF as IconProp} size="2x" />
                </a>
                <a href={instagramLink} target="_blank" rel="noopener noreferrer" className="btn btn-social2 rounded-circle">
                    <FontAwesomeIcon icon={faInstagram as IconProp} size="2x" />
                </a>
                <span className="social-text"><b>@callejeritosmexico</b></span>
            </div>

            <div className="donaciones__subtitle_box">
                {languaje === 'ES' ?
                    <h1 className="donaciones__subtitle">PARA REALIZAR DONATIVOS, LLEVAR TRAZABILIDAD, TRANSPARENCIA Y SEGUIMIENTO HACIA LOS DONANTES ES NECESARIO REGISTRARTE EN NUESTRO PORTAL. ¡ES FÁCIL Y RÁPIDO! Si ya estás registrado, ingresa a tu cuenta para donar</h1>
                    :
                    <h1 className="donaciones__subtitle">TO MAKE DONATIONS, BRING TRACEABILITY, TRANSPARENCY AND FOLLOW UP TOWARDS DONORS, IT IS NECESSARY TO REGISTER IN OUR WEBSITE. IT'S EASY AND FAST! IF YOU ARE ALREADY REGISTERED, LOG IN TO YOUR ACCOUNT TO DONATE</h1>
                }
            </div>
            <div className="donaciones-container mt-2"></div>
            <div className="donaciones__buttonsdiv">
                <div className="donaciones__buttonbox">
                    <img src={nosotrosImages(`./Donaciones/${languaje}/donacion_recurrente.png`).default} alt="Donación recurrente"
                        style={donacionType === 'recurrente' ? { background: 'transparent' } : { background: 'white' }}
                        onClick={(e) => selectDonationType(e, 'recurrente')} />
                </div>
                <div className="donaciones__buttonbox">
                    <img src={nosotrosImages(`./Donaciones/${languaje}/donacion_alimento.png`).default} alt="Donación de alimento"
                        style={donacionType === 'alimento' ? { background: 'transparent' } : { background: 'white' }}
                        onClick={(e) => selectDonationType(e, 'alimento')} />
                </div>
                <div className="donaciones__buttonbox">
                    <img src={nosotrosImages(`./Donaciones/${languaje}/donacion_esterilizacion.png`).default} alt="Donación de esterilización"
                        style={donacionType === 'esterilizacion' ? { background: 'transparent' } : { background: 'white' }}
                        onClick={(e) => selectDonationType(e, 'esterilizacion')} />
                </div>
                <div className="donaciones__buttonbox">
                    <img src={nosotrosImages(`./Donaciones/${languaje}/donacion_tratamiento.png`).default} alt="Donación de tratamiento"
                        style={donacionType === 'tratamiento' ? { background: 'transparent' } : { background: 'white' }}
                        onClick={(e) => selectDonationType(e, 'tratamiento')} />
                </div>
                <div className="donaciones__buttonbox">
                    <img src={nosotrosImages(`./Donaciones/${languaje}/donacion_otros.png`).default} alt="Donación de otros"
                        style={donacionType === 'otros' ? { background: 'transparent' } : { background: 'white' }}
                        onClick={(e) => selectDonationType(e, 'otros')} />
                </div>
            </div>
            <div className="donaciones__formdiv">
                {status !== 'authenticated' ?
                    <div className="row donaciones_login">
                        <div className="col-12 col-md">
                            <h3 className="form__title">{languaje === 'ES' ? 'Únete a Callejeritos' : 'Join Callejeritos'}</h3>
                            <RegisterForm />
                        </div>
                        <div className="col-12 col-md mt-2 mt-md-0">
                            <h3 className="form__title">{languaje === 'ES' ? 'Inicia sesión' : 'Login'}</h3>
                            <LoginForm showLabels />
                        </div>
                        <div className="col-2 d-xl-flex d-md-none d-none flex-column justify-content-center p-0 m-0">
                            <img src={callejeritosImages('./Auth/Login/chihuahuas.png').default} alt="Cachorros" className="img-fluid" />
                        </div>
                    </div>
                    : <div ref={formRef}>
                        {donacionType === 'recurrente' && <DonacionRecurrente />}
                        {donacionType === 'alimento' && <DonacionAlimento />}
                        {donacionType === 'esterilizacion' && <DonacionEsterilizacion />}
                        {donacionType === 'tratamiento' && <DonacionTratamiento />}
                        {donacionType === 'otros' && <DonacionOtros />}
                    </div>
                }
                <div className="d-flex justify-content-center">
                    {languaje === 'ES' ?
                        <img src={nosotrosImages(`./Donaciones/pantalla_pagos_callejeritos.png`).default} className="img-fluid p-3" alt="Pagos Callejeritos" />
                        :
                        <img src={nosotrosImages(`./Donaciones/pantalla_pagos_callejeritos_ingles.png`).default} className="img-fluid p-3" alt="Pagos Callejeritos" />
                    }
                </div>
            </div>
        </div>
    )
}

function DesktopText({ lang }: { lang: string }) {
    return (<div className="d-none d-lg-block">{
        lang === 'ES' ?
            <>
                <p className="title-gray">Cero tolerancia</p>
                <p className="text">Sabemos y entendemos que existen personas y asociaciones apocrifas, que lucran con el maltrato y sufrimiento animal,
                    poca o nula transparencia en sus operaciones. En Callejeritos y Rumbo a Casa tenemos una política de cero tolerancia a la corrupción,
                    no transparencia y malas prácticas. El mejor apoyo y donación que podemos recibir es apadrinado a uno de nuestros mejores amigos y/o
                    adoptando responsablemente; sin embargo entendemos que existen personas que desean apoyar a la causa más no les es posible adoptar
                    por múltiples razones</p>
                <p className="text">Solicitamos tu donativo para el costo de una esterilización. Podrás encontrar nuestro costo para donar una esterilización
                    con evidencia fotográfica, recibo oficial de Callejeritos con todos los detalles y tus datos.</p>
                <p className="title-gray">MANUTENCIÓN Y NUTRICIÓN</p>
                <p className="text">Donaciones de alimento son muy importantes para la manutención y nutrición de nuestros mejores amigos durante su proceso de
                    rehabilitación y Rumbo a Casa. Si deseas donar alimento, nosotros podremos contactarte con un rescatista o albergue certificado dentro de la
                    república mexicana avalado por nuestro equipo de que cumple con las buenas practicas y transparencia.</p>
                <p className="text">Si te encuentras fuera de México y deseas apoyar a la causa en alguno de tus países, te invitamos a sumarte a nuestra red de
                    emprendimiento social y con causa para hacer de este proyecto una red global.</p>
                <p className="title-gray">Tiempo</p>
                <p className="text">Donar es lo más valioso que un ser humano puede hacer... tu tiempo para apoyar esta noble causa.</p>
                <p className="text">Contáctanos a través de nuestras redes sociales o forma de contacto.</p>
            </>
            :
            <>
                <p className="title-gray">Zero Tolerance</p>
                <p className="text">We know and understand that there are apocryphal people and associations that profit from animal abuse and suffering with little
                    or no transparency in their operations. At Callejeritos and Rumbo a Casa we have a policy of zero tolerance for corruption, no transparency and
                    bad practices. The best support and donation we can receive is sponsoring one of our best friends and / or adopting responsibly; However, we
                    understand that there are people who want to support the cause but it is not possible for them to adopt for multiple reasons</p>
                <p className="text">We request your donation be the cost of a sterilization. You can find our cost to donate a sterilization with photographic
                    evidence, official receipt from Callejeritos with all the details and your data.</p>
                <p className="title-gray">Maintenance and nutrition</p>
                <p className="text">Food donations are very important for the maintenance and nutrition of our best friends during their rehabilitation process
                    and Rumbo a Casa. If you want to donate food, we can contact you with a certified rescue worker or shelter within Mexico endorsed by our team
                    that complies with good practices and transparency.</p>
                <p className="text">If you are outside of Mexico and want to support the cause in one of your countries, we invite you to join our network of social
                    entrepreneurship and with a cause to make this project a global network.</p>
                <p className="title-gray">Time</p>
                <p className="text">Donating is the most valuable thing a human being can do ... your time to support this noble cause.</p>
                <p className="text">Contact us through our social media or contact form.</p>
            </>
    }</div>);
}


function MobileText({ lang }: { lang: string }) {

    const activePanelRef = React.useRef < HTMLDivElement | null > (null);
    const [activeKey, setActiveKey] = useState < string > ('0');

    const handlePanelExpand = (key: string) => {
        setActiveKey(key);
        // Hacer scroll al panel activo después de expandirlo
        const oldContent = activePanelRef.current?.parentElement?.childNodes?.[1] as HTMLDivElement;
        console.log(oldContent)
        const height = (activeKey !== key && Number(key) > Number(activeKey)) ? oldContent.getBoundingClientRect()?.height ?? 0 : 0;
        setTimeout(() => {
            const scrollY = window.scrollY
            const rect = activePanelRef.current?.getBoundingClientRect();
            const yPosViewport = rect?.top ?? 0;
            console.log(`${yPosViewport} + ${scrollY} - ${height} - 56`)
            const yPosDocument = yPosViewport + scrollY - height - 56;
            window.scrollTo({
                top: yPosDocument,
                behavior: 'smooth',
            });

        }, 1);
    };
    return (
        <>
            <div className="d-block d-lg-none">{
                lang === 'ES' ?
                    <Accordion defaultActiveKey="0">
                        <div>
                            <div className="callejeritos_accordion_header" >
                                <TextToggler eventKey="0" text="Cero tolerancia" />
                            </div>
                            <Accordion.Collapse eventKey="0">
                                <div >
                                    <p className="text">Sabemos y entendemos que existen personas y asociaciones apocrifas, que lucran con el maltrato y sufrimiento animal,
                                        poca o nula transparencia en sus operaciones. En Callejeritos y Rumbo a Casa tenemos una política de cero tolerancia a la corrupción,
                                        no transparencia y malas prácticas. El mejor apoyo y donación que podemos recibir es apadrinado a uno de nuestros mejores amigos y/o
                                        adoptando responsablemente; sin embargo entendemos que existen personas que desean apoyar a la causa más no les es posible adoptar
                                        por múltiples razones</p>
                                    <p className="text">Solicitamos tu donativo para el costo de una esterilización. Podrás encontrar nuestro costo para donar una esterilización
                                        con evidencia fotográfica, recibo oficial de Callejeritos con todos los detalles y tus datos.</p>
                                </div>
                            </Accordion.Collapse>
                        </div>
                        <div className="mt-5">
                            <div className="callejeritos_accordion_header" >
                                <TextToggler eventKey="1" text="MANUTENCIÓN Y NUTRICIÓN" />
                            </div>
                            <Accordion.Collapse eventKey="1">
                                <div >
                                    <p className="text">Donaciones de alimento son muy importantes para la manutención y nutrición de nuestros mejores amigos durante su proceso de
                                        rehabilitación y Rumbo a Casa. Si deseas donar alimento, nosotros podremos contactarte con un rescatista o albergue certificado dentro de la
                                        república mexicana avalado por nuestro equipo de que cumple con las buenas practicas y transparencia.</p>
                                    <p className="text">Si te encuentras fuera de México y deseas apoyar a la causa en alguno de tus países, te invitamos a sumarte a nuestra red de
                                        emprendimiento social y con causa para hacer de este proyecto una red global.</p>
                                </div>
                            </Accordion.Collapse>
                        </div>
                        <div className="mt-5">
                            <div className="callejeritos_accordion_header" >
                                <TextToggler eventKey="2" text="Tiempo" />
                            </div>
                            <Accordion.Collapse eventKey="2" >
                                <div >
                                    <p className="text">Donar es lo más valioso que un ser humano puede hacer... tu tiempo para apoyar esta noble causa.</p>
                                    <p className="text">Contáctanos a través de nuestras redes sociales o forma de contacto.</p>
                                </div>
                            </Accordion.Collapse>
                        </div>
                    </Accordion>
                    :
                    <Accordion defaultActiveKey="0" >
                        <div>
                            <div className="callejeritos_accordion_header" >
                                <TextToggler eventKey="0" text="Zero Tolerance" />
                            </div>
                            <Accordion.Collapse eventKey="0">
                                <div >
                                    <p className="text">We know and understand that there are apocryphal people and associations that profit from animal abuse and suffering with little
                                        or no transparency in their operations. At Callejeritos and Rumbo a Casa we have a policy of zero tolerance for corruption, no transparency and
                                        bad practices. The best support and donation we can receive is sponsoring one of our best friends and / or adopting responsibly; However, we
                                        understand that there are people who want to support the cause but it is not possible for them to adopt for multiple reasons</p>
                                    <p className="text">We request your donation be the cost of a sterilization. You can find our cost to donate a sterilization with photographic
                                        evidence, official receipt from Callejeritos with all the details and your data.</p>
                                </div>
                            </Accordion.Collapse>
                        </div>
                        <div className="mt-5">
                            <div className="callejeritos_accordion_header" >
                                <TextToggler eventKey="1" text="Maintenance and nutrition" />
                            </div>
                            <Accordion.Collapse eventKey="1">
                                <div >
                                    <p className="text">Food donations are very important for the maintenance and nutrition of our best friends during their rehabilitation process
                                        and Rumbo a Casa. If you want to donate food, we can contact you with a certified rescue worker or shelter within Mexico endorsed by our team
                                        that complies with good practices and transparency.</p>
                                    <p className="text">If you are outside of Mexico and want to support the cause in one of your countries, we invite you to join our network of social
                                        entrepreneurship and with a cause to make this project a global network.</p>
                                </div>
                            </Accordion.Collapse>
                        </div>
                        <div className="mt-5">
                            <div className="callejeritos_accordion_header" >
                                <TextToggler eventKey="2" text="Time" />
                            </div>
                            <Accordion.Collapse eventKey="2" style={{ height: 643 }} className='kegovc'>
                                <div >
                                    <p className="text">Donating is the most valuable thing a human being can do ... your time to support this noble cause.</p>
                                    <p className="text">Contact us through our social media or contact form.</p>
                                </div>
                            </Accordion.Collapse>
                        </div>
                    </Accordion>
            }</div>
        </>);
}
