import React, { useContext } from 'react';
import { UserContext } from '../../context/UserContext';

export const TerminosCondiciones = () => {

    const { languaje } = useContext(UserContext);

    return (
        <div className="container terminos__container">
        { languaje === 'ES' ? 
            <>
                <div className="terminos__titlebox">
                    <h1 className="terminos__title">Términos y condiciones</h1>
                </div>
                <div className="terminos__content">
                    <p><b>Los presentes términos y condiciones explican nuestros servicios y la relación con usted como
                    cliente o usuario y los derechos y responsabilidades recíprocos al utilizar nuestra plataforma
                    web y cualquier comunicación u operación comercial que realice con Yo Soy Patas, S.A.P.I de
                    C.V. (En lo sucesivo Callejeritos).</b></p>
                    <p><b>Usted está de acuerdo en aceptar los términos y condiciones que se describen a continuación
                    incluyendo cualquier nueva característica o herramienta que pueda añadirse a los productos y
                    servicios que ponemos a su disposición, aunque no sean mencionados de manera expresa en el
                    contenido.</b></p>
                    <p><b>Callejeritos se reserva el derecho de actualizar estos términos y condiciones sin previo aviso a
                    los clientes o usuarios, se recomienda revisar regularmente el sitio web www.callejeritos.mx
                    donde encontrará disponible la versión vigente.</b></p>
                    <p><b>Uso de sitio web</b></p>
                    <p><b>a&#41; </b> El dominio <b>www.callejeritos.mx</b> (en lo sucesivo “el sitio web”) es para uso exclusivo de clientes
                    actuales y potenciales de Callejeritos (en lo sucesivo “Usuarios”), que les permite consultar e
                    interactuar con la información necesaria para gestionar solicitudes relacionadas con los servicios
                    que ofrece Callejeritos a través de su sitio web y del módulo Comprar y Donaciones (en lo
                    sucesivo “Compras”). Todo el contenido de este sitio web, incluyendo textos, imágenes, videos,
                    archivos, etc. y toda la información es propiedad de Callejeritos o son utilizados con autorización
                    de sus titulares. Por lo tanto, los derechos sobre dicho contenido se encuentran protegidos por las
                    leyes mexicanas aplicables y por el derecho internacional.</p>
                    <p><b>b&#41; </b>Los nombres, logotipos, productos, siluetas, imágenes, servicios, marcas, lemas publicitarios,
                    videos o cualquier otro elemento de Callejeritos que se encuentren contenidos actualmente o se
                    inserten en el futuro, no pueden ser utilizados en ningún momento sin el previo permiso por
                    escrito de Callejeritos, quedando por tanto prohibida su reproducción, modificación, distribución,
                    transmisión, re publicación, exhibición o ejecución, así como cualquier otro uso del contenido de
                    este sitio web en alguna otra página de Internet o en alguna otra red informática, incluso en la
                    publicidad y anuncios en relación con la conformación de este sitio web.</p>
                    <p><b>c&#41; </b>Queda prohibido al Usuario el intentar obtener información, mensajes, archivos de sonido o de
                    imagen (incluyendo fotografías, grabaciones, videos, dibujos, etc.) o cualquier otro elemento de
                    este sitio web empleando cualquier medio distinto a los puestos expresamente a disposición del
                    Usuario para tal fin. Las descargas para uso estrictamente personal están permitidas, más no su
                    reproducción para cualquier otro propósito. La descarga de cualquier elemento contenido en este
                    sitio web (incluyendo cualquier programa conectado por hipertexto o ligas) sólo se autoriza para
                    efectos de su aplicación en la visita de nuestro sitio web, para cualquier uso distinto, el Usuario
                    debe contar con autorización expresa emitida por Callejeritos.</p>
                    <p><b>d&#41; </b>Queda prohibido al Usuario realizar o intentar realizar cualquier tipo de adición, supresión,
                    transformación o cualquier otra forma de alteración al contenido de este sitio web, y asimismo el
                    Usuario no deberá suprimir o alterar en forma alguna cualquier dato que identifique la
                    titularidad de derechos sobre cualquier contenido de este sitio web.</p>
                    <p><b>e&#41; </b>Callejeritos podrá modificar el sitio web o eliminar características de cualquier modo, en
                    cualquier momento sin previo aviso. El sitio web incluye enlaces que le permitirán visitar sitios
                    de socios comerciales o de terceros, Callejeritos no tiene control sobre estos sitios ni sobre las
                    compañías a las que pertenecen. Al utilizar el sitio web reconoce y acepta que Callejeritos no es
                    responsable por ninguna transmisión de datos que pudieran afectar el comportamiento de su
                    equipo.</p>
                    <p><b>f&#41; </b>El módulo Compras está habilitado únicamente para usuarios registrados en la base datos de
                    Callejeritos y permite gestionar solicitudes de información, pedidos, descarga de facturas y toda
                    la información relacionada con los procesos de adquisición de productos y servicios.</p>
                    <p><b>g&#41; </b>Al registrarse en Callejeritos accede a proporcionar información actualizada y exacta acerca de
                    usted o su empresa que será utilizada para gestionar correctamente los procesos relacionados con
                    la adquisición de productos y servicios. Callejeritos sólo utilizará la información personal de
                    conformidad con los términos del Aviso de Privacidad publicado en su sitio web.</p>
                    <p><b>Políticas de Privacidad</b></p>
                    <ul>
                        <li className="mt-3">Proteger su privacidad es importante para Callejeritos. Esperamos que leer esto le ayude a
                        comprender como Callejeritos obtiene, usa y protege, la información personal que nos hace
                        llegar.</li>
                        <li className="mt-3">Dado que el Internet es un medio donde existen demasiadas personas participando, es
                        imposible que tengamos controlado por completo el ciclo de traslado de su información hasta
                        nuestros servidores, por lo que le sugerimos enviar sólo la información que se solicita y utilizar
                        navegadores reconocidos que no pongan en riesgo la integridad de los contenidos.</li>
                        <li className="mt-3">Tenga por seguro que cualquiera de los datos personales que nos haga llegar serán solamente
                        utilizados con el propósito de hacerle llegar la información útil que caracteriza nuestra página
                        web y sus publicaciones electrónicas y con el fin de adaptar o mejorar nuestros productos y
                        servicios.</li>
                    </ul>
                </div>
            </> 
            : 
            <>
                <div className="terminos__titlebox">
                    <h1 className="terminos__title">Terms and conditions</h1>
                </div>
                <div className="terminos__content">
                    <p><b>These terms and conditions explain our services and the relationship with you as a client or user and the reciprocal
                    rights and responsibilities when using our web platform and any communication or commercial operation that you carry
                    out with Yo Soy Patas, S.A.P.I de C.V. (Hereinafter Callejeritos).</b></p>
                    <p><b>You agree to accept the terms and conditions described below, including any new features or tools that may be added
                    to the products and services that we make available to you, even if they are not expressly mentioned in the content.</b></p>
                    <p><b>Callejeritos reserves the right to update these terms and conditions without prior notice to customers or users, it is
                    recommended to regularly review the website www.callejeritos.mx where you will find the current version available.</b></p>
                    <p><b>Website use</b></p>
                    <p><b>a&#41; </b> The domain <b>www.callejeritos.mx</b> (hereinafter "the website") is for the exclusive use of current and
                    potential customers of Callejeritos (hereinafter "Users"), which allows them to consult and interact with the information
                    necessary to manage requests related to the services offered by Callejeritos through its website and the Buy and Donations
                    module (hereinafter "Purchases"). All content on this website, including texts, images, videos, files, etc. and all the
                    information is the property of Callejeritos or is used with the authorization of its owners. Therefore, the rights to said
                    content are protected by applicable Mexican laws and international law.</p>
                    <p><b>b&#41; </b>The names, logos, products, silhouettes, images, services, brands, advertising slogans, videos or any other
                    element of Callejeritos that are currently contained or are inserted in the future, cannot be used at any time without prior
                    written permission. de Callejeritos, being therefore prohibited its reproduction, modification, distribution, transmission,
                    republication, exhibition or execution, as well as any other use of the content of this website on any other Internet page or
                    on any other computer network, even in the advertising and advertisements in connection with the formation of this website.</p>
                    <p><b>c&#41; </b>The User is prohibited from trying to obtain information, messages, sound or image files (including photographs,
                    recordings, videos, drawings, etc.) or any other element of this website using any means other than those expressly made available
                    to the User. to this end. Downloads for strictly personal use are allowed, but not its reproduction for any other purpose. The
                    download of any element contained in this website (including any program connected by hypertext or links) is only authorized for
                    the purposes of its application when visiting our website, for any different use, the User must have express authorization issued
                    by Callejeritos.</p>
                    <p><b>d&#41; </b>The User is prohibited from making or attempting to make any type of addition, deletion, transformation or any other
                    form of alteration to the content of this website, and likewise the User must not delete or alter in any way any data that identifies
                    the ownership of rights over any content on this website.</p>
                    <p><b>e&#41; </b>Callejeritos may modify the website or remove features in any way, at any time without prior notice. The website
                    includes links that will allow you to visit the sites of business partners or third parties, Callejeritos has no control over these
                    sites or the companies to which they belong. By using the website you acknowledge and accept that Callejeritos is not responsible for
                    any transmission of data that could affect the behavior of your computer.</p>
                    <p><b>f&#41; </b>The Purchasing module is enabled only for users registered in the Callejeritos database and allows managing requests
                    for information, orders, downloading invoices and all the information related to the processes of purchasing products and services.</p>
                    <p><b>g&#41; </b>By registering in Callejeritos you agree to provide updated and accurate information about yourself or your company
                    that will be used to correctly manage the processes related to the acquisition of products and services. Callejeritos will only use
                    personal information in accordance with the terms of the Privacy Notice published on its website.</p>
                    <p><b>Privacy Policies</b></p>
                    <ul>
                        <li className="mt-3">Protecting your privacy is important to Callejeritos. We hope that reading this helps you understand how
                        Callejeritos obtains, uses and protects the personal information that you send us.</li>
                        <li className="mt-3">Since the Internet is a medium where there are too many people participating, it is impossible for us to
                        have completely controlled the cycle of transfer of your information to our servers, so we suggest you send only the information
                        that is requested and use recognized browsers that do not put the integrity of the contents at risk.</li>
                        <li className="mt-3">Rest assured that any of the personal data that you send us will only be used for the purpose of sending you
                        the useful information that characterizes our website and its electronic publications and in order to adapt or improve our products
                        and services.</li>
                    </ul>
                </div>
            </>
        }

        </div>
    )
}
