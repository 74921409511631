import React, { useContext } from 'react';
import { landingpageImages } from '../../helpers/callejeritosImages';
import { Carousel } from 'react-bootstrap';
import { UserContext } from '../../context/UserContext';

export const MainSlider = () => {

    const { languaje } = useContext(UserContext);
    
    return (
        <div className="container-fluid p-0">
            <Carousel indicators={ false } interval={null}>
                {/* <Carousel.Item className="slide-1 animate__animated animate__fadeIn" style={{ zIndex : -1 }}>
                    <div className="d-flex align-items-center justify-content-around main-slider">
                        <div className="slide-1text">
                            <img src={ landingpageImages(`./Banner01/AmorNutre.png`).default } className="img-fluid amorNutre" alt="Amor que nutre" />
                            { languaje === 'ES' ?
                                <>
                                    <h5 className="text-1 mt-md-5 mt-3">Reinvertimos y donamos el <b>100%</b></h5>
                                    <h5 className="text-1">de nuestras utilidades a la causa.</h5>
                                </>
                                :
                                <>
                                    <h5 className="text-1 mt-md-5 mt-3">We reinvest and donate <b>100%</b></h5>
                                    <h5 className="text-1">of our profits to the cause</h5>
                                </>
                            }
                            <img src={ landingpageImages(`./Banner01/Proximamente${ languaje }.png`).default } alt="Proximamente" className="img-fluid prox-btn mt-md-5 mt-2" />
                        </div>
                        <div className="stars-div">
                            <img src={ landingpageImages('./Banner01/Alimento.png').default } className="img-fluid alimento" alt="Alimento Callejeritos" />
                        </div>
                    </div>
                </Carousel.Item> */}
                <Carousel.Item className="slide-2 animate__animated animate__fadeIn" style={{ zIndex : -1 }}>
                    <img src={ landingpageImages(`./Banner02/Frase${ languaje }.png`).default } className="img-fluid frase2" alt="Callejerito" />
                </Carousel.Item>
                <Carousel.Item className="animate__animated animate__fadeIn" style={{ zIndex : -1 }}>
                    <div className="slide-3">
                        <div className="d-flex align-items-end">
                            <img src={ landingpageImages('./Banner03/dog.png').default } className="img-fluid dog3 mb-5" alt="Alimento Callejeritos" />
                        </div>
                        <div className="stars-div-2">
                            <img src={ landingpageImages(`./Banner03/frase${ languaje }.png`).default } className="img-fluid frase3" alt="Alimento Callejeritos" />
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}
