import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import Swal from 'sweetalert2';

import { AuthContext } from '../../context/AuthContext';
import { UserContext } from '../../context/UserContext';
import { IsLoading } from '../ui/IsLoading';

export const PaypalCheckoutSubscription = () => {

    const { user, actualizarUsuario } = useContext(AuthContext);
    const { languaje } = useContext(UserContext);
    const cliente_id = process.env.REACT_APP_PAYPAL_CLIENT_ID;

    const [planId, setPlanId] = useState('');
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!cliente_id) {
            Swal.fire({
            icon: 'error',
            title: 'Ocurrio un error en la configuración de Paypal!',
            text: 'Nuestro equipo está trabajando en la solución',
            });
        }
    }, [cliente_id]);

    const createSubscription = (data: any, actions: any) => {
        setLoading(true);
        return actions.subscription.create({
            plan_id: planId
        });
    };
        
    const onApprove = async (data: any, actions: any) => {

        if (user?.id) {
            const payload = {
                ...user,
                subscription_id: data.subscriptionID
            };
            
            await actualizarUsuario(payload);
        }
        resetStep();
        setLoading(false);

    };

    const onCancel = (data: any) => {
        console.log(data);
        setLoading(false);
    }
    
    const onError = (data: any) => {

        console.log(data);
        setLoading(false);
        Swal.fire({
            icon: 'error',
            title: 'Lo sentimos ha ocurrido un error!',
            text: 'Nuestro equipo está trabajando en la solución',
        });
    };

    const handleChange = (e: any) => {
        const target = e.target;
        if (target.checked) {
          setPlanId(target.value);
        }
    };

    const handleChangeStep = () => {
        if (planId !== '') {
            setStep(2);
        }
    };

    const resetStep = () => {
        setStep(1);
        setPlanId('');
        setLoading(false);
    };

    return (<div className="donaciones-formdiv">
        { user?.subscription_id
            ?
            <>
                <h5 className="text-danger">Se detecto que ya cuentas con una subscripción a un pago recurrente de Callejeritos. </h5>
                <h5 className="text-danger">Ingresa a <Link className="text-danger font-weight-bold" to="/mi-perfil">tú perfil </Link>
                para ver los detalles.</h5>
            </>
            :
            <div className="w-100">
                <h4>{ languaje === 'ES' ? 'Elige el plan de suscripción para tú donativo recurrente:' : 'Choose the subscription plan for your recurring donation:' }</h4>
                <div className="subscriptions_options">
                    <div className="form-check mr-3">
                        <input className="form-check-input" type="radio" name="plan100" id="plan100" value="P-1AC460380R0860632MGZHT5Y"
                            checked={ planId === 'P-1AC460380R0860632MGZHT5Y' } onChange={ handleChange } disabled={ step !== 1 }/>
                        <label className="form-check-label" htmlFor="plan100">
                            Donación $100.00 MXN
                        </label>
                    </div>
                    <div className="form-check mr-3">
                        <input className="form-check-input" type="radio" name="plan200" id="plan200" value="P-526807510W780231DMGZHUTA"
                            checked={ planId === 'P-526807510W780231DMGZHUTA' } onChange={ handleChange } disabled={ step !== 1 }/>
                        <label className="form-check-label" htmlFor="plan200">
                            Donación $250.00 MXN
                        </label>
                    </div>
                    <div className="form-check mr-3">
                        <input className="form-check-input" type="radio" name="plan500" id="plan500" value="P-8KL62082590283714MGZHV3A"
                            checked={ planId === 'P-8KL62082590283714MGZHV3A' } onChange={ handleChange } disabled={ step !== 1 }/>
                        <label className="form-check-label" htmlFor="plan500">
                            Donación $500.00 MXN
                        </label>
                    </div>
                </div>
                <div className="subscriptions_options">
                    <div className="form-check mr-3">
                        <input className="form-check-input" type="radio" name="plan1000" id="plan1000" value="P-9TB03942DN434233JMGZHWTA"
                            checked={ planId === 'P-9TB03942DN434233JMGZHWTA' } onChange={ handleChange } disabled={ step !== 1 }/>
                        <label className="form-check-label" htmlFor="plan1000">
                            Donación $1000.00 MXN
                        </label>
                    </div>
                    <div className="form-check mr-3">
                        <input className="form-check-input" type="radio" name="plan2500" id="plan2500" value="P-7XN73070R3627453MMGZHXGA"
                            checked={ planId === 'P-7XN73070R3627453MMGZHXGA' } onChange={ handleChange } disabled={ step !== 1 }/>
                        <label className="form-check-label" htmlFor="plan2500">
                            Donación $2500.00 MXN
                        </label>
                    </div>
                    <div className="form-check mr-3">
                        <input className="form-check-input" type="radio" name="plan5000" id="plan5000" value="P-7AH492419W450762AMGZHXYI"
                            checked={ planId === 'P-7AH492419W450762AMGZHXYI' } onChange={ handleChange } disabled={ step !== 1 }/>
                        <label className="form-check-label" htmlFor="plan5000">
                            Donación $5000.00 MXN
                        </label>
                    </div>
                </div>
                <div className="subscriptions_options">
                    <div className="form-check mr-3">
                        <input className="form-check-input" type="radio" name="plan10000" id="plan10000" value="P-26P58372L3898532FMGZHYQQ"
                            checked={ planId === 'P-26P58372L3898532FMGZHYQQ' } onChange={ handleChange } disabled={ step !== 1 }/>
                        <label className="form-check-label" htmlFor="plan10000">
                            Donación $10,000.00 MXN
                        </label>
                    </div>
                </div>
                { step === 1 &&
                    <button className="btn btn-callejeritos mb-4" disabled={ planId === '' } type="button" onClick={ handleChangeStep }>
                        { languaje === 'ES' ? 'Continuar' : 'Continue' }
                    </button>
                }

                {
                    step === 2 &&
                    <>
                        <PayPalScriptProvider options={{
                            "client-id": cliente_id || '',
                            currency: 'MXN',
                            locale: 'es_MX',
                            vault: true,
                            intent: 'subscription',
                            "disable-funding": 'credit,card'
                        }}
                        >
                            <PayPalButtons
                                style= {{
                                    shape: 'pill',
                                    color: 'blue',
                                    layout: 'vertical',
                                    label: 'paypal'
                                }}
                                createSubscription={(data: any, actions: any) => createSubscription(data, actions)}
                                onApprove={(data: any, actions: any) => onApprove(data, actions)}
                                onError={(data: any) => onError(data)}
                                onCancel={(data: any) => onCancel(data)}
                                />
                        </PayPalScriptProvider>
                        <div className="col-12 text-center">
                            {   !loading
                                ? (<button className="btn btn-callejeritos mt-4" type="button" onClick={ () => { setStep(1) } }>
                                    { languaje === 'ES' ? 'Editar monto' : 'Edit amount' }
                                </button>)
                                : <IsLoading/>
                            }
                        </div>
                    </>
                    
                }
            </div>
        }
    </div>)
}
