import React, { useContext, useEffect, useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { emailPattern, landingpageImages, ReCAPTCHAAPIKEY } from '../../helpers/callejeritosImages';
import { useForm, SubmitHandler } from 'react-hook-form';
import { UserContext } from '../../context/UserContext';
import { useFetch } from '../../hooks/useFetch';
// import { useKW2P } from '../../hooks/useKW2P';

export const BoletinInfo = () => {

    const { languaje } = useContext(UserContext);
    const { register, formState: { errors }, handleSubmit, setValue, watch, reset } = useForm<IFormInputs>();

    const [succes, setSucces] = useState(false);
    const [fail, setFail] = useState(false);
    const isMounted = useRef(true);
    // const { actualizarCatalogo } = useKW2P();

    useEffect(() => {
        register('captcha', { required : true });
    }, [ register ]);

    useEffect( () =>{
        return () => {
            isMounted.current = false;
        }
    }, []);

    const { beDocumentoCrear } = useFetch();

    const showCaptcha = Boolean(watch('email'));

    const onSubmit: SubmitHandler<IFormInputs> = (form) => {

        const body = {
            schema  : 'boletines',
            payload : {
                // encabezado : {
                    nombre    : form.nombre,
                    telefono  : form.telefono,
                    email : form.email,
                    lang : languaje.toLowerCase()
                // },
            },
        };
        reset();
        beDocumentoCrear('documentos:crear',body)
        .then(() => {
            setSucces(true);
            setTimeout(() => {
                setSucces(false);
            }, 5000);
        }).catch(() => {
            setFail(true);
            setTimeout(() => {
                setFail(false);
            }, 5000);
        });
    };

    const onChange = (value: any) => {
        setValue('captcha', value);
    };

    const disableCaptcha = !watch('captcha');

    return (
        <div className="div-boletin animate__animated animate__fadeIn">
            <div className="boletin-body">
                <div className="boletin_formbox">
                    <h1 className="mb-4">{ languaje === 'ES' ? 'Suscribete a nuestro boletín informativo' : 'Subscribe to our newsletter' }</h1>
                    {
                        succes &&
                        <div className="alert alert-callejeritos alert-dismissible fade show" role="alert">
                            {
                                languaje === 'ES' ?
                                <>
                                    <strong>¡Gracias por unirte a el boletín Callejeritos!</strong>
                                    <p className="mb-0">Nuestro equipo estará en contacto contigo con las noticias más recientes.</p>
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </>
                                :
                                <>
                                    <strong>Thank you for joining the Callejeritos newsletter!</strong>
                                    <p className="mb-0">Our team will be in contact with you with the latest news.</p>
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </>
                            }
                        </div>
                    }
                    {
                        fail &&
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            {
                                languaje === 'ES' ?
                                <>
                                    <p className="mb-0"><strong>Lo sentimos...</strong> ocurrió un error al contactar a callejeritos.</p>
                                    <p className="mb-0">Nuestro equipo está trabajando en para brindarte una solución lo antes posible.</p>
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </>
                                :
                                <>
                                    <p className="mb-0"><strong>Lo sentimos ...</strong> an error occurred when contacting callejeritos.</p>
                                    <p className="mb-0">Our team is working to provide you with a solution as soon as possible.</p>
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                </>
                            }
                        </div>
                    }
                    <form onSubmit={ handleSubmit(onSubmit) }>
                        <div className="row g-3 mb-3">
                            <div className="col-12 mb-2">
                                <input type="text" id="nombre" className="form-control" placeholder={ languaje === 'ES' ? 'Nombre' : 'Name' }
                                autoComplete="off"
                                { ...register('nombre', { required: languaje === 'ES' ? 'El nombre es requerido' : 'The name is required' }) }/>
                                { errors.nombre && <small className="text-danger">{ errors.nombre.message }</small> }
                            </div>
                            <div className="col-12 mb-2">
                                <input id="email" className="form-control" placeholder={ languaje === 'ES' ? 'Correo' : 'Email' } autoComplete="off"
                                { ...register('email', {
                                        required: languaje === 'ES' ? 'El correo es requerido': 'The email is required',
                                        pattern: { value : emailPattern, message: languaje === 'ES' ? 'Ingresa un correo válido': 'Enter a valid email' }
                                    })
                                }/>
                                { errors.email && <small className="text-danger">{ errors.email.message }</small> }
                            </div>
                            <div className="col-12 mb-2">
                                <input type="text" id="telefono" className="form-control" placeholder={ languaje === 'ES' ? 'Teléfono' : 'Phone' }
                                    autoComplete="off"
                                { ...register('telefono', { required: languaje === 'ES' ? 'El teléfono es requerido' : 'The phone is required' }) }/>
                                { errors.telefono && <small className="text-danger">{ errors.telefono.message }</small> }
                            </div>
                        </div>
                        { showCaptcha && <ReCAPTCHA sitekey={ ReCAPTCHAAPIKEY } onChange={onChange} hl={ languaje === 'ES' ? 'es-419' : 'en' }/> }
                        <button className="btn btn-callejeritos w-50" disabled={ disableCaptcha }>{ languaje === 'ES' ? 'Enviar' : 'Subscribe' }</button>
                    </form>
                </div>
                <div className="boletin_imgbox">
                    <img src={ landingpageImages('./Contacto/doglover.png').default } alt="doglover" className="img-fluid w-75" />
                </div>
            </div>
        </div>
    )
}

interface IFormInputs {
    nombre   : string;
    email    : string;
    telefono : string;
    captcha : string;
}