import React from 'react';
import ReactDOM from 'react-dom';
import { CallejeritosApp } from './CallejeritosApp';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'animate.css/animate.min.css';
import './assets/styles/styles.scss';
import Swal from 'sweetalert2/src/sweetalert2.js'


ReactDOM.render(
  <CallejeritosApp />,
  document.getElementById('root')
);
