import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { AvisoPrivacidad } from '../pages/Contacto/AvisoPrivacidad';
import { Boletin } from '../pages/Contacto/Boletin';
import { BoletinInfo } from '../pages/Contacto/BoletinInfo';
import { Informate } from '../pages/Contacto/Informate';
import { Programa } from '../pages/Contacto/Programa';
import { Unete } from '../pages/Contacto/Unete';
import { EnglishRoute } from './EnglishRoute';
import { SpanishRoute } from './SpanishRoute';

export const ContactoRouter = () => {

    const { url } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${ url }`}>
                <Redirect to={`/`} />
            </Route>   
            <SpanishRoute exact path={`${ url }/unete-al-proyecto`} component={ Unete } />
            <SpanishRoute exact path={`${ url }/informate`} component={ Informate } />
            <SpanishRoute exact path={`${ url }/programa-rumbo-a-casa`} component={ Programa } />
            <SpanishRoute exact path={`${ url }/aviso-privacidad`} component={ AvisoPrivacidad } />
            <SpanishRoute exact path={`${ url }/boletin-informativo`} component={ BoletinInfo } />
            <SpanishRoute exact path={`${ url }/boletin`} component={ Boletin } />

            <EnglishRoute exact path={`${ url }/join-the-proyect`} component={ Unete } />
            <EnglishRoute exact path={`${ url }/inform-yourself`} component={ Informate } />
            <EnglishRoute exact path={`${ url }/going-home-program`} component={ Programa } />
            <EnglishRoute exact path={`${ url }/privacy-notice`} component={ AvisoPrivacidad } />
            <EnglishRoute exact path={`${ url }/newsletter`} component={ BoletinInfo } />
            <EnglishRoute exact path={`${ url }/Bulletin`} component={ Boletin } />

            <Route path="*">
                <Redirect to="/" />
            </Route>
        </Switch>
    )
}
