import React, { useContext } from 'react';
import { recursosImages, equipoImages } from '../../helpers/callejeritosImages';
import { UserContext } from '../../context/UserContext';
import { DownloadButton } from '../../components/DownloadButton';

export const Recursos = () => {

    const { languaje } = useContext(UserContext);

    return (
        <div className="div-recursos animate__animated animate__fadeIn">
            {
                languaje === 'ES' ?
                <>
                    <div className="header-recursos">
                        <img src={ recursosImages('./title.png').default } alt="RECURSOS" className="img-fluid title-recursos"/>
                    </div>
                    <div className="body-recursos animate__animated animate__fadeInUp">
                        <p className="title-gray">Estimado usuario</p>
                        <p className="text">Los siguientes contratos están disponible para su descarga y acondicionamiento en cuanto a
                        montos, fechas y nombres. Así mismo son de dominio público para ser utilizados para los fines apropiados y de manera
                        responsable y correcta.</p>
                        <br />
                        <p className="text font-weight-bold">Agradecemos a nuestro patrocionador 
                        <img src={ equipoImages('./Patrocinadores/singular.png').default } alt="Singular law group" height={ 30 } className="mx-2"/>
                        quien apoyó en la elaboración, revisión y aprobación de cada contrato.</p>
                    </div>
                </>
                :
                <>
                    <div className="header-recursos">
                        <img src={ recursosImages('./titleEN.png').default } alt="RECURSOS" className="img-fluid title-recursos"/>
                    </div>
                    <div className="body-recursos animate__animated animate__fadeInUp"> 
                        <p className="title-gray">Dear user</p>
                        <p className="text">The following contracts are available for download and adjusting in terms of amounts, dates and names.
                        Likewise, they are in the public domain to be used for the appropriate purposes, in a responsible and correct way.</p>
                        <br />
                        <p className="text font-weight-bold">We thank our sponsor 
                        <img src={ equipoImages('./Patrocinadores/singular.png').default } alt="Singular law group" height={ 30 } className="mx-2"/>
                        who supported in the preparation, review and approval of each contract.</p>
                    </div>
                </>
            }
            <div className="recusos-files">
                { languaje === 'ES'
                    ? <>
                        {/* <DownloadButton file="contrato_adopcion.pdf" text="Descargar contrato de adopcion"/> */}
                        <DownloadButton file="contrato_adopcion.docx" text="Descargar contrato de adopcion"/>
                        <DownloadButton file="contrato_esterilizacion.docx" text="Descargar contrato de esterilización"/>
                    </>
                    : <>
                        {/* <DownloadButton file="contrato_adopcion.pdf" text="Download Adoption Contract"/> */}
                        <DownloadButton file="contrato_adopcion.docx" text="Download Adoption Contract"/>
                        <DownloadButton file="contrato_esterilizacion.docx" text="Download Sterilization Contract"/>
                    </>
                }
            </div>
            { languaje === 'ES'
                    ? <>   
                        <div className="body-recursos-b animate__animated animate__fadeInUp">
                            <p className="text">La información que aquí proporcionamos es una compilación de varias fuentes tanto nacionales como internacionales,
                            por dependencias públicas, centros de estadística, iniciativa privada, organizaciones, investigadores y universidades los cuales hablan
                            de la problemática, retos, datos, cifras y estadísticas relacionadas con el tema del abandono, maltrato, malas prácticas, organizaciones
                            apócrifas, así como nuestra experiencia del día a día de lo que se vive en las calles, nuestra experiencia en campo y realidad que se vive
                            con el tema de mascotas domésticas.</p>
                            <p className="text">Así mismo compartimos información útil sobre</p>
                        </div>
                        <div className="recusos-files">
                            <DownloadButton file="datos_cifras_estadísticas.pdf" text="Datos, Cifras y Estadísticas"/>
                            <DownloadButton file="importancia_adopciones_responsables_beneficios.pdf" text="Importancia adopciones responsables y beneficios"/>
                        </div>
                    </>
                    :
                    <></>
            }
        </div>
    )
}
