import React, { useState, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useHistory } from 'react-router';

import { callejeritosImages, headerImages, videosModal } from '../../helpers/callejeritosImages';
import { LangSelect } from './LangSelect';
import { UserContext } from '../../context/UserContext';
import PortalModal from './PortalModal';

export const NavBar = () => {

    const { languaje } = useContext(UserContext);

    const [showVideo, setShowVideo] = useState(false);
    const showVideoModal = ()=> setShowVideo(!showVideo);
    const hideVideoModal = () => setShowVideo(false);

    const history = useHistory();
    const handleRedirectNosotros = () => languaje === 'ES' ? history.replace('./nosotros'):history.replace('./us');
    const handleRedirectRecursos = () => languaje === 'ES' ? history.replace('./recursos'):history.replace('./resources');

    const nosotrosTitle = (<i onClick={ handleRedirectNosotros }>{ languaje === 'ES' ? 'Nosotros' : 'Us' }</i>); 
    const [showNosotros, setShowNosotros] = useState(false);
    const showDropdownNosotros = ()=> setShowNosotros(!showNosotros);
    const hideDropdownNosotros = () => setShowNosotros(false);

    const mascotasTitle = (<i>{ languaje === 'ES' ? 'Mascotas' : 'Pets' }</i>);
    const [showMascotas, setShowMascotas] = useState(false);
    const showDropdownMascotas = ()=> setShowMascotas(s=>!s);
    const hideDropdownMascotas = () => setShowMascotas(false);

    const eventosTitle = (<i>{ languaje === 'ES' ? 'Eventos' : 'Events' }</i>);
    const [showEventos, setShowEventos] = useState(false);
    const showDropdownEventos = ()=> setShowEventos(!showEventos);
    const hideDropdownEventos = () => setShowEventos(false);

    const productosTitle = (<i>{ languaje === 'ES' ? 'Productos' : 'Products' }</i>);
    const [showProductos, setShowProductos] = useState(false);
    const showDropdownProductos = ()=> setShowProductos(!showProductos);
    const hideDropdownProductos = () => setShowProductos(false);

    const equipoTitle = (<i>{ languaje === 'ES' ? 'Equipo' : 'Team' }</i>);
    const [showEquipo, setShowEquipo] = useState(false);
    const showDropdownEquipo = ()=> setShowEquipo(!showEquipo);
    const hideDropdownEquipo = () => setShowEquipo(false);

    const recursosTitle = (<i onClick={ handleRedirectRecursos } >{ languaje === 'ES' ? 'Recursos' : 'Resources' }</i>); 
    const [showRecursos, setShowRecursos] = useState(false);
    const showDropdownRecursos = ()=> setShowRecursos(!showRecursos);
    const hideDropdownRecursos = () => setShowRecursos(false);

    const contactoTitle = (<i>{ languaje === 'ES' ? 'Contacto' : 'Contact' }</i>);
    const [showContacto, setShowContacto] = useState(false);
    const showDropdownContacto = ()=> setShowContacto(!showContacto);
    const hideDropdownContacto = () => setShowContacto(false);

    const [navExpanded, setNavExpanded] = useState(false);
    const handleExpand = () => setNavExpanded(!navExpanded);
    const handleExpandOff = () => setNavExpanded(false);

    return (
        <>
            <Navbar id="navbar" expand="lg" variant="light" sticky="top" expanded={ navExpanded }>
                <Navbar.Toggle className="mr-auto ml-1" aria-controls="responsive-navbar-nav" onClick={ handleExpand } />
                <Navbar.Brand className="mr-auto ml-lg-1">
                    <Link to={ languaje === 'ES' ? '/':'/home' } onClick={ handleExpandOff }>
                        <img src={ callejeritosImages('./logotipo.png').default } alt="Callejeritos" className="logo"/>
                    </Link>
                </Navbar.Brand>
                <div className="d-flex align-items-center mr-2 lang__boxleft">
                    <LangSelect handleExpandOff={ handleExpandOff } />
                </div>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav>
                        <Nav.Link as={ NavLink } activeClassName="active" exact to={ languaje === 'ES' ? '/':'/home' } onClick={ handleExpandOff }>
                            <i>{ languaje === 'ES' ? 'Inicio' : 'Home' }</i>
                        </Nav.Link>
                        <NavDropdown title={ nosotrosTitle } id="collasible-nav-dropdown" 
                            className={history.location.pathname === '/nosotros' ? 'show' : ''}
                            show={showNosotros} onMouseEnter={ showDropdownNosotros } onMouseLeave={hideDropdownNosotros}>
                            <NavDropdown.Item as={ NavLink } activeClassName="active" exact to={ languaje === 'ES' ? '/nosotros':'/us' }
                            onClick={ handleExpandOff }>
                                <i>{ languaje === 'ES' ? 'Nosotros' : 'Us' }</i>
                            </NavDropdown.Item>
                            <NavDropdown.Item as={ NavLink } activeClassName="active" exact to={ languaje === 'ES' ? '/nosotros/historia':'/us/history' }
                            onClick={ handleExpandOff }>
                                <i>{ languaje === 'ES' ? 'Historia' : 'History' }</i>
                            </NavDropdown.Item>
                            <NavDropdown.Item as={ NavLink } activeClassName="active" exact to={ languaje === 'ES' ? '/nosotros/manifiesto':'/us/manifesto' }
                                onClick={ handleExpandOff }>
                                <i>{ languaje === 'ES' ? 'Manifiesto' : 'Manifesto' }</i>
                            </NavDropdown.Item>
                            <NavDropdown.Item as={ NavLink } activeClassName="active" exact to={ languaje === 'ES' ? '/nosotros/causa-social':'/us/social-cause' }
                                onClick={ handleExpandOff }>
                                <i>{ languaje === 'ES' ? 'Causa social' : 'Social cause' }</i>
                            </NavDropdown.Item>
                            <NavDropdown.Item as={ NavLink } activeClassName="active" exact to={ languaje === 'ES' ? '/nosotros/albergues':'/us/shelters' }
                                onClick={ handleExpandOff }>
                                <i>{ languaje === 'ES' ? 'Albergues' : 'Shelters' }</i>
                            </NavDropdown.Item>
                        </NavDropdown>

                        <Nav.Link as={ NavLink } activeClassName="active" exact to={ languaje === 'ES' ? '/donaciones':'/donations' } onClick={ handleExpandOff }>
                            <i>{ languaje === 'ES' ? 'Donaciones' : 'Donations' }</i>
                        </Nav.Link>
                        
                        <NavDropdown title={ mascotasTitle } id="collasible-nav-dropdown" show={ showMascotas }
                            onMouseEnter={ showDropdownMascotas } onMouseLeave={ hideDropdownMascotas }>
                                
                            
                            
                            <NavDropdown.Item onClick={ ()=>{handleExpandOff();showVideoModal() }}>
                                <i>{ languaje === 'ES' ? 'Cómo registrar mascotas' : 'How to register pets' }</i>
                            </NavDropdown.Item>
                            <NavDropdown.Item as={ NavLink } exact to={languaje === 'ES' ? '/mascotas/adopcion':'/pets/adoption'} onClick={ handleExpandOff }>
                                <i>{ languaje === 'ES' ? 'Mascotas en Adopción' : 'Pets for adoption' }</i>
                            </NavDropdown.Item>
                            <NavDropdown.Item as={ NavLink } exact to={languaje === 'ES' ? '/mascotas/rehabilitacion':'/pets/rehabilitation'} onClick={ handleExpandOff }>
                                <i>{ languaje === 'ES' ? 'Mascotas en Rehabilitación' : 'Pets in rehabilitation' }</i>
                            </NavDropdown.Item>
                        </NavDropdown>

                        {/* <NavDropdown title={ eventosTitle } id="collasible-nav-dropdown" show={ showEventos }
                            onMouseEnter={ showDropdownEventos } onMouseLeave={ hideDropdownEventos }>
                            <NavDropdown.Item as={ NavLink } exact to={languaje === 'ES' ? '/eventos/adopcion':'/events/adoption'} onClick={ handleExpandOff }>
                                <i>{ languaje === 'ES' ? 'Eventos de adopción' : 'Adoption Events' }</i>
                            </NavDropdown.Item>
                        </NavDropdown> */}

                        <NavDropdown title={ productosTitle } id="collasible-nav-dropdown" show={ showProductos } 
                            onMouseEnter={ showDropdownProductos } onMouseLeave={ hideDropdownProductos }>
                            <NavDropdown.Item as={ NavLink } activeClassName="active" exact to={languaje === 'ES' ? '/productos/alimento':'/products/food'}
                                onClick={ handleExpandOff }>
                                <i>{ languaje === 'ES' ? 'Alimento' : 'Food' }</i>
                            </NavDropdown.Item>
                            <NavDropdown.Item as={ NavLink } activeClassName="active" exact to={languaje === 'ES' ? '/productos/juguetes':'/products/toys'}
                                onClick={ handleExpandOff }>
                                <i>{ languaje === 'ES' ? 'Juguetes' : 'Toys' }</i>
                            </NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title={ equipoTitle } id="collasible-nav-dropdown" show={ showEquipo } 
                            onMouseEnter={ showDropdownEquipo } onMouseLeave={ hideDropdownEquipo }>
                            <NavDropdown.Item as={ NavLink } activeClassName="active" exact to={languaje === 'ES' ? '/equipo/patrocinadores':'/team/sponsors'}
                                onClick={ handleExpandOff }>
                                <i>{ languaje === 'ES' ? 'Patrocinadores' : 'Sponsors' }</i>
                            </NavDropdown.Item>
                            <NavDropdown.Item as={ NavLink } activeClassName="active" exact to={languaje === 'ES' ? '/equipo/alianzas-estrategicas':'/team/strategic-alliances'}
                                onClick={ handleExpandOff }>
                                <i>{ languaje === 'ES' ? 'Alianzas estratégicas' : 'Strategic Alliances' }</i>
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title={ recursosTitle } id="collasible-nav-dropdown" show={ showRecursos } onMouseEnter={ showDropdownRecursos } onMouseLeave={ hideDropdownRecursos }>
                            <NavDropdown.Item as={ Link } to={languaje === 'ES' ? '/recursos':'/resources'} onClick={ handleExpandOff }>
                                <i>{ languaje === 'ES' ? 'Contrato de adopción' : 'Adoption contract' }</i>
                            </NavDropdown.Item>
                            <NavDropdown.Item as={ Link } to={languaje === 'ES' ? '/recursos':'/resources'} onClick={ handleExpandOff }>
                                <i>{ languaje === 'ES' ? 'Contrato de esterilización' : 'Sterilization Contract' }</i>
                            </NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title={ contactoTitle } id="collasible-nav-dropdown" show={ showContacto }
                            onMouseEnter={ showDropdownContacto } onMouseLeave={ hideDropdownContacto }>
                            <NavDropdown.Item as={ NavLink } activeClassName="active" exact to={languaje === 'ES' ? '/contacto/unete-al-proyecto':'/contact/join-the-proyect'}
                                onClick={ handleExpandOff }>
                                <i>{ languaje === 'ES' ? 'Únete al proyecto' : 'Join the project' }</i>
                            </NavDropdown.Item>
                            <NavDropdown.Item as={ NavLink } activeClassName="active" exact to={languaje === 'ES' ? '/contacto/informate':'/contact/inform-yourself'}
                                onClick={ handleExpandOff }>
                                <i>{ languaje === 'ES' ? 'Infórmate' : 'Inform yourself' }</i>
                            </NavDropdown.Item>
                            <NavDropdown.Item as={ NavLink } activeClassName="active" exact to={languaje === 'ES' ? '/contacto/programa-rumbo-a-casa':'/contact/going-home-program'}
                                onClick={ handleExpandOff }>
                            { languaje === 'ES' ? <i>Programa <small>RUMBO A CASA</small></i> : <i><small>GOING HOME</small> Program</i> }
                            </NavDropdown.Item>
                            <NavDropdown.Item as={ NavLink } activeClassName="active" exact to={languaje === 'ES' ? '/contacto/aviso-privacidad':'/contact/privacy-notice'}
                                onClick={ handleExpandOff }>
                                <i>{ languaje === 'ES' ? 'Aviso de privacidad' : 'Privacy notice' }</i>
                            </NavDropdown.Item>
                            <NavDropdown.Item as={ NavLink } activeClassName="active" exact to={languaje === 'ES' ? '/contacto/boletin-informativo':'/contact/newsletter'}
                                onClick={ handleExpandOff }>
                                <i>{ languaje === 'ES' ? 'Boletín informativo' : 'Newsletter' }</i>
                            </NavDropdown.Item>
                        </NavDropdown>
                        
                        <Nav.Link className='d-block' as={ NavLink } activeClassName="active" exact to={languaje === 'ES' ? '/preguntas-frecuentes':'/faqs'} onClick={ handleExpandOff }>
                            { languaje === 'ES' ? <i className="faqs_title">Preguntas Frecuentes</i> : <i>FAQS</i> }
                        </Nav.Link>
                    </Nav>
                    <Nav className="m-auto">
                        <div className="d-flex align-items-center mr-2 lang__boxright">
                            <LangSelect handleExpandOff={ handleExpandOff }/>
                        </div>
                        <div>
                            <img src={headerImages(`./${languaje}/02C.png`).default} alt="Callejeritos"
                                className="adopt-img animate__animated animate__infinite animate__slower animate__tada" />
                        </div>
                        <div>
                            <img src={headerImages(`./${languaje}/03.png`).default} alt="Callejeritos"
                                className="hecho-img animate__animated animate__infinite animate__slower animate__heartBeat" />
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <PortalModal show={showVideo} onClose={hideVideoModal}>
                <video width="100%" src={videosModal('./video.mp4').default} controls autoPlay className="video" />
            </PortalModal>
        </>
        
    )
}
