import React, { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import ScrollToTop from '../components/ScrollToTop';
import { Loader } from '../components/ui/Loader';
import { NavBar } from '../components/ui/NavBar';
import { Donaciones } from '../pages/Donaciones/Donaciones';

import { TerminosCondiciones } from '../pages/Contacto/TerminosCondiciones';
import { Recursos } from '../pages/Recursos/Recursos';
import { Faqs } from '../pages/Faqs/Faqs';
import { Login } from '../pages/Auth/Login';
import { Register } from '../pages/Auth/Register';
import { RecoveryChange } from '../pages/Auth/RecoveryChange';
import { Recovery } from '../pages/Auth/Recovery';
import { CambiarContrasena } from '../pages/Usuario/CambiarContrasena';
import { HomeApp } from '../containers/HomeApp';
import { Footer } from '../components/ui/Footer';
import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';
import { NosotrosRouter } from './NosotrosRouter';
import { ProductosRouter } from './ProductosRouter';
import { EquipoRouter } from './EquipoRouter';
import { ContactoRouter } from './ContactoRouter';
import { AuthContext } from '../context/AuthContext';
import { MiPerfil } from '../pages/Usuario/MiPerfil';
import { MisDonaciones } from '../pages/Donaciones/MisDonaciones';
import { FloatingMenu } from '../components/ui/FloatingMenu';
import { MascotasRouter } from './MascotasRouter';
import { ExpedienteAzulRouter } from './ExpedienteAzulRouter';
import { EnglishRoute } from './EnglishRoute';
import { SpanishRoute } from './SpanishRoute';
import FloatingWhatsapp from '../components/ui/FloatingWhatsapp';
import { EventosRouter } from './EventosRouter';

export const AppRouter = () => {

    const { status } = useContext(AuthContext);

    if (status === 'checking') {
        return (<Loader/>)
    }

    return (
        <Router>
            <NavBar/>
            <ScrollToTop />
            <div>
                <Switch>
                    <Route path="/nosotros" component={ NosotrosRouter } />
                    <Route path="/us" component={ NosotrosRouter } />
                    
                    <SpanishRoute exact path="/donaciones" component={ Donaciones } />
                    <EnglishRoute exact path='/donations' component={Donaciones} />

                    <Route path="/productos" component={ ProductosRouter }/>
                    <Route path="/products" component={ ProductosRouter }/>

                    <Route path="/equipo" component={ EquipoRouter }/>
                    <Route path="/team" component={ EquipoRouter }/>

                    <SpanishRoute exact path="/recursos" component={ Recursos }/>
                    <EnglishRoute exact path="/resources" component={ Recursos }/>

                    <Route path="/contacto" component={ ContactoRouter }/>
                    <Route path="/contact" component={ ContactoRouter }/>

                    <Route exact path="/terminos-condiciones" component={ TerminosCondiciones }/>
                    <SpanishRoute exact path="/preguntas-frecuentes" component={ Faqs }/>
                    <EnglishRoute exact path="/faqs" component={ Faqs }/>
                    
                    <PublicRoute exact path="/login" component={ Login } />
                    <PublicRoute exact path="/registro" component={ Register } />
                    <PublicRoute exact path="/recovery" component={ Recovery } />
                    
                    
                    <PrivateRoute exact path="/cambiar-contrasena" component={ CambiarContrasena } />
                    <Route exact path="/recovery-change/:correo/:token" component={ RecoveryChange }/>

                    <PrivateRoute exact path="/mi-perfil" component={ MiPerfil } />
                    <PrivateRoute exact path="/mis-donativos" component={MisDonaciones} />
                    
                    <Route path="/mascotas" component={ MascotasRouter } />
                    <Route path="/pets" component={ MascotasRouter } />

                    <Route path="/eventos" component={ EventosRouter } />
                    <Route path="/events" component={ EventosRouter } />

                    <Route path="/expediente-azul" component={ExpedienteAzulRouter} />

                    <EnglishRoute exact path="/home" component={ HomeApp } />
                    <SpanishRoute exact path="/" component={ HomeApp } />
                    
                    <Route path="*">
                        <Redirect to="/" />
                    </Route>
                </Switch>
                <Footer/>
            </div>
            <FloatingMenu/>
            <FloatingWhatsapp />
        </Router>
    )
}
