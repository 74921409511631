import { useKeys } from './hooks/useKeys';
import { AppRouter } from './routers/AppRouter';

import { UserContext } from './context/UserContext';
import { AuthProvider } from './context/AuthContext';

interface AppStateProps {
    children: JSX.Element | JSX.Element[]
}

export const CallejeritosApp = () => {

    const { facebookLink, instagramLink, languaje, setLanguaje } = useKeys();

    const AppState = ({ children }: AppStateProps) => {
        return(
            <AuthProvider>
                { children }
            </AuthProvider>
        );
    };

    return (
        <AppState>
            <UserContext.Provider value={{
                facebookLink,
                instagramLink,
                languaje,
                setLanguaje
            }}>
                <AppRouter/>
            </UserContext.Provider>
        </AppState>
    )
}
