import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { DonacionForm } from './';
import { useGetProductosCallejeritos } from '../../hooks';
import { Loader } from '../ui/Loader';

export const DonacionOtros = () => {

    const { languaje } = useContext(UserContext);
    const { productos, gettingProducts } = useGetProductosCallejeritos('donacion');

    if (gettingProducts) return <Loader />;

    return (


        <div className="body-donacionali-form pb-4 animate__animated animate__fadeIn text-center">
            {
                languaje === 'ES'
                    ? <>
                        <h3>Donación otros conceptos</h3>
                        <p className="text font-weight-bold">Selecciona el concepto con el que te gustaria apoyar a las mascotas y la cantidad que deseas aportar.</p>
                    </>
                    : <>
                        <h3>Donation other concepts</h3>
                        <p className="text font-weight-bold">Select the concept with which you would like to support pets and the amount you wish to contribute.</p>
                    </>

            }
            <div className='d-flex justify-content-center'>
                <DonacionForm tipo='otros' productos={productos} />
            </div>

        </div>
    )
}
