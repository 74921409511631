import React, { useContext } from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import { MascotasAdopcion } from '../pages/Mascotas/Adopcion/MascotasAdopcion';
import { MascotasRehabilitacion } from '../pages/Mascotas/Rehabilitacion/MascotasRehabilitacion';
import { PrivateRoute } from './PrivateRoute';
import { RegistrarAdopcion } from '../pages/Mascotas/Adopcion/RegistrarAdopcion';
import { RegistrarRehabilitacion } from '../pages/Mascotas/Rehabilitacion/RegistrarRehabilitacion';
import { MascotaDetalle } from '../components/Mascotas/MascotaDetalle';
import { MisAdopciones } from '../pages/Mascotas/Adopcion/MisAdopciones';
import { MisRehabilitaciones } from '../pages/Mascotas/Rehabilitacion/MisRehabilitaciones';
import { EditarAdopcion } from '../pages/Mascotas/Adopcion/EditarAdopcion';
import { EditarRehabilitacion } from '../pages/Mascotas/Rehabilitacion/EditarRehabilitacion';
import { SpanishRoute } from './SpanishRoute';
import { EnglishRoute } from './EnglishRoute';
import { MascotasAdotadas } from '../pages/Mascotas/Adotadas/MascotasAdotadas';
import { UserContext } from '../context';
import { EventosAdopcion } from '../pages/Eventos/Adopcion/EventosAdopcion';

export const EventosRouter = () => {

    const { url } = useRouteMatch();
    const { languaje } = useContext(UserContext);

    return (
        <Switch>
            <Route exact path={`${ url }`}>
                <Redirect to={`${ url }/${ url === 'ES'?'adopcion':'adoption'}`} />
            </Route>
            
            <SpanishRoute exact path={`/eventos/adopcion`} component={MascotasAdopcion} />
            <EnglishRoute exact path={`/events/adoption`} component={MascotasAdopcion} />

            <PrivateRoute exact path={`${url}/adopcion/registro`} component={EventosAdopcion} />
            
            
            
            {/* <PrivateRoute exact path={`${url}/adopcion/registrar`} component={RegistrarAdopcion} />
            <PrivateRoute exact path={`${url}/adopcion/editar/:id`} component={EditarAdopcion} />
            
            <Route exact path={`${url}/:scope/:id`} component={MascotaDetalle} />
            
            <SpanishRoute exact path={`/mascotas/rehabilitacion`} component={MascotasRehabilitacion} />
            <EnglishRoute exact path={`/pets/rehabilitation`} component={MascotasRehabilitacion} />

            <PrivateRoute exact path={`${url}/registrar`} component={RegistrarRehabilitacion} />
            <PrivateRoute exact path={`${url}/rehabilitacion/editar/:id`} component={EditarRehabilitacion} />

            <PrivateRoute exact path={`${url}/adoptadas`} component={MascotasAdotadas} />

            <PrivateRoute exact path={`${url}/mis-adopciones`} component={MisAdopciones} />
            <PrivateRoute exact path={`${url}/mis-rehabilitaciones`} component={MisRehabilitaciones} /> */}

        </Switch>
    )
}
