import React, { useContext, useRef } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import Swal from 'sweetalert2';

import { IsLoading } from '../../components/ui/IsLoading';
import { UserContext } from '../../context/UserContext';
import { AuthContext } from '../../context/AuthContext';
import { callejeritosImages } from '../../helpers/callejeritosImages'
import kw2pApi from '../../api/kw2pApi';
import Logotipo from '../../logotipo.svg';

export const CambiarContrasena = () => {

    const { user, isLoading, setLoading } = useContext(AuthContext);
    const { languaje } = useContext(UserContext);

    const { formState: { errors }, handleSubmit, register, watch, reset } = useForm<IFormInputs>();
    const match_msj = languaje === 'ES' ? 'Las contraseñas no coinciden' : 'The passwords do not match';

    const contrasena = useRef({});

    contrasena.current = watch('contrasena', '');

    const onSubmit: SubmitHandler<IFormInputs> = async ({ contrasena_anterior, contrasena }) => {

        try {
            
            setLoading(true);

            const payload = {
                usuario_id: user?.id,
                contrasena_anterior,
                contrasena_nueva: contrasena
            };

            await kw2pApi.post<any>('/usuarios:modificar_contrasena', payload);

            setLoading(false);

            Swal.fire({
                title: 'Contraseña actualizada con éxito',
                text: 'Será necesaria la proxima vez que inicies sesión!',
                imageUrl: Logotipo,
                imageHeight: 70, 
                imageWidth: 325
            });
            reset();
        } catch (err: any) {
            
            setLoading(false);

            Swal.fire({
                icon: 'error',
                title: 'Lo sentimos ocurrio un error...',
                text: JSON.stringify(err.response.data.message, null, 2).replace(/[{}]/g, '')
            });
        }
    };

    return (
        <div className="div-cambiarcontra animate__animated animate__fadeIn">
           <div className="row animated fadeIn m-0">
                <div className="col-12 col-md-5 user-inf">
                    <div className="card card-profile h-100">
                        <div className="card-body p-0">
                            <div className="profile-header"></div>
                            <div className="row user-detail">
                                <div className="col-lg-12 col-sm-12 col-12 ">
                                    <div className="d-flex justify-content-center">
                                        { user?.avatar ? 
                                            <img src={ user.avatar } alt={ user.nombre } 
                                             className="rounded-circle img-user img-responsive p-1" width={80} height={80} />
                                            :
                                            <img src={ callejeritosImages('./user.png').default } alt="Callejeritos" 
                                             className="rounded-circle img-user img-responsive" width={80} height={80} />
                                        }
                                       
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <p className="user-mail">{ user?.correo }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-7">
                    <div className="card card-profile h-100">
                        <div className="card-body p-2">
                            <h5 className="card-title cambiarcontra_title">{ languaje === 'ES' ? 'Cambiar contraseña' : 'Change password' }</h5>
                            <form onSubmit={ handleSubmit(onSubmit) }>
                                <div className="row g-3">
                                    <div className="col-12">
                                        <label htmlFor="contrasena_anterior">{ languaje === 'ES' ? 'Contraseña actual:' : 'Current password:' }</label>
                                        <input id="contrasena_anterior" className="form-control"
                                            placeholder={ languaje === 'ES' ? 'Ingresa tú contraseña actual' : 'Enter your current password' } autoComplete="off"
                                            { ...register('contrasena_anterior', { required: languaje === 'ES' ? 'La contraseña actual es requerida': 'Current password is required' }) }
                                            type="password"/>
                                        { errors.contrasena_anterior && <small className="text-danger">{ errors.contrasena_anterior.message }</small> }
                                    </div>
                                    <div className="col-12 mt-2">
                                        <label htmlFor="contrasena">{ languaje === 'ES' ? 'Contraseña nueva:' : 'New password:' }</label>
                                        <input id="contrasena" className="form-control" placeholder={ languaje === 'ES' ? 'Crea tú contraseña nueva' : 'Enter your new password' } autoComplete="off"
                                            { ...register('contrasena', { required: languaje === 'ES' ? 'La contraseña es requerida': 'The new password is required' }) } type="password"/>
                                        { errors.contrasena && <small className="text-danger">{ errors.contrasena.message }</small> }
                                    </div>
                                    <div className="col-12 mt-2">
                                        <label htmlFor="contrasena_repeat">{ languaje === 'ES' ? 'Confirmar contraseña:' : 'Confirm password:' }</label>
                                        <input id="contrasena_repeat" className="form-control" placeholder={ languaje === 'ES' ? 'Confirma tú contraseña nueva' : 'Confirm your new password' } autoComplete="off"
                                            { ...register('contrasena_repeat', { 
                                                required: languaje === 'ES' ? 'Confirma tú contraseña': 'The confirm your password',
                                                validate: value => value === contrasena.current || match_msj
                                            }) } type="password"/>
                                        { errors.contrasena_repeat && <small className="text-danger">{ errors.contrasena_repeat.message }</small> }
                                    </div>
                                </div>
                                <div className="d-flex flex-column mt-2">
                                    { isLoading 
                                        ? <IsLoading />
                                        : <button className="btn login_btn btn-block" type="submit">{ languaje === 'ES' ? 'Guardar' : 'Save' }</button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface IFormInputs {
    contrasena_anterior: string;
    contrasena: string;
    contrasena_repeat: string;
}
