import { useEffect } from 'react';
import { withRouter, useLocation } from "react-router-dom";

const ScrollToTop = () => {

    const { pathname } = useLocation();
    
    useEffect(() => {
        // document.querySelector('#root')
        window?.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }, [pathname]);

      return null;
    
    
}

export default withRouter(ScrollToTop);