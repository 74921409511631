import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { createPortal } from "react-dom";
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface PortalModalProps {
  children: JSX.Element | JSX.Element[] | string | null;
  show: boolean;
  onClose: () => void;
}

function PortalModal({ show, onClose, children }: PortalModalProps) {
  if (!show) return null;
  return (
    <>
      {createPortal(<Modal onClose={onClose}>{children}</Modal>, document.body)}
    </>
  );
}

interface ModalProps {
  children: JSX.Element | JSX.Element[] | string | null;
  onClose: () => void;
}

function Modal({ onClose, children }:ModalProps) {
  return (
    <div className="portal">
      
        <div className="portal-content">
          <button className="portal-close" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes as IconProp} />
          </button>
          {children}
        </div>
      
    </div>
  );
}

PortalModal.propTypes = {};

export default PortalModal;
