import { useContext, useEffect } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import Swal from 'sweetalert2';

import kw2pApiPublic from '../../api/kw2pPublic';

import { AuthContext } from '../../context/AuthContext';
import Logotipo from '../../logotipo.svg';
import { UserContext } from '../../context/UserContext';

interface Props {
  tipo: string;
  cantidad: number;
  impuestos: boolean;
  anonima: boolean;
  producto_id?: number;
  resetStep: () => void;
  setLoad: (stat: boolean) => void;
};

export const PaypalCheckout = ({ tipo, cantidad, impuestos, anonima, resetStep, setLoad, producto_id }: Props) => {


  const { user } = useContext(AuthContext);
  const { languaje } = useContext(UserContext);

  const cliente_id = process.env.REACT_APP_PAYPAL_CLIENT_ID;

  useEffect(() => {
    if (!cliente_id) {
      Swal.fire({
        icon: 'error',
        title: 'Ocurrio un error en la configuración de Paypal!',
        text: 'Nuestro equipo está trabajando en la solución',
      });
    }
  }, [cliente_id]);

  const createOrder = (data: any, actions: any) => {
    setLoad(true);

    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: Number(cantidad),
            currency: 'MXN',
            breakdown: {
              item_total: {
                currency_code: 'MXN',
                value: cantidad
              }
            }
          },
          description: tipo,
          custom_id: `${anonima},${impuestos}`,
          payee: {
            email_address: user?.correo
          },
          items: [
            {
              name: `Donación ${tipo}`,
              unit_amount: {
                currency_code: 'MXN',
                value: cantidad
              },
              quantity: 1
            }
          ]
        },
      ],
      payer: {
        email_address: user?.correo,
        name: {
          given_name: user?.nombre
        }
      },
      application_context: {
        brand_name: 'Callejeritos.mx',
        locale: 'es-MX'
      }
    });
  };

  const onCancel = (data: any) => {
    setLoad(false);
  }

  const onError = (data: any) => {

    console.log(data);
    setLoad(false);

    Swal.fire({
      icon: 'error',
      title: 'Lo sentimos ha ocurrido un error!',
      text: 'Nuestro equipo está trabajando en la solución',
    });
  };

  const createPaypalPayment = async (order_id: string) => {

    const body = {
      order_id,
      monto: Number(cantidad),
      email: user?.correo,
      impuestos,
      anonima,
      tipo,
      producto_id
    };

    try {
      await kw2pApiPublic.post('/donaciones:crear_paypal_payment', body);

      Swal.fire({
        title: '¡Gracias por tu apoyo a la causa!',
        text: 'Nuestro equipo estará en contacto contigo lo antes posible',
        imageUrl: Logotipo,
        imageHeight: 70, 
        imageWidth: 325
      });
      resetStep();
      setLoad(false);

    } catch (error: any) {
      console.log(error.response.data.message); 
      setLoad(false);
       Swal.fire({
        icon: 'error',
        title: languaje === 'ES' ? 'Lo sentimos...': "We're sorry",
        text: 'Ocurrio un error inesperado, nuestro equipo esta trabajando en resolverlo'
      });
    }

  };

  return (<>
    <PayPalScriptProvider options={{
      "client-id": cliente_id || '',
      currency: 'MXN',
      locale: 'es_MX',
      "disable-funding": 'credit,card'
    }}
    >
      <PayPalButtons
        style={{
          shape: 'pill',
          color: 'blue',
          layout: 'vertical',
          label: 'paypal'
        }}
        className="w-100"
        createOrder={(data: any, actions: any) => createOrder(data, actions)}
        onApprove={(data, actions) => {
          return actions?.order?.capture()?.then((details) => {
            createPaypalPayment(details.id);
          })?? Promise.resolve();
        }}
        onError={(data: any) => onError(data)}
        onCancel={(data: any) => onCancel(data)}
      />
    </PayPalScriptProvider>
  </>)
}
