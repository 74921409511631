import { DonacionForm } from './';
import { nosotrosImages } from '../../helpers/callejeritosImages';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';

export const DonacionTratamiento = () => {

    const { languaje } = useContext(UserContext);

    return (
        <div className="body-donacionali-form pb-4 animate__animated animate__fadeIn text-center">
            {
                languaje === 'ES' ?
                <>
                    <h3>Donación de tratamiento</h3>
                    <p className="text font-weight-bold">El primer paso para dar inicio con un proceso de adopción es una pronta y sana recuperación de los cuadros 
                        más comunes en perros en situación de calle, abandono y vulnerables, de los cuales los más comunes y graves son de desnutrición severa, 
                        desparasitación interna y externa, parvovirus, moquillo, ehrlichiosis, anaplasmosis, gusano del corazón y sarna. Una vez que esto ha sido 
                        superado, el siguiente paso es vacunas, esterilización y ¡listo! Ahora sí, Rumbo a casa.
                    </p>
                    <p className="text font-weight-bold">La infografía aquí mostrada y descargable es un aproximado, debido a los incrementos en costos en 
                        medicamentos e insumos cada año por inflación, los costos se actualizan año con año.
                    </p>
                    <div className='d-flex justify-content-center'>
                        <DonacionForm tipo='tratamiento' />
                    </div>
                    <Link className="btn btn-descarga" to={ nosotrosImages(`./Donaciones/Esterilizaciones/infografia_tratamientos.jpeg`).default }
                        target="_blank" download>
                            <div className="d-flex align-items-center">
                                <img  className="mr-4" src={ nosotrosImages('./Donaciones/descarga_icon.png').default } height={ 30 } alt="descarga" />
                                <p className="btn-descarga-text">Descargar infografía donación de tratamientos</p>
                            </div>
                    </Link>   
                </>
                :
                <>
                    <h3>Treatment donation</h3>
                    <p className="text font-weight-bold">The first step to start the adoption process is a prompt and healthy recovery from the most common symptoms 
                        in homeless, abandoned and vulnerable dogs, of which the most common and serious are severe malnutrition, internal and external deworming, 
                        parvovirus, distemper, ehrlichiosis, anaplasmosis, heartworm and scabies. Once this has been overcome, the next step is vaccinations, 
                        sterilization and that's it! Now, heading home.
                    </p>
 
                    <p className="text font-weight-bold">The infographic shown here and downloadable is an approximation, due to the increases in costs of medicines 
                        and supplies each year due to inflation, the costs are updated year after year.
                    </p>
                    <div className='d-flex justify-content-center'>        
                        <DonacionForm tipo='tratamiento' />
                    </div>
                    <Link className="btn btn-descarga" to={ nosotrosImages(`./Donaciones/Esterilizaciones/infografia_tratamientos.jpeg`).default }
                        target="_blank" download>
                            <div className="d-flex align-items-center">
                                <img  className="mr-4" src={ nosotrosImages('./Donaciones/descarga_icon.png').default } height={ 30 } alt="descarga" />
                                <p className="btn-descarga-text">Download treatment donation infographic</p>
                            </div>
                    </Link>   
                </>
            }   
        </div>
    )
}
