import React, { useContext } from 'react';
import { Alert } from 'react-bootstrap';
import { callejeritosImages } from '../../helpers/callejeritosImages';
import { LoginForm } from '../../components/Auth/LoginForm';
import { UserContext } from '../../context/UserContext';
import { AuthContext } from '../../context/AuthContext';

export const Login = () => {

    const { languaje } = useContext(UserContext);
    const { errorMessage, removeError } = useContext(AuthContext);

    return (
        <div className="div-login animate__animated animate__fadeIn">
             <div className="header__login">
                <img src={ callejeritosImages(`./Auth/Login/titulo${ languaje }.png`).default } alt="Unete"
                    className={ 'img-fluid login_title' + languaje } />
            </div>
            <div className="body__login animate__animated animate__fadeInUp">
                {
                    errorMessage &&
                    <div className="row">
                        <div className="col-12 col-sm-8 col-lg-7">
                            <Alert variant="warning" onClose={() => removeError()} dismissible className="mt-4 w-50">
                                <Alert.Heading>{ languaje === 'ES' ? 'Tu sesión ha expirado' : 'Your session has expired' }</Alert.Heading>
                                <p>{ languaje === 'ES' ? 'Ingresa de nuevo para continuar con tu proceso': 'Login again to continue with your process' }</p>
                            </Alert>
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-12 col-md-6 d-flex align-items-center justify-content-start">
                        <LoginForm redirect />
                    </div>
                        <img src={ callejeritosImages('./Auth/Login/chihuahuas.png').default } alt="Cachorros" className="img-fluid cachorros_img"/>
                </div>
            </div>
        </div>
    )
}
