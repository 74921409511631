import React, { useContext } from 'react';
import { nosotrosImages, headerImages } from '../../helpers/callejeritosImages';
import { UserContext } from '../../context/UserContext';

export const Nosotros = () => {

    const { languaje } = useContext(UserContext);

    return (
        <div className="container-nosotros animate__animated animate__fadeIn">
            {
                languaje === 'ES' ?
                <>
                    <div className="header-nosotros">
                        <img src={ nosotrosImages('./Titulo.png').default } alt="Nosotros" className="img-fluid title-nosotros"/>
                    </div>
                    <div className="body-nosotros animate__animated animate__fadeInUp">
                        <div className="nosotros-textdiv">
                            <div className="nosotros-text">
                                <p className="text">Somos un equipo de ciudadanos sensibles, empáticos, conscientes, responsables y 
                                    emprendedores en la causa para generar un cambio positivo, humano y digno en nuestra
                                    sociedad, mediante acciones reales y seguimiento directo a las problemáticas de maltrato
                                    y abandono animal, para impulsar y defender el bienestar y vida digna de nuestros mejores amigos.</p>
                            </div>
                            <div>
                                <img src={ headerImages(`./${ languaje }/03.png`).default } alt="Callejeritos" className="hc-img" />
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="header-nosotros">
                        <img src={ nosotrosImages('./Titulo_en.png').default } alt="Nosotros" className="img-fluid title-us"/>
                    </div>
                    <div className="body-nosotros animate__animated animate__fadeInUp">
                        <div className="nosotros-textdiv">
                            <div className="nosotros-text">
                                <p className="text">We are a team of sensitive, empathetic, conscious, responsible and entrepreneurial
                                citizens in the cause to generate a positive, humane and dignified change in our society, through real
                                actions and direct monitoring of the problems of animal abuse and neglect, to promote and defend the
                                well-being and life worthy of our best friends.</p>
                            </div>
                            <div>
                                <img src={ headerImages(`./${ languaje }/03.png`).default } alt="Callejeritos" className="hc-img" />
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}
