/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useMemo, useState } from 'react'
import { useParams, useHistory } from 'react-router';
import { AxiosResponse } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';


import { useKW2P } from '../../hooks/useKW2P';
import { AuthContext } from '../../context/AuthContext';
import { UserContext } from '../../context/UserContext';
import { ExpedienteAzulFooter } from '../ui/ExpedienteAzulFooter';
import { Loader } from '../../components/ui/Loader';
import { MascotaGalery } from '../../components/Mascotas/MascotaGalery';
import { Estado, MascostasData, Mascota, Payload } from '../../interfaces/mascotasInterfaces';
import { mascotaEdad, mascotaRaza, mascotaSexo, mascotaTamano, mascotaEspecie } from '../../helpers/callejeritosFunciones';
import kw2pApiPublic from '../../api/kw2pPublic';
import { Documento, ExpedienteDetalles } from '../../interfaces/expedienteAzulInterfaces';
import { MascotaDic } from '../../pages/Mascotas/Adotadas/MascotasAdotadas';
import moment from 'moment';


export const MascotaDetalle = () => {

    const history = useHistory();

    const { id, scope } = useParams < RouteParams > ();
    const { languaje } = useContext(UserContext);
    const { handleError, setLoading, isLoading } = useContext(AuthContext);
    const { consultarCatalogoPublic } = useKW2P();

    const [mascota, setMascota] = useState < Mascota > ();
    const [documentos, setDocumentos] = useState < Documento[] > ();

    const MascotaDicMap = useMemo(() => MascotaDic(languaje).reduce((v, c) => ({ ...v, [c.key]: c.label }), {}), [languaje]);

    const consultarMascotas = (): Promise<AxiosResponse<MascostasData>> => {
        const BODY = {
            schema: 'mascotas',
            filter: {
                where: [
                    {
                        field: 'id',
                        operator: '=',
                        value: Number(id)
                    }
                ]
            }
        };

        return consultarCatalogoPublic(BODY);
    }


    const obtenerExpediente = async (folder_id: number) => {
        try {
            const { data: { data } } = await kw2pApiPublic.post('/expediente_azul:expediente_detalles', { folder_id });
            setDocumentos(data);
        } catch (err: any) {
            console.log(err);
            Swal.fire({
                icon: 'error',
                title: 'Lo sentimos ocurrio un error al obtener el expediente...',
                text: 'Nuestro equipo esta trabajando en solucionarlo'
            });
        }
    };

    const obtenerMascota = async () => {
        try {
            setDocumentos([]);
            setLoading(true);
            const { data: { data } } = await consultarMascotas();

            if (!data.length) {
                history.push(`/mascotas/${scope}`);
                setLoading(false);
                return;
            }

            if (data[0].payload.expediente_id) {
                obtenerExpediente(data[0].payload.expediente_id);
            }

            setMascota(data[0]);
            setLoading(false);
        } catch (err: any) {
            setLoading(false);
            handleError(err);
        }
    };

    useEffect(() => {
        obtenerMascota();
    }, []);

    const fecha_Actual = new Date();
    // console.log("fecha_Actual",fecha_Actual);

    const mesAdopcion = mascota?.payload.fecha_creado.slice(5, 7);
    const diaAdopcion = mascota?.payload.fecha_creado.slice(8, 10);
    const anioAdopcion = mascota?.payload.fecha_creado.slice(0, 4);
    const fechaRegistro = new Date(`${mesAdopcion}/${diaAdopcion}/${anioAdopcion}`);
    let resta = fecha_Actual.getTime() - fechaRegistro.getTime()
    let diasAdopcion = Math.round(resta / (1000 * 60 * 60 * 24));
    // console.log("diasAdopcion",diasAdopcion)

    return (
        <>
            <div className='mascotas__container'>
                {mascota &&
                    <>
                        
                        <div className="row mb-3">
                            <div className="col-12 mascotas_detalles_header">
                                <div>
                                    <h1 className="mascotas__detalles_title">{mascota.payload.nombre}</h1>
                                    <p className="mascotas__detalles_subtitle">{languaje === 'ES' ? 'Fecha de registro:' : 'Registration date:'} {mascota.created_at.slice(0, -17)}</p>
                                    <p className="mascotas__detalles_subtitle">{languaje === 'ES' ? 'Días en adopción' : 'Days in adoption:'} {diasAdopcion} </p>
                                    {scope === 'adopcion'
                                        ? <p className="mascotas__detalles_subtitle">{languaje === 'ES' ? 'Mascota en adopción' : 'Pet in adoption'}</p>
                                        : <p className="mascotas__detalles_subtitle">{languaje === 'ES' ? 'Mascota en rehabilitación' : 'Pet in rehabilitation'}</p>
                                    }

                                </div>
                                <div>
                                    {/* <Link to={ `/mascotas/${ scope }` } className="btn btn-sm btn-regresar w-100">{ languaje === 'ES' ? 'REGRESAR' : 'BACK' }</Link> */}
                                    <button onClick={() => history.goBack()} className="btn btn-sm btn-regresar w-100">{languaje === 'ES' ? 'REGRESAR' : 'BACK'}</button>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="row">
                                    <div className="col-12 col-lg-6 mt-3">
                                        <p className="m-0">{languaje === 'ES' ? 'RAZA' : 'BREED'}</p>
                                        <h3 className="m-0">{mascotaRaza(mascota.payload.raza, languaje)}</h3>
                                    </div>
                                    <CheckDetalle
                                        label={languaje === 'ES' ? '¿CONVIVE CON OTRAS ESPECIES?' : 'LIVES WITH OTHER SPECIES?'}
                                        value={mascota.payload.convine_otras}
                                    />

                                    <div className="col-12 col-lg-6 mt-3">
                                        <p className="m-0">{languaje === 'ES' ? 'SEXO:' : 'GENDER:'}</p>
                                        <h3 className="m-0">{mascotaSexo(mascota.payload.sexo, languaje)}</h3>
                                    </div>
                                    <CheckDetalle
                                        label={languaje === 'ES' ? '¿CONVIVE CON OTROS DE SU ESPECIE?' : 'LIVES WITH OTHERS OF ITS SPECIES?'}
                                        value={mascota.payload.convine}
                                    />
                                    <div className="col-12 col-lg-6 mt-3">
                                        <p className="m-0">{languaje === 'ES' ? 'ESPECIE:' : 'SPECIES:'}</p>
                                        <h3 className="m-0">{mascotaEspecie(mascota.payload.especie, languaje)}</h3>
                                    </div>

                                    <CheckDetalle
                                        label={languaje === 'ES' ? '¿CONVIVE CON NIÑOS?' : 'KIDS FRIENDLY?'}
                                        value={mascota.payload.convine_ninos}
                                    />
                                    <div className="col-12 col-lg-6 mt-3">
                                        <p className="m-0">{languaje === 'ES' ? 'PESO:' : 'WEIGHT:'}</p>
                                        <h3 className="m-0">{mascota.payload.peso} KG</h3>
                                    </div>

                                    <div className="col-12 col-lg-6 mt-3">
                                        <p className="m-0">{languaje === 'ES' ? '¿ESTÁ ENTRENADO?' : 'TRAINED?'}</p>
                                        {mascota.payload.entrenado ?
                                            <label className="chk_custom_container mt-1">
                                                <input type="checkbox" checked={mascota.payload.entrenado} disabled />
                                                <span className="checkmark"></span>
                                            </label>
                                            :
                                            <div className="bg-danger d-inline-block text-white ml-2 mt-1 py-0 px-2">
                                                <FontAwesomeIcon icon={faTimes as IconProp} />
                                            </div>
                                        }
                                    </div>
                                    <div className="col-12 col-lg-6 mt-3">
                                        <p className="m-0">COLOR</p>
                                        <h3 className="m-0">{mascota.payload.color}</h3>
                                    </div>
                                    <div className="col-12 col-lg-6 mt-3">
                                        <p className="m-0">{languaje === 'ES' ? '¿ES EDUCADO?' : 'EDUCATED?'}</p>
                                        {mascota.payload.educado ?
                                            <label className="chk_custom_container mt-1">
                                                <input type="checkbox" checked={mascota.payload.educado} disabled />
                                                <span className="checkmark"></span>
                                            </label>
                                            :
                                            <div className="bg-danger d-inline-block text-white ml-2 mt-1 py-0 px-2">
                                                <FontAwesomeIcon icon={faTimes as IconProp} />
                                            </div>
                                        }
                                    </div>
                                    <div className="col-12 col-lg-6 mt-3">
                                        <p className="m-0">{languaje === 'ES' ? 'TAMAÑO:' : 'SIZE:'}</p>
                                        <h3 className="m-0">{mascotaTamano(mascota.payload.tamano, languaje)}</h3>

                                        <p className="m-0 mt-3">{languaje === 'ES' ? 'EDAD:' : 'AGE:'}</p>
                                        <h3 className="m-0">{mascotaEdad(mascota.payload.edad, languaje)}</h3>
                                    </div>
                                    <div className="col-12 col-lg-6 mt-3 ">
                                        <label htmlFor="personalidad">{languaje === 'ES' ? 'PERSONALIDAD:' : 'PERSONALITY:'}</label>
                                        <textarea className="form-control text_desc" id="personalidad" rows={4} value={mascota.payload.personalidad} disabled></textarea>
                                    </div>
                                    <div className="col-12 col-lg-6 mt-3">
                                        <p className="m-0">{languaje === 'ES' ? 'UBICACIÓN:' : 'LOCATION:'}</p>
                                        <h3 className="m-0">{mascota.payload.ciudad} {mascota.payload.entidad_federativa}</h3>
                                    </div>
                                    <div className="col-12 col-lg-6 mt-3 margin_file">
                                        <p className="m-0">{languaje === 'ES' ? '¿Vacunación y desparasitación completa?' : 'FULL VACCINATION AND DEWORKMING?'}</p>
                                        {mascota.payload.desparasitacion ?
                                            <label className="chk_custom_container mt-1">
                                                <input type="checkbox" checked={mascota.payload.desparasitacion} disabled />
                                                <span className="checkmark"></span>
                                            </label>
                                            :
                                            <div className="bg-danger d-inline-block text-white ml-2 mt-1 py-0 px-2">
                                                <FontAwesomeIcon icon={faTimes as IconProp} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="row">
                                    <div className="col-12 col-md-12">
                                        <h5>{languaje === 'ES' ? 'Historial' : 'History'}</h5>
                                        {mascota?.payload?.historial &&
                                            <div className="table-responsive">
                                                <table className="table table-sm table-hover table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>{languaje === 'ES' ? 'Fecha' : 'Date'}</th>
                                                            <th>{languaje === 'ES' ? 'Estado' : 'Status'}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {mascota?.payload?.historial.map((estado: Estado) => (
                                                            <tr key={`${estado.estado}-${estado.fecha}`}>
                                                                <td>{moment(estado.fecha).format('DD/MM/YYYY HH:mm:ss')}</td>
                                                                {/* @ts-ignore */}
                                                                <td>{MascotaDicMap[estado.estado]}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        }

                                    </div>

                                    {scope === 'adopcion' ?
                                        <>
                                            <div className="col-12 col-md-12 col-xl-6">
                                                <h5>{languaje === 'ES' ? 'Expediente' : 'File'}</h5>
                                                {!mascota.payload.expediente_id && <p>Esta mascota aún no cuenta con un expediente abierto</p>}
                                                {documentos &&
                                                    <div className="table-responsive">
                                                        <table className="table table-sm table-hover table-striped">
                                                            <tbody>
                                                                {documentos.map((doc: Documento) => (
                                                                    <tr key={doc.document_id}>
                                                                        <td className='text-center'>
                                                                            {doc.status === 'valid' ?
                                                                                <div className="d-inline-block ml-2 py-0 px-2">
                                                                                    <FontAwesomeIcon icon={faCheck as IconProp} />
                                                                                </div>
                                                                                :
                                                                                <div className="bg-danger d-inline-block text-white ml-2 py-0 px-2">
                                                                                    <FontAwesomeIcon icon={faTimes as IconProp} />
                                                                                </div>
                                                                            }
                                                                        </td>
                                                                        <td>{doc.name}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }

                                            </div>
                                            <div className="col-12 col-md-12 col-xl-6 text-center d-flex align-items-center">
                                                <div className="d-flex flex-column align-items-center w-100">
                                                    <Link to="/donaciones" className="btn btn-callejeritos w-100">
                                                        {languaje === 'ES' ? 'Donar cuota de recuperación' : 'Donate recovery fee'}
                                                    </Link>

                                                    {languaje === 'ES' ?
                                                        (<a href="https://adopciones.callejeritos.mx/" target="_blank" rel="noreferrer"
                                                            className="btn btn-callejeritos ml-1 w-100">Aplicar para Adopción</a>)
                                                        :
                                                        (<a href="http://adoptions.callejeritos.mx/" target="_blank" rel="noreferrer"
                                                            className="btn btn-callejeritos ml-1 w-100">Apply for Adoption</a>)
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        : (scope === 'rehabilitacion' ?
                                            <>
                                                <div className="col-12 mt-4">
                                                    <label htmlFor="necesitades">{languaje === 'ES' ? '¿QUÉ NECESITA?' : 'WHAT DOES THIS PET NEED?'}</label>
                                                    <div className="table-responsive">
                                                        <table className="table table-sm table-hover table-striped">
                                                            <tbody>
                                                                {mascota.payload.necesidades.map((necesidad: any) => (
                                                                    <tr key={necesidad}>
                                                                        <td>{necesidad}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </> : <>
                                            </>)
                                    }
                                    <div className="col-12 mt-4">
                                        <label htmlFor="personalidad">{languaje === 'ES' ? 'RESEÑA:' : 'REVIEW:'}</label>
                                        <textarea className="form-control text_desc" id="personalidad" rows={4} value={mascota.payload.comentarios} disabled></textarea>
                                    </div>
                                    <div className="col-12 col-lg-6 mt-4">
                                        <p className="m-0">{languaje === 'ES' ? '¿ESTÁ ESTERILIZADA?' : 'IS STERILIZED?'}</p>
                                        {mascota.payload.esterilizada ?
                                            <label className="chk_custom_container mt-1">
                                                <input type="checkbox" checked={mascota.payload.esterilizada} disabled />
                                                <span className="checkmark"></span>
                                            </label>
                                            :
                                            <div className="bg-danger d-inline-block text-white ml-2 mt-1 py-0 px-2">
                                                <FontAwesomeIcon icon={faTimes as IconProp} />
                                            </div>
                                        }
                                    </div>
                                    <div className="col-12 mt-4">
                                        <label htmlFor="comentDesparasitacion">{languaje === 'ES' ? '¿CUALES VACUNAS TIENE?:' : 'Specify Vaccines:'}</label>
                                        <textarea className="form-control text_desc" id="comentDesparasitacion" rows={4} value={mascota.payload.comentDesparasitacion} disabled></textarea>
                                    </div>
                                    {console.log("mascotas:", mascota.payload)}
                                </div>
                            </div>
                            <div className="col-12 mt-3 mascotas__body">
                                <MascotaGalery id={mascota.id} scope={scope} />
                            </div>
                        </div>
                    </>
                }
                <div className="mascotas__footer">
                    <ExpedienteAzulFooter />
                </div>
            </div>
            {isLoading && <Loader />}
        </>
    )
}

function CheckDetalle({ label, value }: { label: string, value: string }) {
    return (
        <div className="col-12 col-lg-6 mt-3">
            <p className="m-0">{label}</p>
            {value !== 'NO' ?
                <label className="chk_custom_container mt-1">
                    <input type="checkbox" checked={value == 'SI'} disabled={true} />
                    <span className="checkmark"></span>
                </label>
                :
                <div className="bg-danger d-inline-block text-white ml-2 mt-1 py-0 px-2">
                    <FontAwesomeIcon icon={faTimes as IconProp} />
                </div>
            }
        </div>
    )

}

interface RouteParams {
    id: string;
    scope: 'adopcion' | 'rehabilitacion';
}