/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { obtenerAllProductosCallejeritos } from '../helpers';
import { Producto } from '../interfaces/donativosInterfaces';

export const useGetProductosCallejeritos = (categoria: string) => {

  const [gettingProducts, setGettingProducts] = useState(true);
  const [productos, setProductos] = useState<Producto[]>([]);

  const fetchData = async () => {

    const data = await obtenerAllProductosCallejeritos(categoria);
    console.log("necesidades:",data);
    setProductos((data as Producto[]));
    setGettingProducts(false);
  };

  useEffect(() => {
    fetchData();
  }, [categoria]);


  return {
    productos,
    gettingProducts
  };
}
