import React, { useContext } from 'react';
import { Accordion } from 'react-bootstrap';
import { nosotrosImages } from '../../helpers/callejeritosImages';
import { UserContext } from '../../context/UserContext';
import { TextToggler } from '../../components/ui/TextToggler';

export const CausaSocial = () => {

    const { languaje } = useContext(UserContext);

    return (
        <div className="div-causa animate__animated animate__fadeIn">
            <div className="header-causa">
                <img src={ nosotrosImages(`./Causa/titulo${ languaje }.png`).default } alt="Causa social" className="img-fluid title-causa"/>
            </div>
            <div className="body-causa animate__animated animate__fadeInUp">
                <DesktopText lang={ languaje }/>
                <MobileText lang={ languaje }/>
            </div>
        </div>
    )
}

function DesktopText({ lang }: { lang: string }) {
    return (<div className="d-none d-lg-block">{
        lang === 'ES' ?
        <>
            <p className="title-gray">Soluciones reales</p>
            <p className="text">Brindamos propuestas y soluciones reales a las problemáticas por la falta e incumplimiento de políticas públicas
                eficientes, transparentes y responsables, fomentando la inclusión, sensibilización y concientización de la sociedad.</p>
            <p className="text">Buscamos atender el problema de raíz, informando, creando campañas y programas de <b>Adopta NO compres, Adopciones 
                Responsables y Esterilizaciones</b> en alianza con la iniciativa privada, ciudadanía y sistema educativo desde nivel primaria hasta
                universidades.</p>
            <p className="title-gray">Gestión de calidad y transparencia</p>
            <p className="text">El 100% de las utilidades son destinados  para habilitar y mantener centros de rescates, promover adopciones reales,
                transparentes y responsables para perros en situación de calle, vulnerables, abandono y maltrato, donde se les puede dar una oportunidad
                y calidad de vida, buscando una familia y hogar digno.</p>
            <p className="text">Contamos con un sistema de gestión de calidad orientado a la prevención, organizado y documentado con manuales, formatos,
            protocolos y normas establecidas reconocidos a nivel nacional e internacional bajo la norma ISO 9001:2015.</p>
        </>
        :
        <>
            <p className="title-gray">Real solutions</p>
            <p className="text">We provide proposals and real solutions to problems due to the lack and non-compliance of efficient, transparent and
            responsible public policies, promoting inclusion, and awareness of society</p>
            <p className="text">We seek to address the root problem, informing, creating campaigns and programs of <b>Adopt DO NOT buy, Responsible
            Adoptions and Sterilizations</b> in alliance with the private initiative, citizens and the educational system from primary level to
            universities.</p>
            <p className="title-gray">Quality and transparency management</p>
            <p className="text">100% of the profits are used to enable and maintain rescue centers and the responsible adoption of vulnerable, abandoned
             and mistreated dogs from the streets, where they can be given.</p>
            <p className="text">We have a quality management system aimed at prevention, organized and documented with manuals, formats, protocols
            and established standards recognized nationally and internationally under the ISO 9001: 2015 standard.</p>
        </>
    }</div>);
}

function MobileText({ lang }: { lang: string }) {
    return (<div className="d-block d-lg-none">{
        lang === 'ES' ?
        <Accordion defaultActiveKey="0">
            <div>
                <div className="callejeritos_accordion_header">
                    <TextToggler eventKey="0" text="Soluciones reales" />
                </div>
                <Accordion.Collapse eventKey="0">
                    <>
                        <p className="text">Brindamos propuestas y soluciones reales a las problemáticas por la falta e incumplimiento de políticas públicas
                            eficientes, transparentes y responsables, fomentando la inclusión, sensibilización y concientización de la sociedad.</p>
                        <p className="text">Buscamos atender el problema de raíz, informando, creando campañas y programas de <b>Adopta NO compres, Adopciones 
                            Responsables y Esterilizaciones</b> en alianza con la iniciativa privada, ciudadanía y sistema educativo desde nivel primaria hasta
                            universidades.</p>
                    </>
                </Accordion.Collapse>
            </div>
            <div className="mt-5">
                <div className="callejeritos_accordion_header">
                    <TextToggler eventKey="1" text="Gestión de calidad y transparencia" />
                </div>
                <Accordion.Collapse eventKey="1">
                    <>
                        <p className="text">El 100% de las utilidades son destinados  para habilitar y mantener centros de rescates, promover adopciones reales,
                            transparentes y responsables para perros en situación de calle, vulnerables, abandono y maltrato, donde se les puede dar una oportunidad
                            y calidad de vida, buscando una familia y hogar digno.</p>
                        <p className="text">Contamos con un sistema de gestión de calidad orientado a la prevención, organizado y documentado con manuales, formatos,
                        protocolos y normas establecidas reconocidos a nivel nacional e internacional bajo la norma ISO 9001:2015.</p>
                    </>
                </Accordion.Collapse>
            </div>
        </Accordion>    
        :
        <Accordion defaultActiveKey="0">
            <div>
                <div className="callejeritos_accordion_header">
                    <TextToggler eventKey="0" text="Real solutions" />
                </div>
                <Accordion.Collapse eventKey="0">
                    <>
                        <p className="text">We provide proposals and real solutions to problems due to the lack and non-compliance of efficient, transparent and
                        responsible public policies, promoting inclusion, and awareness of society</p>
                        <p className="text">We seek to address the root problem, informing, creating campaigns and programs of <b>Adopt DO NOT buy, Responsible
                        Adoptions and Sterilizations</b> in alliance with the private initiative, citizens and the educational system from primary level to
                        universities.</p>
                    </>
                </Accordion.Collapse>
            </div>
            <div className="mt-5">
                <div className="callejeritos_accordion_header">
                    <TextToggler eventKey="1" text="Quality and transparency management" />
                </div>
                <Accordion.Collapse eventKey="1">
                    <>
                        <p className="text">100% of the profits are used to enable and maintain rescue centers and the responsible adoption of vulnerable, abandoned
                         and mistreated dogs from the streets, where they can be given.</p>
                        <p className="text">We have a quality management system aimed at prevention, organized and documented with manuals, formats, protocols
                            and established standards recognized nationally and internationally under the ISO 9001: 2015 standard.</p>
                    </>
                </Accordion.Collapse>
            </div>
        </Accordion>
    }</div>);
}
