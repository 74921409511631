import React, { useContext, useEffect, useRef } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import Swal from 'sweetalert2';

import { UserContext } from '../../context/UserContext';
import { callejeritosImages } from '../../helpers/callejeritosImages';
import kw2pApi from '../../api/kw2pApi';
import Logotipo from '../../logotipo.svg';

export const RecoveryChange = () => {

    const { emailPattern, languaje } = useContext(UserContext);
    
    const { formState: { errors }, handleSubmit, register, watch, setValue } = useForm<IFormInputs>();
    const match_msj = languaje === 'ES' ? 'Las contraseñas no coinciden' : 'The passwords do not match';

    const { correo, token } = useParams<RouteParams>();
    const history = useHistory();

    useEffect(() => {
        setValue('correo', correo);
    }, [correo, setValue]);


    const contrasena = useRef({});

    contrasena.current = watch('contrasena', '');

    const onSubmit: SubmitHandler<IFormInputs> = async ({ contrasena }) => {
        try {
            
            const payload = {
                correo,
                token_recuperar: token,
                contrasena_nueva: contrasena
            };

            await kw2pApi.post<any>('/usuarios:modificar_contrasena_recuperar', payload);

            Swal.fire({
                title: 'Correo confirmado con éxito',
                text: 'Te invitamos a iniciar sesión en callejeritos!',
                imageUrl: Logotipo,
                imageHeight: 70, 
                imageWidth: 325
            }).then(() => {
                history.push('/login');
            });
        } catch (err: any) {
            Swal.fire({
                icon: 'error',
                title: 'Lo sentimos ocurrio un error...',
                text: JSON.stringify(err.response.data.errors, null, 2).replace(/[{}]/g, '')
            });
        }
    };

    return (
        <div className="div-recoverychange animate__animated animate__fadeIn">
            <div className="body-recoverychange animate__animated animate__fadeInUp">
                <img src={ callejeritosImages('./logotipo.png').default } alt="Callejeritos" className="img-fluid w-75"/>
                <p className="recoverychange_title mb-0">{ languaje === 'ES' ? 'Ingresa tú nueva contraseña' : 'Enter your new password' }</p>
                <p className="recoverychange_subtitle">{ languaje === 'ES' ? 'Crea tú nueva contraseña para iniciar sesión' : 'Create your new password to log in' }</p>
                <form onSubmit={ handleSubmit(onSubmit) }>
                    <div className="row g-3">
                        <div className="col-12">
                            <label htmlFor="correo">{ languaje === 'ES' ? 'Correo electrónico:' : 'Email:' }</label>
                            <input id="correo" className="form-control" placeholder={ languaje === 'ES' ? 'Ingresa tú correo electrónico' : 'Email' } autoComplete="off"
                                { ...register('correo', { 
                                    required: languaje === 'ES' ? 'El correo es requerido': 'The email is required',
                                    pattern: { value : emailPattern, message: languaje === 'ES' ? 'Ingresa un correo válido': 'Enter a valid email' } }) } type="email"
                                    disabled={ true }/>
                            { errors.correo && <small className="text-danger">{ errors.correo.message }</small> }
                        </div>
                        <div className="col-12 mt-2">
                            <label htmlFor="contrasena">{ languaje === 'ES' ? 'Contraseña:' : 'Password:' }</label>
                            <input id="contrasena" className="form-control" placeholder={ languaje === 'ES' ? 'Crea tú contraseña' : 'Enter your new password' } autoComplete="off"
                                { ...register('contrasena', { required: languaje === 'ES' ? 'La contraseña es requerida': 'The new password is required' }) } type="password"/>
                            { errors.contrasena && <small className="text-danger">{ errors.contrasena.message }</small> }
                        </div>
                        <div className="col-12 mt-2">
                            <label htmlFor="contrasena_repeat">{ languaje === 'ES' ? 'Confirmar contraseña:' : 'Confirm password:' }</label>
                            <input id="contrasena_repeat" className="form-control" placeholder={ languaje === 'ES' ? 'Crea tú contraseña' : 'Enter your new password' } autoComplete="off"
                                { ...register('contrasena_repeat', { 
                                    required: languaje === 'ES' ? 'Confirma tú contraseña': 'The confirm your password',
                                    validate: value => value === contrasena.current || match_msj
                                }) } type="password"/>
                            { errors.contrasena_repeat && <small className="text-danger">{ errors.contrasena_repeat.message }</small> }
                        </div>
                    </div>
                    <div className="d-flex flex-column mt-2">
                        <button className="btn login_btn btn-block" type="submit">{ languaje === 'ES' ? 'Enviar' : 'Send' }</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

interface IFormInputs {
    correo: string;
    contrasena: string;
    contrasena_repeat: string;
}

interface RouteParams {
    correo: string;
    token: string;
}
