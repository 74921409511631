import { useContext, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Swal from 'sweetalert2';

import { AuthContext } from '../../context/AuthContext';
import kw2pApiPublic from '../../api/kw2pPublic';
import Logotipo from '../../logotipo.svg';
import { UserContext } from '../../context/UserContext';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
  locale: 'es-419'
});

const CheckoutForm = ({ tipo, cantidad, impuestos, anonima, resetStep, setLoad, producto_id }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useContext(AuthContext);
  const { languaje } = useContext(UserContext);

  const [errors, setErrors] = useState('');

  const handleSubmit = async(e) => {
    e.preventDefault();
    
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement)
    });

    setLoad(true);
    setErrors('');

    if (error) {
      setLoad(false);
      const { message } = error;
      setErrors(message);
      return;
    }

    const { id: stripe_id } = paymentMethod;

    const body = {
      stripe_id,
      monto: Number(cantidad) * 100,
      email: user.correo,
      impuestos,
      anonima,
      tipo,
      producto_id
    };

    try {
      await kw2pApiPublic.post('/donaciones:crear_stripe_payment', body);
      elements.getElement(CardElement).clear();
      Swal.fire({
        title: '¡Gracias por tu apoyo a la causa!',
        text: 'Nuestro equipo estará en contacto contigo lo antes posible',
        imageUrl: Logotipo,
        imageHeight: 70, 
        imageWidth: 325
      });
      resetStep();
      setLoad(false);

    } catch (error) {
      console.log(error.response.data.message); 
      setLoad(false);
       Swal.fire({
        icon: 'error',
        title: languaje === 'ES' ? 'Lo sentimos...': "We're sorry",
        text: handleError(error.response.data.message)
      });
    }

  };

  const handleError = (err) => {
    
    if (languaje !== 'ES') {
      return err;
    }
    
    switch (err) {
      case 'Amount must be at least $10.00 mxn':
          return 'El monto debe ser mínimo $10.00 mxn';
      case "Your card's security code is incorrect.":
          return "El código de seguridad de su tarjeta es incorrecto.";
      case "Your card was declined.":
        return "Tu tarjeta fue rechazada.";
      case "Your card has insufficient funds.":
          return "Su tarjeta no tiene fondos suficientes.";
      case "Your card has expired.":
        return "Su tarjeta ha caducado.";
      case "An error occurred while processing your card. Try again in a little bit.":
        return "Ocurrió un error al procesar su tarjeta. Vuelve a intentarlo en un rato.";
      default:
          return err;
  }
    

  };

  return(
    <form onSubmit={handleSubmit}>
        <div className="row my-3">
          <div className="col-12">
            <CardElement className='form-control'/>
            { errors && <small className="text-danger">{ errors }</small> }
          </div>
          <div className='col-12'>
            <button type='submit' className="btn btn-callejeritos btn-sm" disabled={ !stripe }>
              { languaje === 'ES' ? 'Pagar con tarjeta de crédito': 'Pay with credit card' }
            </button>
          </div>
        </div>
      </form>
  );
};

const StripeCardPayment = ({ tipo, cantidad, impuestos, anonima, resetStep, setLoad , producto_id}) => {

  return (
    <Elements stripe={ stripePromise }>
        <CheckoutForm 
          tipo={ tipo }
          impuestos={ impuestos }
          cantidad={ cantidad }
          anonima={ anonima }
          resetStep={ resetStep }
          setLoad={ setLoad }
          producto_id={ producto_id }
        />
    </Elements>
  );
}

export default StripeCardPayment;
