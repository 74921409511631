import React, { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { ENFaqs } from '../../components/Faqs/ENFaqs';
import { ESFaqs } from '../../components/Faqs/ESFaqs';

export const Faqs = () => {


    const { languaje } = useContext(UserContext);

    return (
        <div className="container-fluid faqs__container"> 
             <div className="faqs__titlebox">
                <h1 className="aviso__title">{ languaje === 'ES' ? 'Preguntas frecuentes' : 'FAQS' }</h1>
            </div>
            <div className="aviso__content">
                { languaje === 'ES' ? <ESFaqs lang={ languaje } /> : <ENFaqs lang={ languaje } /> }
            </div>
        </div>
    )
}