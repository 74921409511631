import React from 'react';
import { useContext } from 'react';
import { nosotrosImages } from '../../helpers/callejeritosImages';
import { UserContext } from '../../context/UserContext';

export const Manifiesto = () => {

    const { languaje } = useContext(UserContext);

    return (
        <>
            <div className="carousel slide manifiesto animate__animated animate__fadeIn" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={ nosotrosImages(`./Manifiesto/cover${ languaje }.jpg`).default } className="d-none d-sm-block w-100" alt="Manifiesto" />
                        <img src={ nosotrosImages(`./Manifiesto/coversm${ languaje }.jpg`).default } className="d-block d-sm-none w-100" alt="Manifiesto" />
                    </div>
                </div>
            </div>
        </>
    )
}