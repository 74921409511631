import axios from 'axios';

let baseURL: string = (process.env.REACT_APP_KW2P_PUBLIC as string);

const kw2pApiPublic  = axios.create({ baseURL });

export const descargarFile = (formData: any) => {
  return fetch(`${baseURL}/expediente_azul:descargar_archivo`, {
    method: 'POST',
    body : JSON.stringify(formData),
    headers : {
      'Content-Type':  'application/json',
      'Authorization': `Bearer ${ localStorage.getItem('CA_JWT') }`,
    },
  })
  .then(resp => {
    return resp.blob();
  })
  .catch(err => {
    return err;
  });
};


export default kw2pApiPublic;
