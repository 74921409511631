import { useRouteMatch, Switch, Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import { PrivateRoute } from './PrivateRoute';
/* import { RegistroUsuario } from '../pages/ExpedienteAzul/RegistroUsuario'; */
import { EditarExpediente } from '../pages/ExpedienteAzul/EditarExpediente';

export const ExpedienteAzulRouter = () => {

  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${ url }`}>
        <Redirect to={`/`} />
      </Route>
      {/* <PrivateRoute exact path={`${url}/registrar-usuario`} component={RegistroUsuario}/> */}
      <PrivateRoute exact path={`${url}/expediente/:name/:folder_id`} component={EditarExpediente} />
    </Switch>
  );
};
