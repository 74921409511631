import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useAccordionToggle } from 'react-bootstrap';

export const TextToggler = ({ text = '', eventKey, onClick }: TextTogglerData) => {

  const selfRef = React.useRef < HTMLButtonElement | null > (null);

  const onScrollToTop = () => {
    const content = selfRef.current?.parentElement?.parentElement?.parentElement as HTMLButtonElement;
    let key=-1, activeKey = -1;
    //@ts-ignore
    const listContent = [...content.childNodes];
    

    listContent.forEach((item, i) => {
      const btn = item.querySelector('div>button')
      const show = item.querySelector('.show')
      if (show) {
        activeKey = i
      }
      if (btn === selfRef.current) {
        key = i
      }
    })
    const oldContent = listContent[activeKey]?.querySelector('.collapse') as HTMLDivElement;
    
    const height = (activeKey !== key && key > activeKey) ? oldContent?.getBoundingClientRect()?.height ?? 0 : 0;
    
    const scrollY = window.scrollY
    const rect = selfRef.current?.parentElement?.getBoundingClientRect();
    const yPosViewport = rect?.top ?? 0;
    
    const yPosDocument = yPosViewport + scrollY - height - 60;
    
    window.scrollTo({
        top: yPosDocument,
        behavior: 'smooth',
    });
    
    onClick?.()
  }

  const decoratedOnClick = useAccordionToggle(eventKey, onScrollToTop);

  return (
    <button ref={selfRef} className="btn btn-callejeritos-accordion" type="button" onClick={decoratedOnClick}>
      <p className="title-gray mr-3 text-left">{text}</p>
      <FontAwesomeIcon icon={faAngleDown as IconProp} size="2x" />
    </button>
  )
}

interface TextTogglerData {
  text: string;
  eventKey: string;
  onClick?: () => void;
}