import kw2pApi from "../api/kw2pApi";
import kw2pApiPublic from "../api/kw2pPublic";

export const useKW2P = () => {
    
    const consultarCatalogo = (payload: any) => {
        return kw2pApi.post<any>('/catalogos:consultar', payload);
    }

    const consultarCatalogoPublic = (payload: any) => {
        return kw2pApiPublic.post<any>('/catalogos:consultar', payload);
    };

    const actualizarCatalogoPublic = (payload: any) => {
        const jwt = localStorage.getItem('CA_JWT');
        //function mandarEmail
        return kw2pApiPublic.post<any>('/catalogos:actualizar', payload, {
            headers: {
                Authorization : `Bearer ${ jwt }`
            }
        });
    };

    const reemplazarCatalogoPublic = (payload: any) => {
        const jwt = localStorage.getItem('CA_JWT');
        return kw2pApiPublic.post<any>('/catalogos:remplazar', payload, {
            headers: {
                Authorization : `Bearer ${ jwt }`
            }
        });
    };

    const actualizarCatalogo = (payload: any) => {
        return kw2pApi.post<any>('/catalogos:actualizar', payload);
    };

    const eliminarCatalogo = (payload: any) => {
        return kw2pApi.post<any>('/catalogos:eliminar', payload)
    };

    const consultarDocumento = (payload: any) => {
        return kw2pApi.post<any>('/documentos:consultar', payload);
    };

    const eliminarDocumento = (payload: any) => {
        const jwt = localStorage.getItem('CA_JWT');
        return kw2pApi.post<any>('/documentos:cancelar', payload, {
            headers: {
                Authorization : `Bearer ${ jwt }`
            }
        });
    };

    const downloadReport = (reportes: string, body: any) => {
        // Eval del apiendpoint sin instancia de axios
        const baseURL = (process.env.REACT_APP_KW2P as string);

        const jwt = localStorage.getItem('CA_JWT');

        return fetch(`${baseURL}/${reportes}`, {
            method : 'POST',
            body : JSON.stringify(body),
            headers : {
                'Content-Type':  'application/json',
                'Authorization': `Bearer ${ jwt }`
            },
        }).then(resp => {
            return resp.blob();
        }).catch(err => {
            return err;
        })
    };

    return {
        consultarCatalogo,
        consultarCatalogoPublic,
        actualizarCatalogoPublic,
        reemplazarCatalogoPublic,
        actualizarCatalogo,
        eliminarCatalogo,
        consultarDocumento,
        downloadReport,
        eliminarDocumento
        
    };
};
