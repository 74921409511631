import React, { useContext } from 'react';
import { landingpageImages, productosImages } from '../../helpers/callejeritosImages';
import { Carousel } from 'react-bootstrap';
import { UserContext } from '../../context/UserContext';

export const ProductoSection = () => {

    const { languaje } = useContext(UserContext)

    return (
        <>
            {/* <div className="container-prod">
                <Carousel id="productoCarousel" indicators={ false }>
                    <Carousel.Item>
                        <div className="mancha-div">
                            <img src={ productosImages('./Alimento/frente.png').default } className="d-block img-alimento" alt="Alimento"/>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="mancha-div">
                            <img src={ productosImages('./Alimento/atras.png').default } className="d-block img-alimento" alt="Alimento"/>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="mancha-div">
                            <img src={ productosImages('./Alimento/izquierda.png').default } className="d-block img-alimento" alt="Alimento"/>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="mancha-div">
                            <img src={ productosImages('./Alimento/derecha.png').default } className="d-block img-alimento" alt="Alimento"/>
                        </div>
                    </Carousel.Item>
                </Carousel>
                <div className="desc-div">
                    { languaje === 'ES' ? 
                        <>
                            <p className="title">Callejeritos<sup className="reg">&reg;</sup> Alimento Premium</p>
                            <p className="text">Alimento para todos los <b>tamaños y razas</b> en cualquier <b>etapa de vida.</b></p>
                            <p className="text"><b>Formulación en proceso con pasión</b></p>
                            <button className="btn btn-callejeritos float-start">Comprar</button>
                        </> 
                        : 
                        <>
                            <p className="title">Callejeritos<sup className="reg">&reg;</sup> Premium Food</p>
                            <p className="text">Food for all <b>sizes and breeds </b> at any <b>stage of life.</b></p>
                            <p className="text"><b>Formulation in process with passion</b></p>
                            <button className="btn btn-callejeritos float-start">Buy now</button>
                        </> 
                    }
                    
                </div>
            </div> */}
            <div className="container-imp">
                <div id="croq" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src={ landingpageImages('./Producto/impacto_social.jpg').default } className="d-block w-100" alt="Plato" />
                            <div className="carousel-caption">
                            <div className="impacto-textos">
                                <div><p className="imp_title w-75">{ languaje === 'ES' ? 'Nutrición e impacto social' : 'Nutrition and social impact' }</p></div>
                                <div><img src={ landingpageImages('./Producto/icono_amarillo.png').default } height={ 50 } alt="CALLLEJERITOS" /></div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="imp-div">
                    { languaje === 'ES' ? 
                        <>
                            <p className="title-gray">Razón de ser</p>
                            <p className="title">Trabajamos para el bienestar de perros desprotegidos.</p>
                            <p className="text">Proveemos una manera de ayudar de manera sostenible y trascendente la ayuda y empatía que sentimos 
                            por las mascotas en situación de calle.</p>
                            <p className="title-gray">Nutrición y acompañamiento sostenible</p>
                            <p className="title">Nutrir y rehabilitar de manera profesional, responsable y sostenible a perros sin dueño.</p>
                            <p className="text">Hacer equipo, colaborar e influenciar positivamente a los albergues, rescatistas y partes involucradas para lograr
                                un proyecto sostenible durante su proceso de rescate, rehabilitacion y proceso de adopción responsable.</p></> 
                        : 
                        <>
                            <p className="title-gray">Our reason for being</p>
                            <p className="title">We work for the welfare of unprotected dogs.</p>
                            <p className="text">We provide help in a sustainable and transcendent way using our empathy for our friends on the streets.</p>
                            <p className="title-gray">Sustainable</p>
                            <p className="title">Nurture and rehabilitate homeless dogs in a professional, responsible an sustainable manner.</p>
                            <p className="text">Team up, collaborate and positively influence shelters, rescuers and involved parties to achieve a sustainable
                                project during your rescue, rehabilitation and responsible adoption process.</p>
                        </>
                    }
                    
                </div>
            </div>
        </>
    )
}


