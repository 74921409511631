/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form';

import { AuthContext } from '../../context/AuthContext';
import { UserContext } from '../../context/UserContext';
import { emailPattern } from '../../helpers/callejeritosImages';

import { Domicilio, Usuario } from '../../interfaces/usuariosInterfaces';
import { Loader } from '../ui/Loader';
import { AvatarUpload } from './AvatarUpload';
import { useKW2P } from '../../hooks/useKW2P';

export const ActualizarForm = () => {

    const { user, isLoading, obtenerUsuario, actualizarUsuario, handleError, consultarCliente } = useContext(AuthContext);
    const { languaje } = useContext(UserContext);

    const { formState: { errors }, handleSubmit, register, setValue } = useForm<IFormInputs>();

    const { actualizarCatalogo } = useKW2P();

    const onSubmit: SubmitHandler<IFormInputs> = async (data) => {

        if (!user?.id) {
            return;
        }

        const payload = {
            id: user.id,
            ...data
        };

        actualizarUsuario(payload);
        actualizarCliente(data, user);
        
    };

    const formatPhone = (val: string) => {
        const phone = val.replace(/[^0-9.]/g, '');
        const telformated = phone.slice(0,3) + (phone.length > 3?("-" + phone.slice(3,6)):'') + (phone.length > 6?("-" + phone.slice(6)):'');
        setValue('telefono', telformated, { shouldValidate: true, shouldDirty: true });
    };

    const actualizarCliente = async (data: IFormInputs, user: Usuario) => {
        try {

            const cliente = await consultarCliente(user);
            const id = cliente ? cliente.id : null;

            if (!id) {
                return;
            }

            const body = {
                schema: 'clientes',
                id,
                payload: {
                    nombre_comercial: data.nombre,
                    razon_social: data.razon_social,
                    rfc: data.rfc,
                    domicilio: data.domicilio,
                    telefonos: {
                        telefono: data.telefono.replace(/-/g, "")
                    },
                    email: data.correo,
                    lista_precios_id: 1,
                    retenciones: []
                }
            };

            await actualizarCatalogo(body);

        } catch (err: any) {
            console.log(err);
            handleError(err);
        }
    };

    useEffect(() => {
        user?.id && obtenerUsuario(user.id);
    }, []);

    return (
        <>
            <form onSubmit={ handleSubmit(onSubmit) }>
                <div className="row mb-3">
                    <div className="col-12 col-md-6 mt-0 mt-md-3">
                        <div className="row regdata-row">
                            <div className="col-12">
                                <label htmlFor="nombre">{ languaje === 'ES' ? 'Nombre:' : 'Name:' }</label>
                                <input id="nombre" className="form-control" placeholder={ languaje === 'ES' ? 'Ingresa tú nombre' : 'Enter your name' } autoComplete="off"
                                { ...register('nombre', { required: languaje === 'ES' ? 'El nombre es requerido': 'The name is required' }) } defaultValue={ user?.nombre } />
                                { errors.nombre && <small className="text-danger">{ errors.nombre.message }</small> }
                            </div>
                            <div className="col-12 mt-2">
                                <label htmlFor="correo">{ languaje === 'ES' ? 'Correo electrónico:' : 'Email:' }</label>
                                <input id="correo" className="form-control" placeholder={ languaje === 'ES' ? 'Ingresa tú correo electrónico' : 'Email' } autoComplete="off"
                                    { ...register('correo', { required: languaje === 'ES' ? 'El correo es requerido': 'The email is required',
                                        pattern: { value : emailPattern, message: languaje === 'ES' ? 'Ingresa un correo válido': 'Enter a valid email' } }) }
                                    defaultValue={ user?.correo } />
                                { errors.correo && <small className="text-danger">{ errors.correo.message }</small> }
                            </div>
                            <div className="col-12 mt-2">
                                <label htmlFor="telefono">{ languaje === 'ES' ? 'Télefono:' : 'Phone:' }</label>
                                <input id="telefono" className="form-control" placeholder="000-000-0000" autoComplete="off"
                                    { ...register('telefono', { 
                                        required: languaje === 'ES' ? 'El telefono es requerido': 'The phone is required',
                                        maxLength: { value : 12, message: languaje === 'ES' ? 'Ingresa un telefono válido': 'Enter a valid celphone' },
                                        minLength: { value : 12, message: languaje === 'ES' ? 'Ingresa un telefono válido': 'Enter a valid celphone' }
                                    }) }
                                        onChange={ (e) => formatPhone(e.target.value) } defaultValue={ user?.telefono }/>
                                { errors.telefono && <small className="text-danger">{ errors.telefono.message }</small> }
                            </div>
                            <div className="col-12 mt-2">
                                <label htmlFor="avatar">{ languaje === 'ES' ? 'Foto de perfil' : 'Profile picture:' }</label>
                                <AvatarUpload />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mt-3 mt-md-0">
                        <h4>Información de facturación</h4>
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="razon_social">{ languaje === 'ES' ? 'Razón social:' : 'Business name:' }</label>
                                <input id="razon_social" className="form-control" placeholder={ languaje === 'ES' ? 'Ingresa tú razón social' : 'Enter your business name' }
                                    autoComplete="off" { ...register('razon_social') } defaultValue={ user?.razon_social } />
                            </div>
                            <div className="col-12 mt-2">
                                <label htmlFor="rfc">RFC</label>
                                <input id="rfc" className="form-control" placeholder={ languaje === 'ES' ? 'Ingresa tú RFC' : 'Enter your RFC' }
                                    autoComplete="off" { ...register('rfc', {
                                        minLength: { value : 12, message: languaje === 'ES' ? 'Ingresa un RFC válido': 'Enter a valid RFC' },
                                        maxLength: { value : 13, message: languaje === 'ES' ? 'Ingresa un RFC válido': 'Enter a valid RFC' }
                                    }) } defaultValue={ user?.rfc } />
                                { errors.rfc && <small className="text-danger">{ errors.rfc.message }</small> }
                            </div>
                            <div className="col-12 mt-2">
                                <label htmlFor="calle">{ languaje === 'ES' ? 'Calle:' : 'Street:' }</label>
                                <input id="calle" className="form-control" placeholder={ languaje === 'ES' ? 'Ingresa el nombre de tú calle' : 'Enter your street name' }
                                    autoComplete="off" { ...register('domicilio.calle') } defaultValue={ user?.domicilio?.calle } />
                            </div>
                            <div className="col-6 mt-2">
                                <label htmlFor="numero_interior">{ languaje === 'ES' ? 'Número interior:' : 'Interior number:' }</label>
                                <input id="numero_interior" className="form-control" 
                                    placeholder={ languaje === 'ES' ? 'Ingresa el número interior' : 'Enter your house interior number' }
                                    autoComplete="off" { ...register('domicilio.numero_interior') } defaultValue={ user?.domicilio?.numero_interior } />
                            </div>
                            <div className="col-6 mt-2">
                                <label htmlFor="numero_exterior">{ languaje === 'ES' ? 'Número exterior:' : 'Outdoor number:' }</label>
                                <input id="numero_exterior" className="form-control" 
                                    placeholder={ languaje === 'ES' ? 'Ingresa el número exterior' : 'Enter your house outdoor number' }
                                    autoComplete="off" { ...register('domicilio.numero_exterior') } defaultValue={ user?.domicilio?.numero_exterior } />
                            </div>
                            <div className="col-6 mt-2">
                                <label htmlFor="colonia">{ languaje === 'ES' ? 'Colonia:' : 'Cologne:' }</label>
                                <input id="colonia" className="form-control" 
                                    placeholder={ languaje === 'ES' ? 'Ingresa el nombre de tú colonia' : 'Enter your cologne name' }
                                    autoComplete="off" { ...register('domicilio.colonia') } defaultValue={ user?.domicilio?.colonia } />
                            </div>
                            <div className="col-6 mt-2">
                                <label htmlFor="cp">{ languaje === 'ES' ? 'Código postal:' : 'Zip code:' }</label>
                                <input id="cp" className="form-control" 
                                    placeholder={ languaje === 'ES' ? 'Ingresa tú código postal' : 'Enter your zip code' }
                                    autoComplete="off" { ...register('domicilio.cp') } defaultValue={ user?.domicilio?.cp } />
                            </div>
                            <div className="col-12 mt-2">
                                <label htmlFor="city">{ languaje === 'ES' ? 'Ciudad:' : 'City:' }</label>
                                <input id="city" className="form-control" placeholder={ languaje === 'ES' ? 'Ingresa el nombre de tú ciudad' : 'Enter your city name' }
                                    autoComplete="off" { ...register('domicilio.ciudad') } defaultValue={ user?.domicilio?.ciudad } />
                            </div>
                            <div className="col-12 mt-2">
                                <label htmlFor="estado">{ languaje === 'ES' ? 'Estado:' : 'State:' }</label>
                                <input id="estado" className="form-control" placeholder={ languaje === 'ES' ? 'Ingresa el nombre de tú estado' : 'Enter your state name' }
                                    autoComplete="off" { ...register('domicilio.estado') } defaultValue={ user?.domicilio?.estado } />
                            </div>
                            <div className="col-12 mt-2">
                                <label htmlFor="pais">{ languaje === 'ES' ? 'País:' : 'Country:' }</label>
                                <input id="pais" className="form-control" placeholder={ languaje === 'ES' ? 'Ingresa de tú país' : 'Enter your country' }
                                    autoComplete="off" { ...register('domicilio.pais') } defaultValue={ user?.domicilio?.pais } />
                            </div>
                        </div>
                    </div>
                </div>
                { isLoading
                    ? <Loader/>
                    : <button className="btn login_btn btn-sm" type="submit">{ languaje === 'ES' ? 'Guardar' : 'Save' }</button>
                }
            </form>
        </>
    )
}

interface IFormInputs {
    nombre:       string;
    correo:       string;
    telefono:     string;
    razon_social: string;
    rfc:          string;
    domicilio:    Domicilio;
};
