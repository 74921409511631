import React, { useContext } from 'react';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject, listAll } from 'firebase/storage';
import Swal from 'sweetalert2';

import { storage } from '../../firebase/firebaseConfig';
import { AuthContext } from '../../context/AuthContext';

export const AvatarUpload = () => {

    const { user, setLoading, actualizarUsuario } = useContext(AuthContext);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const input = e.target as HTMLInputElement;

        if (!input.files?.length || !user?.id) {
            return;
        }

        setLoading(true);

        deleteAvatar(null);

        const file = input.files[0];
        const storageRef = ref(storage, `usuarios/${ user?.id }/${ file.name }`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                case 'paused':
                    console.log('Upload is paused');
                    break;
                case 'running':
                    console.log('Upload is running');
                    break;
                }
            }, 
            (error) => {
                console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Lo sentimos ocurrio un error...',
                    text: 'contacta al administrador'
                });
                setLoading(false);
            }, 
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    const payload = {
                        ...user,
                        avatar: downloadURL
                    };
                    actualizarUsuario(payload);
                });
            }
        );

    };

    const deleteAvatar = async(e: any) => {

        e && e.preventDefault();

        if (!user?.id) {
            return;
        }

        const storageRef = ref(storage,`usuarios/${ user.id }/`);

        // Find all the prefixes and items.
        listAll(storageRef)
        .then((res) => {
           res.items.forEach((itemRef) => {
            deleteObject(itemRef).then(() => {
            }).catch((error) => {
                console.log(error);
            });
            const payload = {
                ...user,
                avatar: ''
            };
            e && actualizarUsuario(payload);
          });

        }).catch((error) => {
            console.log(error);
        });

        

    };

    return (
        <>
            <div className="col-12">
                { user?.avatar && 
                    <div className="avatar-container">
                        <img src={ user.avatar } alt={ user.nombre } className="img-fluid user-avatar mb-2" />
                        <button className="btn btn-sm btn-outline-danger w-25 mb-3" onClick={ e =>  deleteAvatar(e) } >Eliminar</button>
                    </div>
                }
                <div className="custom-file" id="customFile">
                    <input type="file" className="custom-file-input" id="logo" aria-describedby="logo"
                        accept="image/png, image/jpeg, image/jpg" onChange={ (e) => handleChange(e) } />
                    <label className="custom-file-label" htmlFor="logo">Cambiar tu foto de perfil</label>
                </div>
            </div>
        </>
    )
}
