import React, { useContext, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { UserContext } from '../../context/UserContext';
import { nosotrosImages } from '../../helpers/callejeritosImages';

interface Props {
    monto: number;
    referencia: string;
    resetStep: () => void;
};

export const OxxopayConfirm = ({ monto, referencia, resetStep }: Props) => {

    const [show, setShow] = useState(true);
    const { languaje } = useContext(UserContext);

    const handleClose = () => {
        setShow(false);
        resetStep();
    };

    return (
        <Modal show={ show } onHide={ handleClose } dialogClassName="oxxo_modal" backdrop="static" keyboard={false} centered>
            <Modal.Body>
                <div className="opps">
                    <div className="opps-header">
                        <div className="opps-reminder">
                            { languaje === 'ES' ? 'Ficha digital. No es necesario imprimir.' : 'Digital card. No need to print.' }
                        </div>
                        <div className="opps-info">
                            <div className="opps-brand">
                                <img src={ nosotrosImages('./Donaciones/oxxopay_brand.png').default } alt="OXXOPay" />
                            </div>
                            <div className="opps-ammount">
                                <h3>{ languaje === 'ES' ? 'Monto a pagar.' : 'Amount payable' }</h3>
                                <h2>{ monto ? '$' + Number(monto).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '$ 0,000.00' }
                                <sup>MXN</sup>
                                </h2>
                                <p>{ languaje === 'ES' ? 'OXXO cobrará una comisión adicional al momento de realizar el pago.':
                                    'OXXO will charge an additional commission at the time of payment.' }</p>
                            </div>
                        </div>
                        <div className="opps-reference">
                            <h3>{ languaje === 'ES' ? 'Referencia' : 'Reference' }</h3>
                            <h1>{ referencia ? referencia : '0000-0000-0000-00' }</h1>
                        </div>
                    </div>
                    <div className="opps-instructions">
                    { languaje === 'ES' ?
                        <>
                            <h3>Instrucciones</h3>
                            <ol>
                                <li>
                                    Acude a la tienda OXXO más cercana.
                                    <a href="https://www.google.com.mx/maps/search/oxxo/" target="_blank" rel="noreferrer" >Encuéntrala aquí</a>.
                                </li>
                                <li>
                                    Indica en caja que quieres realizar un pago de
                                    <strong>OXXOPay</strong>.
                                </li>
                                <li>
                                    Dicta al cajero el número de referencia en esta ficha para que
                                    tecleé directamete en la pantalla de venta.
                                </li>
                                <li>Realiza el pago correspondiente con dinero en efectivo.</li>
                                <li>
                                    Al confirmar tu pago, el cajero te entregará un comprobante impreso.
                                    <strong
                                    >En el podrás verificar que se haya realizado
                                    correctamente.</strong
                                    >
                                    Conserva este comprobante de pago.
                                </li>
                            </ol>
                            <div className="opps-footnote">
                                Al completar estos pasos recibirás un correo de <strong> Callejeritos </strong> confirmando tu pago.
                            </div>
                        </>
                        :
                        <>
                            <h3>Instructions</h3>
                            <ol>
                                <li>
                                    Go to the nearest OXXO store.
                                    <a href="https://www.google.com.mx/maps/search/oxxo/" target="_blank" rel="noreferrer">Find it here</a>.
                                </li>
                                <li>
                                    Indicate at checkout that you want to make an
                                    <strong>OXXOPay</strong> payment.
                                </li>
                                <li>
                                    Tell the cashier the reference number on this tab so that they can type directly on the sales screen.
                                </li>
                                <li>Make the corresponding payment with cash.</li>
                                <li>
                                    When confirming your payment, the cashier gives you a printed receipt.
                                    <strong>In it you can verify that it has been done correctly.</strong>
                                    Keep this proof of payment.
                                </li>
                            </ol>
                            <div className="opps-footnote">
                                Upon completing these steps you will receive an email from <strong> Callejeritos </strong> confirming your payment.
                            </div>
                        </>
                    }
                    </div>
                    
                </div>
                    <Button variant="secondary" className="mt-1 float-right" onClick={ handleClose }>
                        { languaje === 'ES' ? 'Cerrar' : 'Close' }
                    </Button>
            </Modal.Body>
        </Modal>
    )
}
