/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { AxiosResponse } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTimes, faCheck, faEye, faClock, faExclamationTriangle, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { Card, Dropdown ,DropdownButton } from 'react-bootstrap';
import Swal from 'sweetalert2';

import kw2pApiPublic from '../../api/kw2pPublic';
import { Documento, ExpedienteDetalles, EAFile, EADetalle, EADetalleResp } from '../../interfaces/expedienteAzulInterfaces';
import { AuthContext } from '../../context/AuthContext';
import { Loader } from '../../components/ui/Loader';
import { UserContext } from '../../context/UserContext';
import { descargarFile } from '../../api/kw2pPublic';

export const EditarExpediente = () => {

  const history = useHistory();

  const { name, folder_id } = useParams<RouteParams>();
  const { isLoading, setLoading } = useContext(AuthContext);
  const { languaje } = useContext(UserContext);

  const [documentos, setDocumentos] = useState<Documento[]>([]);
  const [expediente, setExpediente] = useState<EADetalle>();

  const obtenerExpediente = async() => {
    try {
      setLoading(true);
      
      const { data: { data } } = await kw2pApiPublic.post<any, AxiosResponse<ExpedienteDetalles>>('/expediente_azul:expediente_detalles', { folder_id });
      setDocumentos(data);
      
      const { data: { data: dataExp } } = await kw2pApiPublic.post<any, AxiosResponse<EADetalleResp>>('/expediente_azul:expediente_descripcion', { folder_id });
      setExpediente(dataExp);

      setLoading(false);
    } catch (err: any) {
      console.log(err);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Lo sentimos ocurrio un error al obtener el expediente...',
        text: 'Nuestro equipo esta trabajando en solucionarlo'
      });
    }
  };

  useEffect(() => {
    obtenerExpediente();
  }, []);

  const calcIcon = (status: string) => {
    switch (status) {
      case 'invalid':
        return (<FontAwesomeIcon icon={faTimes as IconProp} color='red' />);
      case 'valid':
        return (<FontAwesomeIcon icon={faCheck as IconProp} color='green' />);
      case 'reviewing':
        return (<FontAwesomeIcon icon={faEye as IconProp} className='text-info' />);
      case 'preparing':
        return (<FontAwesomeIcon icon={faClock as IconProp} className='text-primary' />);
      case 'exclude':
        return (<FontAwesomeIcon icon={faExclamationTriangle as IconProp} className='text-warning' />);
      default:
        return (<FontAwesomeIcon icon={faQuestion as IconProp} className='text-warning' />)
    }    
  };
  
  const calcStatus = (status: string): string => {
    if (languaje !== 'ES') return status;
    
    switch (status) {
      case 'invalid':
        return 'invalido';
      case 'valid':
        return 'valido';
      case 'reviewing':
        return 'en revisión';
      case 'preparing':
        return 'preparando';
      case 'exclude':
        return 'excluir';
      default:
        return status;
    }
  };

  const downloadFile = async(file: EAFile) => {

    const { file_id, filename } = file;

    descargarFile({ folder_id, file_id })
    .then(resp => {
      const objectURL = URL.createObjectURL(resp);
      const link = document.createElement('a');
      link.href = objectURL;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => {
      console.log(err);
      Swal.fire({
        icon: 'error',
        title: 'Lo sentimos ocurrio un error al obtener el expediente...',
        text: 'Nuestro equipo esta trabajando en solucionarlo'
      });
    });

  };

  const handleDeleteFile = async(file: EAFile) => {

    const { file_id, filename } = file;

    Swal.fire({
      title: languaje === 'ES' ? `¿Está seguro de eliminar el archivo ${filename}`:`Are you sure that you want delete the file ${filename}?`,
      text: languaje === 'ES' ? '¡No podrás revertir esto!': "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: languaje === 'ES' ? '¡Sí, bórralo!':'Yes, delete it!',
      cancelButtonText: languaje === 'ES' ? 'Cancelar':'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {

          try {
              setLoading(true);
              const { data } = await kw2pApiPublic.post<any, AxiosResponse<any>>('/expediente_azul:eliminar_archivo', { folder_id, file_id });
              console.log(data);
              setLoading(false);
              Swal.fire(
                  languaje === 'ES' ? 'Eliminado':'Deleted!',
                  languaje === 'ES' ? 'Archivo eliminado con éxito': 'Your file has been deleted.',
                  'success'
              );
              obtenerExpediente();
          } catch (err: any) {
            setLoading(false);
            console.log(err);
            Swal.fire({
              icon: 'error',
              title: 'Lo sentimos ocurrio un error al obtener el expediente...',
              text: 'Nuestro equipo esta trabajando en solucionarlo'
            });
          }
      }
    });
  };

  return (
    <>
      <div className='expediente__detalles animate__animated animate__fadeIn'>
        <div className="expediente__detalles__header">
          <div>
            <p className='title'>Expediente de <b>{ name }</b></p>

          </div>
          <div>
            <button onClick={ () => history.goBack() } className="btn btn-sm btn-regresar w-100">{ languaje === 'ES' ? 'REGRESAR' : 'BACK' }</button>
          </div>
        </div>
        { expediente &&
          <h5 className='mt-2'>Termina de completar el expediente de tu mascota en la
            <a href={`https://sistema.expedienteazul.com/expedienteazul/vault/${ expediente.encrypted_id }`}
              target="_blank" rel="noreferrer" className="text-primary"> herramienta de Expediente Azul</a>
          </h5>
        }
        <div className="row">
          { documentos.map((doc: Documento) => (
            <div className="col-12 col-md-6 col-xl-3 mt-2" key={ doc.document_id }>
              <Card className='h-100'>
                <Card.Body>
                  <Card.Title>{ doc.name }</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    <small className='text-capitalize'>{ calcIcon(doc.status) } { calcStatus(doc.status) }</small>
                    { doc.valid_until && <small>{ languaje === 'ES' ? ' hasta':' until' } { doc.valid_until }</small> }
                  </Card.Subtitle>
                  <Card.Text className='m-0'>
                    { doc.comments }
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <p className='m-0 text-center'>Archivos</p>
                  <div className="files__container">
                    { doc.files.map((file: EAFile) => (
                      <DropdownButton key={file.file_id} title={`${ file.filename }`} size='sm' id={`dropdown-button-drop-${file.file_id}`}
                       className='m-1'>
                        <Dropdown.Item eventKey="1" onClick={() => downloadFile(file)}>{ languaje === 'ES'?'Descargar':'Download' }</Dropdown.Item>
                        <Dropdown.Item eventKey="2" onClick={() => handleDeleteFile(file)} className='text-danger'>{ languaje === 'ES'?'Eliminar':'Delete' }</Dropdown.Item>
                      </DropdownButton>
                    ))}
                    
                  </div>
                  
                </Card.Footer>
              </Card>
            </div>
          ))}
        </div>
      </div>
      { isLoading && <Loader/> }
    </>
  );
};

interface RouteParams {
  name: string;
  folder_id: string;
}


