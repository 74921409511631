import React, { useContext } from 'react';
import { Accordion } from 'react-bootstrap';
import { nosotrosImages } from '../../helpers/callejeritosImages';
import { UserContext } from '../../context/UserContext';
import { TextToggler } from '../../components/ui/TextToggler';

export const Albergues = () => {

    const { languaje } = useContext(UserContext);

    return (
        <div className="div-albergues animate__animated animate__fadeIn">
            <>
                <div className="header-albergues">
                    <img src={nosotrosImages(`./Albergues/titulo${languaje}.png`).default} alt="Nosotros"
                        className={ 'img-fluid title-albergues' + languaje } />
                </div>
                <div className="body-albergues animate__animated animate__fadeInUp">
                    <DesktopText lang={ languaje } />
                    <MobileText lang={ languaje } />
                </div>
            </>
        </div>
    )
}

function DesktopText({ lang }: { lang: string }) {
    return (<div className="d-none d-lg-block">{
        lang === 'ES' ?
        <>
            <p className="title-gray">Interesados</p>
            <p className="text">Si eres un albergue o rescatista independiente que apoya esta noble causa y te interesa sumarte a 
            nuestra red y esfuerzo, favor de contactarnos llenando nuestra forma de contacto.</p>
            <p className="title-gray">Redes sociales</p>
            <p className="text">A través de nuestras redes sociales subiremos videos e imágenes de nuestro día a día en <b>#CallejeritosMexico</b>.</p>
        </>
        :
        <>
            <p className="title-gray">Interested</p>
            <p className="text">If you are a shelter or independent rescuer that supports this noble cause and you are interested in
            joining our network and effort, please contact us by filling out our contact form.</p>
            <p className="title-gray">Social media</p>
            <p className="text">Through our social media we will upload videos and images of our day to day in <b>#CallejeritosMexico</b>.</p>
        </>
    }</div>);
}


function MobileText({ lang }: { lang: string }) {
    return (<div className="d-block d-lg-none">{
        lang === 'ES' ?
        <Accordion defaultActiveKey="0">
            <div>
                <div className="callejeritos_accordion_header">
                    <TextToggler eventKey="0" text="Interesados" />
                </div>
                <Accordion.Collapse eventKey="0">
                    <>
                        <p className="text">Si eres un albergue o rescatista independiente que apoya esta noble causa y te interesa sumarte a 
                        nuestra red y esfuerzo, favor de contactarnos llenando nuestra forma de contacto.</p>
                    </>
                </Accordion.Collapse>
            </div>
            <div className="mt-5">
                <div className="callejeritos_accordion_header">
                    <TextToggler eventKey="1" text="Redes sociales" />
                </div>
                <Accordion.Collapse eventKey="1">
                    <>
                        <p className="text">A través de nuestras redes sociales subiremos videos e imágenes de nuestro día a día en <b>#CallejeritosMexico</b>.</p>
                    </>
                </Accordion.Collapse>
            </div>
        </Accordion>
        :
        <Accordion defaultActiveKey="0">
            <div>
                <div className="callejeritos_accordion_header">
                    <TextToggler eventKey="0" text="Interested" />
                </div>
                <Accordion.Collapse eventKey="0">
                    <>
                        <p className="text">If you are a shelter or independent rescuer that supports this noble cause and you are interested in
                        joining our network and effort, please contact us by filling out our contact form.</p>
                    </>
                </Accordion.Collapse>
            </div>
            <div className="mt-5">
                <div className="callejeritos_accordion_header">
                    <TextToggler eventKey="1" text="Social media" />
                </div>
                <Accordion.Collapse eventKey="1">
                    <>
                        <p className="text">Through our social media we will upload videos and images of our day to day in <b>#CallejeritosMexico</b>.</p>
                    </>
                </Accordion.Collapse>
            </div>
        </Accordion>
    }</div>);
}