import React from 'react';
import { equipoImages } from '../../helpers/callejeritosImages';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';

export const Alianza = () => {

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };

    const CustomRight = ({ onClick }: any) => (
        <button className="arrow right" onClick={onClick}>
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            {/* <span className="visually-hidden">Siguiente</span> */}
        </button>
    );

    const CustomLeft = ({ onClick }: any) => (
        <button className="arrow left" onClick={onClick}>
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            {/* <span className="visually-hidden">Anterior</span> */}
        </button>
    );

    const { languaje } = useContext(UserContext);

    return (
        <div className="div-alianza animate__animated animate__fadeIn">
            {
                languaje === 'ES' ?
                <>
                    <div className="header-alianza">
                        <img src={ equipoImages('./Alianza/titulo.png').default } alt="Alianzas estratégicas" className="img-fluid title-alianza"/>
                    </div>
                    <div className="body-alianza animate__animated animate__fadeInUp">
                        <p className="text">Ellos forman parte de nuestra red de aliados estratégicos en la concientización,
                        promoción, comunicación y ejecución de nuestras diferentes actividades relacionadas con la causa.</p>
                        <p className="text"><b>Agradecemos a todos y cada uno de ellos por su tiempo y por sumarse al proyecto</b></p>
                    </div>
                </>
                :
                <>
                    <div className="header-alianza">
                        <img src={ equipoImages('./Alianza/tituloEN.png').default } alt="STRATEGIC ALLIANCES" className="img-fluid title-alianzaEN"/>
                    </div>
                    <div className="body-alianza animate__animated animate__fadeInUp">
                        <p className="text">They are part of our network of strategic allies in raising awareness, promoting, communicating and
                        executing our different activities related to the cause.</p>
                        <p className="text"><b>We thank each and every one of them for their time and for joining the project</b></p>
                    </div>
                </>
            }
            <div className="container-fluid">
                
                {/* @ts-ignore */}
                <Carousel responsive={responsive} infinite={true} arrows={true} customRightArrow={<CustomRight />} customLeftArrow={<CustomLeft />}>
                    <div className="pat-item">
                        <a href="https://restforpets.com.mx/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/rest_for_pets.png').default } alt="Rest for pets" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="https://www.facebook.com/llantek.vulcanizadora/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/llantek.png').default } alt="Vulcanizadora llantek" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="https://www.instagram.com/mo_artmx/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/mo_art.png').default } alt="Mo Art" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="https://www.expedienteazul.com/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/expediente_azul.png').default } alt="Expediete Azul" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="https://www.blackcoffeegallery.com.mx/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/blackcoffe.png').default } alt="BlackCoffee Gallery" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="http://www.stx.com.mx/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/stx.png').default } alt="STX" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="https://motidigital.com/index.html" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/moti.png').default } alt="Moti" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="https://hibarra.com/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/hibarra.jpg').default } alt="Hiram Ibarra" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="http://pieinthesky.com.mx/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/pieinthesky.jpg').default } alt="PieInTheSky" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="https://www.instagram.com/plantbasedsupperclub/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/SuperPlantBasedClub.jpg').default } alt="SuperPlantBasedClub" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        {/* <a href="https://www.reservanao.com/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/nao.png').default } alt="Reserva Nao" className="img-fluid"/>
                        </a> */}
                        <a href="https://www.petco.com.mx/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/petco.png').default } alt="petco" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="https://granadata.com/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/granadata.png').default } alt="Reserva Nao" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="https://www.wework.com/l/office-space/mexico" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/wework.png').default } alt="Reserva Nao" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="https://atletasmx.com/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/atletasmx.png').default } alt="AtletasMX" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="http://www.cambiaree.com/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/cambiaree.jpg').default } alt="AtletasMX" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="https://navidalia.mx/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/Navidalia_Logo.png').default } alt="Navidalia" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="https://www.calaverandia.mx/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/Calaverandia_Logo.png').default } alt="Calaverandia" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="https://www.alteacorp.com/es/" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/Altea_Logo.png').default } alt="Altea Corp" className="img-fluid"/>
                        </a>
                    </div>
                    <div className="pat-item">
                        <a href="https://www.nativaalimento.com" target="_blank" rel="noreferrer">
                            <img src={ equipoImages('./Patrocinadores/Nativa_Logo.png').default } alt="Nativa Alimento" className="img-fluid"/>
                        </a>
                    </div>
                </Carousel>
            </div>
        </div>
    )
}
