import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { equipoImages } from '../../helpers/callejeritosImages';

export const ExpedienteAzulFooter = () => {

    const { languaje } = useContext(UserContext);

    return (
        <>
            <p className='mt-2'>
                { languaje === 'ES'?
                    'Expedientes de mascotas integrados, administrados y en seguimiento gracias a:':
                    'Pet files integrated, managed and tracked thanks to:' }
            </p>
            <a href="https://www.expedienteazul.com/" target="_blank" rel="noreferrer">
                <img src={ equipoImages('./Patrocinadores/expediente_azul.png').default } alt="Expediete Azul" className="img-fluid"/>
            </a>
        </>
    )
}
