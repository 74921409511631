import React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { contactoImages } from '../../helpers/callejeritosImages';
import { UserContext } from '../../context/UserContext';
import { TextToggler } from '../../components/ui/TextToggler';
import { DownloadButton } from '../../components/DownloadButton';

interface Props {
    lang: string;
}

export const Informate = () => {

    const { languaje } = useContext(UserContext);

    return (
        <div className="div-informate animate__animated animate__fadeIn">
            <div className="header-informate">
                <img src={contactoImages(`./Informate/titulo${languaje}.png`).default} alt="INFORMATE"
                    className={ 'img-fluid title-informate' + languaje } />
            </div>
            <div className="body-informate animate__animated animate__fadeInUp">
                <DesktopText lang={ languaje } />
                <MobileText lang={ languaje } />
                <div className="d-flex justify-content-center">
                    <Link to="/donaciones" className="btn btn-callejeritos mb-3" type="button">
                        { languaje === 'ES' ? 'Dona una esterilización' : 'Donate a Sterilization' }
                    </Link>
                </div>
            </div>
        </div>
    )
}

const DesktopText = ({ lang }: Props) => {
    return (<div className="d-none d-lg-block">{
        lang === 'ES' ?
        <>
            <p className="title-gray">Abandono</p>
            <p className="text">El abandono de perros y gatos es una mala práctica por gente que adquiere mascotas o incluso las abandona después de
            haber sido comprada o adoptada. Esto es un problema de seguridad, higiene, conciencia social, falta de empatía. Algunos gobiernos federales
            y/o estatales alrededor del mundo empiezan a despertar, entender y tomar acciones con políticas públicas y leyes dónde se prohíbe la crianza,
            promoción e incluso venta de animales. Denuncias ciudadanas que pueden incurrir en multas y/o encarcelamiento por descuido, omisiones, maltrato,
            abandono y/o muerte de alguna mascota. Muchas de ellas pueden sobrevivir y darse una vida más digna en la calle que incluso en algunos hogares.</p>
            <p className="title-gray">Problemática, retos, efectos positivos y beneficios</p>
            <p className="text">En México así como en ningún país donde esto es una problemática y realidad, no existe un censo oficial, no se obtiene, mide y
            da seguimiento a las cifras, no se cuenta un programa formal y oficial para registrar mascotas por parte de ninguna dependencia u organización
            estatal o federal a diferencia de otros países como Estados Unidos, Canadá o Europa. No hay una fuente real y confiable de datos reales de mascotas
            en la calle, sea por compra y abandono o por reproducción masiva de los que ya se encuentran en situación de calle desde que nacen. Se estima se
            habla de millones de perros.</p>
            <PresentationSection lang={ lang } />
            <p className="title-gray">Esterilización</p>
            <p className="text">Nuestra campaña de esterilización ayudará a disminuir la población en las calles, la reproducción dentro de los hogares.</p>
        </>
        :
        <>
            <p className="title-gray">Abandonment</p>
            <p className="text">The abandonment of dogs and cats is a terrible practice in which people who acquire pets decide to no longer look after
             them. This is a problem of safety, hygiene, social conscience and lack of empathy. Some federal and / or state governments around the world
             are beginning to awaken, understand and take action with public policies and laws where the breeding, promotion and even sale of animals is
             prohibited. Citizen complaints that may incur fines and / or imprisonment for neglect, omissions, mistreatment, abandonment and / or death
             of a pet. Many of them can survive and lead a more dignified life on the street than even in some homes.</p>
            <p className="title-gray">Issues, challenges, positive effects and benefits</p>
            <p className="text">In Mexico, as well as in other countries where this is a problem and reality there is no official census, the figures are not
            obtained, measured and monitored, there is no formal and official program to register pets by any agency, state or federal organization unlike
            other countries such as the United States, Canada or Europe. There is no real and reliable source of data on pets on the streets, either by
            purchase and abandonment or by mass reproduction of those that are already on the street since they are born. It is estimated that there are
            millions of dogs.</p>
            <PresentationSection lang={ lang } />
            <p className="title-gray">Sterilization</p>
            <p className="text">Our sterilization campaign will help reduce the population in the streets, reproduction within homes.</p>
        </>
    }</div>);
}


const MobileText = ({ lang }: Props) => {
    return (<div className="d-block d-lg-none">{
        lang === 'ES' ?
        <Accordion defaultActiveKey="0">
            <div>
                <div className="callejeritos_accordion_header">
                    <TextToggler eventKey="0" text="Abandono" />
                </div>
                <Accordion.Collapse eventKey="0">
                    <>
                        <p className="text">El abandono de perros y gatos es una mala práctica por gente que adquiere mascotas o incluso las abandona después de
                        haber sido comprada o adoptada. Esto es un problema de seguridad, higiene, conciencia social, falta de empatía. Algunos gobiernos federales
                        y/o estatales alrededor del mundo empiezan a despertar, entender y tomar acciones con políticas públicas y leyes dónde se prohíbe la crianza,
                        promoción e incluso venta de animales. Denuncias ciudadanas que pueden incurrir en multas y/o encarcelamiento por descuido, omisiones, maltrato,
                        abandono y/o muerte de alguna mascota. Muchas de ellas pueden sobrevivir y darse una vida más digna en la calle que incluso en algunos hogares.</p>
                    </>
                </Accordion.Collapse>
            </div>
            <div className="mt-5">
                <div className="callejeritos_accordion_header">
                    <TextToggler eventKey="1" text="Problemática, retos, efectos positivos y beneficios" />
                </div>
                <Accordion.Collapse eventKey="1">
                    <>
                        <p className="text">En México así como en ningún país donde esto es una problemática y realidad, no existe un censo oficial, no se obtiene, mide y
                        da seguimiento a las cifras, no se cuenta un programa formal y oficial para registrar mascotas por parte de ninguna dependencia u organización
                        estatal o federal a diferencia de otros países como Estados Unidos, Canadá o Europa. No hay una fuente real y confiable de datos reales de mascotas
                        en la calle, sea por compra y abandono o por reproducción masiva de los que ya se encuentran en situación de calle desde que nacen. Se estima se
                        habla de millones de perros.</p>
                        <PresentationSection lang={ lang } />
                    </>
                </Accordion.Collapse>
            </div>
            <div className="mt-5">
                <div className="callejeritos_accordion_header">
                    <TextToggler eventKey="2" text="Esterilización" />
                </div>
                <Accordion.Collapse eventKey="2">
                    <>
                        <p className="text">Nuestra campaña de esterilización ayudará a disminuir la población en las calles, la reproducción dentro de los hogares.</p>
                    </>
                </Accordion.Collapse>
            </div>
        </Accordion>
        :
        <Accordion defaultActiveKey="0">
            <div>
                <div className="callejeritos_accordion_header">
                    <TextToggler eventKey="0" text="Abandonment" />
                </div>
                <Accordion.Collapse eventKey="0">
                    <>
                        <p className="text">The abandonment of dogs and cats is a terrible practice in which people who acquire pets decide to no longer look after
                         them. This is a problem of safety, hygiene, social conscience and lack of empathy. Some federal and / or state governments around the world
                         are beginning to awaken, understand and take action with public policies and laws where the breeding, promotion and even sale of animals is
                         prohibited. Citizen complaints that may incur fines and / or imprisonment for neglect, omissions, mistreatment, abandonment and / or death
                         of a pet. Many of them can survive and lead a more dignified life on the street than even in some homes.</p>
                    </>
                </Accordion.Collapse>
            </div>
            <div className="mt-5">
                <div className="callejeritos_accordion_header">
                    <TextToggler eventKey="1" text="Issues, challenges, positive effects and benefits" />
                </div>
                <Accordion.Collapse eventKey="1">
                    <>
                        <p className="text">In Mexico, as well as in other countries where this is a problem and reality there is no official census, the figures are not
                        obtained, measured and monitored, there is no formal and official program to register pets by any agency, state or federal organization unlike
                        other countries such as the United States, Canada or Europe. There is no real and reliable source of data on pets on the streets, either by
                        purchase and abandonment or by mass reproduction of those that are already on the street since they are born. It is estimated that there are
                        millions of dogs.</p>
                        <PresentationSection lang={ lang } />
                    </>
                </Accordion.Collapse>
            </div>
            <div className="mt-5">
                <div className="callejeritos_accordion_header">
                    <TextToggler eventKey="2" text="Sterilization" />
                </div>
                <Accordion.Collapse eventKey="2">
                    <>
                        <p className="text">Our sterilization campaign will help reduce the population in the streets, reproduction within homes.</p>
                    </>
                </Accordion.Collapse>
            </div>
        </Accordion>
    }</div>);
}

const PresentationSection = ({ lang }: Props) => {

    return (<div className="recusos-files presentation_section">
            { lang === 'ES'
            ? <>
                <DownloadButton file="presentacion_retos_efectos_positivos.pdf" text="Descargar Presentación"/>
            </>
            : <>
                <DownloadButton file="presentacion_retos_efectos_positivos.pdf" text="Download Presentation"/>
            </>}
        </div>
    )

}