import React, { useContext, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { ActualizarForm } from '../../components/Usuario/ActualizarForm';
import { SubscripcionDetalles } from '../../components/Usuario/SubscripcionDetalles';
import { AuthContext } from '../../context/AuthContext';

export const MiPerfil = () => {
    debugger

    const [key, setKey] = useState('data');

    const { user } = useContext(AuthContext);


    return (
        <div className="div-perfil">
            <div className="body-perfil">
                <Tabs id="mi-perfil-tabs" activeKey={key} onSelect={(k) => k ? setKey(k) : undefined} className="mb-1">
                    <Tab eventKey="data" title="Mi perfil">
                        <ActualizarForm />
                    </Tab>
                    <Tab eventKey="exp" title="Expediente" disabled>
                        <h1>Mi expediente</h1>
                    </Tab>

                    {user?.subscription_id &&

                        <Tab eventKey="subsciption" title="Donacion Recurrente">
                            <SubscripcionDetalles setKey={setKey} />
                        </Tab>
                    }
                </Tabs>
            </div>
        </div>
    )
}
