import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import Swal from 'sweetalert2';

import { AuthContext, UserContext } from '../../context';
import { OxxopayConfirm, PaypalCheckout } from '../Pagos';
import StripeCardPayment from '../Pagos/StripeCardPayment';

import { IsLoading } from '../ui/IsLoading';

import { OxxopayResp } from '../../interfaces/servicesInterfaces';
import { nosotrosImages } from '../../helpers/callejeritosImages';

import kw2pApiPublic from '../../api/kw2pPublic';
import Logotipo from '../../logotipo.svg';
import { Producto } from '../../interfaces/donativosInterfaces';

interface Props {
    tipo: string;
    productos?: Producto[]
}

export const DonacionForm = ({ tipo, productos = [] }: Props) => {

    const { user } = useContext(AuthContext);
    const { languaje } = useContext(UserContext);
    const history = useHistory();

    const [step, setStep] = useState(1);

    const { formState: { errors }, handleSubmit, register, reset } = useForm<FormValues>();

    const [loading, setLoading] = useState(false);
    const [oxxoData, setOxxoData] = useState({ monto: 0, referencia: '' });

    const [{ cantidad, impuestos, anonima, producto_id }, setDonacionData] = useState<FormValues>({
        anonima: 'false',
        cantidad: 0,
        impuestos: 'false',
        producto_id: undefined
    });


    const resetStep = () => {
        setStep(1);
        reset();
        setLoading(false);
        setOxxoData({ monto: 0, referencia: '' });
    };

    const setLoad = (stat: boolean) => {
        setLoading(stat);
    };

    const handleOxxoPayment = async () => {
        try {

            if (!user) {
                return;
            }

            setLoading(true);

            const payload = {
                name: user.nombre,
                email: user.correo,
                phone: user.telefono?.replace(/[^0-9.]/g, ''),
                monto: cantidad,
                impuestos: impuestos === 'true' ? true : false,
                tipo,
                anonima: anonima === 'true' ? true : false,
                producto_id
            };

            const { data: { data } } = await kw2pApiPublic.post<OxxopayResp>('/donaciones:crear_oxxo_pay', payload);

            const { payment_method: { reference }, amount } = data;

            setOxxoData({
                monto: (amount / 100),
                referencia: reference
            });

        } catch (err: any) {

            setLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Lo sentimos ocurrio un error...',
                text: JSON.stringify(err.response.data.errors, null, 2).replace(/[{}]/g, '')
            });
        }
    }

    const onSubmit: SubmitHandler<FormValues> = async (FormData) => {

        const { impuestos, anonima, cantidad, producto_id} = FormData;

        if (FormData.impuestos) {
            if (!user?.razon_social || !user?.rfc) {
                Swal.fire({
                    title: '¡Espera!',
                    text: 'Detectamos que aún no completas tus datos de facturación',
                    footer: 'Seras redireccionado para completar tu perfil',
                    imageUrl: Logotipo,
                    imageHeight: 70,
                    imageWidth: 325
                }).then(() => {
                    history.push('/mi-perfil');
                });
            }
        }

        setDonacionData({
            anonima : anonima.toString(),
            impuestos: impuestos.toString(),
            cantidad: Number(cantidad),
            producto_id
        });

        setStep(2);
    };

    return (
        <div className="donaciones-formdiv mb-3">
            <form onSubmit={handleSubmit(onSubmit)} className="form__donaciones">
                {
                    tipo === 'otros' ?
                        <div className='form-group'>

                            <label htmlFor="producto_id" className='mt-lg-4'>{languaje === 'ES' ? 'Concepto:' : 'Concept:'}</label>
                            <select id="producto_id" className="form-control"
                                {...register('producto_id', { required: languaje === 'ES' ? 'Elije una opción' : 'Choose one option' })}
                                defaultValue={0}>
                                {productos.map((producto) => (<option key={producto.id} value={producto.id}>{producto.payload.nombre}</option>))}
                            </select>
                            {errors.producto_id && <small className="text-danger">{errors.producto_id.message}</small>}
                        </div>
                        :
                        undefined
                }
                <div className="form-group">
                    <label htmlFor="nombre">{languaje === 'ES' ? 'Monto a donar:' : 'Donation amount:'}</label>
                    <input
                        id="cantidad"
                        type="number"
                        className="form-control"
                        placeholder={languaje === 'ES' ? 'Ingrese el monto a donar:' : `Enter the donation amount`}
                        autoComplete="off"
                        {...register('cantidad', {
                            required: languaje === 'ES' ? 'Ingrese la cantidad a donar' : 'Enter the donation amount',
                            min: { value: 100, message: languaje === 'ES' ? 'El valor mínimo admitido son $100.00 MXN' : 'The minimum value admitted is $100.00 MXN' }
                        })}
                        defaultValue={0}
                    />
                    {errors.cantidad && <small className="text-danger">{errors.cantidad.message}</small>}
                </div>

                <div className="form-group form-check">
                    <label className="chk_custom_container">{languaje === 'ES' ? 'Requiero factura de mi donativo' : 'I need an invoice for my donation'}
                        <input
                            id="impuestos"
                            type="checkbox"
                            disabled={step !== 1}
                            {...register('impuestos')}
                            className={`form-check-input`}
                        />
                        <span className="checkmark"></span>
                    </label>
                    <div className="invalid-feedback">{errors.impuestos?.message}</div>
                </div>

                <div className="form-group form-check">
                    <label className="chk_custom_container">{languaje === 'ES' ? 'Deseo mantener mi donativo anónimo' : 'I want to keep my donations Anonymous' }
                        <input
                            id="anonima"
                            type="checkbox"
                            disabled={step !== 1}
                            {...register('anonima')}
                            className={`form-check-input`}
                        />
                        <span className="checkmark"></span>
                    </label>
                    <div className="invalid-feedback">{errors.anonima?.message}</div>
                </div>

                {step === 1 &&
                    <button className="btn btn-callejeritos w-50" type="submit">
                        {languaje === 'ES' ? 'Continuar' : 'Continue'}
                    </button>
                }
            </form>

            
            {step === 2 &&
                <div className="row mt-5">
                    <div className="col-12">
                        <StripeCardPayment
                            tipo={tipo}
                            impuestos={impuestos === 'true' ? true : false}
                            cantidad={cantidad}
                            anonima={anonima === 'true' ? true : false}
                            resetStep={resetStep}
                            setLoad={setLoad}
                            producto_id={ producto_id }
                        />
                    </div>
                    <div className="col-12 col-md-6 mt-2 d-flex align-items-center">
                        <PaypalCheckout
                            tipo={tipo}
                            impuestos={impuestos === 'true' ? true : false}
                            cantidad={cantidad}
                            anonima={anonima === 'true' ? true : false}
                            resetStep={resetStep}
                            setLoad={setLoad}
                            producto_id={ producto_id }
                        />
                    </div>
                    <div className="col-12 col-md-6 pago_col">
                        <img src={nosotrosImages('./Donaciones/oxxopay_brand.png').default} alt="OXXO PAY" className="pago_type_img img-fluid"
                            onClick={handleOxxoPayment} />
                    </div>
                    <div className="col-12 text-center">
                        {!loading
                            ? (<button className="btn btn-callejeritos mt-4" type="button" onClick={() => { setStep(1) }}>
                                {languaje === 'ES' ? 'Editar monto' : 'Edit amount'}
                            </button>)
                            : <IsLoading />
                        }
                    </div>
                </div>
            }
            {oxxoData.referencia !== '' && <OxxopayConfirm monto={oxxoData.monto} referencia={oxxoData.referencia} resetStep={resetStep} />}
        </div>)
}

interface FormValues {
    cantidad: number;
    anonima: string;
    impuestos: string;
    producto_id?: number;
};